<template>
  <v-app :style="colorVapp">
    <div>
      <!-- @advancesearch="fn_advancesearch" -->
      <onebox_toolbar
        :sendswitchapi="switch_api_upload"
        :aspectSearch="aspectData"
        :quicksearch="true"
        :parentfolder="parentfolder"
        :departmentid="departmentid"
        :permissionfolder="permission_currentfolder"
        :heardertextsearch="heardertextsearch"
        @countoffset="fn_newsearch()"
        @search_advanced_new="fn_advancesearch_new"
        @inputdata="filteredItems"
        @loadfile="fn_loadfileandfolder_scrolling_child(),fn_reload()"
        @loadfile_reload="default_func()"
        @callparentfolder="sendParentfolder()"
        @callstorage="loadstorage()"
        @closeDrag="removeEvent()"
        @openDrag="addEvent()"
        @callsmartuploadsearch="callsmartuploadsearch"
        @cloasedialogsmartsearch="backfromsearch"
        @open_file_office="fn_open_file_office"
        @open_folder="fn_open_folder"
      ></onebox_toolbar>
      <!-- Floating Button -->
      <div v-if="resolutionScreen < 500">
        <floatingbutton
          :departmentid="departmentid"
          @loadfile="loadstorage(), fn_loadfileandfolder_scrolling_child()"
        />
      </div>
      <v-content>
        <!-- <v-overlay :value="processloader" z-index="2" color="black" dark>
          <v-progress-circular indeterminate size="64" color="white"></v-progress-circular>
          <span :style="colorProgress">&nbsp; loading</span>
        </v-overlay> -->
        <!-- Add loaderprocress -->
      <v-overlay :value="processloader" absolute :color="color.BG" dark>
        <v-progress-circular
          indeterminate
          size="64"
          :color="color.theme"></v-progress-circular>
        <br />
        <br />
        <!-- <span :style="colorProgress_biz">&nbsp; &nbsp; loading</span> -->
      </v-overlay>
      <!-- /// -->
        <v-overlay :value="processloader_Sync" z-index="2" color="black" dark>
        <!-- <v-progress-circular indeterminate size="64" color="white"></v-progress-circular> -->
        <span :style="colorProgress">&nbsp; กำลัง Sync ข้อมูล...</span>
      </v-overlay>
        <v-overlay :value="loaddataprogress_print" z-index="3" color="black" dark>
          <v-progress-circular indeterminate size="64" color="white">
            <span :style="colorProgress">&nbsp; loading</span>
          </v-progress-circular>
        </v-overlay>
        <v-overlay :value="processloader_ocr" z-index="2" color="black" dark>
          <v-progress-circular indeterminate size="64" color="white">
            <span :style="colorProgress">loading</span>
          </v-progress-circular>
        </v-overlay>
        <v-card class="elevation-0">
          <!-- Header Page lg -->
          <v-list nav :color="color.BG" v-if="resolutionScreen >= 500">
            <v-list-item class="text-left" v-if="heardertextsearch === true || smartuplloadsearch === true">
              <v-btn
              @mouseover="show_back = true"
              @mouseleave="show_back = false"
              fab 
              small 
              :color="color.theme" 
              @click="backfromsearch(),show_back = false, statusmutipledownload = false, loaddataprogress = true" 
              class="ma-2" 
              >
                <v-icon color="white">keyboard_backspace</v-icon>
                <v-badge v-if="$t('default') === 'th'"
                      style="z-index:10000;bottom:-45px;left:-35px;"
                      :value="show_back"
                      :content="$t('balloon.back')"
                      transition="slide-y-transition"
                    ></v-badge>
                  <v-badge v-else
                      style="z-index:10000;bottom:-45px;left:-27px;"
                      :value="show_back"
                      :content="$t('balloon.back')"
                      transition="slide-y-transition"
                    ></v-badge>
              </v-btn>
              <v-list-item-avatar class="text-center">
                <v-avatar :color="color.theme" size="35">
                  <v-icon dark size="20">mdi-magnify</v-icon>
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <span :style="headerPage">&nbsp;{{ $t("tablefile.searchtext") }}</span>
                </v-list-item-title>
                <!-- <v-list-item-subtitle>
                 {{ $vuetify.breakpoint.name }}
              </v-list-item-subtitle> -->
              </v-list-item-content>
              <v-list-item-action class="pa-0 text-center">
                <v-layout>
                  <v-btn
                    @mouseover="show_grid = true"
                    @mouseleave="show_grid = false"
                    @click="resetFile(), changepage(), changeLocalStorage('false')"
                    :text="!changeUI"
                    fab
                    small
                    v-if="resolutionScreen < 500 ? false : true"
                  >
                    <v-icon :color="color.theme">mdi-view-grid-outline</v-icon>
                    <v-badge v-if="$t('default') === 'th'"
                          style="z-index:10000;bottom:-45px;left:-60px;"
                          :value="show_grid"
                          :content="$t('balloon.grid')"
                          transition="slide-y-transition"
                        ></v-badge>
                      <v-badge v-else
                          style="z-index:10000;bottom:-45px;left:-30px;"
                          :value="show_grid"
                          :content="$t('balloon.grid')"
                          transition="slide-y-transition"
                        ></v-badge>
                  </v-btn>
                  <v-btn
                    @mouseover="show_list = true"
                    @mouseleave="show_list = false"
                    @click="resetFile(), changepage(), changeLocalStorage('true')"
                    :text="changeUI"
                    fab
                    small
                    v-if="resolutionScreen < 500 ? false : true"
                  >
                    <v-icon :color="color.theme">mdi-view-list</v-icon>
                    <v-badge v-if="$t('default') === 'th'"
                          style="z-index:10000;bottom:-45px;left:-75px;"
                          :value="show_list"
                          :content="$t('balloon.list')"
                          transition="slide-y-transition"
                        ></v-badge>
                      <v-badge v-else
                          style="z-index:10000;bottom:-45px;left:-30px;"
                          :value="show_list"
                          :content="$t('balloon.list')"
                          transition="slide-y-transition"
                        ></v-badge>
                  </v-btn>
                </v-layout>
                <v-layout v-if="heardertextsearch === true">
                  <v-chip class="mt-4 white--text" :color="color.theme" >{{ $t("count_data_Advasearch") }} : {{ count_data_adv_ }}</v-chip>
                </v-layout>
                <v-layout v-else-if="smartuplloadsearch === true">
                  <v-chip class="mt-4 white--text" :color="color.theme" >{{ $t("count_data_Advasearch") }} : {{ count_data_smup_ }}</v-chip>
                </v-layout>
              </v-list-item-action>              
            </v-list-item>
            <v-list-item class="text-left" v-else>
              <v-btn
              @mouseover="show_back = true"
              @mouseleave="show_back = false"
              fab 
              small 
              :color="color.theme" 
              @click="backtopreventfolder(),show_back = false, statusmutipledownload = false" 
              class="ma-2" 
              v-if="this.navigate.length > 1">
                <v-icon color="white">keyboard_backspace</v-icon>
                <v-badge v-if="$t('default') === 'th'"
                      style="z-index:10000;bottom:-45px;left:-35px;"
                      :value="show_back"
                      :content="$t('balloon.back')"
                      transition="slide-y-transition"
                    ></v-badge>
                  <v-badge v-else
                      style="z-index:10000;bottom:-45px;left:-27px;"
                      :value="show_back"
                      :content="$t('balloon.back')"
                      transition="slide-y-transition"
                    ></v-badge>
              </v-btn>
              <v-list-item-avatar class="text-center">
                <v-avatar :color="color.theme" size="35">
                  <v-icon dark size="20">folder</v-icon>
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-title class="pt-1 pb-1">
                <v-breadcrumbs :items="navigate" divider=">" class="pa-0">
                  <template v-slot:item="props">
                    <v-btn rounded text>
                    <v-breadcrumbs-item 
                      style="cursor: pointer;"
                      @click.stop="gotofolder(props.item.text[1])"
                      :disabled="loaddataprogress"
                    > 
                      <span v-if="props.item.text[2]" :style="headerPage">
                        <span v-if="$t('default') === 'en'" :style="headerPage">
                          {{ props.item.text[0] }}
                        </span>
                        <span v-else-if="$t('default') === 'th'" :style="headerPage">
                          {{ props.item.text[2] }}
                        </span>
                      </span>
                      <span v-else :style="headerPage">
                        {{ props.item.text[0] }}
                      </span>
                    </v-breadcrumbs-item>
                    </v-btn>
                  </template>
                </v-breadcrumbs>
              </v-list-item-title>
              
              
              <!-- <v-list-item-content>
                <v-list-item-subtitle>
                 {{ $vuetify.breakpoint.name }}
                </v-list-item-subtitle>
              </v-list-item-content> -->
              <v-list-item-action class="pa-0 text-center">
                <v-layout>
                  <v-flex xs7 lg7 class="text-right" v-if="resolutionScreen < 500 ? false : true"> 
                  <v-chip class="ma-2" :color="color.theme" :text-color="color.chipText">
                          <v-icon left>folder</v-icon>
                          {{ totaldata["totalfolder"] }} {{ totaldata["totalfolder"] > 1 ? $t("folders") : $t("folder") }}
                  </v-chip>
                  <v-chip class="ma-2" :color="color.theme" :text-color="color.chipText">
                          <v-icon left>mdi-file</v-icon>
                          {{ totaldata["totalfile"] }} {{ totaldata["totalfile"] > 1 ? $t("files") : $t("file") }}
                  </v-chip>
                  <!-- <v-btn 
                  @mouseover="show_back = true"
                  @mouseleave="show_back = false"
                  fab 
                  small 
                  @click="backtopreventfolder(),show_back = false, statusmutipledownload = false" 
                  class="ma-2" 
                  v-if="this.navigate.length > 1">
                    <v-icon :color="color.theme">keyboard_backspace</v-icon>
                    <v-badge v-if="$t('default') === 'th'"
                          style="z-index:10000;bottom:-45px;left:-35px;"
                          :value="show_back"
                          :content="$t('balloon.back')"
                          transition="slide-y-transition"
                        ></v-badge>
                      <v-badge v-else
                          style="z-index:10000;bottom:-45px;left:-27px;"
                          :value="show_back"
                          :content="$t('balloon.back')"
                          transition="slide-y-transition"
                        ></v-badge>
                  </v-btn> -->
                  <v-btn
                    @mouseover="show_grid = true"
                    @mouseleave="show_grid = false"
                    @click="resetFile(), changepage(), changeLocalStorage('false'),statusmutipledownload = false"
                    :text="!changeUI"
                    fab
                    small
                    v-if="resolutionScreen < 500 ? false : true"
                  >
                    <v-icon :color="color.theme">mdi-view-grid-outline</v-icon>
                    <v-badge v-if="$t('default') === 'th'"
                          style="z-index:10000;bottom:-45px;left:-60px;"
                          :value="show_grid"
                          :content="$t('balloon.grid')"
                          transition="slide-y-transition"
                        ></v-badge>
                      <v-badge v-else
                          style="z-index:10000;bottom:-45px;left:-27px;"
                          :value="show_grid"
                          :content="$t('balloon.grid')"
                          transition="slide-y-transition"
                        ></v-badge>
                  </v-btn>
                  <v-btn
                    @mouseover="show_list = true"
                    @mouseleave="show_list = false"
                    @click="resetFile(), changepage(), changeLocalStorage('true')"
                    :text="changeUI"
                    fab
                    small
                    v-if="resolutionScreen < 500 ? false : true"
                  >
                    <v-icon :color="color.theme">mdi-view-list</v-icon>
                    <v-badge v-if="$t('default') === 'th'"
                          style="z-index:10000;bottom:-45px;left:-75px;"
                          :value="show_list"
                          :content="$t('balloon.list')"
                          transition="slide-y-transition"
                        ></v-badge>
                      <v-badge v-else
                          style="z-index:10000;bottom:-45px;left:-25px;"
                          :value="show_list"
                          :content="$t('balloon.list')"
                          transition="slide-y-transition"
                        ></v-badge>
                  </v-btn>
                  </v-flex>
                </v-layout>
              
                  <!-- Sort File ใหม่-->
              <v-layout 
                row 
                wrap 
                class="pl-3 pr-0" 
                v-if="resolutionScreen >= 500"
                >
                    <v-list nav :color="color.BG" class="py-0">
                      <v-list-item>
                        <v-layout>                
                          <v-flex class="text-right" lg12>
                            <v-menu offset-y rounded="lg">
                              <template v-slot:activator="{ attrs, on }">
                                <v-btn small rounded
                                  class="white--text"
                                  :color="color.theme" 
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                <v-icon small>mdi-sort</v-icon>
                                  {{ $t("sorting.sort") }}: 
                                  <div class="font-weight-bold">
                                    {{ $t(sorting['sort'].find(x => x.id === sort_by).text) }}, {{ $t(sorting['direction'].find(x => x.id === direction).text) }}
                                  </div>
                                </v-btn>
                              </template>
                              <v-list dense nav>
                                <v-list-item-group
                                  v-model="sort_by"
                                  active-class="border"
                                  :color="color.theme"
                                  mandatory
                                >
                                  <template v-for="(item, i) in sorting['sort']">
                                    <v-list-item
                                      :key="`item-${i}`"
                                      :value="item.id"
                                      @click="sort_by === item.id ? reloadSortByAndDirection() : ''"
                                    >
                                      <template>
                                        <v-list-item-content>
                                          <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
                                        </v-list-item-content>
                                      </template>
                                    </v-list-item>
                                  </template>
                                </v-list-item-group>
                                <v-divider class="mt-2" />
                                <v-list-item-group
                                  class="mt-4"
                                  v-model="direction"
                                  active-class="border"
                                  :color="color.theme"
                                  mandatory
                                >
                                  <template v-for="(item, i) in sorting['direction']">
                                    <v-list-item
                                      :key="`item-${i}`"
                                      :value="item.id"
                                      @click="direction === item.id ? reloadSortByAndDirection() : ''"
                                    >
                                      <template>
                                        <v-list-item-content>
                                          <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
                                        </v-list-item-content>
                                      </template>
                                    </v-list-item>
                                  </template>
                                </v-list-item-group>
                              </v-list>
                            </v-menu>
                          </v-flex>
                        </v-layout>
                      </v-list-item>
                    </v-list>
                </v-layout>
                </v-list-item-action>
            </v-list-item>
            <!-- v-if="show_menumanage && changeUI === false" -->
            <v-list-item  
              v-if="show_menumanage && changeUI === false"
              style="overflow: visible; padding-right: 1.15rem;"  
              class="mt-2 mb-n2"
            >
            <v-layout wrap :class="animationlistmenu_v2" :style="headeranimationmenu" justify-space-between>
              <v-flex style="max-width: max-content;">
                <div :class="animationlistmenu">
                <v-btn
                  @mouseover="show_load = true"
                  @mouseleave="show_load = false"
                  fab
                  small
                  outlined
                  :color="color.theme"
                  class="ma-1 pa-0"
                  @click="multipledownload()"
                >
                  <v-icon>mdi-download-outline</v-icon>
                  <v-badge
                    v-if="$t('default') === 'th'"
                    style="z-index: 10000; bottom: -45px; left: -42px"
                    :value="show_load"
                    :content="$t('balloon.download')"
                    transition="slide-y-transition"
                  ></v-badge>
                  <v-badge
                    v-else
                    style="z-index: 10000; bottom: -45px; left: -45px"
                    :value="show_load"
                    :content="$t('balloon.download')"
                    transition="slide-y-transition"
                  ></v-badge>
                </v-btn>
                <v-btn
                  @mouseover="show_print = true"
                  @mouseleave="show_print = false"
                  fab
                  small
                  outlined
                  :color="color.theme"
                  class="ma-1 pa-0"
                  @click="fn_printPDF()"
                >
                  <v-icon>print</v-icon>
                  <v-badge
                    v-if="$t('default') === 'th'"
                    style="z-index: 10000; bottom: -45px; left: -40px"
                    :value="show_print"
                    :content="$t('balloon.print')"
                    transition="slide-y-transition"
                  ></v-badge>
                  <v-badge
                    v-else
                    style="z-index: 10000; bottom: -45px; left: -42px"
                    :value="show_print"
                    :content="$t('balloon.print')"
                    transition="slide-y-transition"
                  ></v-badge>
                </v-btn>
                <v-btn
                  outlined
                  :color="color.theme"
                  fab
                  small
                  class="ma-1 pa-0"
                  @click="setmultiupdate(filemultipledownload, 'S')"
                  @mouseover="show_star = true"
                  @mouseleave="show_star = false"
                >
                  <v-icon dark>star_border</v-icon>
                  <v-badge
                    v-if="$t('default') === 'th'"
                    style="z-index: 10000; bottom: -45px; left: -45px"
                    :value="show_star"
                    :content="$t('balloon.star')"
                    transition="slide-y-transition"
                  ></v-badge>
                  <v-badge
                    v-else
                    style="z-index: 10000; bottom: -45px; left: -43px"
                    :value="show_star"
                    :content="$t('balloon.star')"
                    transition="slide-y-transition"
                  ></v-badge>
                </v-btn>

                <v-btn
                  @mouseover="show_delete = true"
                  @mouseleave="show_delete = false"
                  outlined
                  fab
                  small
                  :color="color.theme"
                  class="ma-1 pa-0"
                  @click="multideletetotrash()"
                >
                  <v-icon>delete_outline</v-icon>
                  <v-badge
                    v-if="$t('default') === 'th'"
                    style="z-index: 10000; bottom: -45px; left: -22px"
                    :value="show_delete"
                    :content="$t('balloon.delete')"
                    transition="slide-y-transition"
                  ></v-badge>
                  <v-badge
                    v-else
                    style="z-index: 10000; bottom: -45px; left: -35px"
                    :value="show_delete"
                    :content="$t('balloon.delete')"
                    transition="slide-y-transition"
                  ></v-badge>
                </v-btn>
                <v-btn
                  @mouseover="show_move = true"
                  @mouseleave="show_move = false"
                  outlined
                  fab
                  small
                  class="ma-1 pa-0"
                  :color="color.theme"
                  @click="appsbarmultimovefile($event)"
                >
                  <v-icon>mdi-folder-swap-outline</v-icon>
                  <v-badge
                    v-if="$t('default') === 'th'"
                    style="z-index: 10000; bottom: -45px; left: -60px"
                    :value="show_move"
                    :content="$t('balloon.move')"
                    transition="slide-y-transition"
                  ></v-badge>
                  <v-badge
                    v-else
                    style="z-index: 10000; bottom: -45px; left: -65px"
                    :value="show_move"
                    :content="$t('balloon.move')"
                    transition="slide-y-transition"
                  ></v-badge>
                </v-btn>

                <v-btn
                  @mouseover="show_addhashtag = true"
                  @mouseleave="show_addhashtag = false"
                  outlined
                  fab
                  small
                  class="ma-1 pa-0"
                  :color="color.theme"
                  @click="apphashtag()"
                >
                  <v-icon>mdi-pen-plus</v-icon>
                  <v-badge
                    v-if="$t('default') === 'th'"
                    style="z-index: 10000; bottom: -45px; left: -45px"
                    :value="show_addhashtag"
                    :content="$t('balloon.addhashtag')"
                    transition="slide-y-transition"
                  ></v-badge>
                  <v-badge
                    v-else
                    style="z-index: 10000; bottom: -45px; left: -65px"
                    :value="show_addhashtag"
                    :content="$t('balloon.addhashtag')"
                    transition="slide-y-transition"
                  ></v-badge>
                </v-btn>
                <v-btn
                  @mouseover="show_clear = true"
                  @mouseleave="show_clear = false"
                  color="error"
                  outlined
                  fab
                  small
                  class="ma-1 pa-0"
                  @click="
                  (statusmutipledownload = false), 
                  (isCheckbox_sep_header = false),
                  clearmultipledownload(),
                  (show_menumanage=  false),
                  (close_header = true),
                  (show_clear = false)"
                >
                  <v-icon>clear</v-icon>
                   <v-badge v-if="$t('default') === 'th'"
                      style="z-index:10000;bottom:-45px;left:-20px;"
                      :value="show_clear"
                      :content="$t('balloon.clear')"
                      transition="slide-y-transition"
                    ></v-badge>
                    <v-badge v-else
                      style="z-index:10000;bottom:-45px;left:-30px;"
                      :value="show_clear"
                      :content="$t('balloon.clear')"
                      transition="slide-y-transition"
                    ></v-badge>
                </v-btn>
              </div>
              </v-flex>
              <v-flex style="max-width: min-content;">
                <v-select
                  prepend-inner-icon="sort"
                  hide-details
                  outlined
                  solo
                  dense
                  rounded
                  v-model="size"
                  :items="listsize"
                  class="text-center pt-2 pr-10"
                  @change="selectedLimit()"
                >
                  <template v-slot:item="{ item }">
                    <div class="text-center">
                      <span style="font-size: 17px;">{{ item }}</span>
                    </div>
                  </template>
                  <template v-slot:selection="{ item }">
                    <div class="text-center">
                      <span style="font-size: 17px;">{{ item }}</span>
                    </div>
                  </template>
                </v-select>
              </v-flex>
              <v-tabs  v-model="tabdepartment" outlined id="borderradius">
                <v-tab  @click="(tapbusiness = true), checktabs()" v-if="$t('default') === 'th'">ทั่วไป</v-tab>
                <v-tab  @click="(tapbusiness = false), checktabs()" v-if="dataAccountActive['type'] === 'Business' && $t('default') === 'th'"
                >แผนก</v-tab
                >
                <v-tab  @click="(tapbusiness = true), checktabs()" v-if="$t('default') === 'en'">Main</v-tab>
                <v-tab  @click="(tapbusiness = false), checktabs()" v-if="dataAccountActive['type'] === 'Business' && $t('default') === 'en'"
                >Department</v-tab
                >
                <!-- table Main -->
              <v-tab-item>
              <v-data-table
                :headers="headers"
                :hide-default-footer="true"
                class="custom-table"
                :style="headerdatatable"
                :items="[]"
                item-selected
                :show-select="statusmutipledownload"
              >
                <template v-slot:[`header.data-table-select`]="{ on }">
                <div>
                  <v-checkbox
                    hide-details
                    style="position: relative; bottom: 10px;"
                    v-model="checkbox_value"
                    :indeterminate="indeterminate_custom_checkbox"
                    v-on="on"
                    @change="Checkbox_custom_header"
                    color="primary"
                />
                </div>
                </template>
                <template v-slot:[`header.file_type`]="{ header }">
                  <span :style="headerTable">
                    {{ header.text }}
                  </span>
                </template>
                <template v-slot:[`header.file_name`]="{ header }">
                  <span
                    class="pointer"
                    @click="(multiSort = !multiSort), (sortby = 'name'), (clickSort = true), sortFile(rootfile)"
                    :style="headerTable"
                    >{{ $t(header.text) }}</span
                  >
                </template>
                <template v-slot:[`header.file_owner`]="{ header }">
                  <span
                    class="pointer"
                    @click="(multiSort = !multiSort), (sortby = 'owner'), (clickSort = true), sortFile(rootfile)"
                    :style="headerTable"
                    >{{ $t(header.text) }}</span
                  >
                </template>
                <template v-slot:[`header.file_size`]="{ header }">
                  <span
                    class="pointer"
                    @click="(multiSort = !multiSort), (sortby = 'size'), (clickSort = true), sortFile(rootfile)"
                    :style="headerTable"
                    >{{ $t(header.text) }}</span
                  >
                </template>
                <template v-slot:[`header.file_location`]="{ header }">
                  <span
                    class="pointer"
                    @click="(multiSort = !multiSort), (sortby = 'location'), (clickSort = true), sortFile(rootfile)"
                    :style="headerTable"
                    >{{ $t(header.text) }}</span
                  >
                </template>
                <template v-slot:[`header.file_lastdtm`]="{ header }">
                  <span
                    class="pointer"
                    @click="(multiSort = !multiSort), (sortby = 'date'), (clickSort = true), sortFile(rootfile)"
                    :style="headerTable"
                    >{{ $t(header.text) }}</span
                  >
                </template>
                 <template v-slot:[`header.file_status`]="{ header }">
                  <span :style="headerTable">
                    {{ $t(header.text) }}
                  </span>
                </template>
                <template slot="no-data">
                  
                </template>
              </v-data-table>
              </v-tab-item>
              <v-tab-item v-if="dataAccountActive['type'] === 'Business'">
                <v-data-table
                  :headers="headers"
                  :hide-default-footer="true"
                  class="custom-table"
                  :items="[]"
                  item-selected
                  :show-select="statusmutipledownload"
                >
                  <template v-slot:[`header.file_type`]="{ header }">
                    <span :style="headerTable">
                      {{ header.text }}
                    </span>
                  </template>
                  <template v-slot:[`header.file_name`]="{ header }">
                    <span
                      class="pointer"
                      @click="(multiSort = !multiSort), (sortby = 'name'), (clickSort = true), sortFile(rootfile)"
                      :style="headerTable"
                      >{{ $t(header.text) }}</span
                    >
                  </template>
                  <template v-slot:[`header.file_owner`]="{ header }">
                    <span
                      class="pointer"
                      @click="(multiSort = !multiSort), (sortby = 'owner'), (clickSort = true), sortFile(rootfile)"
                      :style="headerTable"
                      >{{ $t(header.text) }}</span
                    >
                  </template>
                  <template v-slot:[`header.file_size`]="{ header }">
                    <span
                      class="pointer"
                      @click="(multiSort = !multiSort), (sortby = 'size'), (clickSort = true), sortFile(rootfile)"
                      :style="headerTable"
                      >{{ $t(header.text) }}</span
                    >
                  </template>
                  <template v-slot:[`header.file_location`]="{ header }">
                    <span
                      class="pointer"
                      @click="(multiSort = !multiSort), (sortby = 'location'), (clickSort = true), sortFile(rootfile)"
                      :style="headerTable"
                      >{{ $t(header.text) }}</span
                    >
                  </template>
                  <template v-slot:[`header.file_lastdtm`]="{ header }">
                    <span
                      class="pointer"
                      @click="(multiSort = !multiSort), (sortby = 'date'), (clickSort = true), sortFile(rootfile)"
                      :style="headerTable"
                      >{{ $t(header.text) }}</span
                    >
                  </template>
                   <!-- หัวข้อจัดการไฟล์ -->
                   <template v-slot:[`header.file_status`]="{ header }">
                    <span :style="headerTable">
                      {{ $t(header.text) }}
                    </span>
                  </template>
                  <template slot="no-data">
              
                  </template>
                </v-data-table>
              </v-tab-item>
              </v-tabs>
            </v-layout>
            </v-list-item>  
          </v-list>
          <!-- Header Page xs -->
          <v-list nav :color="color.BG" class="mb-n2" v-else>
            <v-list-item class="text-left" v-if="heardertextsearch === true || smartuplloadsearch === true">
              <v-chip :style="headerChipPage" :color="$vuetify.theme.dark ? '#989898' : 'white'">
                <v-list-item-avatar class="text-center" size="20">
                  <v-btn
                    elevation="1"
                    fab
                    class="text-center"
                    :color="$vuetify.theme.dark ? 'white' : color.theme"
                    @click="backfromsearch(),show_back = false, statusmutipledownload = false, loaddataprogress = true"
                  >
                    <v-icon :color="$vuetify.theme.dark ? '#989898' : 'white'" dark size="16">keyboard_backspace</v-icon>
                  </v-btn>
                </v-list-item-avatar>
                <v-list-item-avatar class="text-center pr-2">
                  <v-icon :color="$vuetify.theme.dark ? 'white' : color.theme" dark size="20">mdi-magnify</v-icon>
                </v-list-item-avatar>
                <v-list-item-title class="ml-n2">
                  <span :style="headerPageMobile">&nbsp;{{ $t("tablefile.searchtext") }}</span>
                </v-list-item-title>
              </v-chip>
            </v-list-item>
            <v-list-item class="text-left" v-else>
              <v-chip :style="headerChipPage" :color="$vuetify.theme.dark ? '#989898' : 'white'">
                <v-list-item-avatar class="text-center" v-if="this.navigate.length > 1" size="20">
                  <v-btn
                    elevation="1"
                    fab
                    class="text-center" v-if="this.navigate.length > 1"
                    :color="$vuetify.theme.dark ? 'white' : color.theme"
                    @click="backtopreventfolder(),show_back = false, statusmutipledownload = false"
                  >
                    <v-icon :color="$vuetify.theme.dark ? '#989898' : 'white'" dark size="16">keyboard_backspace</v-icon>
                  </v-btn>
                </v-list-item-avatar>
                <v-list-item-avatar class="text-center" v-else>
                  <v-icon :color="$vuetify.theme.dark ? 'white' : color.theme" dark size="20">mdi-cube</v-icon>
                </v-list-item-avatar>
                <v-list-item-title class="ml-n2">
                  <v-breadcrumbs :items="navigate" divider=">" class="pa-0">
                    <template v-slot:item="props">
                      <v-breadcrumbs-item 
                        style="cursor: pointer;"
                        @click.stop="gotofolder(props.item.text[1])"
                        :disabled="loaddataprogress"
                      > 
                        <span v-if="props.item.text[2]" :style="headerPageMobile">
                          <span v-if="$t('default') === 'en'" :style="headerPageMobile" :color="$vuetify.theme.dark ? 'white' : color.theme">
                            {{ props.item.text[0] }}
                          </span>
                          <span v-else-if="$t('default') === 'th'" :style="headerPageMobile" :color="$vuetify.theme.dark ? 'white' : color.theme">
                            {{ props.item.text[2] }}
                          </span>
                        </span>
                        <span v-else :style="headerPageMobile" :color="$vuetify.theme.dark ? 'white' : color.theme">
                          {{ props.item.text[0] }}
                        </span>
                      </v-breadcrumbs-item>
                    </template>
                  </v-breadcrumbs>
                </v-list-item-title>
              </v-chip>              
            </v-list-item>
          </v-list>

          <!-- Sort File -->
          <!-- <v-list nav :color="color.BG" class="py-0">
            <v-list-item>
              <v-layout>                
                <v-flex class="text-right" lg12>
                  <v-menu offset-y rounded="lg">
                    <template v-slot:activator="{ attrs, on }">
                      <v-btn
                        text 
                        rounded
                        :color="color.theme" 
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ $t("sorting.sort") }}: 
                        <div class="font-weight-bold">
                          {{ $t(sorting['sort'].find(x => x.id === sort_by).text) }}, {{ $t(sorting['direction'].find(x => x.id === direction).text) }}
                        </div>
                      </v-btn>
                    </template>
                    <v-list dense nav>
                      <v-list-item-group
                        v-model="sort_by"
                        active-class="border"
                        :color="color.theme"
                      >
                        <template v-for="(item, i) in sorting['sort']">
                          <v-list-item
                            :key="`item-${i}`"
                            :value="item.id"
                          >
                            <template>
                              <v-list-item-content>
                                <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
                              </v-list-item-content>
                            </template>
                          </v-list-item>
                        </template>
                      </v-list-item-group>
                      <v-divider class="mt-2" />
                      <v-list-item-group
                        class="mt-4"
                        v-model="direction"
                        active-class="border"
                        :color="color.theme"
                      >
                        <template v-for="(item, i) in sorting['direction']">
                          <v-list-item
                            :key="`item-${i}`"
                            :value="item.id"
                          >
                            <template>
                              <v-list-item-content>
                                <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
                              </v-list-item-content>
                            </template>
                          </v-list-item>
                        </template>
                      </v-list-item-group>
                    </v-list>
                  </v-menu>
                </v-flex>
              </v-layout>
            </v-list-item>
          </v-list> -->

          <v-card class="elevation-0" :color="color.BG" :id="container_directory" @scroll.passive="onScroll">
            <v-card-text class="pa-0">
              <v-container fluid @contextmenu="show">
                <v-layout row wrap fill-height justify-center>
                  <!-- ปิดโหลด -->
                  <!-- <v-flex lg12 class="ma-4" v-if="loaddataprogress === true">
                    <div class="text-center">
                      <v-progress-circular :size="50" :color="color.theme" indeterminate></v-progress-circular>
                    </div>
                  </v-flex> -->
                  <v-flex lg12 xs12 class="ma-4" v-if="clickSort === true || status_aspect_key === 'Y'">
                    <dialog_filter
                      :system="system"
                      :status_aspect_key="status_aspect_key"
                      @datafilter="loadfilterdata"
                      :folderid_yourfolder="data_doctype"
                    ></dialog_filter>
                    <!-- <v-layout row wrap class="pl-3 pr-3" v-if="resolutionScreen >= 500"> -->
                      <!-- <v-flex lg2 xs4> -->
                        <!-- ส่วนของตัว select size -->
                        <!-- ไม่ได้ใช้แต่สร้างไว้จัดให้ layout ตรงกับหน้าอื่น -->
                      <!-- </v-flex> -->
                      <!-- ปิดชื่อฟิลเตอร์ตามหัวข้อสีเขียว -->
                      <!-- <v-flex lg4 offset-lg6 class="pl-3 pr-3 hidden-md-and-down">
                        <v-alert
                          dense
                          height="41"
                          v-model="clickSort"
                          :color="color.alertSort"
                          dark
                          v-show="clickSort"
                          style="margin-bottom: 0%;"
                        >
                          <div>
                            <v-icon :color="color.alertText" v-if="multiSort === true">mdi-chevron-up</v-icon>
                            <v-icon :color="color.alertText" v-else-if="multiSort === false">mdi-chevron-down</v-icon>
                            <span :style="colorSort" v-if="sortby === 'name'">{{ $t("Filter.name") }}</span>
                            <span :style="colorSort" v-else-if="sortby === 'date'">{{ $t("Filter.date") }}</span>
                            <span :style="colorSort" v-else-if="sortby === 'size'">{{ $t("Filter.size") }}</span>
                            <span :style="colorSort" v-else-if="sortby === 'owner'">{{ $t("Filter.owner") }}</span>
                            <v-btn
                              width="24"
                              height="24"
                              style="float: right;"
                              text
                              fab
                              x-small
                              :color="color.alertSort"
                              dark
                              @click="checkSort"
                            >
                              <v-icon :color="color.alertText">mdi-close-circle</v-icon>
                            </v-btn>
                          </div>
                        </v-alert>
                      </v-flex> -->
                    <!-- </v-layout> -->
                  </v-flex>
                  <v-flex lg12 xs12>
                <v-layout row wrap class="pl-3 pr-1" v-if="resolutionScreen >= 500">
                  <!-- Sort File เก่า -->
                  <v-flex lg8 xs8>
                  <!-- ส่วนของตัว select size -->
                  <!-- ไม่ได้ใช้แต่สร้างไว้จัดให้ layout ตรงกับหน้าอื่น -->
                  </v-flex>
                  <!-- <v-flex lg4 offset-lg8 offset-xs4 class="pl-3 pr-0">
                    <v-list nav :color="color.BG" class="py-0">
                      <v-list-item>
                        <v-layout>                
                          <v-flex class="text-right" lg12>
                            <v-menu offset-y rounded="lg">
                              <template v-slot:activator="{ attrs, on }">
                                <v-btn small rounded
                                  class="white--text"
                                  :color="color.theme" 
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                <v-icon small>mdi-sort</v-icon>
                                  {{ $t("sorting.sort") }}: 
                                  <div class="font-weight-bold">
                                    {{ $t(sorting['sort'].find(x => x.id === sort_by).text) }}, {{ $t(sorting['direction'].find(x => x.id === direction).text) }}
                                  </div>
                                </v-btn>
                              </template>
                              <v-list dense nav>
                                <v-list-item-group
                                  v-model="sort_by"
                                  active-class="border"
                                  :color="color.theme"
                                  mandatory
                                >
                                  <template v-for="(item, i) in sorting['sort']">
                                    <v-list-item
                                      :key="`item-${i}`"
                                      :value="item.id"
                                      @click="sort_by === item.id ? reloadSortByAndDirection() : ''"
                                    >
                                      <template>
                                        <v-list-item-content>
                                          <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
                                        </v-list-item-content>
                                      </template>
                                    </v-list-item>
                                  </template>
                                </v-list-item-group>
                                <v-divider class="mt-2" />
                                <v-list-item-group
                                  class="mt-4"
                                  v-model="direction"
                                  active-class="border"
                                  :color="color.theme"
                                  mandatory
                                >
                                  <template v-for="(item, i) in sorting['direction']">
                                    <v-list-item
                                      :key="`item-${i}`"
                                      :value="item.id"
                                      @click="direction === item.id ? reloadSortByAndDirection() : ''"
                                    >
                                      <template>
                                        <v-list-item-content>
                                          <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
                                        </v-list-item-content>
                                      </template>
                                    </v-list-item>
                                  </template>
                                </v-list-item-group>
                              </v-list>
                            </v-menu>
                          </v-flex>
                        </v-layout>
                      </v-list-item>
                    </v-list>
                  </v-flex> -->
                </v-layout>
                  </v-flex>
                  <v-flex lg12 class="ma-2">
                    <!-- หน้าจอ md++  Multiple Download, Starred , Move, Delete | Count Files & Folders  -->
                    <v-layout v-if="resolutionScreen >= 500" row wrap fill-height class="pl-2 pr-2">
                      <!-- Button Multiple -->
                      <!-- <v-flex xs5 lg5>
                        <div v-if="statusmutipledownload === false" class="pt-2 ">
                          <v-btn
                            @mouseover="show_menu = true"
                            @mouseleave="show_menu = false"
                            :color="color.theme"
                            rounded
                            class="ma-1 pa-3 white--text"
                            @click="statusmutipledownload = true,show_menu = false"
                            v-if="changeUI === false"
                          >
                            <v-icon>mdi-menu</v-icon>
                            <p class="ml-2" style="font-size:15px;margin-bottom:2px;">{{$t('balloon.menu')}}</p>
                            <v-badge v-if="$t('default') === 'th'"
                              style="z-index:10000;bottom:-45px;left:-40px;"
                              :value="show_menu"
                              :content="$t('balloon.menu')"
                              transition="slide-y-transition"
                            ></v-badge>
                            <v-badge v-else
                              style="z-index:10000;bottom:-45px;left:-55px;"
                              :value="show_menu"
                              :content="$t('balloon.menu')"
                              transition="slide-y-transition"
                            ></v-badge>
                          </v-btn>
                        </div>
                        <div v-else class="pt-2 pl-10">
                          <v-btn
                            
                            fab
                            small
                            @mouseover="show_load = true"
                            @mouseleave="show_load = false"
                            outlined
                            :color="color.theme"
                            class="ma-1 pa-0"
                            @click="multipledownload()"
                          >
                            <v-icon>mdi-download-outline</v-icon>
                            <v-badge v-if="$t('default') === 'th'"
                              style="z-index:10000;bottom:-45px;left:-42px;"
                              :value="show_load"
                              :content="$t('balloon.download')"
                              transition="slide-y-transition"
                            ></v-badge>
                            <v-badge v-else
                              style="z-index:10000;bottom:-45px;left:-45px;"
                              :value="show_load"
                              :content="$t('balloon.download')"
                              transition="slide-y-transition"
                            ></v-badge>
                          </v-btn>
                          <v-btn
                            
                            fab
                            small
                            @mouseover="show_print = true"
                            @mouseleave="show_print = false"
                            outlined
                            :color="color.theme"
                            class="ma-1 pa-0"
                            @click="fn_printPDF()"
                          >
                            <v-icon>print</v-icon>
                            <v-badge v-if="$t('default') === 'th'"
                              style="z-index:10000;bottom:-45px;left:-40px;"
                              :value="show_print"
                              :content="$t('balloon.print')"
                              transition="slide-y-transition"
                            ></v-badge>
                            <v-badge v-else
                              style="z-index:10000;bottom:-45px;left:-42px;"
                              :value="show_print"
                              :content="$t('balloon.print')"
                              transition="slide-y-transition"
                            ></v-badge>
                          </v-btn>
                          <v-btn
                           
                            fab
                            small
                            outlined
                            :color="color.theme"
                            class="ma-1 pa-0"
                            @click="setmultiupdate(filemultipledownload, 'S')"
                            @mouseover="show_star = true"
                            @mouseleave="show_star = false"
                          >
                            <v-icon dark>star_border</v-icon>
                            <v-badge v-if="$t('default') === 'th'"
                              style="z-index:10000;bottom:-45px;left:-45px;"
                              :value="show_star"
                              :content="$t('balloon.star')"
                              transition="slide-y-transition"
                            ></v-badge>
                            <v-badge v-else
                              style="z-index:10000;bottom:-45px;left:-43px;"
                              :value="show_star"
                              :content="$t('balloon.star')"
                              transition="slide-y-transition"
                            ></v-badge>
                          </v-btn>

                          <v-btn
                            
                            fab
                            small
                            @mouseover="show_delete = true"
                            @mouseleave="show_delete = false"
                            outlined
                            :color="color.theme"
                            class="ma-1 pa-0"
                            @click="multideletetotrash()"
                          >
                            <v-icon>delete_outline</v-icon>
                            <v-badge v-if="$t('default') === 'th'"
                              style="z-index:10000;bottom:-45px;left:-22px;"
                              :value="show_delete"
                              :content="$t('balloon.delete')"
                              transition="slide-y-transition"
                            ></v-badge>
                            <v-badge v-else
                              style="z-index:10000;bottom:-45px;left:-35px;"
                              :value="show_delete"
                              :content="$t('balloon.delete')"
                              transition="slide-y-transition"
                            ></v-badge>
                          </v-btn>
                          <v-btn
                           
                            fab
                            small
                            @mouseover="show_move = true"
                            @mouseleave="show_move = false"
                            outlined
                            class="ma-1 pa-0"
                            :color="color.theme"
                            @click="appsbarmultimovefile($event)"
                          >
                            <v-icon>mdi-folder-swap-outline</v-icon>
                            <v-badge v-if="$t('default') === 'th'"
                              style="z-index:10000;bottom:-45px;left:-60px;"
                              :value="show_move"
                              :content="$t('balloon.move')"
                              transition="slide-y-transition"
                            ></v-badge>
                            <v-badge v-else
                              style="z-index:10000;bottom:-45px;left:-65px;"
                              :value="show_move"
                              :content="$t('balloon.move')"
                              transition="slide-y-transition"
                            ></v-badge>
                          </v-btn>
                          <v-btn
                            @mouseover="show_clear = true"
                            @mouseleave="show_clear = false"
                            fab
                            small
                            color="error"
                            outlined
                            class="ma-1 pa-0"
                            @click="(statusmutipledownload = false), clearmultipledownload(),show_clear = false"
                          >
                            <v-icon>clear</v-icon>
                            <v-badge v-if="$t('default') === 'th'"
                              style="z-index:10000;bottom:-45px;left:-20px;"
                              :value="show_clear"
                              :content="$t('balloon.clear')"
                              transition="slide-y-transition"
                            ></v-badge>
                            <v-badge v-else
                              style="z-index:10000;bottom:-45px;left:-30px;"
                              :value="show_clear"
                              :content="$t('balloon.clear')"
                              transition="slide-y-transition"
                            ></v-badge>
                          </v-btn>
                        </div>
                      </v-flex> -->
                      <!-- Countfiles&Folders -->
                      <!--<v-flex xs7 lg7 class="text-right">-->
                        <!-- OLD Countfiles -->
                        <!-- <v-chip class="ma-2" :color="color.theme" :text-color="color.chipText">
                          <v-icon left>folder</v-icon>
                          {{countfolder}} {{countfolder > 1 ? $t('folders'): $t('folder')}}
                        </v-chip>
                        <v-chip class="ma-2" :color="color.theme" :text-color="color.chipText">
                          <v-icon left>mdi-file</v-icon>
                          {{countfile}} {{countfile > 1 ? $t('files'): $t('file')}}
                        </v-chip> -->
                        <!-- New Countfiles by scrolling -->
                        <!--<v-chip class="ma-2" :color="color.theme" :text-color="color.chipText">
                          <v-icon left>folder</v-icon>
                          {{ totaldata["totalfolder"] }} {{ totaldata["totalfolder"] > 1 ? $t("folders") : $t("folder") }}
                        </v-chip>
                        <v-chip class="ma-2" :color="color.theme" :text-color="color.chipText">
                          <v-icon left>mdi-file</v-icon>
                          {{ totaldata["totalfile"] }} {{ totaldata["totalfile"] > 1 ? $t("files") : $t("file") }}
                        </v-chip>
                      </v-flex>-->
                    </v-layout>

                    <!-- หน้าจอ xs  Multiple Download, Starred , Move, Delete | Count Files & Folders  -->
                    <v-layout v-else row wrap class="mt-n2">
                      <!-- <v-flex lg2 xs3>
                        <v-select
                          prepend-inner-icon="sort"
                          hide-details
                          outlined
                          solo
                          dense
                          v-model="size"
                          :items="listsize"
                          class="text-center"
                        >
                          <template v-slot:item="{ item }">
                            <div class="text-center">
                              <span style="font-size: 17px;">{{ item }}</span>
                            </div>
                          </template>
                          <template v-slot:selection="{ item }">
                            <div class="text-center">
                              <span style="font-size: 17px;">{{ item }}</span>
                            </div>
                          </template>
                        </v-select>
                      </v-flex> -->
                      <!-- Sort By -->
                      <v-flex xs3 class="text-left">
                        <v-menu offset-y rounded="lg">
                          <template v-slot:activator="{ attrs, on }">
                            <v-btn x-small rounded
                              :color="color.theme" 
                              v-bind="attrs"
                              v-on="on"
                              text
                              style="font-size:16px; letter-spacing: 0px;"
                              class="ml-3"
                            >
                              <span>{{ $t("sorting.sort") }} : </span>
                              <span class="font-weight-bold" :color="color.theme"> {{ $t(sorting['sort'].find(x => x.id === sort_by).text) }} </span><v-icon size="20">{{ $t(sorting['direction'].find(x => x.id === direction).text) === $t('sorting.ascending') ? 'mdi-arrow-up' : 'mdi-arrow-down'}}</v-icon>
                            </v-btn>
                          </template>
                          <v-list dense nav :style="roundListSort">
                            <v-list-item-group
                              v-model="sort_by"
                              active-class="border"
                              :color="color.theme"
                              mandatory
                            >
                              <template v-for="(item, i) in sorting['sort']">
                                <v-list-item
                                  :key="`item-${i}`"
                                  :value="item.id"
                                  @click="sort_by === item.id ? reloadSortByAndDirection() : ''"
                                >
                                  <template>
                                    <v-list-item-content>
                                      <v-list-item-title :style="sort_by === item.id ? listOnBold : listNoBold" ><v-icon size="17" class="mr-2">{{item.icon}}</v-icon>{{ $t(item.text) }}</v-list-item-title>
                                    </v-list-item-content>
                                  </template>
                                </v-list-item>
                              </template>
                            </v-list-item-group>
                            <v-divider class="mt-2" />
                            <v-list-item-group
                              class="mt-4"
                              v-model="direction"
                              active-class="border"
                              :color="color.theme"
                              mandatory
                            >
                              <template v-for="(item, i) in sorting['direction']">
                                <v-list-item
                                  :key="`item-${i}`"
                                  :value="item.id"
                                  @click="direction === item.id ? reloadSortByAndDirection() : ''"
                                >
                                  <template>
                                    <v-list-item-content>
                                      <v-list-item-title :style="direction === item.id ? listOnBold : listNoBold">{{ $t(item.text) }}</v-list-item-title>
                                    </v-list-item-content>
                                  </template>
                                </v-list-item>
                              </template>
                            </v-list-item-group>
                          </v-list>
                        </v-menu>
                      </v-flex>
                         <!-- Total File&Folder -->
                      <!-- Total File&Folder -->
                      <v-flex xs9 class="text-right ml-n5" v-if="heardertextsearch === true">
                        <v-chip style="height: 32px; border-radius: 32px;" class="px-4 white--text" :color="color.theme">{{ $t("count_data_Advasearch") }} : {{ count_data_adv_ }}</v-chip>
                        <!-- <v-chip class="ma-2" :color="color.theme" :text-color="color.chipText">
                          <v-icon left>folder</v-icon>
                          {{countfolder}} {{countfolder > 1 ? $t('folders'): $t('folder')}}
                        </v-chip>
                        <v-chip class="ma-2" :color="color.theme" :text-color="color.chipText">
                          <v-icon left>mdi-file</v-icon>
                          {{countfile}} {{countfile > 1 ? $t('files'): $t('file')}}
                        </v-chip> -->
                        <!-- <v-btn fab small @click="backtopreventfolder()" class="ma-2" v-if="this.navigate.length > 1">
                         <v-icon :color="color.theme">keyboard_backspace</v-icon>
                        </v-btn> -->
                        <!-- New Countfiles by scrolling -->
                        <!-- <v-chip style="height: 32px; border-radius: 32px;" class="px-4 mr-2" color="#1876D1" :text-color="color.chipText">
                          <v-icon center size="18" class="mr-2">folder</v-icon>
                          <span style="font-size:16px; font-weight: 700;">{{ totaldata["totalfolder"] }} <span v-if="resolutionScreen >= 400">{{ totaldata["totalfolder"] > 1 ? $t('folders'): $t('folder')}}</span></span>
                        </v-chip>
                        <v-chip style="height: 32px; border-radius: 32px;" class="px-4" color="#5AB685" :text-color="color.chipText">
                          <v-icon center size="18" class="mr-2">mdi-file-document</v-icon>
                          <span style="font-size:16px; font-weight: 700;">{{ totaldata["totalfile"] }} <span v-if="resolutionScreen >= 400">{{ totaldata["totalfile"] > 1 ? $t('files') : $t('file')}}</span></span>
                        </v-chip> -->
                      </v-flex>
                      <v-flex xs9 class="text-right ml-n5" v-else-if="smartuplloadsearch === true">
                        <v-chip style="height: 32px; border-radius: 32px;" class="px-4 white--text" :color="color.theme">{{ $t("count_data_Advasearch") }} : {{ count_data_smup_ }}</v-chip>
                      </v-flex>
                      
                      <!-- Total File&Folder -->
                      <v-flex xs9 class="text-right ml-n5" v-else>
                        <!-- <v-chip class="ma-2" :color="color.theme" :text-color="color.chipText">
                          <v-icon left>folder</v-icon>
                          {{countfolder}} {{countfolder > 1 ? $t('folders'): $t('folder')}}
                        </v-chip>
                        <v-chip class="ma-2" :color="color.theme" :text-color="color.chipText">
                          <v-icon left>mdi-file</v-icon>
                          {{countfile}} {{countfile > 1 ? $t('files'): $t('file')}}
                        </v-chip> -->
                        <!-- <v-btn fab small @click="backtopreventfolder()" class="ma-2" v-if="this.navigate.length > 1">
                         <v-icon :color="color.theme">keyboard_backspace</v-icon>
                        </v-btn> -->
                        <!-- New Countfiles by scrolling -->
                        <v-chip style="height: 32px; border-radius: 32px;" class="px-4 mr-2" color="#1876D1" :text-color="color.chipText">
                          <v-icon center size="18" class="mr-2">folder</v-icon>
                          <span style="font-size:16px; font-weight: 700;">{{ totaldata["totalfolder"] }} <span v-if="resolutionScreen >= 400">{{ totaldata["totalfolder"] > 1 ? $t('folders'): $t('folder')}}</span></span>
                        </v-chip>
                        <v-chip style="height: 32px; border-radius: 32px;" class="px-4" color="#5AB685" :text-color="color.chipText">
                          <v-icon center size="18" class="mr-2">mdi-file-document</v-icon>
                          <span style="font-size:16px; font-weight: 700;">{{ totaldata["totalfile"] }} <span v-if="resolutionScreen >= 400">{{ totaldata["totalfile"] > 1 ? $t('files') : $t('file')}}</span></span>
                        </v-chip>
                      </v-flex>
                    </v-layout>
                  </v-flex>

                  <!-- Display Folder & files -->
                  <v-flex xs12 lg12 class="pa-4">
                    <!-- ตรวจสอบขนาดหน้าจอ  -->
                    <div
                      v-if="
                        $vuetify.breakpoint.name === 'xl' ||
                          $vuetify.breakpoint.name === 'lg' ||
                          $vuetify.breakpoint.name === 'md'
                      "
                    >
                      <!-- ตรวจสอบว่า layout Grid หรือไม่ -->
                      <div v-if="changeUI === true">
                        <drag-selector v-model="checkedList" @change="fn_handleDragSelectorChange(checkedList)" v-if="sort_by === 'size_file'">
                          <!-- โฟลเดอร์แผนก -->
                          <v-card
                          v-if="
                              rootfile.filter((item) => {
                                return item.file_status === 'M';
                              }).length > 0
                            "
                           
                            class="elevation-0"
                            :color="color.BG"
                            style="cursor: default;"
                            @mousedown="resetFile"
                          >
                            <v-card-title
                              class="pb-2"
                              v-if="
                                rootfile.filter((item) => {
                                  return item.file_status === 'M';
                                }).length > 0
                              "
                              >{{ $t("company") }}</v-card-title
                            >
                            <v-divider  v-if="
                                rootfile.filter((item) => {
                                  return item.file_status === 'M';
                                }).length > 0
                              "></v-divider>
                            <br />
                            <v-layout justify-center wrap row align-center>
                              <v-flex lg11>
                                <v-row>
                                  <v-col
                                    v-for="(item, index) in rootfile.filter((item) => {
                                      return item.file_status === 'M';
                                    })"
                                    :key="index"
                                    cols="6"
                                    md="2"
                                    class="pa-2"
                                  >
                                    <drag-selector-item class="drag-selector__item" :value="item">
                                      <v-card
                                        id="borderradius"
                                        outlined
                                        class="pa-0 d-flex align-center"
                                        @contextmenu="rightclickfileandfolder($event, item)"
                                        @dblclick="gotodirectory(item.file_id, item.file_type, item)"
                                        hover
                                        :title="item.file_name"
                                        :color="selectColor(item.file_id, 'card')"
                                        @click.ctrl="removeCheckedListItem(item)"
                                        @click.exact="shiftSelect(item)"
                                        :disabled="
                                          disableformulticlick(
                                            item.status_lock_file || item.status_lock_folder || item.status_lock_data
                                          )
                                        "
                                      >
                                        <v-layout justify-center column align-center>
                                          <v-col class="pa-0">
                                            <!-- <div class="text-center">
                                            <v-icon :color="item.item.file_icon[1]" size="100px">{{
                                              item.item.file_icon[0]
                                            }}</v-icon>
                                          </div> -->
                                            <div class="text-center pa-0">
                                              <v-app-bar class="pa-0" flat color="rgba(0, 0, 0, 0)" height="45px" :disabled="disableByPackage"
                                                ><v-app-bar-nav-icon
                                                  v-if="item.file_status === 'S'"
                                                  class="pa-0"
                                                  color="white"
                                                  @click="setPermissionFileStar(item, 'N', 'st'), (display_grid = true)"
                                                  :disabled="disableByPackage"
                                                  ><v-icon class="pa-0" color="amber">star</v-icon></v-app-bar-nav-icon
                                                >
                                                <v-app-bar-nav-icon
                                                  v-else
                                                  class="pa-0"
                                                  color="white"
                                                  @click="setPermissionFileStar(item, 'S', 'st'), (display_grid = true)"
                                                  :disabled="disableByPackage"
                                                  ><v-icon color="#D9D9D9" class="pa-0">star_outline</v-icon></v-app-bar-nav-icon
                                                >
                                                <v-spacer></v-spacer>

                                                <v-btn color="white" icon @click="rightclickfileandfolder($event, item)">
                                                  <v-icon color="#D9D9D9">mdi-dots-vertical</v-icon>
                                                </v-btn>
                                              </v-app-bar>
                                              <v-icon v-if="item.status_lock_data === 'Y'" :color="item.file_icon[1]" size="100px"
                                                >mdi-folder-lock</v-icon
                                              >
                                              <v-icon v-else :color="item.file_icon[1]" size="100px">{{
                                                item.file_icon[0]
                                              }}</v-icon>
                                            </div>

                                            <v-col id="borderradius-word" class="pt-3" :style="word_grid">
                                              <v-layout>
                                                <v-flex md9 lg9>
                                                  <div
                                                    style="whiteSpace: nowrap;overflow: hidden;textOverflow: ellipsis;padding-left:8px ;"
                                                    :style="selectColor(item.file_id, 'text')"
                                                  >
                                                    <span style="font-weight: 450; font-size:16px;">
                                                      {{ item.file_name }}
                                                    </span>
                                                  </div>
                                                </v-flex>
                                                <v-flex md2 lg3 :style="word_grid" class="d-inline-flex justify-end">
                                                  <v-avatar
                                                    title="ข้อมูลใหม่"
                                                    v-if="(checkNewDataFormDate(item) === true && checkNewDataNoRefresh(item) === true && checkNewDataRefresh(item) === true) || item.onlyofficeeditsecces === true"
                                                    size="24"
                                                    ><v-icon dark medium color="red">mdi-new-box</v-icon></v-avatar
                                                  >
                                                  <v-avatar
                                                    title="แชร์ลิงก์"
                                                    v-if="item.file_status_sharelink === 'Y' || item.file_status_sharelink === 'P'"
                                                    size="20"
                                                    color="#F37958"
                                                    ><v-icon dark small>link</v-icon></v-avatar
                                                  >
                                                  <v-avatar
                                                    title="แชร์ไฟล์"
                                                    v-if="item.file_status_share === 'Y'"
                                                    size="20"
                                                    color="green"
                                                    ><v-icon dark small>group_add</v-icon></v-avatar
                                                  >
                                                  <v-avatar
                                                    title="อัพเดท"
                                                    v-if="item.status_updating === ''"
                                                    size="20"
                                                    color="primary"
                                                    ><v-icon dark small>check</v-icon></v-avatar
                                                  >

                                                  <v-avatar
                                                    title="ไฟล์หมดอายุ"
                                                    v-if="item.status_expired === '0'"
                                                    size="20"
                                                    color="#D50000"
                                                    ><v-icon dark small>mdi-timer</v-icon></v-avatar
                                                  >
                                                  <v-avatar
                                                    title="ไฟล์ใกล้หมดอายุ"
                                                    v-if="item.status_expired === '1' || item.status_expired === '4'"
                                                    size="20"
                                                    color="#fc9208e6"
                                                    ><v-icon dark small>mdi-timer</v-icon></v-avatar
                                                  >
                                                  <v-avatar
                                                    title="ตั้งค่าวันหมดอายุ"
                                                    v-if="item.status_expired === '2'"
                                                    size="20"
                                                    color="green"
                                                    ><v-icon dark small>mdi-timer</v-icon></v-avatar
                                                  >
                                                  <v-avatar title="relation"
                                                   v-if="item.relation_status === 0"
                                                    size="20"
                                                    color="red">
                                                  <v-icon dark small >device_hub</v-icon></v-avatar>
                                                  <v-avatar title="relation"
                                                   v-else-if="item.relation_status === 1"
                                                    size="20"
                                                    color="amber accent-4">
                                                  <v-icon dark small >device_hub</v-icon></v-avatar>
                                            
                                                </v-flex>
                                              </v-layout>
                                              <!-- <v-flex lg2 align-self-center>
                                              <v-icon v-if="item.status_lock_data === 'Y'" color="error" right>lock</v-icon>
                                            </v-flex> -->
                                            </v-col>
                                          </v-col>
                                        </v-layout>
                                        <!-- v-on:keyup.ctrl.67="test(item)" -->
                                        <!-- v-on:keyup.ctrl="fn_copyfile(item)" -->
                                        <!-- <v-badge
                                          offset-x="10"
                                          offset-y="6"
                                          color="orange"
                                          :icon="vBadgeIcon(item)"
                                          v-if="
                                            item.file_status_sharelink === 'Y' ||
                                              item.file_status_share === 'Y' ||
                                              item.status_updating === ''
                                          "
                                        >
                                          <v-icon large :color="item.file_icon[1]">
                                            {{ item.file_status === "S" ? "mdi-folder-star" : item.file_icon[0] }}
                                          </v-icon>
                                        </v-badge> -->

                                        <!-- <v-icon v-else large :color="item.file_icon[1]">
                                          {{ item.file_status === "S" ? "mdi-folder-star" : item.file_icon[0] }}
                                        </v-icon>
                                        <div style="overflow: hidden;">
                                          <div
                                            style="whiteSpace: nowrap;overflow: hidden;textOverflow: ellipsis;padding-left:8px"
                                            :style="selectColor(item.file_id, 'text')"
                                          >
                                            {{ item.file_name }}
                                          </div>
                                          <v-chip
                                            x-small
                                            dark
                                            v-show="item.status_expired === '0' || item.status_expired === '1' || item.status_expired === '4'" :color="
                                              item.status_expired === '0'
                                                ? 'red'
                                                : item.status_expired === '1' || item.status_expired === '4'
                                                ? '#fc9208e6'
                                                : 'white'
                                            "
                                            >{{
                                              item.status_expired === "0"
                                                ? $t("statusfileexpired.expired")
                                                : item.status_expired === "1" || item.status_expired === "4"
                                                ? $t("statusfileexpired.nearexpired")
                                                : ""
                                            }}</v-chip
                                          >
                                        </div> -->
                                        <!-- <v-icon class="pr-1" color="red" x-small v-if="item.relation_status === 0">device_hub</v-icon>
                                            <v-icon class="pr-1" color="amber accent-4" x-small v-else-if="item.relation_status === 1">device_hub</v-icon>-->
                                        <!-- <v-spacer></v-spacer> -->
                                        <!-- icon lock -->
                                        <!-- <v-icon v-if="item.status_lock_folder === 'Y'" color="error">lock</v-icon> -->
                                      </v-card>
                                    </drag-selector-item>
                                  </v-col>
                                </v-row>
                              </v-flex>
                            </v-layout>
                          </v-card>
                          <br />
                             <!-- ไฟล์ -->
                          <v-card
                            class="elevation-0"
                            :color="color.BG"
                            style="cursor: default;"
                            @mousedown="resetFile"
                            v-if="totaldata['totalfile'] !== 0"
                          >
                            <v-card-title class="pb-2">{{ totaldata["totalfile"] == 0 ? "" : $t("files") }}</v-card-title>
                            <v-divider></v-divider>
                            <br />
                            <v-layout justify-center wrap row align-center>
                              <v-flex lg11>
                                <v-row>
                                  <v-col
                                    v-for="(item, index) in rootfile.filter((item) => {
                                      return item.file_type !== 'folder';
                                    })"
                                    :key="index"
                                    cols="6"
                                    md="2"
                                    class="pa-2"
                                  >
                                    <drag-selector-item class="drag-selector__item" :value="item">
                                      <v-tooltip 
                                      color="#6b6b6b"
                                      :right="((index+1) % 6) === 0 ? false : true" 
                                      :left="((index+1) % 6) === 0 ? true : false"
                                      v-model="idPointed === item.file_id ? isOpenTooltip : false">
                                      <template v-slot:activator="{ on }">
                                      <v-card
                                        id="borderradius"
                                        outlined
                                        class="pa-0 d-flex align-center"
                                        hover
                                        @contextmenu="rightclickfileandfolder($event, item)"
                                        @dblclick="gotodirectory(item.file_id, item.file_type, item)"
                                        :title="officefilescompatible.includes(item.file_type) ? idPointed === item.file_id ? isOpenTooltip ? null : item.file_name : item.file_name : item.file_name"
                                        :color="selectColor(item.file_id, 'card')"
                                        @click.ctrl="removeCheckedListItem(item)"
                                        @click.exact="shiftSelect(item)"
                                        :disabled="
                                          disableformulticlick(
                                            item.status_lock_file || item.status_lock_folder || item.status_lock_data
                                          )
                                        "
                                        :v-on="isOpenTooltip ? on : null" 
                                        @mouseover="officefilescompatible.includes(item.file_type) ? mouseOverFile_onlyoffice(item) : null"
                                        @mouseleave="mouseLeaveFile_onlyoffice"
                                      >
                                        <!-- v-on:keyup.ctrl.67="test(item)" -->
                                        <!-- v-on:keyup.ctrl="fn_copyfile(item)" -->
                                        <v-layout justify-center column align-center>
                                          <v-col class="pa-0">
                                            <!-- Thumbnail กับ Icon -->
                                            <v-img
                                              :src="item.file_thumbnail"
                                              max-height="145px"
                                              height="145px"
                                              id="borderradius-img"
                                              v-if="item.file_thumbnail != '' && item.editing_status === 'N'"
                                              :style="selectCardBgColor(item)"
                                            >
                                              <v-app-bar dense class="pa-0" flat color="rgba(0, 0, 0, 0)" height="45px" :disabled="disableByPackage"
                                                ><v-app-bar-nav-icon
                                                  v-if="item.file_status === 'S'"
                                                  class="pa-0"
                                                  color="white"
                                                  @click="setPermissionFileStar(item, 'N', 'st'), (display_grid = true)"
                                                  :disabled="disableByPackage"
                                                  ><v-icon class="pa-0" color="amber">star</v-icon></v-app-bar-nav-icon
                                                >
                                                <v-app-bar-nav-icon
                                                  v-else
                                                  class="pa-0"
                                                  color="white"
                                                  @click="setPermissionFileStar(item, 'S', 'st'), (display_grid = true)"
                                                  :disabled="disableByPackage"
                                                  ><v-icon color="#D9D9D9" class="pa-0">star_outline</v-icon></v-app-bar-nav-icon
                                                >
                                                <v-spacer></v-spacer>

                                                <v-btn color="white" icon @click="rightclickfileandfolder($event, item)">
                                                  <v-icon color="#D9D9D9">mdi-dots-vertical</v-icon>
                                                </v-btn>
                                              </v-app-bar>
                                              <v-card-title class="white--text mt-12 pa-0">
                                                <v-avatar size="56"
                                                  ><v-icon :color="item.file_icon[1]" large>{{ item.file_icon[0] }}</v-icon>
                                                </v-avatar>
                                              </v-card-title>
                                              <v-spacer></v-spacer>
                                            </v-img>
                                            <div
                                              v-else-if="item.status_lock_data === 'Y' || item.editing_status === 'Y' || item.editing_status === 'S'"
                                              class="text-center pa-0"
                                              id="borderradius-img"
                                            >
                                              <v-app-bar class="pa-0" flat color="rgba(0, 0, 0, 0)" height="45px" :disabled="disableByPackage"
                                                ><v-app-bar-nav-icon
                                                  v-if="item.file_status === 'S'"
                                                  class="pa-0"
                                                  color="white"
                                                  @click="setPermissionFileStar(item, 'N', 'st'), (display_grid = true)"
                                                  :disabled="disableByPackage"
                                                  ><v-icon class="pa-0" color="amber">star</v-icon></v-app-bar-nav-icon
                                                >
                                                <v-app-bar-nav-icon
                                                  v-else
                                                  class="pa-0"
                                                  color="white"
                                                  @click="setPermissionFileStar(item, 'S', 'st'), (display_grid = true)"
                                                  :disabled="disableByPackage"
                                                  ><v-icon color="#D9D9D9" class="pa-0">star_outline</v-icon></v-app-bar-nav-icon
                                                >
                                                <v-spacer></v-spacer>

                                                <v-btn color="white" icon @click="rightclickfileandfolder($event, item)">
                                                  <v-icon color="#D9D9D9">mdi-dots-vertical</v-icon>
                                                </v-btn>
                                              </v-app-bar>
                                              <v-card-title class="white--text pa-0" :style="selectCardBgColor(item)">
                                                <v-avatar class="mt-12" size="56"
                                                  ><v-icon :color="item.file_icon[1]" large>{{ item.file_icon[0] }}</v-icon>
                                                </v-avatar>
                                                <div style="position: absolute; right: 0; left: 0; top: 25%;">
                                                  <v-icon color="#999999" size="50px">mdi-lock-outline</v-icon>
                                                </div>
                                                <div
                                                  style="position: absolute; z-index: 9; right: 25%; left: 25%; top: 60%;"
                                                  v-show="dataAccountActive.type === 'Business' && item.editing_status === 'Y'"
                                                >
                                                  <v-chip x-small color="primary" v-if="$t('default') === 'th'"
                                                    >{{ item.last_update_name.name_th }} กำลังแก้ไข</v-chip
                                                  >
                                                  <v-chip x-small color="primary" v-else
                                                    >{{ item.last_update_name.name_eng }} editting</v-chip
                                                  >
                                                </div>
                                                <div
                                                  style="position: absolute; z-index: 9; right: 25%; left: 25%; top: 60%;"
                                                  v-show="dataAccountActive.type === 'Business' && item.editing_status === 'S'"
                                                >
                                                  <v-chip x-small color="primary" v-if="$t('default') === 'th'"
                                                    >กำลังซิงค์... </v-chip
                                                  >
                                                  <v-chip x-small color="primary" v-else
                                                    >Syncing...</v-chip
                                                  >
                                                </div>
                                              </v-card-title>
                                            </div>
                                            <div
                                              v-else
                                              class="text-center pa-0"
                                              id="borderradius-img"
                                            >
                                              <v-app-bar class="pa-0" flat color="rgba(0, 0, 0, 0)" height="45px" :disabled="disableByPackage"
                                                ><v-app-bar-nav-icon
                                                  v-if="item.file_status === 'S'"
                                                  class="pa-0"
                                                  color="white"
                                                  @click="setPermissionFileStar(item, 'N', 'st'), (display_grid = true)"
                                                  :disabled="disableByPackage"
                                                  ><v-icon class="pa-0" color="amber">star</v-icon></v-app-bar-nav-icon
                                                >
                                                <v-app-bar-nav-icon
                                                  v-else
                                                  class="pa-0"
                                                  color="white"
                                                  @click="setPermissionFileStar(item, 'S', 'st'), (display_grid = true)"
                                                  :disabled="disableByPackage"
                                                  ><v-icon color="#D9D9D9" class="pa-0">star_outline</v-icon></v-app-bar-nav-icon
                                                >
                                                <v-spacer></v-spacer>

                                                <v-btn color="white" icon @click="rightclickfileandfolder($event, item)">
                                                  <v-icon color="#D9D9D9">mdi-dots-vertical</v-icon>
                                                </v-btn>
                                              </v-app-bar>
                                              <v-icon :color="item.file_thumbnail_icon[1]" size="100px" :style="selectCardBgColor(item)">{{
                                                item.file_thumbnail_icon[0]
                                              }}</v-icon>
                                            </div>
                                            <v-col id="borderradius-word" class="pt-3" :style="word_grid">
                                              <v-layout >
                                                <!-- <v-flex lg9 style="background-color:#F6F7FA"> -->
                                                <!-- <v-badge
                                                color="orange"
                                                :icon="vBadgeIcon(item)"
                                                v-if="item.file_status_sharelink === 'Y' || item.file_status_share === 'Y'"
                                                offset-x="10"
                                                offset-y="10"
                                              >
                                                <div>
                                                  <v-icon
                                                    color="orange"
                                                    v-if="item.file_status === 'S'"
                                                    style="position: absolute;z-index:1;left:-8px;top:-11px"
                                                    >star</v-icon
                                                  > -->
                                                <!-- <v-icon :color="item.file_icon[1]" large>{{ item.file_icon[0] }}</v-icon> -->
                                                <!-- </div> -->
                                                <!-- </v-badge> -->
                                                <!-- <div v-else> -->
                                                <!-- <v-icon
                                                  color="orange"
                                                  v-if="item.file_status === 'S'"
                                                  style="position: absolute;z-index:1;left:-4px;top:117px"
                                                  >star</v-icon
                                                > -->
                                                <!-- <v-icon :color="item.file_icon[1]" large>{{ item.file_icon[0] }}</v-icon> -->
                                                <!-- </div> -->
                                                <!-- </v-flex> -->
                                                <v-flex md9 lg9 >
                                                  <div
                                                    style="whiteSpace: nowrap;overflow: hidden;textOverflow: ellipsis;padding-left:8px ;"
                                                    :style="selectColor(item.file_id, 'text')"
                                                  >
                                                    <span style="font-weight: 450; font-size:16px;" 
                                                      v-if="item.status_hilightword === undefined">
                                                      {{ item.file_name }}
                                                    </span>
                                                    <span style="font-weight: 450; font-size:16px;"
                                                    v-else
                                                    v-html="fn_hilightWord(item.file_name)"
                                                    >
                                                    </span>

                                                    <!-- v-if="item.status_expired !== '3' && item.status_expired !== '2' && item.status_expired !== undefined && item.status_expired !== '' && item.status_expired !== null" -->

                                                    <!-- <div> -->
                                                    <!-- <v-chip
                                                      x-small
                                                      dark
                                                      v-show="
                                                        item.status_expired === '0' ||
                                                          item.status_expired === '1' ||
                                                          item.status_expired === '4'
                                                      "
                                                      :color="
                                                        item.status_expired === '0'
                                                          ? 'red'
                                                          : item.status_expired === '1' || item.status_expired === '4'
                                                          ? '#fc9208e6'
                                                          : ''
                                                      "
                                                      >{{
                                                        item.status_expired === "0"
                                                          ? $t("statusfileexpired.expired")
                                                          : item.status_expired === "1" || item.status_expired === "4"
                                                          ? $t("statusfileexpired.nearexpired")
                                                          : ""
                                                      }}</v-chip
                                                    > -->
                                                    <!-- <div v-else-if="item.status_expired === '2'" > -->
                                                    <!-- <v-icon v-show="item.status_expired === '2'" small color="green"
                                                      >access_time</v-icon
                                                    > -->
                                                    <!-- </div> -->
                                                  </div>
                                                </v-flex>
                                                <v-flex md2 lg3 :style="word_grid" class="d-inline-flex justify-end">
                                                  <v-avatar
                                                    title="แชร์ลิงก์"
                                                    v-if="item.file_status_sharelink === 'Y' || item.file_status_sharelink === 'P'"
                                                    size="20"
                                                    color="#F37958"
                                                    ><v-icon dark small>link</v-icon></v-avatar
                                                  >
                                                  <v-avatar
                                                    title="แชร์ไฟล์"
                                                    v-if="item.file_status_share === 'Y'"
                                                    size="20"
                                                    color="green"
                                                    ><v-icon dark small>group_add</v-icon></v-avatar
                                                  >
                                                  <v-avatar
                                                    title="อัพเดท"
                                                    v-if="item.status_updating === ''"
                                                    size="20"
                                                    color="primary"
                                                    ><v-icon dark small>check</v-icon></v-avatar
                                                  >

                                                  <v-avatar
                                                    title="ไฟล์หมดอายุ"
                                                    v-if="item.status_expired === '0'"
                                                    size="20"
                                                    color="#D50000"
                                                    ><v-icon dark small>mdi-timer</v-icon></v-avatar
                                                  >
                                                  <v-avatar
                                                    title="ไฟล์ใกล้หมดอายุ"
                                                    v-if="item.status_expired === '1' || item.status_expired === '4'"
                                                    size="20"
                                                    color="#fc9208e6"
                                                    ><v-icon dark small>mdi-timer</v-icon></v-avatar
                                                  >
                                                  <v-avatar
                                                    title="ตั้งค่าวันหมดอายุ"
                                                    v-if="item.status_expired === '2'"
                                                    size="20"
                                                    color="green"
                                                    ><v-icon dark small>mdi-timer</v-icon></v-avatar
                                                  >
                                                   <v-avatar title="relation"
                                                   v-if="item.relation_status === 0"
                                                    size="20"
                                                    color="red">
                                                  <v-icon dark small >device_hub</v-icon></v-avatar>
                                                  <v-avatar title="relation"
                                                   v-else-if="item.relation_status === 1"
                                                    size="20"
                                                    color="amber accent-4">
                                                  <v-icon dark small >device_hub</v-icon></v-avatar>
                                                </v-flex>
                                              </v-layout>
                                              <!-- <v-flex lg2 align-self-center>
                                              <v-icon v-if="item.status_lock_data === 'Y'" color="error" right>lock</v-icon>
                                            </v-flex> -->
                                            </v-col>
                                          </v-col>
                                        </v-layout>
                                      </v-card>
                                      </template>
                                      <div class="pb-2">
                                        <div 
                                          :class="((index+1) % 6) === 0 ? `tri-right right-top` : `tri-right left-top`">
                                        </div>
                                        <div class="pb-1">
                                          <span>
                                            {{$t('currentRClick.editting')}}
                                          </span>
                                        </div>
                                        <div v-for="(itemperson, index) in editor_person"
                                          :key="index">
                                          <div style="display: flex">
                                            <div class="dot" :style="`backgroundColor: #${random_color[index]};`">
                                              <span>
                                                {{itemperson.first_name_eng.split("")[0].toUpperCase()}}
                                              </span>
                                            </div>
                                            <span class="ml-2">
                                              {{itemperson.first_name_eng.charAt(0).toUpperCase() + itemperson.first_name_eng.slice(1)}} {{  itemperson.last_name_eng.charAt(0).toUpperCase() + itemperson.last_name_eng.slice(1) }}
                                            </span>
                                          </div>
                                        </div>
                                        <div style="display: flex; align-items: center; justify-content: center" v-if="remain_person > 0" >
                                          <span>
                                            +{{remain_person}}
                                          </span>
                                        </div>
                                      </div>
                                      </v-tooltip>
                                    </drag-selector-item>
                                  </v-col>
                                </v-row>
                              </v-flex>
                            </v-layout>
                          </v-card>
                            <br />
                          <!-- โฟลเดอร์ -->
                          <v-card
                            class="elevation-0"
                            :color="color.BG"
                            style="cursor: default;"
                            @mousedown="resetFile"
                            v-if="totaldata['totalfolder'] !== 0"
                          >
                            <v-card-title class="pb-2">{{ totaldata["totalfolder"] == 0 ? "" : $t("folders") }}</v-card-title>
                            <v-divider></v-divider>
                            <br />
                            <v-layout justify-center wrap row align-center>
                              <v-flex lg11>
                                <v-row>
                                  <v-col
                                    v-for="(item, index) in rootfile.filter((item) => {
                                      return item.file_type === 'folder' && item.file_status !== 'M';
                                    })"
                                    :key="index"
                                    cols="6"
                                    md="2"
                                    class="pa-2"
                                  >
                                    <drag-selector-item class="drag-selector__item" :value="item">
                                      <v-card
                                        id="borderradius"
                                        outlined
                                        class="pa-0 d-flex align-center"
                                        @contextmenu="rightclickfileandfolder($event, item)"
                                        @dblclick="gotodirectory(item.file_id, item.file_type, item)"
                                        hover
                                        :title="item.file_name"
                                        :color="selectColor(item.file_id, 'card')"
                                        @click.ctrl="removeCheckedListItem(item)"
                                        @click.exact="shiftSelect(item)"
                                        :disabled="
                                          disableformulticlick(
                                            item.status_lock_file || item.status_lock_folder || item.status_lock_data
                                          )
                                        "
                                      >
                                        <!-- v-on:keyup.ctrl.67="test(item)" -->
                                        <!-- v-on:keyup.ctrl="fn_copyfile(item)" -->
                                        <!-- <div>
                                          <v-badge
                                            offset-x="10"
                                            offset-y="6"
                                            color="orange"
                                            :icon="vBadgeIcon(item)"
                                            v-if="
                                              item.file_status_sharelink === 'Y' ||
                                                item.file_status_share === 'Y' ||
                                                item.status_updating === ''
                                            "
                                          >
                                            <v-icon large :color="item.file_icon[1]">
                                              {{ item.file_status === "S" ? "mdi-folder-star" : item.file_icon[0] }}
                                            </v-icon>
                                          </v-badge>
                                          <v-icon v-else large :color="item.file_icon[1]">
                                            {{ item.file_status === "S" ? "mdi-folder-star" : item.file_icon[0] }}
                                          </v-icon>
                                        </div> -->
                                        <v-layout justify-center column align-center>
                                          <v-col class="pa-0">
                                            <!-- <div class="text-center">
                                            <v-icon :color="item.item.file_icon[1]" size="100px">{{
                                              item.item.file_icon[0]
                                            }}</v-icon>
                                          </div> -->
                                            <div class="text-center pa-0">
                                              <v-app-bar dense class="pa-0" flat color="rgba(0, 0, 0, 0)" height="45px" :disabled="disableByPackage"
                                                ><v-app-bar-nav-icon
                                                  dense
                                                  v-if="item.file_status === 'S'"
                                                  class="pa-0"
                                                  color="white"
                                                  @click="setPermissionFileStar(item, 'N', 'st'), (display_grid = true)"
                                                  :disabled="disableByPackage"
                                                  ><v-icon class="pa-0" color="amber">star</v-icon></v-app-bar-nav-icon
                                                >
                                                <v-app-bar-nav-icon
                                                  dense
                                                  v-else
                                                  class="pa-0"
                                                  color="white"
                                                  @click="setPermissionFileStar(item, 'S', 'st'), (display_grid = true)"
                                                  :disabled="disableByPackage"
                                                  ><v-icon color="#D9D9D9" class="pa-0">star_outline</v-icon></v-app-bar-nav-icon
                                                >
                                                <v-spacer></v-spacer>

                                                <v-btn color="white" icon @click="rightclickfileandfolder($event, item)">
                                                  <v-icon color="#D9D9D9">mdi-dots-vertical</v-icon>
                                                </v-btn>
                                              </v-app-bar>
                                              <!-- <v-card-title class="white--text mt-10 pa-0"> -->
                                              <!-- <v-avatar size="56"
                                                ><v-icon :color="item.file_thumbnail_icon[1]" large>{{
                                                  item.file_thumbnail_icon[0]
                                                }}</v-icon>
                                              </v-avatar> -->
                                              <!-- </v-card-title> -->

                                              <v-icon v-if="item.status_lock_data === 'Y'" :color="item.file_icon[1]" size="100px"
                                                >mdi-folder-lock</v-icon
                                              >
                                              <v-icon v-else :color="item.file_icon[1]" size="100px">mdi-folder</v-icon>
                                            </div>

                                            <v-col id="borderradius-word" class="pt-3" :style="word_grid">
                                              <v-layout>
                                                <!-- <v-flex lg9 style="background-color:#F6F7FA"> -->
                                                <!-- <v-badge
                                                color="orange"
                                                :icon="vBadgeIcon(item)"
                                                v-if="item.file_status_sharelink === 'Y' || item.file_status_share === 'Y'"
                                                offset-x="10"
                                                offset-y="10"
                                              >
                                                <div>
                                                  <v-icon
                                                    color="orange"
                                                    v-if="item.file_status === 'S'"
                                                    style="position: absolute;z-index:1;left:-8px;top:-11px"
                                                    >star</v-icon
                                                  > -->
                                                <!-- <v-icon :color="item.file_icon[1]" large>{{ item.file_icon[0] }}</v-icon> -->
                                                <!-- </div> -->
                                                <!-- </v-badge> -->
                                                <!-- <div v-else> -->
                                                <!-- <v-icon
                                                  color="orange"
                                                  v-if="item.file_status === 'S'"
                                                  style="position: absolute;z-index:1;left:-4px;top:117px"
                                                  >star</v-icon
                                                > -->
                                                <!-- <v-icon :color="item.file_icon[1]" large>{{ item.file_icon[0] }}</v-icon> -->
                                                <!-- </div> -->
                                                <!-- </v-flex> -->
                                                <v-flex md9 lg9>
                                                  <div
                                                    style="whiteSpace: nowrap;overflow: hidden;textOverflow: ellipsis;padding-left:8px ;"
                                                    :style="selectColor(item.file_id, 'text')"
                                                  >
                                                    <span style="font-weight: 450; font-size:16px;" v-if="item.status_hilightword === undefined"> 
                                                      {{ item.file_name }}
                                                    </span>
                                                    <span style="font-weight: 450; font-size:16px;"
                                                    v-else v-html="fn_hilightWord(item.file_name)">
                                                    </span>

                                                    <!-- v-if="item.status_expired !== '3' && item.status_expired !== '2' && item.status_expired !== undefined && item.status_expired !== '' && item.status_expired !== null" -->

                                                    <!-- <div> -->
                                                    <!-- <v-chip
                                                      x-small
                                                      dark
                                                      v-show="
                                                        item.status_expired === '0' ||
                                                          item.status_expired === '1' ||
                                                          item.status_expired === '4'
                                                      "
                                                      :color="
                                                        item.status_expired === '0'
                                                          ? 'red'
                                                          : item.status_expired === '1' || item.status_expired === '4'
                                                          ? '#fc9208e6'
                                                          : ''
                                                      "
                                                      >{{
                                                        item.status_expired === "0"
                                                          ? $t("statusfileexpired.expired")
                                                          : item.status_expired === "1" || item.status_expired === "4"
                                                          ? $t("statusfileexpired.nearexpired")
                                                          : ""
                                                      }}</v-chip
                                                    > -->
                                                    <!-- <div v-else-if="item.status_expired === '2'" > -->
                                                    <!-- <v-icon v-show="item.status_expired === '2'" small color="green"
                                                      >access_time</v-icon
                                                    > -->
                                                    <!-- </div> -->
                                                  </div>
                                                </v-flex>
                                                <v-flex md2 lg3 :style="word_grid" class="d-inline-flex justify-end">
                                                  <v-avatar
                                                    title="ข้อมูลใหม่"
                                                    v-if="checkNewDataFormDate(item) === true && checkNewDataNoRefresh(item) === true && checkNewDataRefresh(item) === true || item.onlyofficeeditsecces === true"
                                                    size="24"
                                                    ><v-icon dark medium color="red">mdi-new-box</v-icon></v-avatar
                                                  >
                                                  <v-avatar
                                                    title="แชร์ลิงก์"
                                                    v-if="item.file_status_sharelink === 'Y' || item.file_status_sharelink === 'P'"
                                                    size="20"
                                                    color="#F37958"
                                                    ><v-icon dark small>link</v-icon></v-avatar
                                                  >
                                                  <v-avatar
                                                    title="แชร์ไฟล์"
                                                    v-if="item.file_status_share === 'Y'"
                                                    size="20"
                                                    color="green"
                                                    ><v-icon dark small>group_add</v-icon></v-avatar
                                                  >
                                                  <v-avatar
                                                    title="อัพเดท"
                                                    v-if="item.status_updating === ''"
                                                    size="20"
                                                    color="primary"
                                                    ><v-icon dark small>check</v-icon></v-avatar
                                                  >

                                                  <v-avatar
                                                    title="ไฟล์หมดอายุ"
                                                    v-if="item.status_expired === '0'"
                                                    size="20"
                                                    color="#D50000"
                                                    ><v-icon dark small>mdi-timer</v-icon></v-avatar
                                                  >
                                                  <v-avatar
                                                    title="ไฟล์ใกล้หมดอายุ"
                                                    v-if="item.status_expired === '1'|| item.status_expired === '4'"
                                                    size="20"
                                                    color="#fc9208e6"
                                                    ><v-icon dark small>mdi-timer</v-icon></v-avatar
                                                  >
                                                   <v-avatar
                                                    title="ตั้งค่าวันหมดอายุ"
                                                    v-if="item.status_expired === '2'"
                                                    size="20"
                                                    color="green"
                                                    ><v-icon dark small>mdi-timer</v-icon></v-avatar
                                                  >
                                                   <v-avatar title="relation"
                                                   v-if="item.relation_status === 0"
                                                    size="20"
                                                    color="red">
                                                  <v-icon dark small >device_hub</v-icon></v-avatar>
                                                  <v-avatar title="relation"
                                                   v-else-if="item.relation_status === 1"
                                                    size="20"
                                                    color="amber accent-4">
                                                  <v-icon dark small >device_hub</v-icon></v-avatar>
                                                </v-flex>
                                              </v-layout>
                                              <!-- <v-flex lg2 align-self-center>
                                              <v-icon v-if="item.status_lock_data === 'Y'" color="error" right>lock</v-icon>
                                            </v-flex> -->
                                            </v-col>
                                          </v-col>
                                        </v-layout>
                                        <!-- FILE NAME -->
                                        <!-- <div
                                          style="whiteSpace: nowrap;overflow: hidden;textOverflow: ellipsis;padding-left:8px"
                                          :style="selectColor(item.file_id, 'text')"
                                        >
                                          {{ item.file_name }}
                                          <div>
                                            <v-chip
                                              x-small
                                              dark
                                              v-show="
                                                item.status_expired === '0' ||
                                                  item.status_expired === '1' || item.status_expired === '4'
                                              "
                                              :color="
                                                item.status_expired === '0'
                                                  ? 'red'
                                                  : item.status_expired === '1' || item.status_expired === '4'
                                                  ? '#fc9208e6'
                                                  : 'white'
                                              "
                                              >{{
                                                item.status_expired === "0"
                                                  ? $t("statusfileexpired.expired")
                                                  : item.status_expired === "1" || item.status_expired === '4'
                                                  ? $t("statusfileexpired.nearexpired")
                                                  : ""
                                              }}</v-chip
                                            >
                                            <v-icon v-show="item.status_expired === '2'" small color="green"
                                              >access_time</v-icon
                                            >
                                          </div>
                                          <div>
                                           
                                          </div>
                                        </div> -->
                                        <!-- <v-spacer></v-spacer>
                                        <v-icon v-if="item.status_lock_folder === 'Y' || item.status_lock_data === 'Y'" color="error">lock</v-icon> -->
                                      </v-card>
                                    </drag-selector-item>
                                  </v-col>
                                </v-row>
                              </v-flex>
                            </v-layout>
                          </v-card>
                          <v-card
                            v-if="rootfile.length === 0 && disablescrolling === true && loaddataprogress === false"
                            class="pb-4 elevation-0"
                            :color="color.BG"
                            style="cursor: default;"
                            @mousedown="resetFile"
                          >
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title
                                  class="text-center"
                                  v-text="heardertextsearch === false ? $t('tablefile.empty') : $t('tablefile.emptysearch')"
                                ></v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-card>
                        </drag-selector>
                        <drag-selector v-model="checkedList" @change="fn_handleDragSelectorChange(checkedList)" v-else>
                          <!-- โฟลเดอร์แผนก -->
                          <v-card
                            v-if="
                              rootfile.filter((item) => {
                                return item.file_status === 'M';
                              }).length > 0
                            "
                           
                            class="elevation-0"
                            :color="color.BG"
                            style="cursor: default;"
                            @mousedown="resetFile"
                          >
                            <v-card-title
                              class="pb-2"
                              v-if="
                                rootfile.filter((item) => {
                                  return item.file_status === 'M';
                                }).length > 0
                              "
                              >{{ $t("company") }}</v-card-title
                            >
                            <v-divider  v-if="
                                rootfile.filter((item) => {
                                  return item.file_status === 'M';
                                }).length > 0
                              "></v-divider>
                            <br />
                            <v-layout justify-center wrap row align-center>
                              <v-flex lg11>
                                <v-row>
                                  <v-col
                                    v-for="(item, index) in rootfile.filter((item) => {
                                      return item.file_status === 'M';
                                    })"
                                    :key="index"
                                    cols="6"
                                    md="2"
                                    class="pa-2"
                                  >
                                    <drag-selector-item class="drag-selector__item" :value="item">
                                      <v-card
                                        id="borderradius"
                                        outlined
                                        class="pa-0 d-flex align-center"
                                        @contextmenu="rightclickfileandfolder($event, item)"
                                        @dblclick="gotodirectory(item.file_id, item.file_type, item)"
                                        hover
                                        :title="item.file_name"
                                        :color="selectColor(item.file_id, 'card')"
                                        @click.ctrl="removeCheckedListItem(item)"
                                        @click.exact="shiftSelect(item)"
                                        :disabled="
                                          disableformulticlick(
                                            item.status_lock_file || item.status_lock_folder || item.status_lock_data
                                          )
                                        "
                                      >
                                        <v-layout justify-center column align-center>
                                          <v-col class="pa-0">
                                            <!-- <div class="text-center">
                                            <v-icon :color="item.item.file_icon[1]" size="100px">{{
                                              item.item.file_icon[0]
                                            }}</v-icon>
                                          </div> -->
                                            <div class="text-center pa-0">
                                              <v-app-bar class="pa-0" flat color="rgba(0, 0, 0, 0)" height="45px" :disabled="disableByPackage"
                                                ><v-app-bar-nav-icon
                                                  v-if="item.file_status === 'S'"
                                                  class="pa-0"
                                                  color="white"
                                                  @click="setPermissionFileStar(item, 'N', 'st'), (display_grid = true)"
                                                  :disabled="disableByPackage"
                                                  ><v-icon class="pa-0" color="amber">star</v-icon>
                                                  <v-avatar
                                                    v-if="item.file_owner_eng === 'Me'"
                                                    title="ฉัน"
                                                    size="20"
                                                    :color="color.theme"
                                                    >
                                                    <v-icon dark size="18" >mdi-account-outline</v-icon>
                                                  </v-avatar>
                                                  </v-app-bar-nav-icon
                                                >
                                                <v-app-bar-nav-icon
                                                  v-else
                                                  class="pa-0"
                                                  color="white"
                                                  @click="setPermissionFileStar(item, 'S', 'st'), (display_grid = true)"
                                                  :disabled="disableByPackage"
                                                  ><v-icon color="#D9D9D9" class="pa-0">star_outline</v-icon>
                                                  <v-avatar
                                                    v-if="item.file_owner_eng === 'Me'"
                                                    title="ฉัน"
                                                    size="20"
                                                    :color="color.theme"
                                                    >
                                                    <v-icon dark size="18" >mdi-account-outline</v-icon>
                                                  </v-avatar>
                                                  </v-app-bar-nav-icon
                                                >
                                                <v-spacer></v-spacer>

                                                <v-btn color="white" icon @click="rightclickfileandfolder($event, item)">
                                                  <v-icon color="#D9D9D9">mdi-dots-vertical</v-icon>
                                                </v-btn>
                                              </v-app-bar>
                                              <v-icon v-if="item.status_lock_data === 'Y'" :color="item.file_icon[1]" size="100px"
                                                >mdi-folder-lock</v-icon
                                              >
                                              <v-icon v-else :color="item.file_icon[1]" size="100px">{{
                                                item.file_icon[0]
                                              }}</v-icon>
                                            </div>

                                            <v-col id="borderradius-word" class="pt-3" :style="word_grid">
                                              <v-layout>
                                                <v-flex md9 lg9>
                                                  <div
                                                    style="whiteSpace: nowrap;overflow: hidden;textOverflow: ellipsis;padding-left:8px ;"
                                                    :style="selectColor(item.file_id, 'text')"
                                                  >
                                                    <span style="font-weight: 450; font-size:16px;">
                                                      {{ item.file_name }}
                                                    </span>
                                                  </div>
                                                </v-flex>
                                                <v-flex md2 lg3 :style="word_grid" class="d-inline-flex justify-end">
                                                  <v-avatar
                                                    title="ข้อมูลใหม่"
                                                    v-if="checkNewDataFormDate(item) === true && checkNewDataNoRefresh(item) === true && checkNewDataRefresh(item) === true || item.onlyofficeeditsecces === true"
                                                    size="24"
                                                    ><v-icon dark medium color="red">mdi-new-box</v-icon></v-avatar
                                                  >
                                                  <v-avatar
                                                    title="แชร์ลิงก์"
                                                    v-if="item.file_status_sharelink === 'Y' || item.file_status_sharelink === 'P'"
                                                    size="20"
                                                    color="#F37958"
                                                    ><v-icon dark small>link</v-icon></v-avatar
                                                  >
                                                  <v-avatar
                                                    title="แชร์ไฟล์"
                                                    v-if="item.file_status_share === 'Y'"
                                                    size="20"
                                                    color="green"
                                                    ><v-icon dark small>group_add</v-icon></v-avatar
                                                  >
                                                  <v-avatar
                                                    title="อัพเดท"
                                                    v-if="item.status_updating === ''"
                                                    size="20"
                                                    color="primary"
                                                    ><v-icon dark small>check</v-icon></v-avatar
                                                  >

                                                  <v-avatar
                                                    title="ไฟล์หมดอายุ"
                                                    v-if="item.status_expired === '0'"
                                                    size="20"
                                                    color="#D50000"
                                                    ><v-icon dark small>mdi-timer</v-icon></v-avatar
                                                  >
                                                  <v-avatar
                                                    title="ไฟล์ใกล้หมดอายุ"
                                                    v-if="item.status_expired === '1' || item.status_expired === '4'"
                                                    size="20"
                                                    color="#fc9208e6"
                                                    ><v-icon dark small>mdi-timer</v-icon></v-avatar
                                                  >
                                                   <v-avatar
                                                    title="ตั้งค่าวันหมดอายุ"
                                                    v-if="item.status_expired === '2'"
                                                    size="20"
                                                    color="green"
                                                    ><v-icon dark small>mdi-timer</v-icon></v-avatar
                                                  >
                                                  <v-avatar title="relation"
                                                   v-if="item.relation_status === 0"
                                                    size="20"
                                                    color="red">
                                                  <v-icon dark small >device_hub</v-icon></v-avatar>
                                                  <v-avatar title="relation"
                                                   v-else-if="item.relation_status === 1"
                                                    size="20"
                                                    color="amber accent-4">
                                                  <v-icon dark small >device_hub</v-icon></v-avatar>
                                            
                                                </v-flex>
                                              </v-layout>
                                              <!-- <v-flex lg2 align-self-center>
                                              <v-icon v-if="item.status_lock_data === 'Y'" color="error" right>lock</v-icon>
                                            </v-flex> -->
                                            </v-col>
                                          </v-col>
                                        </v-layout>
                                        <!-- v-on:keyup.ctrl.67="test(item)" -->
                                        <!-- v-on:keyup.ctrl="fn_copyfile(item)" -->
                                        <!-- <v-badge
                                          offset-x="10"
                                          offset-y="6"
                                          color="orange"
                                          :icon="vBadgeIcon(item)"
                                          v-if="
                                            item.file_status_sharelink === 'Y' ||
                                              item.file_status_share === 'Y' ||
                                              item.status_updating === ''
                                          "
                                        >
                                          <v-icon large :color="item.file_icon[1]">
                                            {{ item.file_status === "S" ? "mdi-folder-star" : item.file_icon[0] }}
                                          </v-icon>
                                        </v-badge> -->

                                        <!-- <v-icon v-else large :color="item.file_icon[1]">
                                          {{ item.file_status === "S" ? "mdi-folder-star" : item.file_icon[0] }}
                                        </v-icon>
                                        <div style="overflow: hidden;">
                                          <div
                                            style="whiteSpace: nowrap;overflow: hidden;textOverflow: ellipsis;padding-left:8px"
                                            :style="selectColor(item.file_id, 'text')"
                                          >
                                            {{ item.file_name }}
                                          </div>
                                          <v-chip
                                            x-small
                                            dark
                                            v-show="item.status_expired === '0' || item.status_expired === '1' || item.status_expired === '4'" :color="
                                              item.status_expired === '0'
                                                ? 'red'
                                                : item.status_expired === '1' || item.status_expired === '4'
                                                ? '#fc9208e6'
                                                : 'white'
                                            "
                                            >{{
                                              item.status_expired === "0"
                                                ? $t("statusfileexpired.expired")
                                                : item.status_expired === "1" || item.status_expired === "4"
                                                ? $t("statusfileexpired.nearexpired")
                                                : ""
                                            }}</v-chip
                                          >
                                        </div> -->
                                        <!-- <v-icon class="pr-1" color="red" x-small v-if="item.relation_status === 0">device_hub</v-icon>
                                            <v-icon class="pr-1" color="amber accent-4" x-small v-else-if="item.relation_status === 1">device_hub</v-icon>-->
                                        <!-- <v-spacer></v-spacer> -->
                                        <!-- icon lock -->
                                        <!-- <v-icon v-if="item.status_lock_folder === 'Y'" color="error">lock</v-icon> -->
                                      </v-card>
                                    </drag-selector-item>
                                  </v-col>
                                </v-row>
                              </v-flex>
                            </v-layout>
                          </v-card>
                          <br />
                          <!-- โฟลเดอร์ -->
                          <v-card
                            class="elevation-0"
                            :color="color.BG"
                            style="cursor: default;"
                            @mousedown="resetFile"
                            v-if="totaldata['totalfolder'] !== 0"
                          >
                            <v-card-title class="pb-2">{{ totaldata["totalfolder"] == 0 ? "" : $t("folders") }}</v-card-title>
                            <v-divider></v-divider>
                            <br />
                            <v-layout justify-center wrap row align-center>
                              <v-flex lg11>
                                <v-row>
                                  <v-col
                                    v-for="(item, index) in rootfile.filter((item) => {
                                      return item.file_type === 'folder' && item.file_status !== 'M';
                                    })"
                                    :key="index"
                                    cols="6"
                                    md="2"
                                    class="pa-2"
                                  >
                                    <drag-selector-item class="drag-selector__item" :value="item">
                                      <v-card
                                        id="borderradius"
                                        outlined
                                        class="pa-0 d-flex align-center"
                                        @contextmenu="rightclickfileandfolder($event, item)"
                                        @dblclick="gotodirectory(item.file_id, item.file_type, item)"
                                        hover
                                        :title="item.file_name"
                                        :color="selectColor(item.file_id, 'card')"
                                        @click.ctrl="removeCheckedListItem(item)"
                                        @click.exact="shiftSelect(item)"
                                        :disabled="
                                          disableformulticlick(
                                            item.status_lock_file || item.status_lock_folder || item.status_lock_data
                                          )
                                        "
                                      >
                                        <!-- v-on:keyup.ctrl.67="test(item)" -->
                                        <!-- v-on:keyup.ctrl="fn_copyfile(item)" -->
                                        <!-- <div>
                                          <v-badge
                                            offset-x="10"
                                            offset-y="6"
                                            color="orange"
                                            :icon="vBadgeIcon(item)"
                                            v-if="
                                              item.file_status_sharelink === 'Y' ||
                                                item.file_status_share === 'Y' ||
                                                item.status_updating === ''
                                            "
                                          >
                                            <v-icon large :color="item.file_icon[1]">
                                              {{ item.file_status === "S" ? "mdi-folder-star" : item.file_icon[0] }}
                                            </v-icon>
                                          </v-badge>
                                          <v-icon v-else large :color="item.file_icon[1]">
                                            {{ item.file_status === "S" ? "mdi-folder-star" : item.file_icon[0] }}
                                          </v-icon>
                                        </div> -->
                                        <v-layout justify-center column align-center>
                                          <v-col class="pa-0">
                                            <!-- <div class="text-center">
                                            <v-icon :color="item.item.file_icon[1]" size="100px">{{
                                              item.item.file_icon[0]
                                            }}</v-icon>
                                          </div> -->
                                            <div class="text-center pa-0">
                                              <v-app-bar dense class="pa-0" flat color="rgba(0, 0, 0, 0)" height="45px" :disabled="disableByPackage"
                                                ><v-app-bar-nav-icon
                                                  dense
                                                  v-if="item.file_status === 'S'"
                                                  class="pa-0"
                                                  color="white"
                                                  @click="setPermissionFileStar(item, 'N', 'st'), (display_grid = true)"
                                                  :disabled="disableByPackage"
                                                  ><v-icon class="pa-0" color="amber">star</v-icon>
                                                  <!-- โฟลเดอร์ -->
                                                  <v-avatar
                                                    v-if="item.file_owner_eng === 'Me'"
                                                    title="ฉัน"
                                                    size="20"
                                                    :color="color.theme"
                                                    >
                                                    <v-icon dark size="18" >mdi-account-outline</v-icon>
                                                  </v-avatar>
                                                  </v-app-bar-nav-icon
                                                >
                                                <v-app-bar-nav-icon
                                                  dense
                                                  v-else
                                                  class="pa-0"
                                                  color="white"
                                                  @click="setPermissionFileStar(item, 'S', 'st'), (display_grid = true)"
                                                  :disabled="disableByPackage"
                                                  ><v-icon color="#D9D9D9" class="pa-0">star_outline</v-icon>
                                                  <v-avatar
                                                    v-if="item.file_owner_eng === 'Me'"
                                                    title="ฉัน"
                                                    size="20"
                                                    :color="color.theme"
                                                    >
                                                    <v-icon dark size="18" >mdi-account-outline</v-icon>
                                                  </v-avatar>
                                                  </v-app-bar-nav-icon
                                                >
                                                <v-spacer></v-spacer>

                                                <v-btn color="white" icon @click="rightclickfileandfolder($event, item)">
                                                  <v-icon color="#D9D9D9">mdi-dots-vertical</v-icon>
                                                </v-btn>
                                              </v-app-bar>
                                              <!-- <v-card-title class="white--text mt-10 pa-0"> -->
                                              <!-- <v-avatar size="56"
                                                ><v-icon :color="item.file_thumbnail_icon[1]" large>{{
                                                  item.file_thumbnail_icon[0]
                                                }}</v-icon>
                                              </v-avatar> -->
                                              <!-- </v-card-title> -->

                                              <v-icon v-if="item.status_lock_data === 'Y'" :color="item.file_icon[1]" size="100px"
                                                >mdi-folder-lock</v-icon
                                              >
                                              <v-icon v-else :color="item.file_icon[1]" size="100px">mdi-folder</v-icon>
                                            </div>

                                            <v-col id="borderradius-word" class="pt-3" :style="word_grid">
                                              <v-layout>
                                                <!-- <v-flex lg9 style="background-color:#F6F7FA"> -->
                                                <!-- <v-badge
                                                color="orange"
                                                :icon="vBadgeIcon(item)"
                                                v-if="item.file_status_sharelink === 'Y' || item.file_status_share === 'Y'"
                                                offset-x="10"
                                                offset-y="10"
                                              >
                                                <div>
                                                  <v-icon
                                                    color="orange"
                                                    v-if="item.file_status === 'S'"
                                                    style="position: absolute;z-index:1;left:-8px;top:-11px"
                                                    >star</v-icon
                                                  > -->
                                                <!-- <v-icon :color="item.file_icon[1]" large>{{ item.file_icon[0] }}</v-icon> -->
                                                <!-- </div> -->
                                                <!-- </v-badge> -->
                                                <!-- <div v-else> -->
                                                <!-- <v-icon
                                                  color="orange"
                                                  v-if="item.file_status === 'S'"
                                                  style="position: absolute;z-index:1;left:-4px;top:117px"
                                                  >star</v-icon
                                                > -->
                                                <!-- <v-icon :color="item.file_icon[1]" large>{{ item.file_icon[0] }}</v-icon> -->
                                                <!-- </div> -->
                                                <!-- </v-flex> -->
                                                <v-flex md9 lg9>
                                                  <div
                                                    style="whiteSpace: nowrap;overflow: hidden;textOverflow: ellipsis;padding-left:8px ;"
                                                    :style="selectColor(item.file_id, 'text')"
                                                  >
                                                    <span style="font-weight: 450; font-size:16px;"
                                                    v-if="item.status_hilightword === undefined" > 
                                                      {{ item.file_name }}
                                                    </span>
                                                    <span style="font-weight: 450; font-size:16px;"
                                                    v-else
                                                    v-html="fn_hilightWord(item.file_name)"
                                                    >
                                                    </span>
                                                    <!-- v-if="item.status_expired !== '3' && item.status_expired !== '2' && item.status_expired !== undefined && item.status_expired !== '' && item.status_expired !== null" -->

                                                    <!-- <div> -->
                                                    <!-- <v-chip
                                                      x-small
                                                      dark
                                                      v-show="
                                                        item.status_expired === '0' ||
                                                          item.status_expired === '1' ||
                                                          item.status_expired === '4'
                                                      "
                                                      :color="
                                                        item.status_expired === '0'
                                                          ? 'red'
                                                          : item.status_expired === '1' || item.status_expired === '4'
                                                          ? '#fc9208e6'
                                                          : ''
                                                      "
                                                      >{{
                                                        item.status_expired === "0"
                                                          ? $t("statusfileexpired.expired")
                                                          : item.status_expired === "1" || item.status_expired === "4"
                                                          ? $t("statusfileexpired.nearexpired")
                                                          : ""
                                                      }}</v-chip
                                                    > -->
                                                    <!-- <div v-else-if="item.status_expired === '2'" > -->
                                                    <!-- <v-icon v-show="item.status_expired === '2'" small color="green"
                                                      >access_time</v-icon
                                                    > -->
                                                    <!-- </div> -->
                                                  </div>
                                                </v-flex>
                                                <v-flex md2 lg3 :style="word_grid" class="d-inline-flex justify-end">
                                                  <v-avatar
                                                    title="ข้อมูลใหม่"
                                                    v-if="checkNewDataFormDate(item) === true && checkNewDataNoRefresh(item) === true && checkNewDataRefresh(item) === true || item.onlyofficeeditsecces === true"
                                                    size="24"
                                                    ><v-icon dark medium color="red">mdi-new-box</v-icon></v-avatar
                                                  >
                                                  <v-avatar
                                                    title="แชร์ลิงก์"
                                                    v-if="item.file_status_sharelink === 'Y' || item.file_status_sharelink === 'P'"
                                                    size="20"
                                                    color="#F37958"
                                                    ><v-icon dark small>link</v-icon></v-avatar
                                                  >
                                                  <v-avatar
                                                    title="แชร์ไฟล์"
                                                    v-if="item.file_status_share === 'Y'"
                                                    size="20"
                                                    color="green"
                                                    ><v-icon dark small>group_add</v-icon></v-avatar
                                                  >
                                                  <v-avatar
                                                    title="อัพเดท"
                                                    v-if="item.status_updating === ''"
                                                    size="20"
                                                    color="primary"
                                                    ><v-icon dark small>check</v-icon></v-avatar
                                                  >

                                                  <v-avatar
                                                    title="ไฟล์หมดอายุ"
                                                    v-if="item.status_expired === '0'"
                                                    size="20"
                                                    color="#D50000"
                                                    ><v-icon dark small>mdi-timer</v-icon></v-avatar
                                                  >
                                                  <v-avatar
                                                    title="ไฟล์ใกล้หมดอายุ"
                                                    v-if="item.status_expired === '1' || item.status_expired === '4'"
                                                    size="20"
                                                    color="#fc9208e6"
                                                    ><v-icon dark small>mdi-timer</v-icon></v-avatar
                                                  >
                                                   <v-avatar
                                                    title="ตั้งค่าวันหมดอายุ"
                                                    v-if="item.status_expired === '2'"
                                                    size="20"
                                                    color="green"
                                                    ><v-icon dark small>mdi-timer</v-icon></v-avatar
                                                  >
                                                   <v-avatar title="relation"
                                                   v-if="item.relation_status === 0"
                                                    size="20"
                                                    color="red">
                                                  <v-icon dark small >device_hub</v-icon></v-avatar>
                                                  <v-avatar title="relation"
                                                   v-else-if="item.relation_status === 1"
                                                    size="20"
                                                    color="amber accent-4">
                                                  <v-icon dark small >device_hub</v-icon></v-avatar>
                                                </v-flex>
                                              </v-layout>
                                              <!-- <v-flex lg2 align-self-center>
                                              <v-icon v-if="item.status_lock_data === 'Y'" color="error" right>lock</v-icon>
                                            </v-flex> -->
                                            </v-col>
                                          </v-col>
                                        </v-layout>
                                        <!-- FILE NAME -->
                                        <!-- <div
                                          style="whiteSpace: nowrap;overflow: hidden;textOverflow: ellipsis;padding-left:8px"
                                          :style="selectColor(item.file_id, 'text')"
                                        >
                                          {{ item.file_name }}
                                          <div>
                                            <v-chip
                                              x-small
                                              dark
                                              v-show="
                                                item.status_expired === '0' ||
                                                  item.status_expired === '1' || item.status_expired === '4'
                                              "
                                              :color="
                                                item.status_expired === '0'
                                                  ? 'red'
                                                  : item.status_expired === '1' || item.status_expired === '4'
                                                  ? '#fc9208e6'
                                                  : 'white'
                                              "
                                              >{{
                                                item.status_expired === "0"
                                                  ? $t("statusfileexpired.expired")
                                                  : item.status_expired === "1" || item.status_expired === '4'
                                                  ? $t("statusfileexpired.nearexpired")
                                                  : ""
                                              }}</v-chip
                                            >
                                            <v-icon v-show="item.status_expired === '2'" small color="green"
                                              >access_time</v-icon
                                            >
                                          </div>
                                          <div>
                                           
                                          </div>
                                        </div> -->
                                        <!-- <v-spacer></v-spacer>
                                        <v-icon v-if="item.status_lock_folder === 'Y' || item.status_lock_data === 'Y'" color="error">lock</v-icon> -->
                                      </v-card>
                                    </drag-selector-item>
                                  </v-col>
                                </v-row>
                              </v-flex>
                            </v-layout>
                          </v-card>
                          <br />
                          <!-- ไฟล์ -->
                          <v-card
                            class="elevation-0"
                            :color="color.BG"
                            style="cursor: default;"
                            @mousedown="resetFile"
                            v-if="totaldata['totalfile'] !== 0"
                          >
                            <v-card-title class="pb-2">{{ totaldata["totalfile"] == 0 ? "" : $t("files") }}</v-card-title>
                            <v-divider></v-divider>
                            <br />
                            <v-layout justify-center wrap row align-center>
                              <v-flex lg11>
                                <v-row>
                                  <v-col
                                    v-for="(item, index) in rootfile.filter((item) => {
                                      return item.file_type !== 'folder';
                                    })"
                                    :key="index"
                                    cols="6"
                                    md="2"
                                    class="pa-2"
                                  >
                                    <drag-selector-item class="drag-selector__item" :value="item">
                                      <v-tooltip 
                                      color="#6b6b6b"
                                      :right="((index+1) % 6) === 0 ? false : true" 
                                      :left="((index+1) % 6) === 0 ? true : false"
                                      v-model="idPointed === item.file_id ? isOpenTooltip : false">
                                      <template v-slot:activator="{ on }">
                                      <v-card
                                        id="borderradius"
                                        outlined
                                        class="pa-0 d-flex align-center"
                                        hover
                                        @contextmenu="rightclickfileandfolder($event, item)"
                                        @dblclick="gotodirectory(item.file_id, item.file_type, item)"
                                        :title="officefilescompatible.includes(item.file_type) ? idPointed === item.file_id ? isOpenTooltip ? null : item.file_name : item.file_name : item.file_name"
                                        :color="selectColor(item.file_id, 'card')"
                                        @click.ctrl="removeCheckedListItem(item)"
                                        @click.exact="shiftSelect(item)"
                                        :disabled="
                                          disableformulticlick(
                                            item.status_lock_file || item.status_lock_folder || item.status_lock_data
                                          )
                                        "
                                        :v-on="isOpenTooltip ? on : null" 
                                        @mouseover="officefilescompatible.includes(item.file_type) ? mouseOverFile_onlyoffice(item) : null"
                                        @mouseleave="mouseLeaveFile_onlyoffice"
                                      >
                                        <!-- v-on:keyup.ctrl.67="test(item)" -->
                                        <!-- v-on:keyup.ctrl="fn_copyfile(item)" -->
                                        <v-layout justify-center column align-center>
                                          <v-col class="pa-0">
                                            <!-- Thumbnail กับ Icon -->
                                            <v-img
                                              :src="item.file_thumbnail"
                                              max-height="145px"
                                              height="145px"
                                              id="borderradius-img"
                                              v-if="item.file_thumbnail != '' && item.editing_status === 'N'"
                                              :style="selectCardBgColor(item)"
                                            >
                                              <v-app-bar dense class="pa-0" flat color="rgba(0, 0, 0, 0)" height="45px" :disabled="disableByPackage"
                                                ><v-app-bar-nav-icon
                                                  v-if="item.file_status === 'S'"
                                                  class="pa-0"
                                                  color="white"
                                                  @click="setPermissionFileStar(item, 'N', 'st'), (display_grid = true)"
                                                  :disabled="disableByPackage"
                                                  ><v-icon class="pa-0" color="amber">star</v-icon>
                                                  <v-avatar
                                                    v-if="item.file_owner_eng === 'Me'"
                                                    title="ฉัน"
                                                    size="20"
                                                    :color="color.theme"
                                                    >
                                                    <v-icon dark size="18" >mdi-account-outline</v-icon>
                                                  </v-avatar>
                                                  </v-app-bar-nav-icon
                                                >
                                                <v-app-bar-nav-icon
                                                  v-else
                                                  class="pa-0"
                                                  color="white"
                                                  @click="setPermissionFileStar(item, 'S', 'st'), (display_grid = true)"
                                                  :disabled="disableByPackage"
                                                  ><v-icon color="#D9D9D9" class="pa-0">star_outline</v-icon>
                                                  <v-avatar
                                                    v-if="item.file_owner_eng === 'Me'"
                                                    title="ฉัน"
                                                    size="20"
                                                    :color="color.theme"
                                                    >
                                                    <v-icon dark size="18" >mdi-account-outline</v-icon>
                                                  </v-avatar>
                                                  </v-app-bar-nav-icon
                                                >
                                                <v-spacer></v-spacer>

                                                <v-btn color="white" icon @click="rightclickfileandfolder($event, item)">
                                                  <v-icon color="#D9D9D9">mdi-dots-vertical</v-icon>
                                                </v-btn>
                                              </v-app-bar>
                                              <v-card-title class="white--text mt-12 pa-0">
                                                <v-avatar size="56"
                                                  ><v-icon :color="item.file_icon[1]" large>{{ item.file_icon[0] }}</v-icon>
                                                </v-avatar>
                                              </v-card-title>
                                              <v-spacer></v-spacer>
                                            </v-img>
                                            <div
                                              v-else-if="item.status_lock_data === 'Y' || item.editing_status === 'Y' || item.editing_status === 'S'"
                                              class="text-center pa-0"
                                              id="borderradius-img"
                                            >
                                              <v-app-bar class="pa-0" flat color="rgba(0, 0, 0, 0)" height="45px" :disabled="disableByPackage"
                                                ><v-app-bar-nav-icon
                                                  v-if="item.file_status === 'S'"
                                                  class="pa-0"
                                                  color="white"
                                                  @click="setPermissionFileStar(item, 'N', 'st'), (display_grid = true)"
                                                  :disabled="disableByPackage"
                                                  ><v-icon class="pa-0" color="amber">star</v-icon>
                                                  <v-avatar
                                                    v-if="item.file_owner_eng === 'Me'"
                                                    title="ฉัน"
                                                    size="20"
                                                    :color="color.theme"
                                                    >
                                                    <v-icon dark size="18" >mdi-account-outline</v-icon>
                                                  </v-avatar>
                                                  </v-app-bar-nav-icon
                                                >
                                                <v-app-bar-nav-icon
                                                  v-else
                                                  class="pa-0"
                                                  color="white"
                                                  @click="setPermissionFileStar(item, 'S', 'st'), (display_grid = true)"
                                                  :disabled="disableByPackage"
                                                  ><v-icon color="#D9D9D9" class="pa-0">star_outline</v-icon>
                                                  <v-avatar
                                                    v-if="item.file_owner_eng === 'Me'"
                                                    title="ฉัน"
                                                    size="20"
                                                    :color="color.theme"
                                                    >
                                                    <v-icon dark size="18" >mdi-account-outline</v-icon>
                                                  </v-avatar>
                                                  </v-app-bar-nav-icon
                                                >
                                                <v-spacer></v-spacer>

                                                <v-btn color="white" icon @click="rightclickfileandfolder($event, item)">
                                                  <v-icon color="#D9D9D9">mdi-dots-vertical</v-icon>
                                                </v-btn>
                                              </v-app-bar>
                                              <v-card-title class="white--text pa-0" :style="selectCardBgColor(item)">
                                                <v-avatar class="mt-12" size="56"
                                                  ><v-icon :color="item.file_icon[1]" large>{{ item.file_icon[0] }}</v-icon>
                                                </v-avatar>
                                                <div style="position: absolute; right: 0; left: 0; top: 25%;">
                                                  <v-icon color="#999999" size="50px">mdi-lock-outline</v-icon>
                                                </div>
                                                <div
                                                  style="position: absolute; z-index: 9; right: 25%; left: 25%; top: 60%;"
                                                  v-show="dataAccountActive.type === 'Business' && item.editing_status === 'Y'"
                                                >
                                                  <v-chip x-small color="primary" v-if="$t('default') === 'th'"
                                                    >{{ item.last_update_name.name_th }} กำลังแก้ไข</v-chip
                                                  >
                                                  <v-chip x-small color="primary" v-else
                                                    >{{ item.last_update_name.name_eng }} editting</v-chip
                                                  >
                                                </div>
                                                <div
                                                  style="position: absolute; z-index: 9; right: 25%; left: 25%; top: 60%;"
                                                  v-show="dataAccountActive.type === 'Business' && item.editing_status === 'S'"
                                                >
                                                  <v-chip x-small color="primary" v-if="$t('default') === 'th'"
                                                    >กำลังซิงค์...</v-chip
                                                  >
                                                  <v-chip x-small color="primary" v-else
                                                    >Syncing...</v-chip
                                                  >
                                                </div>
                                              </v-card-title>
                                            </div>
                                            <div
                                              v-else
                                              class="text-center pa-0"
                                              id="borderradius-img"
                                            >
                                              <v-app-bar class="pa-0" flat color="rgba(0, 0, 0, 0)" height="45px" :disabled="disableByPackage"
                                                ><v-app-bar-nav-icon
                                                  v-if="item.file_status === 'S'"
                                                  class="pa-0"
                                                  color="white"
                                                  @click="setPermissionFileStar(item, 'N', 'st'), (display_grid = true)"
                                                  :disabled="disableByPackage"
                                                  ><v-icon class="pa-0" color="amber">star</v-icon>
                                                  <v-avatar
                                                    v-if="item.file_owner_eng === 'Me'"
                                                    title="ฉัน"
                                                    size="20"
                                                    :color="color.theme"
                                                    >
                                                    <v-icon dark size="18" >mdi-account-outline</v-icon>
                                                  </v-avatar>
                                                  </v-app-bar-nav-icon
                                                >
                                                <v-app-bar-nav-icon
                                                  v-else
                                                  class="pa-0"
                                                  color="white"
                                                  @click="setPermissionFileStar(item, 'S', 'st'), (display_grid = true)"
                                                  :disabled="disableByPackage"
                                                  ><v-icon color="#D9D9D9" class="pa-0">star_outline</v-icon>
                                                  <v-avatar
                                                    v-if="item.file_owner_eng === 'Me'"
                                                    title="ฉัน"
                                                    size="20"
                                                    :color="color.theme"
                                                    >
                                                    <v-icon dark size="18" >mdi-account-outline</v-icon>
                                                  </v-avatar>
                                                  </v-app-bar-nav-icon
                                                >
                                                <v-spacer></v-spacer>

                                                <v-btn color="white" icon @click="rightclickfileandfolder($event, item)">
                                                  <v-icon color="#D9D9D9">mdi-dots-vertical</v-icon>
                                                </v-btn>
                                              </v-app-bar>
                                              <v-icon :color="item.file_thumbnail_icon[1]" size="100px" :style="selectCardBgColor(item)">{{
                                                item.file_thumbnail_icon[0]
                                              }}</v-icon>
                                            </div>
                                            <v-col id="borderradius-word" class="pt-3" :style="word_grid">
                                              <v-layout >
                                                <!-- <v-flex lg9 style="background-color:#F6F7FA"> -->
                                                <!-- <v-badge
                                                color="orange"
                                                :icon="vBadgeIcon(item)"
                                                v-if="item.file_status_sharelink === 'Y' || item.file_status_share === 'Y'"
                                                offset-x="10"
                                                offset-y="10"
                                              >
                                                <div>
                                                  <v-icon
                                                    color="orange"
                                                    v-if="item.file_status === 'S'"
                                                    style="position: absolute;z-index:1;left:-8px;top:-11px"
                                                    >star</v-icon
                                                  > -->
                                                <!-- <v-icon :color="item.file_icon[1]" large>{{ item.file_icon[0] }}</v-icon> -->
                                                <!-- </div> -->
                                                <!-- </v-badge> -->
                                                <!-- <div v-else> -->
                                                <!-- <v-icon
                                                  color="orange"
                                                  v-if="item.file_status === 'S'"
                                                  style="position: absolute;z-index:1;left:-4px;top:117px"
                                                  >star</v-icon
                                                > -->
                                                <!-- <v-icon :color="item.file_icon[1]" large>{{ item.file_icon[0] }}</v-icon> -->
                                                <!-- </div> -->
                                                <!-- </v-flex> -->
                                                <v-flex md9 lg9 >
                                                  <div
                                                    style="whiteSpace: nowrap;overflow: hidden;textOverflow: ellipsis;padding-left:8px ;"
                                                    :style="selectColor(item.file_id, 'text')"
                                                  >
                                                    <span style="font-weight: 450; font-size:16px;"
                                                    v-if="item.status_hilightword === undefined">
                                                      {{ item.file_name }}
                                                    </span>
                                                    <span style="font-weight: 450; font-size:16px;"
                                                    v-else
                                                    v-html="fn_hilightWord(item.file_name)">
                                                    </span>
                                                    <!-- v-if="item.status_expired !== '3' && item.status_expired !== '2' && item.status_expired !== undefined && item.status_expired !== '' && item.status_expired !== null" -->

                                                    <!-- <div> -->
                                                    <!-- <v-chip
                                                      x-small
                                                      dark
                                                      v-show="
                                                        item.status_expired === '0' ||
                                                          item.status_expired === '1' ||
                                                          item.status_expired === '4'
                                                      "
                                                      :color="
                                                        item.status_expired === '0'
                                                          ? 'red'
                                                          : item.status_expired === '1' || item.status_expired === '4'
                                                          ? '#fc9208e6'
                                                          : ''
                                                      "
                                                      >{{
                                                        item.status_expired === "0"
                                                          ? $t("statusfileexpired.expired")
                                                          : item.status_expired === "1" || item.status_expired === "4"
                                                          ? $t("statusfileexpired.nearexpired")
                                                          : ""
                                                      }}</v-chip
                                                    > -->
                                                    <!-- <div v-else-if="item.status_expired === '2'" > -->
                                                    <!-- <v-icon v-show="item.status_expired === '2'" small color="green"
                                                      >access_time</v-icon
                                                    > -->
                                                    <!-- </div> -->
                                                  </div>
                                                </v-flex>
                                                <v-flex md2 lg3 :style="word_grid" class="d-inline-flex justify-end">
                                                  <v-avatar
                                                    title="ข้อมูลใหม่"
                                                    v-if="checkNewDataFormDate(item) === true && checkNewDataNoRefresh(item) === true && checkNewDataRefresh(item) === true || item.onlyofficeeditsecces === true"
                                                    size="24"
                                                    ><v-icon dark medium color="red">mdi-new-box</v-icon></v-avatar
                                                  >
                                                  <v-avatar
                                                    title="แชร์ลิงก์"
                                                    v-if="item.file_status_sharelink === 'Y' || item.file_status_sharelink === 'P'"
                                                    size="20"
                                                    color="#F37958"
                                                    ><v-icon dark small>link</v-icon></v-avatar
                                                  >
                                                  <v-avatar
                                                    title="แชร์ไฟล์"
                                                    v-if="item.file_status_share === 'Y'"
                                                    size="20"
                                                    color="green"
                                                    ><v-icon dark small>group_add</v-icon></v-avatar
                                                  >
                                                  <v-avatar
                                                    title="อัพเดท"
                                                    v-if="item.status_updating === ''"
                                                    size="20"
                                                    color="primary"
                                                    ><v-icon dark small>check</v-icon></v-avatar
                                                  >

                                                  <v-avatar
                                                    title="ไฟล์หมดอายุ"
                                                    v-if="item.status_expired === '0'"
                                                    size="20"
                                                    color="#D50000"
                                                    ><v-icon dark small>mdi-timer</v-icon></v-avatar
                                                  >
                                                  <v-avatar
                                                    title="ไฟล์ใกล้หมดอายุ"
                                                    v-if="item.status_expired === '1' || item.status_expired === '4'"
                                                    size="20"
                                                    color="#fc9208e6"
                                                    ><v-icon dark small>mdi-timer</v-icon></v-avatar
                                                  >
                                                   <v-avatar
                                                    title="ตั้งค่าวันหมดอายุ"
                                                    v-if="item.status_expired === '2'"
                                                    size="20"
                                                    color="green"
                                                    ><v-icon dark small>mdi-timer</v-icon></v-avatar
                                                  >
                                                   <v-avatar title="relation"
                                                   v-if="item.relation_status === 0"
                                                    size="20"
                                                    color="red">
                                                  <v-icon dark small >device_hub</v-icon></v-avatar>
                                                  <v-avatar title="relation"
                                                   v-else-if="item.relation_status === 1"
                                                    size="20"
                                                    color="amber accent-4">
                                                  <v-icon dark small >device_hub</v-icon></v-avatar>
                                                </v-flex>
                                              </v-layout>
                                              <!-- <v-flex lg2 align-self-center>
                                              <v-icon v-if="item.status_lock_data === 'Y'" color="error" right>lock</v-icon>
                                            </v-flex> -->
                                            </v-col>
                                          </v-col>
                                        </v-layout>
                                      </v-card>
                                      </template>
                                      <div class="pb-2">
                                        <div 
                                          :class="((index+1) % 6) === 0 ? `tri-right right-top` : `tri-right left-top`">
                                        </div>
                                        <div class="pb-1">
                                          <span>
                                            {{$t('currentRClick.editting')}}
                                          </span>
                                        </div>
                                        <div v-for="(itemperson, index) in editor_person"
                                          :key="index">
                                          <div style="display: flex">
                                            <div class="dot" :style="`backgroundColor: #${random_color[index]};`">
                                              <span>
                                                {{itemperson.first_name_eng.split("")[0].toUpperCase()}}
                                              </span>
                                            </div>
                                            <span class="ml-2">
                                              {{itemperson.first_name_eng.charAt(0).toUpperCase() + itemperson.first_name_eng.slice(1)}} {{  itemperson.last_name_eng.charAt(0).toUpperCase() + itemperson.last_name_eng.slice(1) }}
                                            </span>
                                          </div>
                                        </div>
                                        <div style="display: flex; align-items: center; justify-content: center" v-if="remain_person > 0" >
                                          <span>
                                            +{{remain_person}}
                                          </span>
                                        </div>
                                      </div>
                                      </v-tooltip>
                                    </drag-selector-item>
                                  </v-col>
                                </v-row>
                              </v-flex>
                            </v-layout>
                          </v-card>
                          <v-card
                            v-if="rootfile.length === 0 && disablescrolling === true && loaddataprogress === false"
                            class="pb-4 elevation-0"
                            :color="color.BG"
                            style="cursor: default;"
                            @mousedown="resetFile"
                          >
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title
                                  class="text-center"
                                  v-text="heardertextsearch === false ? $t('tablefile.empty') : $t('tablefile.emptysearch')"
                                ></v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-card>
                        </drag-selector>
                      </div>
                      <!-- ตรวจสอบว่า layout table -->
                      <div v-else>
                        <v-card outlined id="borderradius" class="elevation-0" :style="this.$vuetify.theme.dark ? 'background: #1E1E1E;' : 'background: #fafafa;'">
                          <v-layout row wrap fill-height class="pl-2 pr-2" justify-space-between>
                          <!-- Button Multiple -->
                          <v-flex style="max-width: max-content;">
                            <div v-if="statusmutipledownload === false" class="pa-1 pl-10">
                              <v-btn
                                @mouseover="show_menu = true"
                                @mouseleave="show_menu = false"
                                :color="color.theme"
                                rounded
                                class="ma-1 pa-3 white--text"
                                @click="(statusmutipledownload = true), (show_menu = false), (filemultipledownload = [])"
                                v-if="changeUI === false"
                              >
                                <v-icon>mdi-menu</v-icon>
                                <p class="ml-2" style="font-size:15px;margin-bottom:2px;">{{$t('balloon.menu')}}</p>
                                <v-badge v-if="$t('default') === 'th'"
                                    style="z-index:10000;bottom:-45px;left:-40px;"
                                    :value="show_menu"
                                    :content="$t('balloon.menu')"
                                    transition="slide-y-transition"
                                  ></v-badge>
                                  <v-badge v-else
                                    style="z-index:10000;bottom:-45px;left:-55px;"
                                    :value="show_menu"
                                    :content="$t('balloon.menu')"
                                    transition="slide-y-transition"
                                  ></v-badge>
                              </v-btn>
                            </div>
                            <div v-else class="pa-1 pl-10 darkmode">
                              <div v-if="close_header === true" >
                              <v-btn
                                @mouseover="show_load = true"
                                @mouseleave="show_load = false"
                                fab
                                small
                                outlined
                                :color="color.theme"
                                class="ma-1 pa-0"
                                @click="multipledownload()"
                              >
                                <v-icon>mdi-download-outline</v-icon>
                                <v-badge v-if="$t('default') === 'th'"
                                  style="z-index:10000;bottom:-45px;left:-42px;"
                                  :value="show_load"
                                  :content="$t('balloon.download')"
                                  transition="slide-y-transition"
                                ></v-badge>
                                <v-badge v-else
                                  style="z-index:10000;bottom:-45px;left:-45px;"
                                  :value="show_load"
                                  :content="$t('balloon.download')"
                                  transition="slide-y-transition"
                                ></v-badge>
                              </v-btn>
                              <v-btn
                                @mouseover="show_print = true"
                                @mouseleave="show_print = false"
                                fab
                                small
                                outlined
                                :color="color.theme"
                                class="ma-1 pa-0"
                                @click="fn_printPDF()"
                              >
                                <v-icon>print</v-icon>
                                <v-badge v-if="$t('default') === 'th'"
                                  style="z-index:10000;bottom:-45px;left:-40px;"
                                  :value="show_print"
                                  :content="$t('balloon.print')"
                                  transition="slide-y-transition"
                                ></v-badge>
                                <v-badge v-else
                                  style="z-index:10000;bottom:-45px;left:-42px;"
                                  :value="show_print"
                                  :content="$t('balloon.print')"
                                  transition="slide-y-transition"
                                ></v-badge>
                              </v-btn>
                              <v-btn
                                outlined
                                :color="color.theme"
                                fab
                                small
                                class="ma-1 pa-0"
                                @click="setmultiupdate(filemultipledownload, 'S')"
                                @mouseover="show_star = true"
                                @mouseleave="show_star = false"
                              >
                                <v-icon dark>star_border</v-icon>
                                <v-badge v-if="$t('default') === 'th'"
                                  style="z-index:10000;bottom:-45px;left:-45px;"
                                  :value="show_star"
                                  :content="$t('balloon.star')"
                                  transition="slide-y-transition"
                                ></v-badge>
                                <v-badge v-else
                                  style="z-index:10000;bottom:-45px;left:-43px;"
                                  :value="show_star"
                                  :content="$t('balloon.star')"
                                  transition="slide-y-transition"
                                ></v-badge>
                              </v-btn>

                              <v-btn
                                @mouseover="show_delete = true"
                                @mouseleave="show_delete = false"
                                outlined
                                fab
                                small
                                :color="color.theme"
                                class="ma-1 pa-0"
                                @click="multideletetotrash()"
                              >
                                <v-icon>delete_outline</v-icon>
                                <v-badge v-if="$t('default') === 'th'"
                                  style="z-index:10000;bottom:-45px;left:-22px;"
                                  :value="show_delete"
                                  :content="$t('balloon.delete')"
                                  transition="slide-y-transition"
                                ></v-badge>
                                <v-badge v-else
                                  style="z-index:10000;bottom:-45px;left:-35px;"
                                  :value="show_delete"
                                  :content="$t('balloon.delete')"
                                  transition="slide-y-transition"
                                ></v-badge>
                              </v-btn>
                              <v-btn
                                @mouseover="show_move = true"
                                @mouseleave="show_move = false"
                                outlined
                                fab
                                small
                                class="ma-1 pa-0"
                                :color="color.theme"
                                @click="appsbarmultimovefile($event)"
                              >
                                <v-icon>mdi-folder-swap-outline</v-icon>
                                <v-badge v-if="$t('default') === 'th'"
                                  style="z-index:10000;bottom:-45px;left:-60px;"
                                  :value="show_move"
                                  :content="$t('balloon.move')"
                                  transition="slide-y-transition"
                                ></v-badge>
                                <v-badge v-else
                                  style="z-index:10000;bottom:-45px;left:-65px;"
                                  :value="show_move"
                                  :content="$t('balloon.move')"
                                  transition="slide-y-transition"
                                ></v-badge>
                              </v-btn>
                              <v-btn
                                @mouseover="show_addhashtag = true"
                                @mouseleave="show_addhashtag = false"
                                outlined
                                fab
                                small
                                class="ma-1 pa-0"
                                :color="color.theme"
                                @click="apphashtag()"
                              >
                                <v-icon>mdi-pen-plus</v-icon>
                                <v-badge v-if="$t('default') === 'th'"
                                  style="z-index:10000;bottom:-45px;left:-45px;"
                                  :value="show_addhashtag"
                                  :content="$t('balloon.addhashtag')"
                                  transition="slide-y-transition"
                                ></v-badge>
                                <v-badge v-else
                                  style="z-index:10000;bottom:-45px;left:-65px;"
                                  :value="show_addhashtag"
                                  :content="$t('balloon.addhashtag')"
                                  transition="slide-y-transition"
                                ></v-badge>
                              </v-btn>
                              <v-btn
                                @mouseover="show_clear = true"
                                @mouseleave="show_clear = false"
                                color="error"
                                outlined
                               
                                fab
                                small
                                class="ma-1 pa-0"
                                @click="(isCheckbox_sep_header = false),(statusmutipledownload = false), clearmultipledownload(),show_clear = false, close_header = true"
                              >
                                <v-icon>clear</v-icon>
                                 <v-badge v-if="$t('default') === 'th'"
                                    style="z-index:10000;bottom:-45px;left:-20px;"
                                    :value="show_clear"
                                    :content="$t('balloon.clear')"
                                    transition="slide-y-transition"
                                  ></v-badge>
                                  <v-badge v-else
                                    style="z-index:10000;bottom:-45px;left:-30px;"
                                    :value="show_clear"
                                    :content="$t('balloon.clear')"
                                    transition="slide-y-transition"
                                  ></v-badge>
                              </v-btn>
                            </div>
                            </div>
                          </v-flex>
                          <v-flex style="max-width: min-content;">
                            <v-select
                              prepend-inner-icon="sort"
                              hide-details
                              outlined
                              solo
                              dense
                              rounded
                              v-model="size"
                              :items="listsize"
                              class="text-center pt-2 pr-10"
                              @change="selectedLimit()"
                            >
                              <template v-slot:item="{ item }">
                                <div class="text-center">
                                  <span style="font-size: 17px;">{{ item }}</span>
                                </div>
                              </template>
                              <template v-slot:selection="{ item }">
                                <div class="text-center">
                                  <span style="font-size: 17px;">{{ item }}</span>
                                </div>
                              </template>
                            </v-select>
                          </v-flex>
                        </v-layout>
                        <!-- ตาราง  smartupload search -->
                        <v-data-table
                            v-if="smartuplloadsearch === true"
                              v-model="filemultipledownload"
                              :headers="headers_smup"
                              :items="
                                rootfile.filter((item) => {
                                  return item.file_status !== 'M';
                                })
                              "
                              :search="search"
                              :single-select="false"
                              item-key="file_id"
                              :show-select="statusmutipledownload"
                              item-selected
                              :no-data-text="smartuplloadsearch === false ? $t('tablefile.empty') : $t('tablefile.emptysearch')"
                              class="elevation-0 cardDataTable"
                              :items-per-page="rootfile.length"
                              :hide-default-footer="true"
                              :loading="loaddataprogress"
                              :loading-text="$t('tablefile.loadingitems')"
                              >
                              <template v-for="item in headers_smup">
                                <span :key="item.aspect_id" :style="headerTable" class="cardDataTable">
                                  {{ item.aspect_label }}
                                </span>
                              </template>
                              <template v-slot:item="props">
                                <tr
                                  :style="selectItemTable(props.item.file_id, 'table') + 'cursor: pointer;'"
                                  @contextmenu="rightclickfileandfolder($event, props.item)"
                                  @dblclick="gotodirectory(props.item.file_id, props.item.file_type, props.item)"
                                  @mouseover="officefilescompatible.includes(props.item.file_type) ? mouseOverFile_onlyoffice(props.item) : null"
                                  @mouseleave="mouseLeaveFile_onlyoffice"
                                  :id="props.item.file_id"
                                  class="cardDataTable detect-folder dragSelectTable"
                                  @click="setSelectitemtable"
                                >
                                  <td class="text-center cardDataTable dragSelectTable" v-if="statusmutipledownload" :id="props.item.file_id">
                                    <v-checkbox
                                      class="dragSelectTable"
                                      v-model="filemultipledownload"
                                      :value="props.item"
                                      v-if="props.item.file_status != 'M'"
                                    />
                                  </td>
                                  <td width="7%" class="text-center cardDataTable nameDragSelectTable dragSelectTable" :id="props.item.file_id">
                                    <v-badge
                                      class="cardDataTable nameDragSelectTable dragSelectTable"
                                      :id="props.item.file_id"
                                      color="orange"
                                      overlap
                                      icon="link"
                                      v-if="(props.item.file_status_sharelink === 'Y' || props.item.file_status_sharelink === 'P') && props.item.editing_status === 'N'"
                                    >
                                      <v-icon large :color="props.item.file_icon[1]">{{ props.item.file_icon[0] }}</v-icon>
                                    </v-badge>
                                    <v-badge :id="props.item.file_id" class="cardDataTable nameDragSelectTable dragSelectTable" color="red" overlap icon="lock" v-else-if="props.item.editing_status === 'Y'">
                                      <v-icon :id="props.item.file_id" class="cardDataTable nameDragSelectTable dragSelectTable" large :color="props.item.file_icon[1]">{{ props.item.file_icon[0] }}</v-icon>
                                    </v-badge>
                                    <v-badge :id="props.item.file_id" class="cardDataTable nameDragSelectTable dragSelectTable" color="red" overlap icon="mdi-sync-circle" v-else-if="props.item.editing_status === 'S'">
                                      <v-icon :id="props.item.file_id" class="cardDataTable nameDragSelectTable dragSelectTable" large :color="props.item.file_icon[1]">{{ props.item.file_icon[0] }}</v-icon>
                                    </v-badge>

                                    <v-icon
                                      :id="props.item.file_id"
                                      class="cardDataTable nameDragSelectTable dragSelectTable"
                                      v-else-if="props.item.file_status_sharelink === 'N' && props.item.editing_status === 'N'"
                                      large
                                      :color="props.item.file_icon[1]"
                                      >{{ props.item.file_icon[0] }}</v-icon
                                    >
                                    <div>
                                      <!-- <v-chip x-small color="yellow darken-2" dark v-if="props.item.status_updating !== 'Done'" -->
                                      <!-- >กำลังอัปเดต</v-chip> -->
                                    </div>
                                    <div :id="props.item.file_id" class="cardDataTable nameDragSelectTable dragSelectTable">
                                      <v-chip
                                        :id="props.item.file_id"
                                        class="cardDataTable nameDragSelectTable dragSelectTable"
                                        x-small
                                        dark
                                        v-if="
                                          props.item.status_expired === '0' ||
                                            props.item.status_expired === '1' ||
                                            props.item.status_expired === '4'
                                        "
                                        :color="
                                          props.item.status_expired === '0'
                                            ? 'red'
                                            : props.item.status_expired === '1' || props.item.status_expired === '4'
                                            ? '#fc9208e6'
                                            : 'white'
                                        "
                                        >{{
                                          props.item.status_expired === "0"
                                            ? $t("statusfileexpired.expired")
                                            : props.item.status_expired === "1" || props.item.status_expired === "4"
                                            ? $t("statusfileexpired.nearexpired")
                                            : ""
                                        }}</v-chip
                                      >
                                    </div>
                                  </td>
                                  <td width="43%" :title="props.item.file_name" style="padding:0px;margin:0px;" class="cardDataTable dragSelectTable"
                                    :id="props.item.file_id">
                                    <div :id="props.item.file_id" class="dragSelectTable" style="whiteSpace: nowrap;overflow: hidden;textOverflow: ellipsis; width:540px">
                                      <v-icon
                                        v-if="props.item.status_lock_folder === 'Y' || props.item.status_lock_data === 'Y'"
                                        color="error"
                                        small
                                        class="dragSelectTable"
                                        :id="props.item.file_id"
                                        >lock</v-icon
                                      >
                                        <v-tooltip 
                                          nudge-top="10"
                                          nudge-right="130"
                                          color="#6b6b6b" class="tooltiptable" top 
                                          v-model="idPointed === props.item.file_id ? isOpenTooltip : false"
                                        >
                                        <template v-slot:activator="{ on, attrs }">
                                          <div :id="props.item.file_id" class="cardDataTable dragSelectTable nameDragSelectTable" style="display: inline-block; margin-left: 5px;"
                                            v-if="props.item.status_hilightword === undefined" v-bind="attrs" :v-on="isOpenTooltip ? on : null">
                                            {{ props.item.file_name | subStr }}
                                          </div>
                                          <div class="cardDataTable nameDragSelectTable dragSelectTable" v-html="fn_hilightWord(props.item.file_name)" style="display: inline-block; margin-left: 5px;" v-else>
                                          </div>
                                        </template>
                                        <div class="pb-2">
                                          <div 
                                            class="tri-right btm-left">
                                          </div>
                                          <div class="pb-1">
                                            <span>
                                              {{$t('currentRClick.editting')}}
                                            </span>
                                          </div>
                                          <div v-for="(itemperson, index) in editor_person" :key="index">
                                            <div style="display: flex">
                                              <div class="dot" :style="`backgroundColor: #${random_color[index]};`">
                                                <span>
                                                  {{itemperson.first_name_eng.split("")[0].toUpperCase()}}
                                                </span>
                                              </div>
                                              <span class="ml-2">
                                                {{itemperson.first_name_eng.charAt(0).toUpperCase() + itemperson.first_name_eng.slice(1)}} {{  itemperson.last_name_eng.charAt(0).toUpperCase() + itemperson.last_name_eng.slice(1) }}
                                              </span>
                                            </div>
                                          </div>
                                          <div style="display: flex; align-items: center; justify-content: center" v-if="remain_person > 0">
                                            <span>
                                              +{{remain_person}}
                                            </span>
                                          </div>
                                        </div>
                                      </v-tooltip>
                                      <v-icon
                                        v-if="checkNewDataFormDate(props.item) === true && checkNewDataNoRefresh(props.item) === true && checkNewDataRefresh(props.item) === true  || props.item.onlyofficeeditsecces === true"
                                        size="20"
                                        color="red"
                                        class="dragSelectTable"
                                        :id="props.item.file_id"
                                        >mdi-new-box</v-icon
                                      >
                                      <div :id="props.item.file_id" class="dragSelectTable" v-if="props.item.editing_status === 'Y'">
                                        <v-chip :id="props.item.file_id" class="dragSelectTable" x-small color="primary" v-if="$t('default') === 'th'"
                                          >{{ props.item.last_update_name.name_th }} กำลังแก้ไข</v-chip
                                        >
                                        <v-chip :id="props.item.file_id" class="dragSelectTable" x-small color="primary" v-else
                                          >{{ props.item.last_update_name.name_eng }} editting</v-chip
                                        >
                                      </div>
                                      <div :id="props.item.file_id" class="dragSelectTable" v-if="props.item.editing_status === 'S'">
                                        <v-chip :id="props.item.file_id" class="dragSelectTable" x-small color="primary" v-if="$t('default') === 'th'"
                                          >กำลังซิงค์...</v-chip
                                        >
                                        <v-chip :id="props.item.file_id" class="dragSelectTable" x-small color="primary" v-else
                                          >Syncing...</v-chip
                                        >
                                      </div>
                                      <!-- <v-badge center v-if="props.item.relation_status === 0" color="red" icon="device_hub"></v-badge>
                                      <v-badge  v-else-if="props.item.relation_status === 1" color="amber accent-4" icon="device_hub"></v-badge>-->
                                      <v-icon :id="props.item.file_id" class="pr-1 dragSelectTable" color="red" x-small v-if="props.item.relation_status === 0"
                                        >device_hub</v-icon
                                      >
                                      <v-icon
                                        :id="props.item.file_id"
                                        class="pr-1 dragSelectTable"
                                        color="amber accent-4"
                                        x-small
                                        v-else-if="props.item.relation_status === 1"
                                        >device_hub</v-icon
                                      >
                                      <v-icon :id="props.item.file_id" small class="pr-1 dragSelectTable" color="orange" v-if="props.item.file_status_share === 'Y'"
                                        >group_add</v-icon
                                      >
                                      <v-icon :id="props.item.file_id" class="pr-1 dragSelectTable" v-if="props.item.status_expired === '2'" small color="green"
                                        >access_time</v-icon
                                      >
                                      <!-- <v-icon
                                        small
                                        color="orange"
                                        v-if="props.item.status_updating === 'Done'"
                                      >check</v-icon> -->
                                    </div>
                                  </td>
                                  <td class="text-center cardDataTable dragSelectTable" width="15%" style="padding:0px;margin:0px;" :id="props.item.file_id">
                                    {{ props.item.owner }}
                                  </td>
                                  <td v-for="(item, i) in props.item.key_body" :key="i" class="text-center dragSelectTable" width="10%" style="padding:0px;margin:0px;">
                                      <div  v-for="(items_, index) in headers_smup.filter((items_) => {return items_.key === item.aspect_id})" :key="index">
                                        {{ item.value }}
                                      </div>
                                    <!-- <div v-for="(items_, i) in headers_smup" :key="i">
                                      <div v-if="item.aspect_id === items_.key">
                                        {{ item.value }}
                                      </div>
                                    </div> -->
                                  </td>

                                    <!-- ปุ่มจัดการไฟล์ -->
                                  <td
                                    width="10%"
                                    class="text-center cardDataTable"
                                    v-if="props.item.file_status === 'M'"
                                    style="padding:0px;margin:0px;"
                                    :id="props.item.file_id"
                                  ></td>
                                  <!-- เปลี่ยนมาเช็คอันนี้ -->
                                  <!-- ปุ่มจัดการไฟล์ -->
                                  <td width="10%" v-else class="text-right cardDataTable" :id="props.item.file_id">
                                    <span>
                                      <v-icon small v-if="props.item.file_permission_2 === '06'">mdi-crown</v-icon>

                                      <v-btn
                                        fab
                                        class="elevation-0"
                                        text
                                        small
                                        @click="setPermissionFileStar(props.item, 'N', 'st')"
                                        v-if="props.item.file_status === 'S'"
                                      >
                                        <v-icon color="amber">star</v-icon>
                                      </v-btn>
                                      <v-btn
                                        fab
                                        class="elevation-0"
                                        text
                                        small
                                        @click="setPermissionFileStar(props.item, 'S', 'st')"
                                        v-else
                                      >
                                        <v-icon>star_border</v-icon>
                                      </v-btn>
                                    </span>
                                    <v-btn
                                      class="elevation-0"
                                      outlined
                                      fab
                                      small
                                      @click="rightclickfileandfolder($event, props.item)"
                                    >
                                      <v-icon>more_vert</v-icon>
                                    </v-btn>
                                  </td>
                                </tr>
                              </template>
                        </v-data-table>
                        <v-tabs v-model="tabdepartment" outlined id="borderradius" v-else>
                          <v-tab @click="(tapbusiness = true), checktabs()">Main</v-tab>
                          <v-tab @click="(tapbusiness = false), checktabs()" v-if="dataAccountActive['type'] === 'Business'"
                            >Department</v-tab
                          >
                          <!-- table Main -->
                          <v-tab-item>
                            <v-data-table
                              v-model="filemultipledownload"
                              :headers="headers"
                              :items="
                                rootfile.filter((item) => {
                                  return item.file_status !== 'M';
                                })
                              "
                              :search="search"
                              :single-select="false"
                              item-key="file_id"
                              :show-select="statusmutipledownload"
                              item-selected
                              :no-data-text="heardertextsearch === false ? $t('tablefile.empty') : $t('tablefile.emptysearch')"
                              class="elevation-0"
                              :items-per-page="rootfile.length"
                              :hide-default-footer="true"
                              :loading="loaddataprogress"
                            >
                              <template v-slot:[`header.file_type`]="{ header }">
                                <span :style="headerTable">
                                  {{ header.text }}
                                </span>
                              </template>
                              <template v-slot:[`header.file_name`]="{ header }">
                                <span
                                  :style="headerTable"
                                  >{{ $t(header.text) }}</span>
                                <!-- คลิก Sort File จากหัวข้อ -->
                                <!-- <span
                                  class="pointer"
                                  @click="(multiSort = !multiSort), (sortby = 'name'), (clickSort = true), sortFile(rootfile)"
                                  :style="headerTable"
                                  >{{ $t(header.text) }}</span
                                > -->
                              </template>
                              <template v-slot:[`header.file_owner`]="{ header }">
                                <span
                                  :style="headerTable"
                                  >{{ $t(header.text) }}</span>
                                <!-- คลิก Sort File จากหัวข้อ -->
                                <!-- <span
                                  class="pointer"
                                  @click="(multiSort = !multiSort), (sortby = 'owner'), (clickSort = true), sortFile(rootfile)"
                                  :style="headerTable"
                                  >{{ $t(header.text) }}</span
                                > -->
                              </template>
                              <template v-slot:[`header.file_size`]="{ header }">
                                <span
                                  :style="headerTable"
                                  >{{ $t(header.text) }}</span>
                                <!-- คลิก Sort File จากหัวข้อ -->
                                <!-- <span
                                  class="pointer"
                                  @click="(multiSort = !multiSort), (sortby = 'size'), (clickSort = true), sortFile(rootfile)"
                                  :style="headerTable"
                                  >{{ $t(header.text) }}</span
                                > -->
                              </template>
                              <template v-slot:[`header.file_location`]="{ header }">
                                <span
                                  :style="headerTable"
                                  >{{ $t(header.text) }}</span>
                              </template>
                              <template v-slot:[`header.file_lastdtm`]="{ header }">
                                <span
                                  :style="headerTable"
                                  >{{ $t(header.text) }}</span
                                >
                                <!-- คลิก Sort File จากหัวข้อ -->
                                <!-- <span
                                  class="pointer"
                                  @click="(multiSort = !multiSort), (sortby = 'date'), (clickSort = true), sortFile(rootfile)"
                                  :style="headerTable"
                                  >{{ $t(header.text) }}</span
                                > -->
                              </template>
                              <template v-slot:[`header.file_status`]="{ header }">
                                <span :style="headerTable">
                                  {{ $t(header.text) }}
                                </span>
                              </template>
                              <template v-slot:item="props">
                                <tr
                                  :style="selectItemTable(props.item.file_id, 'table') + 'cursor: pointer;'"
                                  @contextmenu="rightclickfileandfolder($event, props.item)"
                                  @dblclick="gotodirectory(props.item.file_id, props.item.file_type, props.item)"     
                                  @mouseover="officefilescompatible.includes(props.item.file_type) ? mouseOverFile_onlyoffice(props.item) : null"
                                  @mouseleave="mouseLeaveFile_onlyoffice"
                                  @click="setSelectitemtable(props.item)"
                                >
                                  <td class="text-center" v-show="statusmutipledownload">
                                    <v-checkbox
                                      v-model="filemultipledownload"
                                      :value="props.item"
                                      v-if="props.item.file_status != 'M'"
                                    />
                                  </td>
                                  <td width="7%" class="text-center">
                                    <v-badge
                                      color="orange"
                                      overlap
                                      icon="link"
                                      v-if="(props.item.file_status_sharelink === 'Y' || props.item.file_status_sharelink === 'P') && props.item.editing_status === 'N'"
                                    >
                                      <v-icon large :color="props.item.file_icon[1]">{{ props.item.file_icon[0] }}</v-icon>
                                    </v-badge>
                                    <v-badge color="red" overlap icon="lock" v-else-if="props.item.editing_status === 'Y'">
                                      <v-icon large :color="props.item.file_icon[1]">{{ props.item.file_icon[0] }}</v-icon>
                                    </v-badge>
                                    <v-badge color="red" overlap icon="mdi-sync-circle" v-else-if="props.item.editing_status === 'S'">
                                      <v-icon large :color="props.item.file_icon[1]">{{ props.item.file_icon[0] }}</v-icon>
                                    </v-badge>

                                    <v-icon
                                      v-else-if="props.item.file_status_sharelink === 'N' && props.item.editing_status === 'N'"
                                      large
                                      :color="props.item.file_icon[1]"
                                      >{{ props.item.file_icon[0] }}</v-icon
                                    >
                                    <div>
                                      <!-- <v-chip x-small color="yellow darken-2" dark v-if="props.item.status_updating !== 'Done'" -->
                                      <!-- >กำลังอัปเดต</v-chip> -->
                                    </div>
                                    <div>
                                      <v-chip
                                        x-small
                                        dark
                                        v-show="
                                          props.item.status_expired === '0' ||
                                            props.item.status_expired === '1' ||
                                            props.item.status_expired === '4'
                                        "
                                        :color="
                                          props.item.status_expired === '0'
                                            ? 'red'
                                            : props.item.status_expired === '1' || props.item.status_expired === '4'
                                            ? '#fc9208e6'
                                            : 'white'
                                        "
                                        >{{
                                          props.item.status_expired === "0"
                                            ? $t("statusfileexpired.expired")
                                            : props.item.status_expired === "1" || props.item.status_expired === "4"
                                            ? $t("statusfileexpired.nearexpired")
                                            : ""
                                        }}</v-chip
                                      >
                                    </div>
                                  </td>
                                  <td width="43%" :title="props.item.file_name" style="padding:0px;margin:0px;">
                                    <div style="whiteSpace: nowrap;overflow: hidden;textOverflow: ellipsis; width:540px">
                                      <v-icon
                                        v-if="props.item.status_lock_folder === 'Y' || props.item.status_lock_data === 'Y'"
                                        color="error"
                                        small
                                        >lock</v-icon
                                      >
                                      <!-- {{ props.item.file_name | subStr }} -->
                                      <v-tooltip 
                                          nudge-top="10"
                                          nudge-right="130"
                                          color="#6b6b6b" class="tooltiptable" top 
                                          v-model="idPointed === props.item.file_id ? isOpenTooltip : false"
                                        >
                                        <template v-slot:activator="{ on, attrs }">
                                          <div style="display: inline-block; margin-left: 5px;"
                                            v-if="props.item.status_hilightword === undefined" v-bind="attrs" :v-on="isOpenTooltip ? on : null">
                                            {{ props.item.file_name | subStr }}
                                          </div>
                                          <div v-html="fn_hilightWord(props.item.file_name)" style="display: inline-block; margin-left: 5px;" v-else>
                                          </div>
                                        </template>
                                        <div class="pb-2">
                                          <div 
                                            class="tri-right btm-left">
                                          </div>
                                          <div class="pb-1">
                                            <span>
                                              {{$t('currentRClick.editting')}}
                                            </span>
                                          </div>
                                          <div v-for="(itemperson, index) in editor_person" :key="index">
                                            <div style="display: flex">
                                              <div class="dot" :style="`backgroundColor: #${random_color[index]};`">
                                                <span>
                                                  {{itemperson.first_name_eng.split("")[0].toUpperCase()}}
                                                </span>
                                              </div>
                                              <span class="ml-2">
                                                {{itemperson.first_name_eng.charAt(0).toUpperCase() + itemperson.first_name_eng.slice(1)}} {{  itemperson.last_name_eng.charAt(0).toUpperCase() + itemperson.last_name_eng.slice(1) }}
                                              </span>
                                            </div>
                                          </div>
                                          <div style="display: flex; align-items: center; justify-content: center" v-if="remain_person > 0">
                                            <span>
                                              +{{remain_person}}
                                            </span>
                                          </div>
                                        </div>
                                      </v-tooltip>
                                      <v-icon
                                        v-if="checkNewDataFormDate(props.item) === true && checkNewDataNoRefresh(props.item) === true && checkNewDataRefresh(props.item) === true  || props.item.onlyofficeeditsecces === true"
                                        size="20"
                                        color="red"
                                        >mdi-new-box</v-icon
                                      >
                                      <div v-show="props.item.editing_status === 'Y'">
                                        <v-chip x-small color="primary" v-if="$t('default') === 'th'"
                                          >{{ props.item.last_update_name.name_th }} กำลังแก้ไข</v-chip
                                        >
                                        <v-chip x-small color="primary" v-else
                                          >{{ props.item.last_update_name.name_eng }} editting</v-chip
                                        >
                                      </div>
                                      <div v-show="props.item.editing_status === 'S'">
                                        <v-chip x-small color="primary" v-if="$t('default') === 'th'"
                                          >กำลังซิงค์...</v-chip
                                        >
                                        <v-chip x-small color="primary" v-else
                                          >Syncing...</v-chip
                                        >
                                      </div>
                                      <!-- <v-badge center v-if="props.item.relation_status === 0" color="red" icon="device_hub"></v-badge>
                                      <v-badge  v-else-if="props.item.relation_status === 1" color="amber accent-4" icon="device_hub"></v-badge>-->
                                      <v-icon class="pr-1" color="red" x-small v-if="props.item.relation_status === 0"
                                        >device_hub</v-icon
                                      >
                                      <v-icon
                                        class="pr-1"
                                        color="amber accent-4"
                                        x-small
                                        v-else-if="props.item.relation_status === 1"
                                        >device_hub</v-icon
                                      >
                                      <v-icon small class="pr-1" color="orange" v-if="props.item.file_status_share === 'Y'"
                                        >group_add</v-icon
                                      >
                                      <v-icon class="pr-1" v-if="props.item.status_expired === '2'" small color="green"
                                        >access_time</v-icon
                                      >
                                      <!-- <v-icon
                                        small
                                        color="orange"
                                        v-if="props.item.status_updating === 'Done'"
                                      >check</v-icon> -->
                                    </div>
                                  </td>
                                  <td class="text-center" width="10%" style="padding:0px;margin:0px;" v-if="$t('default') === 'th'">
                                    {{ props.item.file_owner_th }}
                                    <!-- <v-icon
                                          small
                                          color="orange"
                                          v-if="props.item.file_status_share === 'Y'"
                                        >group_add</v-icon>-->
                                  </td>
                                  <td class="text-center" width="10%" style="padding:0px;margin:0px;" v-else>
                                    <div style="justify-content: center;">{{ props.item.file_owner_eng }}</div>
                                  </td>
                                  <td class="text-center" width="10%" style="padding:0px;margin:0px;">
                                    {{ formatdatetime(props.item.file_lastdtm) }}
                                  </td>
                                  <td class="text-center" width="10%" style="padding:0px;margin:0px;">
                                    {{
                                      fn_calculatesize(props.item.file_size) == "0.00 B"
                                        ? "-"
                                        : fn_calculatesize(props.item.file_size)
                                    }}
                                  </td>
                                  <td v-if="isAdvancedSearch" class="cardDataTable dragSelectTable" width="10%" style="padding:0px;margin:0px;" :id="props.item.file_id">                                    
                                    <template v-if="props.item.file_location.length > 0">
                                      <v-menu
                                        rounded="xl"
                                        left
                                        open-on-hover
                                        @input="onToggleFileLocation($event, props.item)"
                                        content-class="menu-content"
                                      >
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-btn text rounded v-bind="attrs" v-on="on">
                                            <v-icon small color="grey">mdi-folder</v-icon>
                                            <span class="name-location">
                                              {{ props.item.file_location[props.item.file_location.length - 1].folder_name }}
                                            </span>
                                          </v-btn>
                                        </template>
                                        <v-list class="px-2 breadcrumbs-location" max-width="1000" height="52px" style="overflow-y: hidden;">
                                          <v-breadcrumbs 
                                            v-if="props.item.is_expand_file_location"
                                            :items="props.item.file_location" 
                                            divider=">" 
                                            class="pa-0"
                                            style="display: inline-block; overflow-x: auto; white-space: nowrap;"
                                          >
                                            <template v-slot:item="prop">
                                              <v-breadcrumbs-item
                                                style="cursor: pointer;"
                                                @click.stop="gotofolder(prop.item.folder_id)"
                                                :disabled="loaddataprogress"
                                              >
                                                <v-tooltip bottom content-class="tooltip-content">
                                                  <template v-slot:activator="{ on, attrs }">
                                                    <v-btn text rounded v-bind="attrs" v-on="on">
                                                      <v-icon small color="grey">{{ prop.item.file_type === 'folder' ? 'mdi-folder' : 'mdi-file' }}</v-icon>
                                                      <span class="path-name-location">{{ prop.item.folder_name }}</span>
                                                    </v-btn>
                                                  </template>
                                                  <span style="font-size: 12px;">{{ prop.item.folder_name }}</span>
                                                </v-tooltip>
                                              </v-breadcrumbs-item>
                                            </template>
                                          </v-breadcrumbs>
                                          <v-breadcrumbs 
                                            v-else
                                            :items="props.item.display_file_location" 
                                            divider=">" 
                                            class="pa-0"
                                            style="display: inline-block; overflow-x: auto; white-space: nowrap;"
                                          >
                                            <template v-slot:item="prop">
                                              <v-breadcrumbs-item
                                                style="cursor: pointer;"
                                                @click.stop="prop.item.folder_name === '...' ? props.item.is_expand_file_location = true : gotofolder(prop.item.folder_id)"
                                                :disabled="loaddataprogress"
                                              >
                                                <v-btn v-if="prop.item.folder_name === '...'" text rounded>
                                                  <v-icon small color="grey">mdi-dots-horizontal</v-icon>
                                                </v-btn>
                                                <v-tooltip v-else bottom content-class="tooltip-content">
                                                  <template v-slot:activator="{ on, attrs }">
                                                    <v-btn text rounded v-bind="attrs" v-on="on">
                                                      <v-icon small color="grey">{{ prop.item.file_type === 'folder' ? 'mdi-folder' : 'mdi-file' }}</v-icon>
                                                      <span class="path-name-location">{{ prop.item.folder_name }}</span>
                                                    </v-btn>
                                                  </template>
                                                  <span style="font-size: 12px;">{{ prop.item.folder_name }}</span>
                                                </v-tooltip>
                                              </v-breadcrumbs-item>
                                            </template>
                                          </v-breadcrumbs>
                                        </v-list>
                                      </v-menu>
                                    </template>
                                    <template v-else>
                                      <v-tooltip bottom content-class="tooltip-content">
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-btn text rounded v-bind="attrs" v-on="on">
                                            <v-icon small color="grey">{{ props.item.file_type === 'folder' ? 'mdi-folder' : 'mdi-file' }}</v-icon>
                                            <span class="name-location">{{ props.item.file_name }}</span>
                                          </v-btn>
                                        </template>
                                        <span style="font-size: 12px;">{{ props.item.file_name }}</span>
                                      </v-tooltip>
                                    </template>
                                  </td>
                                    <!-- ปุ่มจัดการไฟล์ -->
                                  <td
                                    width="10%"
                                    class="text-center"
                                    v-if="props.item.file_status === 'M'"
                                    style="padding:0px;margin:0px;"
                                  ></td>
                                  <!-- <v-icon small v-if="props.item.file_permission['admin_data'] === 'True'">mdi-crown</v-icon> -->
                                  <!-- เปลี่ยนมาเช็คอันนี้ -->
                                  <!-- ปุ่มจัดการไฟล์ -->
                                  <td width="10%" v-else class="text-right">
                                    <span>
                                      <v-icon small v-if="props.item.file_permission_2 === '06'">mdi-crown</v-icon>

                                      <v-btn
                                        fab
                                        class="elevation-0"
                                        text
                                        small
                                        @click="setPermissionFileStar(props.item, 'N', 'st')"
                                        v-if="props.item.file_status === 'S'"
                                      >
                                        <v-icon color="amber">star</v-icon>
                                      </v-btn>
                                      <v-btn
                                        fab
                                        class="elevation-0"
                                        text
                                        small
                                        @click="setPermissionFileStar(props.item, 'S', 'st')"
                                        v-else
                                      >
                                        <v-icon>star_border</v-icon>
                                      </v-btn>
                                    </span>
                                    <v-btn
                                      class="elevation-0"
                                      outlined
                                      fab
                                      small
                                      @click="rightclickfileandfolder($event, props.item)"
                                    >
                                      <v-icon>more_vert</v-icon>
                                    </v-btn>
                                  </td>
                                </tr>
                                </template>
                            </v-data-table>
                          </v-tab-item>
                          <v-tab-item v-if="dataAccountActive['type'] === 'Business'">
                            <v-data-table
                              v-model="filemultipledownload"
                              :headers="headers"
                              :items="
                                rootfile.filter((item) => {
                                  return item.file_status === 'M';
                                })
                              "
                              :search="search"
                              :single-select="false"
                              item-key="file_id"
                              :show-select="statusmutipledownload"
                              item-selected
                              :no-data-text="heardertextsearch === false ? $t('tablefile.empty') : $t('tablefile.emptysearch')"
                              class="elevation-0"
                              :items-per-page="rootfile.length"
                              :hide-default-footer="true"
                              :loading="loaddataprogress"
                            >
                              <template v-slot:[`header.file_type`]="{ header }">
                                <span :style="headerTable">
                                  {{ header.text }}
                                </span>
                              </template>
                              <template v-slot:[`header.file_name`]="{ header }">
                                <span
                                  class="pointer"
                                  @click="(multiSort = !multiSort), (sortby = 'name'), (clickSort = true), sortFile(rootfile)"
                                  :style="headerTable"
                                  >{{ $t(header.text) }}</span
                                >
                              </template>
                              <template v-slot:[`header.file_owner`]="{ header }">
                                <span
                                  class="pointer"
                                  @click="(multiSort = !multiSort), (sortby = 'owner'), (clickSort = true), sortFile(rootfile)"
                                  :style="headerTable"
                                  >{{ $t(header.text) }}</span
                                >
                              </template>
                              <template v-slot:[`header.file_size`]="{ header }">
                                <span
                                  class="pointer"
                                  @click="(multiSort = !multiSort), (sortby = 'size'), (clickSort = true), sortFile(rootfile)"
                                  :style="headerTable"
                                  >{{ $t(header.text) }}</span
                                >
                              </template>
                              <template v-slot:[`header.file_lastdtm`]="{ header }">
                                <span
                                  class="pointer"
                                  @click="(multiSort = !multiSort), (sortby = 'date'), (clickSort = true), sortFile(rootfile)"
                                  :style="headerTable"
                                  >{{ $t(header.text) }}</span
                                >
                              </template>
                               <!-- หัวข้อจัดการไฟล์ -->
                               <template v-slot:[`header.file_status`]="{ header }">
                                <span :style="headerTable">
                                  {{ $t(header.text) }}
                                </span>
                              </template>
                              <template v-slot:item="props">
                                <tr
                                  style="cursor: pointer"
                                  @contextmenu="rightclickfileandfolder($event, props.item)"
                                  @dblclick="gotodirectory(props.item.file_id, props.item.file_type, props.item)"
                                  >
                                  <td v-show="statusmutipledownload">
                                    <v-checkbox
                                      v-model="filemultipledownload"
                                      :value="props.item"
                                      v-if="props.item.file_status != 'M'"
                                    />
                                  </td>
                                  <td width="7%" class="text-center">
                                    <v-badge color="orange" overlap icon="link" v-if="props.item.file_status_sharelink === 'Y' || props.item.file_status_sharelink === 'P'">
                                      <v-icon large :color="props.item.file_icon[1]">{{ props.item.file_icon[0] }}</v-icon>
                                    </v-badge>
                                    <v-badge color="red" overlap icon="lock" v-else-if="props.item.editing_status === 'Y'">
                                      <v-icon large :color="props.item.file_icon[1]">{{ props.item.file_icon[0] }}</v-icon>
                                    </v-badge>
                                    <v-badge color="red" overlap icon="mdi-sync-circle" v-else-if="props.item.editing_status === 'S'">
                                      <v-icon large :color="props.item.file_icon[1]">{{ props.item.file_icon[0] }}</v-icon>
                                    </v-badge>
                                    <v-icon v-else large :color="props.item.file_icon[1]">{{ props.item.file_icon[0] }}</v-icon>
                                  </td>
                                  <td width="33%" :title="props.item.file_name" style="padding:0px;margin:0px;">
                                    <div style="whiteSpace: nowrap;overflow: hidden;textOverflow: ellipsis; width:300px">
                                      <v-icon
                                        v-if="props.item.status_lock_folder === 'Y' || props.item.status_lock_data === 'Y'"
                                        color="error"
                                        small
                                        >lock</v-icon
                                      >
                                      {{ props.item.file_name | subStr}}
                                      <v-icon
                                        v-if="checkNewDataFormDate(props.item) === true && checkNewDataNoRefresh(props.item) === true && checkNewDataRefresh(props.item) === true  || props.item.onlyofficeeditsecces === true"
                                        size="20"
                                        color="red"
                                        >mdi-new-box</v-icon
                                      >
                                      <div v-show="props.item.editing_status === 'Y'">
                                        <v-chip x-small color="primary" v-if="$t('default') === 'th'"
                                          >{{ props.item.last_update_name.name_th }} กำลังแก้ไข</v-chip
                                        >
                                        <v-chip x-small color="primary" v-else
                                          >{{ props.item.last_update_name.name_eng }} editting</v-chip
                                        >
                                      </div>
                                      <div v-show="props.item.editing_status === 'S'">
                                        <v-chip x-small color="primary" v-if="$t('default') === 'th'"
                                          >กำลังซิงค์...</v-chip
                                        >
                                        <v-chip x-small color="primary" v-else
                                          >Syncing...</v-chip
                                        >
                                      </div>
                                      <!-- <div v-if="dataAccountActive.type === 'Business' && props.item.editing_status === 'Y'">
                                        <v-chip  x-small color="primary" v-if="$t('default') === 'th'">{{ props.item.last_update_name.name_th}} กำลังแก้ไข</v-chip>
                                        <v-chip  x-small color="primary" v-else>{{ props.item.last_update_name.name_eng}} editting</v-chip>
                                      </div> -->
                                      <v-icon small class="pr-1" color="orange" v-if="props.item.file_status_share === 'Y'"
                                        >group_add</v-icon
                                      >
                                    </div>
                                  </td>
                                  <td class="text-center" width="15%" style="padding:0px;margin:0px;" v-if="$t('default') === 'th'">
                                    {{ props.item.file_owner_th }}
                                    <!-- <v-icon small color="orange" v-if="props.item.file_status_share === 'Y'">group_add</v-icon> -->
                                  </td>
                                  <td class="text-center" width="15%" style="padding:0px;margin:0px;" v-else>
                                    <div style="justify-content: center;">
                                      {{ props.item.file_owner_eng }}
                                      <!-- <v-icon small color="orange" v-if="props.item.file_status_share === 'Y'">group_add</v-icon> -->
                                    </div>
                                  </td>
                                  <td class="text-center" width="18%" style="padding:0px;margin:0px;">
                                    {{ formatdatetime(props.item.file_lastdtm) }}
                                  </td>

                                  <td class="text-center" width="15%" style="padding:0px;margin:0px;">
                                    {{
                                      fn_calculatesize(props.item.file_size) == "0.00 B"
                                        ? "-"
                                        : fn_calculatesize(props.item.file_size)
                                    }}
                                  </td>
                                  <!-- <td
                                    width="10%"
                                    class="text-center"
                                    v-if="props.item.file_status === 'M'"
                                    style="padding:0px;margin:0px;"
                                  ></td>
                                  <td width="15%" v-else class="text-right">
                                    <span>
                                      <v-btn
                                        class="elevation-0"
                                        text
                                        small
                                        @click="setPermissionFileStar(props.item, 'N')"
                                        v-if="props.item.file_status === 'S'"
                                      >
                                        <v-icon color="amber">star</v-icon>
                                      </v-btn>
                                      <v-btn
                                        class="elevation-0"
                                        text
                                        small
                                        @click="setPermissionFileStar(props.item, 'S')"
                                        v-else
                                      >
                                        <v-icon>star_border</v-icon>
                                      </v-btn>
                                    </span>
                                    <v-btn
                                      class="elevation-0"
                                      outlined
                                      small
                                      @click="rightclickfileandfolder($event, props.item)"
                                    >
                                      <v-icon>more_vert</v-icon>
                                    </v-btn>
                                  </td> -->
                                </tr>
                              </template>
                            </v-data-table>
                          </v-tab-item>
                        </v-tabs>
                        </v-card>
                      </div>
                    </div>

                    <!-- ตรวจสอบว่าเป็นจอ มือถือ -->
                    <v-card v-else class="mt-n2" :style="displayMobile" elevation="0">
                      <v-list two-line class="elevation-0 px-2" v-if="rootfile.length > 0" :style="styleDisplayMobile">
                        <v-list-item
                          v-for="item in rootfile"
                          :key="item.title"
                          @contextmenu="rightclickfileandfolder($event, item)"
                          @click="gotodirectory(item.file_id, item.file_type, item)"
                          :style="displayMobileForListItem"
                        >                  
                          <v-list-item-action>
                            <v-badge
                              color="orange"
                              overlap
                              icon="link"
                              v-if="(item.file_status_sharelink === 'Y' || item.file_status_sharelink === 'P' ) && item.editing_status === 'N'"
                            >
                              <v-icon large :color="item.file_icon[1]">
                                {{ item.file_icon[0] }}
                              </v-icon>
                            </v-badge>
                            <v-badge color="red" overlap icon="lock" v-else-if="item.editing_status === 'Y'">
                              <v-icon large :color="item.file_icon[1]">
                                {{ item.file_icon[0] }}
                              </v-icon>
                            </v-badge>
                            <v-badge color="red" overlap icon="mdi-sync-circle" v-else-if="item.editing_status === 'S'">
                              <v-icon large :color="item.file_icon[1]">
                                {{ item.file_icon[0] }}
                              </v-icon>
                            </v-badge>
                            <v-icon
                              v-else-if="item.file_status_sharelink === 'N' && item.editing_status === 'N'"
                              large
                              :color="item.file_icon[1]"
                            >
                              {{ item.file_icon[0] }}
                            </v-icon>
                            <!-- <div> -->
                              <!-- <v-chip x-small color="yellow darken-2" dark v-if="item.status_updating !== 'Done'">กำลังอัปเดต</v-chip> -->
                            <!-- </div> -->
                          </v-list-item-action>

                          <v-list-item-content>
                            <v-list-item-title style="font-size: 14px;">
                              <v-icon dark small class="pr-1" color="error" v-if="item.status_lock_data === 'Y'">mdi-lock</v-icon>
                              {{ item.file_name }}
                              <!-- <v-icon class="pr-1" color="red" x-small v-if="item.relation_status === 0">device_hub</v-icon>
                                <v-icon class="pr-1" color="amber accent-4" x-small v-else-if="item.relation_status === 1">device_hub</v-icon> -->
                            </v-list-item-title>
                            <v-list-item-subtitle style="font-size: 12px;">
                              {{fn_calculatesize(item.file_size) == '0.00 B' ? '-' : fn_calculatesize(item.file_size)}}
                              <v-icon dark small class="pr-1" color="orange" v-if="item.file_status_share === 'Y'">group_add</v-icon>
                              <v-icon dark small class="pr-1" color="#D50000" v-if="item.status_expired === '0'">mdi-timer</v-icon>
                              <v-icon dark small class="pr-1" color="#fc9208e6" v-if="item.status_expired === '1' || item.status_expired === '4'">mdi-timer</v-icon>
                              <v-icon dark small class="pr-1" color="green" v-if="item.status_expired === '2'">mdi-timer</v-icon>
                              <v-icon dark small class="pr-1" color="red" v-if="item.relation_status === 0">device_hub</v-icon>
                              <v-icon dark size="20" class="pr-2" color="red" v-if="checkNewDataFormDate(item) === true && checkNewDataNoRefresh(item) === true && checkNewDataRefresh(item) === true  || item.onlyofficeeditsecces === true">mdi-new-box</v-icon>
                            </v-list-item-subtitle>
                            <v-list-item-subtitle v-show="item.editing_status === 'Y'">
                              <v-chip x-small color="primary" v-if="$t('default') === 'th'">{{ item.last_update_name.name_th }} กำลังแก้ไข</v-chip>
                              <v-chip x-small color="primary" v-else>{{ item.last_update_name.name_eng }} editting</v-chip>
                            </v-list-item-subtitle>
                            <v-list-item-subtitle v-show="item.editing_status === 'S'">
                              <v-chip x-small color="primary" v-if="$t('default') === 'th'"> กำลังซิงค์...</v-chip>
                              <v-chip x-small color="primary" v-else>Syncing...</v-chip>
                            </v-list-item-subtitle>
                          </v-list-item-content>

                          <v-list-item-action>
                            <v-btn class="elevation-0" fab icon small @click.stop="rightclickfileandfolder($event, item)">
                              <v-icon>mdi-dots-horizontal</v-icon>
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                      <v-card-text class="mt-4 mb-4" v-if="rootfile.length === 0 && disablescrolling === true && loaddataprogress === false">
                        <v-img
                          :src="require('../assets/img/icon_empty_data.png')"
                          max-width="132"
                          max-height="150"
                          class="mx-auto"
                        />
                        <v-list-item-title
                          :style="'font-size: 16px; font-weight: 700; line-height: 24px; color:' + color.theme + ';'"
                          class="text-center mt-8"
                        > {{heardertextsearch === false ? $t('tablefile.empty') : $t('tablefile.emptysearch')}} </v-list-item-title>
                      </v-card-text>
                      <!-- <v-list v-if="rootfile.length === 0 && disablescrolling === true" class="elevation-0">
                        <v-list-item class="pt-n10" :style="styleDisplayMobile">
                          <v-list-item-content>
                            <v-list-item-title
                              class="text-center"
                              v-text="heardertextsearch === false ? $t('tablefile.empty') : $t('tablefile.emptysearch')"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list> -->
                    </v-card>
                  </v-flex>
                </v-layout>
                <scroll-loader class="pa-0" :loader-method="looplistdatarootfile_smartsearch" :loader-disable="disablescrolling_smartsearch">
                  <div class="text-center">
                    <v-progress-circular v-if="this.disablescrolling_smartsearch === false" :size="50" :color="color.theme" indeterminate></v-progress-circular>
                  </div>
                </scroll-loader>
                <scroll-loader class="pa-0" :loader-method="looplistdatarootfile" :loader-disable="disablescrolling_advancedsearch">
                  <div class="text-center">
                    <v-progress-circular v-if="this.disablescrolling_advancedsearch === false" :size="50" :color="color.theme" indeterminate></v-progress-circular>
                  </div>
                </scroll-loader>
                <scroll-loader class="pa-0" :loader-method="fn_loadfileandfolder_scrolling" :loader-disable="disablescrolling">
                  <div></div>
                </scroll-loader>
                <scroll-loader class="pa-0" :loader-method="fn_limit_filter" :loader-disable="disablescrollfilter">
                  <div class="text-center">
                    <v-progress-circular v-if="loaddataprogress === false && this.disablescrollfilter === false" :size="50" :color="color.theme" indeterminate></v-progress-circular>
                  </div>
                </scroll-loader>

                <div class="text-center" v-if="loaddataprogress === true">
                  <v-progress-circular :size="50" :color="color.theme" indeterminate></v-progress-circular>
                </div>

                <!-- snackbardowload -->
                <div class="text-center ma-2">
                  <v-snackbar v-model="snackbardowload" bottom color="white">
                    <v-list color="white" style="padding-top: 0px;">
                      <v-toolbar :color="color.theme" dense short :dark="color.darkTheme" flat fab>
                        {{ $t("myinboxPage.dowload.dowloading") }}
                        <v-spacer></v-spacer>
                        <v-btn color="red" text @click="snackbardowload = false" fab x-small>
                          <v-icon>mdi-window-close</v-icon>
                        </v-btn>
                      </v-toolbar>
                      <v-list-item dense style="height:40px; width:400px;">
                        <v-list-item-content>
                          <v-list-item-title>{{ datetime }}</v-list-item-title>
                        </v-list-item-content>
                        <v-progress-circular
                          :rotate="90"
                          :size="35"
                          :width="3"
                          :value="percentCompleted"
                          color="teal"
                          style="margin-left:25px"
                          >&nbsp; {{ percentCompleted }}</v-progress-circular
                        >
                      </v-list-item>
                    </v-list>
                  </v-snackbar>
                </div>
              </v-container>
              <previewfile
                :show="openpreviewfile"
                :filedata_1="currentfile"
                :arrayfile="rootfile"
                :parentfolder="parentfolder"
                :param_preview="param_preview"
                @closepreviewfile="openpreviewfile = false"
                @openpreviewfile="openpreviewfile = true"
                @closeDrag="removeEvent(), cacheOpenNewfiles(currentfile.file_id)"
                @openDrag="addEvent()"
                @reload="fn_loadfileandfolder_scrolling_child()"
              ></previewfile>
              <uploadfiles
                :show="openUploadfiles"
                :parentfolder="parentfolder"
                :departmentid="departmentid"
                @close="loadstorage(), fn_loadfileandfolder_scrolling_child(), (openUploadfiles = false)"
                @closeDrag="removeEvent()"
                @openDrag="addEvent()"
              ></uploadfiles>
              <!-- <uploadfolder
                :show="openUploadfolder"
                :parentfolder="parentfolder"
                :departmentid="departmentid"
                @close="openUploadfolder = false"
                @closeandreload="fn_loadfileandfolder_scrolling_child(), (openUploadfolder = false)"
                @closeDrag="removeEvent()"
                @openDrag="addEvent()"
              ></uploadfolder> -->
              <mainrightclick
                :sendswitchapi="switch_api_upload"
                :show="showMenu"
                :parentfolder="parentfolder"
                :departmentid="departmentid"
                :AreaX="x"
                :AreaY="y"
                :permissionfolder="permission_currentfolder"
                @loaddatafrommainright="loadstorage(), fn_loadfileandfolder_scrolling_child(), (showMenu = false)"
                @closemainonly="showMenu = false"
                @closeDrag="removeEvent()"
                @openDrag="addEvent()"
                @reload="loadstorage(), fn_loadfileandfolder_scrolling_child()"
                @openuploadfolder="openUploadfolder = true"
                @open_file_office="fn_open_file_office"
                @open_folder="fn_open_folder"
              ></mainrightclick>
              <!-- <listfile @closemaincontextmenu="showMenu = false"></listfile> -->
              <currentfilerightclick
                :parentfolder="parentfolder"
                :parentfoldername="foldername"
                :show="showcurrentMenu"
                :AreaX="x"
                :AreaY="y"
                @callupdatestatusfile="setPermissionFileStar"
                @callparantfolder="gotoparent"
                @newmultidelete="multideletetotrash"
                @closecurrent="loadstorage(), fn_loadfileandfolder_scrolling_child(), (showcurrentMenu = false)"
                @closecurrentonly="showcurrentMenu = false"
                :file="currentfile"
                :arrayfile="rootfile"
                @closeDrag="removeEvent()"
                @openDrag="addEvent()"
                :checksetpermission="checksetpermission"
                :permission_folder="permission_currentfolder_v2"
                @deleterelation="deleterelationfile"
                @reload="loadstorage(), fn_loadfileandfolder_scrolling_child()"
                @loading_print="loaddataprogress_print = true"
                @disloading_print="loaddataprogress_print = false" 
                @toggle_loading_ocr="processloader_ocr = !processloader_ocr"
                @updateparentfolder="parentfolder = $route.params.id"
              ></currentfilerightclick>
              <multifilerightclick
                :status_data="status_data"
                :show="rightclickmultifile"
                :AreaX="x"
                :AreaY="y"
                :filemultipledownload="filemultipledownload"
                :parentfolder="parentfolder"
                @closecurrentonly="rightclickmultifile = false"
                @reload="loadstorage(), fn_loadfileandfolder_scrolling_child()"
                @clearparentvalue="clearparentvalue()"
                @closeappsbar="statusmutipledownload = false"
                @multideleteforbusinsess="multideletetotrash()"
              ></multifilerightclick>
              <!-- ลอง -->
              <checkpassword
                :show="opendialog_checklog"
                @closedialog="backtopreventfolder()"
                @close_c_star="clickclick"
                @close="opendialog_checklog = false"
                :filedata="newdata"
                :key_st="check_btn"
                @close_star="setPermissionFileStar(newdata, star_status)"
                @filedata="gotodirectory"
              />
              <multi_movefile
                :show="opendialogmultimove"
                :filemultipledownload="filemultipledownload"
                :parentfolder="parentfolder"
                @closedialog="opendialogmultimove = false"
                @reload="fn_loadfileandfolder_scrolling_child(), (filemultipledownload = []), (checkedList = [])"
                @closeappsbar="statusmutipledownload = false"
              ></multi_movefile>
              <dialogsnackbermultidowload
                :show="opensnackbarmultidownload"
                @closedialog="opensnackbarmultidownload = false"
                :percent="percent"
                :date="datetime"
              ></dialogsnackbermultidowload>
              <dialogsnackbarloading_print
                :show="opensnackbarloadingprint"
                @closedialog="opensnackbarloadingprint = false"
                :percent="percent"
                :date="datetime"
              ></dialogsnackbarloading_print>
              <dialogcheckmultidownload
                :show="opendialogcheckmultidownload"
                @closedialog="(opendialogcheckmultidownload = false), (filemultipledownload = [])"
                :data="filemultipledownload"
              ></dialogcheckmultidownload>
              <checkmultidelete
                :show="openchekmultidelete"
                @closedialog="(openchekmultidelete = false), (filemultipledownload = []), (show_menumanage = false), (close_header = true)"
                @closedialogreload="
                  (openchekmultidelete = false),
                    (filemultipledownload = []),
                    loadstorage(),
                    fn_loadfileandfolder_scrolling_child()
                "
                :filedata="filemultipledownload"
                :status_data="status_data"
                @closeDrag="removeEvent()"
                @openDrag="addEvent()"
                @closebuttonmulti="(statusmutipledownload = false), clearmultipledownload()"
              ></checkmultidelete>
              <dialogcheckdeleterightclick
                :show="opensdialogcheckdeleterightclick"
                @closedialog="opensdialogcheckdeleterightclick = false"
                @closedialogreload="
                  (opensdialogcheckdeleterightclick = false), loadstorage(), fn_loadfileandfolder_scrolling_child()
                "
                :filedata="currentfile"
              ></dialogcheckdeleterightclick>
              <dialogcheckrememberlogin
                :show="opendialogcheckrememberlogin"
                @closedialog="opendialogcheckrememberlogin = false"
              ></dialogcheckrememberlogin>
              <dialogalertstoragefull
                :show="opendialogalertstoragefull"
                @closedialog="opendialogalertstoragefull = false"
              ></dialogalertstoragefull>
              <dialogeditdeletehashtag
                :show="opendialogeditdeletehashtag"
                :multihashtag="filemultipledownload"
                @close="opendialogeditdeletehashtag = false"
                @reload="loadstorage(), fn_loadfileandfolder_scrolling_child()"
                :filedata="{}"
                :listcheckhashtag="{}"
              ></dialogeditdeletehashtag>
              <dialogalertevaluation
                :show="opendialogalertevaluation"
                @closedialog="opendialogalertevaluation = false"
                @reload="fn_checkevaluate()"
              ></dialogalertevaluation>
              <!-- <dialogdownloadvideo
                :show="opendialogdownloadvideo"
              ></dialogdownloadvideo> -->
            </v-card-text>
          </v-card>
        </v-card>
        <uploadfolder
          :parentfolder="parentfolder"
          :enabled_inputfile="openUploadfolder"
          :departmentid="departmentid"
          @closewithoutload="openUploadfolder = false"
          @closeUpload="(openUploadfolder = false), fn_loadfileandfolder_scrolling_child()"
        ></uploadfolder>
      </v-content>
      <dragdropupload
        :removeevent="removeevent"
        :addevent="addevent"
        :departmentid="departmentid"
        @loadfile="loadstorage(), fn_loadfileandfolder_scrolling_child()"
        :parentfolder="parentfolder"
        @snackbarupload="removeevent = true"
        @detailupload="removeevent = true"
      ></dragdropupload>
      <dialogsnackbarmultidownload_new
        :show="opendialogmultidownload_new"
        @closedialog="opendialogmultidownload_new = false"
        :data="filemultipledownload"
      ></dialogsnackbarmultidownload_new>
    </div>
  </v-app>
</template>

<script>
//Import lib
import Swal from "sweetalert2/dist/sweetalert2.js";
import { mapState, mapGetters } from "vuex";
import "vue-full-screen-file-drop/dist/vue-full-screen-file-drop.css";
import VueFullScreenFileDrop from "vue-full-screen-file-drop";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import axios from "axios";
import VueCookies from "vue-cookies";
import CryptoJS from "crypto-js";

// Import Component
const floatingbutton = () => import("../components/optional/dialog-floatingbutton")
// const uploadfolder = () => import("../components/upload/dialog-uploadfolder");
const uploadfolder = () => import("../components/upload/input-uploadfolder");
const uploadfiles = () => import("../components/upload/dialog-uploadfiles");
const currentfilerightclick = () => import("../components/contextmenu/currentfilerightclick");
const multifilerightclick = () => import("../components/contextmenu/multifilerightclick");
const mainrightclick = () => import("../components/contextmenu/mainrightclick");
const onebox_toolbar = () => import("../components/layout/layout-toolbar-new");
const previewfile = () => import("../components/optional/dialog-preview");
const dialog_filter = () => import("../components/optional/dialog-filter");
const checkpassword = () => import("../components/optional/dialog-checkpassword");
const multi_movefile = () => import("../components/optional/dialog-multimovefile");
const dragdropupload = () => import("../components/optional/dialog-dragdropupload-chunk");
const dialogsnackbermultidowload = () => import("../components/optional/dialog-snackbarmultidowmload");
const dialogcheckmultidownload = () => import("../components/optional/dialog-checkmultidownload");
const checkmultidelete = () => import("../components/optional/dialog-checkmultidelete");
const dialogcheckdeleterightclick = () => import("../components/optional/dialog-checkdeleterightclick");
const dialogcheckrememberlogin = () => import("../components/optional/dialog-checkrememberlogin.vue");
const dialogsnackbarloading_print = () => import("../components/optional/dialog-snackbarloadding-print.vue");
const dialogalertstoragefull = () => import("../components/optional/dialog-alertemail-storagefull.vue");
const dialogeditdeletehashtag = () => import("../components/optional/dialog-editdeletehashtag.vue");
const dialogsnackbarmultidownload_new = () => import("../components/optional/dialog-snackbermultidownload-new.vue");
const dialogalertevaluation = () => import("../components/optional/dialog-alert-evaluation.vue");
// const dialogdownloadvideo = () => import("../components/optional/dialog-cannotpreviewvideo");

// Import GlobalFunction
import gb_advanceSearch from "@/globalFunctions/advanceSearch";
import gb_setIconFiles from "@/globalFunctions/setIconFiles";
import gb_sizeDataConverter from "@/globalFunctions/sizeDataConverter";
import gb_checkeditonline from "@/globalFunctions/checkinetjv";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

export default {
  components: {
    dialogcheckrememberlogin,
    dragdropupload,
    uploadfiles,
    onebox_toolbar,
    mainrightclick,
    VueFullScreenFileDrop,
    uploadfolder,
    currentfilerightclick,
    previewfile,
    dialog_filter,
    multifilerightclick,
    multi_movefile,
    checkpassword,
    dialogsnackbermultidowload,
    dialogcheckmultidownload,
    checkmultidelete,
    dialogcheckdeleterightclick,
    dialogsnackbarloading_print,
    dialogalertstoragefull,
    floatingbutton,
    dialogeditdeletehashtag,
    dialogsnackbarmultidownload_new,
    dialogalertevaluation
    // dialogdownloadvideo
  },
  data: function() {
    return {
      isAdvancedSearch: false,
      count_data_smup_:0,
      listdatarootfile_smup:[],
      headers_smup:[],
      dialogsearchsmart:false,
      list_smartsearch:[],
      datasmartupload:[],
      dataheader:[],
      statusevaluation: "",
      eva_expired: "",
      opendialog_eva:"",
      icon_eva:false,
      opendialogalertevaluation:false,
      listdatarootfile:[],
      // num_count:30,
      // index_loop:0,
      count_push:0,
      data_search:{},
      pagee:1,
      load_advanced:false,
      path_folder: "",
      path_folder_split: "",
      count_data_adv_:0,
      test_person: [
        {id: 1, name: "Umai Hemman"}, 
        {id: 2, name: "John Wick"},
        {id: 3, name: "Peenoon Bellingham"},
      ],
      editor_person: [],
      isCheckbox_sep_header: false,
      random_color: [],
      logicNumberMouse: 0,
      remain_person: 0,
      timeOutTriggleMouse: "",
      isOpenTooltip: false,
      idPointed: "",
      //officefilescompatible: ["xlsx", "pptx", "xls", "ppt", "doc", "docx"],
      officefilescompatible: ["xlsx", "xlsb", "pptx", "docx", "xml", "html", "txt", "csv"],
      param_preview:"",
      opendialogmultidownload_new:false,
      myfolder:'',
      processloader_Sync:false,
      opendialogalertstoragefull: false,
      show_grid: false,
      show_list: false,
      show_back:false,
      opensnackbarloadingprint:false,
      opendialogeditdeletehashtag: false,
      loadingpercent:0,
      opendialogcheckrememberlogin:false,
      // opendialogdownloadvideo:false,
      show_print: false,
      check_folder_department: false,
      show_menu: false,
      show_star: false,
      show_delete: false,
      show_move: false,
      show_load: false,
      show_clear:false,
      show_menumanage: false, //Add new
      status_animation: "",
      close_header: true,
      check_btn: "",
      id_default: "",
      listfilecopy: [],
      checkdatapaste: [],
      listcopy: [],
      checkdatacopy: [],
      choosecopy: [],
      heardertextsearch: false,
      smartuplloadsearch:false,
      opensdialogcheckdeleterightclick: false,
      count_folder_filter: [],
      count_file_filter: [],
      tabdepartment: 0,
      departmentid: "",
      taxbox_status:"",
      opendialogcheckmultidownload: false,
      tapbusiness: true,
      removeevent: false,
      addevent: false,
      percent: 0,
      opensnackbarmultidownload: false,
      openchekmultidelete: false,
      disablescrolling: false,
      disablescrolling_advancedsearch:true,
      disablescrolling_smartsearch:true,
      totaldata: { totalall: 0, totalfile: 0, totalfolder: 0 },
      newdata: {},
      // sizeOnList: 30,
      opendialog_checklog: false,
      opendialogmultimove: false,
      noparent: "",
      noparent_M: "",
      parentfolderDD2: "",
      checkCC: false,
      checkCCFolder: false,
      parentidfile: "",
      rightclickmultifile: false,
      data_doctype: [],
      aspectData: [{ aspect: "False", aspect_key: [] }],
      switch_api_upload: false,
      testtttt: [],
      _customer_box: "",
      folderid: "",
      system: "",
      aspectSearch: [],
      status_aspect_key: "",
      permission_currentfolder: {
        download: "False",
        edit: "False",
        view_only: "True",
        upload: "False",
        delete: "False",
      },
      checkedList: [],
      // changeUI: localStorage.getItem("changeStoreUI") === "true" ? true : false,
      changeUI: localStorage.getItem("changeStoreUI") === "false" ? false : true,
      percentCompleted: 0,
      datetime: "",
      snackbardowload: false,
      listDataUpload: [],
      snackbar: false,
      hover: false,
      timeout: 0,
      countfolder: "",
      filestatus: "",
      countfile: "",
      statusmutipledownload: false,
      filemultipledownload: [],
      search: "",
      navigate: [],
      page: 1,
      size: 30,
      listsize: [30, 50, 100],
      foldername: "",
      loaddataprogress: true,
      loaddataprogress_print: false,
      processloader_ocr: false,
      parentfoldername: "",
      parentfolder: this.$route.params.id,
      loadingfile: false,
      openpreviewfile: false,
      openUploadfolder: false,
      openUploadfiles: false,
      showcurrentMenu: false,
      showPrevMenu: false,
      triggered_rc_fileorfolder: false,
      checksetpermission: false,
      currentfile: {
        file_icon: ["", ""],
        file_id: "",
        file_name: "",
        file_size: "",
        file_status: "",
        file_type: "",
      },
      showMenu: false,
      currentfilename: "",
      x: 0,
      y: 0,
      singleSelect: false,
      selected: [],
      // headers: [
      //   {
      //     text: "#",
      //     align: "center",
      //     value: "file_type",
      //     width: "7%",
      //     sortable: false,
      //   },
      //   {
      //     text: "tablefile.filename",
      //     align: "left",
      //     value: "file_name",
      //     width: "43%",
      //     sortable: false,
      //   },
      //   {
      //     text: "tablefile.owner",
      //     align: "center",
      //     value: "file_owner",
      //     width: "10%",
      //     sortable: false,
      //   },
      //   {
      //     text: "tablefile.lastmodified",
      //     value: "file_lastdtm",
      //     width: "10%",
      //     align: "center",
      //     sortable: false,
      //   },
      //   {
      //     text: "tablefile.filesize",
      //     value: "file_size",
      //     width: "10%",
      //     align: "center",
      //     sortable: false,
      //   },
      //   {
      //     text: "tablefile.location",
      //     value: "file_location",
      //     width: "10%",
      //     align: "center",
      //     sortable: false,
      //   },
      //    // หัวข้อจัดการไฟล์
      //    {
      //     text: "tablefile.option",
      //     value: "file_status",
      //     width: "10%",
      //     align: "right",
      //     sortable: false,
      //   },
      // ],
      sortby: "",
      clickSort: false,
      multiSort: false,
      rootfile: [],
      folderSort: [],
      fileSort: [],
      sortdata: [],
      buff: [],
      parentfolderDD: "",
      parentfolderDDfile: "",
      re1: "",
      checkpath: "",
      folderupload: [],
      rootname: "",
      totalcountt: 0,
      rootFolder: "",
      count: 0,
      total: 0,
      status_data: "",
      default_folder_id: false,
      star_status: "",
      sorting: {
        sort: [{
            id: 'name',
            text: 'sorting.name',
            icon: 'mdi-file-document'
          }, {
            id: 'cre_dtm',
            text: 'sorting.date_created',
            icon: 'mdi-calendar-multiselect'
          }, {
            id: 'last_dtm',
            text: 'sorting.date_modified',
            icon: 'mdi-timer'
          }, {
            id: 'type',
            text: 'sorting.type',
            icon: 'mdi-text'
          },{
            id: 'size_file',
            text: 'sorting.size_file',
            icon: 'mdi-clipboard'
          }
        ],
        direction: [{
            id: 'ASC',
            text: 'sorting.ascending'
          }, {
            id: 'DESC',
            text: 'sorting.descending'
          }
        ]
      },
      // sort_by: VueCookies.get('taxbox') === 'true' ? "cre_dtm" : "name" ,
      sort_by: VueCookies.get('taxbox') === 'true' ? "cre_dtm" : localStorage.getItem('sort_by') !== null ? localStorage.getItem('sort_by') : "cre_dtm" ,
      direction: VueCookies.get('taxbox') === 'true' ? "DESC" : localStorage.getItem('direction_by') !== null ? localStorage.getItem('direction_by') : "ASC",
      scrollY: 0,
      dataCredtmNewUpload: [],
      arrayCredtmNewUpload: [],
      arrayOpenNewfiles: [],
      dataafterfilter: [],
      filteroffset: 0,
      disablescrollfilter: true,
      show_addhashtag: false,
      permission_currentfolder_v2: "01",
      param_preview:"",
    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color", "role_level", "feature_onlyoffice"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataLoginDefault",
      "dataCheckPackage",
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    pageCount: {
      get() {
        // let l = this.rootfile.length;
        let l = this.heardertextsearch ? this.count_data_adv_ : this.smartuplloadsearch ? this.count_data_smup_ : this.totaldata["totalall"];
        let s = this.size;
        console.log(Math.ceil(l / s));
        return Math.ceil(l / s);
      },
      set(newName) {
        return newName;
      },
    },
    paginatedData() {
      const start = (this.page - 1) * this.size;
      const end = start + this.size;
      return this.rootfile.slice(start, start + this.size);
    },
    headerTable() {
      return "color:" + this.color.theme + ";" + "font-weight: 600; font-size:15px;";
    },
    headerPage() {
      return "color:" + this.color.theme + ";" + "font-size: 24px; font-weight: 600;";
    },
    colorVapp() {
      return "background:" + this.color.BG + ";";
    },
    colorSort() {
      return "color:" + this.color.alertText + ";";
    },
    // colorProgress() {
    //   return "color:" + this.color.theme + ";";
    // },
    colorProgress() {
      //return "color:" + this.color.theme + ";";
      return "color:" + "white" + ";";
    },
    word_grid() {
      return this.$vuetify.theme.dark ? "background: #464646;" : "background: #fafafa;";
    },
    disableByPackage() {      
      // return this.dataCheckPackage.type === 'Business'? this.dataCheckPackage.package ? false : true : this.dataCheckPackage.package ? false : true
      return false
    },
    //สีอักษรของ headerpage ของ mobile
    headerPageMobile() {
      return "color:" + this.color.theme + ";" + "font-size: 16px; font-weight: 700; line-height: 24px;";
    },
    // ขนาดของ chip ที่แสดง headerpage
    headerChipPage() {
      return "width: 100%; min-height: 40px; max-height: auto; height: auto !important; border-radius: 40px;"
    },
    //สีพื้นหลังและขอบของ card ที่แสดงรายการ
    displayMobile() {
      if (this.$vuetify.theme.dark){
        return "background: #464646; border: 1px solid #F2F2F2; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1); border-radius: 10px;"
      } else {
        return "background: #FFFFFF; border: 1px solid #F2F2F2; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1); border-radius: 10px;"
      }
    },
    // ตัวหนา
    listOnBold() {
      return "font-size: 14px; font-weight: 700; line-height: 24px;";
    },
    // ตัวบาง
    listNoBold() {
      return "font-size: 14px; font-weight: 400; color: #636363; line-height: 24px;";
    },
    // ขอบของ sortby
    roundListSort() {
      return "border-radius: 10px; border: 1px solid #f3f5f7; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);"
    },
    // สีพื้นหลังของ list ที่แสดงรายการไฟล์
    styleDisplayMobile() {
      if (this.$vuetify.theme.dark) {
        return "background: #464646;"
      } else {
        return "background: #FFFFFF;"
      }
    },
    // สีพื้นหลังและเส้นขอบล่างของ list item ของแต่ละอัน
    displayMobileForListItem() {
      if (this.$vuetify.theme.dark) {
        return "background: #464646; border-bottom: 1px solid #F3F5F7;"
      } else {
        return "background: #FFFFFF; border-bottom: 1px solid #F3F5F7;"
      }
    },
    // สีพื้นหลังของ Floating Button (ปุ่มลอยขวาล่าง)
    colorFlotingButton() {
      if (this.$vuetify.theme.dark) {
        return "background: #9A9A9A;"
      } else {
        return "background: linear-gradient(270deg, #176C91 0%, #71B5D8 100%);"
      }
    },
    checkbox_value : {
      // getter
      get () {
        let resultCheckBox
        if(this.filemultipledownload.length === this.rootfile.length) {
          resultCheckBox = true
        } else {
          resultCheckBox = false
        }
        return resultCheckBox
      },
      // setter
      set (newValue) {
        if(newValue === false){
          this.isCheckbox_sep_header = false
        } else {
          this.isCheckbox_sep_header = true
        }
      }
    },
    indeterminate_custom_checkbox () {
      if(this.filemultipledownload.length > 0 && this.filemultipledownload.length < this.rootfile.length){
        return true
      } else {
        console.log("Why heree")
        return false
      }
    },

    //animation list menu 
    animationlistmenu() {
      if(this.show_menumanage === true){
        return `pa-1 pl-10 darkmode` 
      }
    },
    animationlistmenu_v2() {
      if(this.show_menumanage === true){
        return `${this.status_animation}` 
      }
    },
    headerdatatable () {
      return `borderLeft: 1px solid; borderRight: 1px solid;
              borderLeftColor: ${this.$vuetify.theme.dark ? '#383838' :'#dcdcdc'};
              borderRightColor: ${this.$vuetify.theme.dark ? '#383838' :'#dcdcdc'};`
    },
    headeranimationmenu() {
      return  `background: ${this.$vuetify.theme.dark ? '#1e1e1e' : '#fafafa'}; width: 100%; ` +
              `border: 1px solid ${this.$vuetify.theme.dark ? '#383838' :'#dcdcdc'}; `+ 
              `borderTopLeftRadius: 12px 12px; borderTopRightRadius: 12px 12px;`
    },
    randomColor () {
      return `backgroundColor: ${this.color.theme};`
    },
    headers() {
      let _headers = [
        {
          text: "#",
          align: "center",
          value: "file_type",
          width: "7%",
          sortable: false,
        },
        {
          text: "tablefile.filename",
          align: "left",
          value: "file_name",
          width: "43%",
          sortable: false,
        },
        {
          text: "tablefile.owner",
          align: "center",
          value: "file_owner",
          width: "10%",
          sortable: false,
        },
        {
          text: "tablefile.lastmodified",
          value: "file_lastdtm",
          width: "10%",
          align: "center",
          sortable: false,
        },
        {
          text: "tablefile.filesize",
          value: "file_size",
          width: "10%",
          align: "center",
          sortable: false,
        }
      ]
      if (this.isAdvancedSearch && this.tapbusiness) {
        _headers.push({
          text: "tablefile.location",
          value: "file_location",
          width: "10%",
          align: "center",
          sortable: false,
        });
      }
      if (this.tapbusiness) {
        _headers.push({
          text: "tablefile.option",
          value: "file_status",
          width: "10%",
          align: "right",
          sortable: false,
        });
      }
      return _headers
    },
    container_directory () { // ไว้ใช้กำหนดความสูง card 
      if(this.resolutionScreen >= 500){
        return this.show_menumanage ? this.changeUI ? 'thiscontainer_directoryfile' : 'thiscontainer_directoryfile_with_header' : 'thiscontainer_directoryfile'
      } else {
        return 'thiscontainer_directoryfile'
      }
    },
  },
  filters: {
    subStr: function(string) {
      if (string == null || string == "" || string == undefined) {
        return string;
      } else {
        if (string.length > 100) return string.substring(0, 100) + "...";
        else return string;
      }
    },
  },
  watch: {
    rightclickmultifile(val) {
      if (val === false) this.checkedList = [];
    },

    permission_currentfolder(val) {
      if (val["upload"] == "False") {
        this.removeEvent();
      } else {
        this.addEvent();
      }
    },

    navigate(val) {
      console.log("update navigate", val);
      // console.log('ssssssss',this.dataAccountActive["business_info"]["my_folder_id"])
      // วน loop แล้ว navigate พัง
      // for (let index = 0; index < this.navigate.length; index++) {
      //   const element = this.navigate[index];
      //   if(element.text = this.dataAccountActive["business_info"]["my_folder_id"]){
      //     console.log('Switch to new api upload')
      //   }
      // }
      if (this.dataAccountActive.type === "Buiness") {
        if (this.navigate[1].text[1] == this.dataAccountActive["business_info"]["my_folder_id"]) {
          // console.log('Switch to new api upload')
          this.switch_api_upload = true;
          console.log("switch api upload", this.switch_api_upload);
        }
        console.log("this.navigate", this.navigate, this.dataLoginDefault);
        if (this.dataLoginDefault !== "") {
          console.log("this.navigate", this.navigate);
        }
      } else {
        this.switch_api_upload = false;
        console.log("switch api upload", this.switch_api_upload);
      }
    },
    sort_by() {
      localStorage.setItem('sort_by', this.sort_by)
      this.loadstorage();
      this.fn_loadfileandfolder_scrolling_child();
    },
    direction() {
      localStorage.setItem('direction_by', this.direction)
      this.loadstorage();
      this.fn_loadfileandfolder_scrolling_child();
    }
    ,
    scrollY: {
      immediate: true,
      handler (value) {
        if(this.rootfile.length > 100){
          this.animatemenumanage(value, 1000, 400)
        } else {
          this.animatemenumanage(value, 600, 400)
        }
      }
    },
    changeUI(newValue) {
      // this.rootfile = [];
      if (newValue) {
        // this.sizeOnList = this.size;
        // reset limit
        this.size = 30
      } else {
        // this.size = this.sizeOnList;
        this.size = localStorage.getItem("limitLoadFileFolder") ? parseInt(localStorage.getItem("limitLoadFileFolder")) : 30;
      }
    }
  },
  methods: {
    onToggleFileLocation(open, item) {
      // console.log('open', open, item)
      if (!open) { // ตอนไม่ hover menu file location
        item.is_expand_file_location = false;
      }
    },
    fn_open_folder(data){
      // console.log("fn_open_folder",data);
      let folder_data = {
        account_id: this.dataAccountId,
        account_reciever: "",
        account_sender: this.dataAccountId,
        countnum: 0,
        data_type: "r",
        editing_status: "N",
        expiration_time: "00000000000000",
        file_createdtm: data.cre_dtm,
        file_department: "",
        file_icon: [
                "mdi-folder",
                "#ffc107"
            ],
        file_id: data.folder_id,
        file_lastdtm: data.cre_dtm,
        file_linkshare: null,
        file_name: data.folder_name,
        file_owner_eng: "Me",
        file_owner_th: "ฉัน",
        file_parent_folder_id: this.$route.params.id,
        file_permission: {
                "admin_data": "False",
                "delete": "True",
                "download": "True",
                "edit": "True",
                "upload": "True",
                "view_only": "True"
            },
        file_permission_2: "05",
        file_permission_department_setting: "False",
        file_permission_setting: "False",
        file_permission_role_setting: "False",
        file_size: "",
        file_status: "N",
        file_status_share: "N",
        file_status_sharelink: "N",
        file_thumbnail: "",
        file_thumbnail_icon: [
                 "mdi-folder",
                 "#ffc107"
        ],
        file_type: "folder",
        folder_id: this.$route.params.id,
        hashtag:[],
        last_update_name:{
                  "name_eng": "Me",
                  "name_th": "ฉัน"
        },
        last_view:"",
        permission_business_setting: "False",
        permispermission_department_setting: "False",
        permission_role_setting: "False",
        permission_setting: "False",
        priority: "0",
        relation_id: "",
        relation_name: "",
        relation_status: 2,
        starred_id: null,
        status_encrypt: "N",
        status_expired: "3",
        status_lock_data: "N",
        status_lock_file: "",
        status_lock_folder: "",
        status_updating: "Done",
        system: null,
        tag_id: "N",
        type: "folder",
        update_by: "",
        user_id: this.dataUsername,
        version_tag: "N",
        warning_expiration_time: "-"
      }
      this.gotodirectory(data.folder_id, folder_data.file_type, folder_data)
    },
    selectedLimit() {
      localStorage.setItem("limitLoadFileFolder", this.size);
      if (this.heardertextsearch) {
        this.rootfile = [];
        this.disablescrolling_advancedsearch = false;
      } else if (this.smartuplloadsearch) {
        this.rootfile = [];
        this.disablescrolling_smartsearch = false;
      } else {        
        this.fn_loadfileandfolder_scrolling_child('', { cal_offset: 0 });
      }
    },
    fn_open_file_office(data){
      // console.log("fn_open_file_office",data);
      let file_data = {
            account_reciever: "",
            account_sender: this.dataAccountId,
            account_id: this.dataAccountId,
            countnum: 0,
            file_createdtm: "",
            file_id: data.id,
            file_lastdtm: "",
            file_linkshare: null,
            file_name: data.filename,
            file_owner_eng: "Me",
            file_owner_th: "ฉัน",
            priority: 1,
            file_size: data.size_file,
            file_status: "N",
            file_status_share: "N",
            file_status_sharelink: "N",
            system: null,
            type: "file",
            file_icon: [
                "mdi-file-excel",
                "#00733b"
            ],
            file_type: data.filename.split(".")[data.filename.split(".").length -1],
            data_type: "file",
            user_id: this.dataUsername,
            starred_id: null,
            file_parent_folder_id: this.$route.params.id,
            file_permission: {
                "admin_data": "False",
                "delete": "True",
                "download": "True",
                "edit": "True",
                "upload": "True",
                "view_only": "True"
            },
            file_permission_department_setting: "False",
            permission_department_setting: "False",
            permission_business_setting: "True",
            permission_setting: "True",
            permission_role_setting: "False",
            file_permission_setting: "True",
            file_department: "",
            relation_id: "",
            relation_name: "",
            relation_status: 2,
            file_permission_2: "05",
            status_updating: "Done",
            status_lock_data: "N",
            status_expired: "3",
            expiration_time: "00000000000000",
            version_tag: "False",
            tag_id: "N",
            hashtag: [],
            file_thumbnail: "",
            file_thumbnail_icon: [
                "mdi-file-excel",
                "#00733b"
            ],
            editing_status: "N",
            update_by: "",
            last_update_name: {
                "name_eng": "Me",
                "name_th": "ฉัน"
            },
            warning_expiration_time: "-",
            status_encrypt: "N",
            last_view: ""
        }
        // console.log("file_data",file_data);
      this.gotodirectory(data.file_id, data.file_type, file_data)
    },
    backfromsearch(){
      console.log("this.$route",this.$route.params.id);
      this.heardertextsearch === false;
      this.smartuplloadsearch === false;
      this.$router.push({path: "/directory/" + this.$route.params.id});
      this.fn_loadfileandfolder_scrolling_child();
      this.parentfolder = this.$route.params.id;
    },
    async callsmartuploadsearch(datatemplate,id){
      console.log("เข้าcallsmartuploadsearch");
      this.list_smartsearch = [];
      for(let i=0; i < datatemplate.length; i++){
        if(datatemplate[i].aspect_type === "TEXT"){
          console.log("value_search",datatemplate[i].value_search);
         if(datatemplate[i].value_search === null || datatemplate[i].value_search === undefined || datatemplate[i].value_search === ""){
          }else{
            this.list_smartsearch.push({
              "aspect_id":datatemplate[i].aspect_id,
              "aspect_key":datatemplate[i].aspect_name,
              "aspect_label":datatemplate[i].aspect_label,
              "aspect_value":datatemplate[i].value_search
            })
          }
        }else if(datatemplate[i].aspect_type === "DROPDOWN"){
          if(datatemplate[i].value === null || datatemplate[i].value === undefined || datatemplate[i].value === ""){
          }else{
            this.list_smartsearch.push({
              "aspect_id":datatemplate[i].aspect_id,
              "aspect_key":datatemplate[i].aspect_name,
              "aspect_label":datatemplate[i].aspect_label,
              "aspect_value":datatemplate[i].value
            })
          }
        }else if(datatemplate[i].aspect_type === "DATE"){
          if(datatemplate[i].fromdate === null || datatemplate[i].fromdate === undefined || datatemplate[i].fromdate === ""){
          }else{
            let dt_date = datatemplate[i].fromdate.split("-")
            this.list_smartsearch.push({
              "aspect_id":datatemplate[i].aspect_id,
              "aspect_key":datatemplate[i].aspect_name,
              "aspect_label":datatemplate[i].aspect_label,
              "aspect_value":dt_date[0] + dt_date[1] + dt_date[2]
            })
          }
        }
      }
      console.log("list_smartsearch",this.list_smartsearch);
      if(this.list_smartsearch.length === 0){
        this.loaddataprogress = false;
        Toast.fire({
          icon: "warning",
          title: this.$t("smartinput.warning_search_smup")
        });

      }else{
      this.loaddataprogress = true;
      this.disablescrolling_advancedsearch = true;
      this.disablescrolling = true;
      this.disablescrollfilter = true;
      this.smartuplloadsearch = true;
      this.headers_smup = [];
      this.listdatarootfile_smup = [];
      this.buff=[];
      this.rootfile = [];
      this.count_data_smup_ = 0
      console.log("callsmartuploadsearch",datatemplate);

        let payload = {
          "business_id": this.dataAccountActive.business_info.business_id,
          "account_id": this.dataAccountId,
          "key_body": this.list_smartsearch,
          "template_id": id
        }
        console.log("payload",payload);
        let auth = await gbfGenerate.generateToken();
        this.axios
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/smartbox/v1/smart_search",
          payload,{
              headers: { Authorization: auth.code },
            }
        )
        .then((response) => {
          if (response.data.status == "OK") {
            this.loaddataprogress = false;
            this.disablescrolling_smartsearch = true;
            console.log("search smaert UP",response.data);
            this.datasmartupload = response.data.data
            this.dataheader = response.data.key_header
            this.dialogsearchsmart = true;
        
            if(this.dataheader.length > 0){
              this.headers_smup.push({
                text: "#",
                align: "center",
                value: "file_type",
                width: "7%",
                sortable: false,
              },
              {
                text: "ชื่อไฟล์",
                align: "left",
                value: "file_name",
                width: "43%",
                sortable: false,
              },
              {
                text: "ผู้สร้าง",
                align: "center",
                value: "owner",
                width: "15%",
                sortable: false,
              },

              )
              for(let i=0; i < this.dataheader.length; i++){
                this.headers_smup.push({
                  text: this.dataheader[i].aspect_label,
                  align: "center",
                  value: this.dataheader[i].aspect_name,
                  width: "10%",
                  key:this.dataheader[i].aspect_id,
                  sortable: false,
                })
              }
              this.headers_smup.push({
                text: "จัดการไฟล์",
                value: "file_status",
                width: "10%",
                align: "right",
                sortable: false,
              })
            }
            console.log("this.headers_smup",this.headers_smup);

            for(let i=0; i < response.data.data.length; i++){
              console.log("key_body11",response.data.data[i]["key_body"][i]); 
                  let datafolder = {};
                  let typefile = response.data.data[i]["type"] === 'folder' ? "folder" : response.data.data[i]["name"].split(".") ;
                    datafolder["account_reciever"] = "";
                    datafolder["account_sender"] = this.dataAccountId;
                    datafolder["folder_id"] = response.data.data[i]["folder_id"];
                    if ("file_id" in response.data.data) {
                      datafolder["file_id"] = response.data.data[i]["file_id"];
                    } else {
                      datafolder["file_id"] = response.data.data[i]["id"];
                    }
                    datafolder["file_name"] = response.data.data[i]["name"];
                    datafolder["file_size"] = response.data.data[i]["size"];
                    datafolder["full_path"] = response.data.data[i]["full_path"];
                    datafolder["full_path_id"] = response.data.data[i]["full_path_id"];
                    let fullPathName = datafolder["full_path"] ? datafolder["full_path"].split('/') : [];
                    let fullPathId = datafolder["full_path_id"] ? datafolder["full_path_id"].split('/') : [];
                    datafolder["file_location"] = fullPathName.map((name, index) => {
                      let fileExtensions = [".jpeg", ".jpg", ".png", ".gif", ".pdf", ".xlsx", ".pptx", ".xls", ".ppt", ".doc", ".docx", ".mp4", ".mov", ".txt", ".xml", ".xlsb", ".html", ".csv"];
                      let fileType = fileExtensions.some(ext => name.toLowerCase().endsWith(ext)) ? "file" : "folder";
                      return {
                        folder_id: fullPathId[index],
                        folder_name: name,
                        file_type: fileType
                      };
                    });
                    datafolder["file_location"].pop();
                    if (datafolder["file_location"].length <= 3) {
                      datafolder["display_file_location"] = datafolder["file_location"];
                    } else {
                      datafolder["display_file_location"] = [
                        datafolder["file_location"][0], {
                          folder_id: '',
                          folder_name: '...',
                          file_type: ''
                        }, 
                        datafolder["file_location"][datafolder["file_location"].length - 1]
                      ];
                    }
                    datafolder["is_expand_file_location"] = false;

                    datafolder["file_status"] = response.data.data[i]["status"];
                    datafolder["file_owner_eng"] = '';
                    datafolder["file_owner_th"] = '';
                    datafolder["file_linkshare"] = "";
                    datafolder["file_lastdtm"] = response.data.data[i]["last_dtm"];
                    datafolder["file_date"] = "";
                    datafolder["file_permission_2"] = response.data.data[i]["permission_account_v2"];
                    datafolder["file_parent_folder_id"] = response.data.data[i]["folder_id"];
                    // if ("permission_account" in response.data.data) {
                    datafolder["file_permission"] = response.data.data[i]["permission_account"];
                    // } else {
                    //   datafolder["file_permission"] = {
                    //     admin_data: "False",
                    //     delete: "False",
                    //     download: "False",
                    //     edit: "False",
                    //     upload: "False",
                    //     view_only: "True",
                    //   };
                    // }

                    datafolder["file_permission_department_setting"] = response.data.data[i]["permission_department_setting"];
                    datafolder["file_permission_setting"] = response.data.data[i]["permission_setting"];
                    datafolder["permission_business_setting"] = response.data.data[i]["permission_business_setting"];
                    datafolder["permission_role_setting"] = response.data.data[i]["permission_role_setting"];
                    datafolder["file_status_share"] = response.data.data[i]["status_share"];
                    datafolder["file_status_sharelink"] = response.data.data[i]["status_share_link"];
                    datafolder["file_type"] = response.data.data[i]["data_type"];
                    datafolder["data_type"] = typefile[typefile.length - 1];
                    datafolder["status_updating"] = response.data.data[i]["status_updating"];
                    datafolder["file_department"] = "";
                    datafolder["file_icon"] = this.fn_setThumbnailIcon(typefile[typefile.length - 1]);
                    datafolder["status_updating"] = response.data.data[i]["status_updating"];
                    datafolder["status_lock_folder"] = response.data.data[i]["status_lock_folder"];
                    datafolder["status_lock_file"] = response.data.data[i]["status_lock_file"];
                    datafolder["status_lock_data"] = response.data.data[i]["status_lock_data"];
                    datafolder["file_thumbnail"] = "";
                    datafolder["file_thumbnail_icon"] = this.fn_setThumbnailIcon(typefile[typefile.length - 1]);
                    datafolder["editing_status"] = response.data.data[i]["editing_status"];
                    datafolder["update_by"] = response.data.data[i]["update_by"];
                    datafolder["last_update_name"] = response.data.data[i]["last_update_name"];
                    datafolder["version_tag"] = response.data.data[i]["version_tag"];
                    datafolder["tag_id"] = response.data.data[i]["tag_id"];
                    datafolder["hashtag"] = response.data.data[i]["hashtag"];
                    datafolder["status_expired"] = response.data.data[i]["status_expired"];
                    datafolder["expiration_time"] = response.data.data[i]["expiration_time"];
                    datafolder["warning_expiration_time"] = response.data.data[i]["warning_expiration_time"];
                    datafolder["key_body"] = response.data.data[i]["key_body"];
                    datafolder["user_id"] = response.data.data[i]["user_id"];
                    datafolder["owner"] = "";

                    // if(datafolder["key_body"].length > 0){

                    // let list_keybody = [];
                    // for(let i = 0; i < datafolder["key_body"].length; i++){
                    //   let key_index  = this.dataheader.findIndex((obj) => obj.aspect_id === datafolder["key_body"][i].aspect_id);
                    //   console.log("this.dataheader",this.dataheader);
                    //   console.log("datafolder keybody",datafolder["key_body"][i]);
                    //   console.log("id",datafolder["key_body"][i].aspect_id);
                    //   console.log("key_index",key_index);
                    //   if(key_index !== -1){
                    //     list_keybody.push(datafolder["key_body"][key_index])
                    //   }
                    // }
                    // console.log("list_keybody",list_keybody);
                    // datafolder["key_body"] = list_keybody;
                    // }

                    if(datafolder["key_body"].length > 0){
                      let list_keybody = [];
                      for(let i = 0; i < this.dataheader.length; i++){
                        let key_index  = datafolder["key_body"].findIndex((obj) => obj.aspect_id === this.dataheader[i].aspect_id);
                        console.log("this.dataheader",this.dataheader);
                        console.log("datafolder keybody",datafolder["key_body"][i]);
                        console.log("id",datafolder["key_body"].aspect_id);
                        console.log("key_index",key_index);
                        if(key_index !== -1){
                          list_keybody.push(datafolder["key_body"][key_index])
                        }
                      }
                      console.log("list_keybody",list_keybody);
                      datafolder["key_body"] = list_keybody;
                      }
                    this.listdatarootfile_smup.push(datafolder);
                    // this.rootfile.push(datafolder);
                    // this.buff.push(datafolder);
                    datafolder["status_hilightword"] = true
                
                  
            }
            console.log("this.listdatarootfile_smup",this.listdatarootfile_smup);
            this.count_data_smup_ = response.data.total;
            // this.index_loop = 0;
            // this.num_count = 30;
            this.looplistdatarootfile_smartsearch();

          } else {
            this.loaddataprogress = false;
            console.log("response error",response.data);
          }
        })
        .catch((error) => {
          this.loaddataprogress = false;
          console.log("error gettamplate search", error);
        });

      }
    },
    async fn_getowner_template(rootfile) {
      let auth = await gbfGenerate.generateToken();
      let name_creator = ""
      await this.axios
      .post(
      process.env.VUE_APP_SERVICE_ADMIN_BOX + "/api/admin/get_data_by_user_id",
      {user_id: rootfile.user_id},{headers: { Authorization: auth.code },}
      )
      .then((response)=> {
        if(response.data.status === 'OK'){
          rootfile.owner = response.data.result.t_named
        }
      })
    },
    looplistdatarootfile_smartsearch(){
      this.loaddataprogress = false;
      // console.log('this.index_loop1',this.index_loop);
      if (this.changeUI) {
        this.size = 30;
      }
      let cal_limit = this.size;
      let cal_offset = this.rootfile.filter((item) => { 
        return item.file_status !== 'M';
      }).length;

      let count_push = 0;
      for(let i = cal_offset; i < this.count_data_smup_; i++){
        if (count_push >= cal_limit) break;

        this.rootfile.push(this.listdatarootfile_smup[i])
        this.buff.push(this.listdatarootfile_smup[i])
        const element = this.rootfile[i];
            element.file_thumbnail = "";
            let typeFile = element.file_name.split(".");
            this.fn_loadthumbnail(element.file_id, typeFile[typeFile.length - 1],element.file_size, element.status_lock_data);
            // if(this.rootfile[this.index_loop].file_owner_th === '' || this.rootfile[this.index_loop].file_owner_eng === ''){
            //  this.get_name_owner(this.rootfile[this.index_loop])
            // }
        count_push += 1;
      }
      for(let y = 0; y < this.rootfile.length; y++){
              if(this.rootfile[y].owner === ''){
                 this.fn_getowner_template(this.rootfile[y])
              }
            }
      console.log('this.rootfile',this.rootfile);

      // if(this.num_count < this.count_data_smup_ ){
      //     this.num_count += 30;
      //   }
      // for (let index = 0; index < this.rootfile.length; index++) {
      // const element = this.rootfile[index];
      // element.file_thumbnail = "";
      // let typeFile = element.file_name.split(".");
      // await this.fn_loadthumbnail(element.file_id, typeFile[typeFile.length - 1],element.file_size, element.status_lock_data);
      
      // if(this.rootfile[index].file_owner_th === '' || this.rootfile[index].file_owner_eng === ''){
      //   await this.get_name_owner(this.rootfile[index])
      // }
      // }
       this.disablescrolling_smartsearch = this.count_data_smup_ <= this.rootfile.length;
       console.log("this.count_data_smup_",this.count_data_smup_);
       console.log("this.rootfile.length",this.rootfile.length);
       console.log("this.disablescrolling_smartsearch",this.disablescrolling_smartsearch);
    },
    fn_hilightWord (text) {
      var regex = new RegExp(this.data_search.name, "gi")
      if(this.$vuetify.theme.dark){
        var response = text.replaceAll(regex, function(str) {
          return "<span style='background-color: #827917'>" + str + "</span>"
        })
      } else {
        var response = text.replaceAll(regex, function(str) {
          return "<mark>" + str + "</mark>"
        })
      }
      return response
      //return text.replaceAll(new RegExp(this.data_search.name,"gi"), `<mark>${this.data_search.name}</mark>`);
    },
    Checkbox_custom_header (value) {
      if(value){
        this.filemultipledownload = [...this.rootfile.filter((item) => {return item.file_status !== 'M'})]
        this.isCheckbox_sep_header = true 
      } else {
        this.filemultipledownload = []
        this.isCheckbox_sep_header = false
      }
    },
    async mouseOverFile_onlyoffice (item) {
      if(this.dataAccountActive.type !== "Business"){ // ในกรณีที่ไม่ใช่ business 
        return
      }
      clearTimeout(this.timeOutTriggleMouse)
      this.logicNumberMouse++
      if(this.logicNumberMouse <= 10){
        this.idPointed = item.file_id
        this.timeOutTriggleMouse = setTimeout(async () => {
          let payload = {
            file_id: item.file_id
          }
          let auth = await gbfGenerate.generateToken();
          this.axios
          .post(
            process.env.VUE_APP_SERVICE_TEST_UAT + "/api/get_user_preview_onlyoffice", payload, {
              headers: { Authorization: auth.code } 
            }
          )
          .then((response) => {
            if(response.data.status === 'OK'){
              for(var i=0; i < [1,2,3].length; i++) {
                this.random_color.push(this.fn_randomColor())
              }
              if(response.data.result.length > 3 ){
                this.editor_person = response.data.result.slice(0, 3)
                this.remain_person = response.data.result.length - this.editor_person.length
                this.isOpenTooltip = true
                return
              }
              this.editor_person = [...response.data.result]
              this.isOpenTooltip = true
            } else {
              this.isOpenTooltip = false
              this.remain_person = 0
            }
            
          })
          .catch ((err) => {
            this.isOpenTooltip = false
            this.remain_person = 0
          })
        }, 2000)
      } else {
        clearTimeout(this.timeOutTriggleMouse)
      }
    },
    mouseLeaveFile_onlyoffice () {
      if(this.dataAccountActive.type !== "Business"){ // ในกรณีที่ไม่ใช่ business 
        return
      }
      clearTimeout(this.timeOutTriggleMouse)
      this.isOpenTooltip = false
      this.logicNumberMouse = 0
      this.remain_person = 0
      this.random_color = []
    },
    reloadSortByAndDirection() {
      this.loadstorage();
      this.fn_loadfileandfolder_scrolling_child();
    },
    fn_randomColor () {
      let random_ = Math.floor(Math.random()*16777215).toString(16)
      console.log("Random _", random_)
      return random_
    },
    onScroll (e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset ||   e.target.scrollTop || 0
      this.fab = top > 20
    },
    async fn_printPDF() {
      if (this.filemultipledownload.length > 0) {
        // this.loaddataprogress_print = true;
        this.opensnackbarloadingprint = true;
        // this.progresspercent = 0;
        this.percent = 0;
        let listfile = "";
        let notpdf = false;
        let i;
        for (i = 0; i < this.filemultipledownload.length; i++) {
          if (this.filemultipledownload[i]["data_type"] !== "pdf") {
            Toast.fire({
              icon: "error",
              title: "โปรดเลือกเฉพาะไฟล์ PDF",
            });
            notpdf = true;
            // this.loaddataprogress_print = false;
            this.opensnackbarloadingprint = false;
          } else if (this.filemultipledownload[i]["status_lock_data"] === "Y") {
            Toast.fire({
              icon: "error",
              title: "บางไฟล์ที่คุณเลือกมีการล็อกไว้",
            });
            notpdf = true;
            this.opensnackbarloadingprint = false;
            // this.loaddataprogress_print = false;
          } else {
            listfile += "&list_file=" + this.filemultipledownload[i]["file_id"];
            //console.log(listfile);
          }
        }
        if (i === this.filemultipledownload.length && notpdf !== true) {
          let auth = await gbfGenerate.generateToken();

          let url =
            process.env.VUE_APP_SERVICE_DOWNLOAD_API +
            "/api/merge_pdf?account_id=" +
            this.dataAccountId +
            "&user_id=" +
            this.dataUsername +
            "&Authorization=" +
            auth.code +
            listfile;
          this.axios.get(url, {
                headers: { Authorization: auth.code },
                onDownloadProgress: (progressEvent) => {
                  console.log("progressEvent", progressEvent);
                  let progresspercent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                  // this.loadingpercent = progresspercent;
                  this.percent = progresspercent;
                  // console.log("progresspercent",progresspercent);
                },
                responseType: 'blob',
              }).then((response) => {
            console.log("===========",response);
            this.opensnackbarloadingprint = false;
            //  this.loaddataprogress_print = false;
            if (response.data.type === "application/pdf") {
              this.statusmutipledownload = false;
              this.filemultipledownload = [];
              this.opensnackbarloadingprint = false;
              // this.loaddataprogress_print = false;
              let url = window.URL.createObjectURL(response.data);
              window.open(url).print();
              this.show_menumanage = false
              this.close_header = true
            } else {
              this.opensnackbarloadingprint = false;
              // this.loaddataprogress_print = false;
              Toast.fire({
                icon: "error",
                title: "ไม่สามารถรวมไฟล์เสียได้",
              });
            }
          });
        }
      } else {
        Toast.fire({
          icon: "warning",
          title: this.$t("myinboxPage.msg.nofile"),
        });
      }
    },
    clickclick() {
      this.opendialog_checklog = false;
    },
    default_func() {
      this.default_folder_id = true;
      console.log("default_folder_id", this.default_folder_id);
    },
    fn_checkkeyevent(e) {
      console.log("e", e);
      // e.preventDefault();
      // e.stopPropagation();
      this.checkdatacopy = [];
      //เช็คว่า กด ctrl+c หรือ ctrl+v
      if (e.ctrlKey == true && e.code === "KeyC") {
        console.log("choosecopy", this.choosecopy);
        this.checkdatacopy = Array.from(new Set(this.choosecopy));
        console.log("checkdatacopy", this.checkdatacopy);
        console.log("KeyC");
        this.fn_copyfile(this.checkdatacopy);
      } else if (e.ctrlKey == true && e.code === "KeyV") {
        this.loaddataprogress = true;
        this.disablescrolling = true;
        console.log("KeyV");
        let data_copy = sessionStorage.getItem("filecopy");
        let data_paste = JSON.parse(data_copy);
        console.log("data_paste", data_paste);
        //วนเพื่อปั้นข้อมูลก่อนยิง api
        for (let i = 0; i < data_paste.length; i++) {
          this.listfilecopy.push({
            data_type: data_paste[i].file_type === "folder" ? "folder" : "file",
            data_id: data_paste[i].file_id,
          });
        }
        this.fn_pastefiles(this.listfilecopy);
      }
    },
    fn_copyfile(item) {
      // console.log("fn_copyfile", item);
      this.listcopy = [];
      for (var i = 0; i < item.length; i++) {
        // console.log("i*tem*", item[i]);
        //เช็คว่าถ้าเป็นไฟล์ถึงจะ push ใส่ listcopy
        if (item[i].file_type === "folder") {
        // } else if (item[i].file_permission.download === "True" && item[i].status_lock_data === "N") {
        //   this.listcopy.push(item[i]);
        // }เปลี่ยนมาเช็คอันนี้
        } else if (item[i].file_permission_2 !== "01" && item[i].status_lock_data === "N") {
          this.listcopy.push(item[i]);
        }
      }
      //เช็ค listcopy เพื่อ เก็บใน sessionstorage
      if (this.listcopy.length == 0) {
        Toast.fire({
          icon: "error",
          title: "กรุณาเลือกไฟล์ที่ต้องการคัดลอก",
        });
      } else {
        console.log("listcopy", this.listcopy);
        sessionStorage.setItem("filecopy", JSON.stringify(this.listcopy));
        Toast.fire({
          icon: "success",
          title: "คัดลอกไฟล์",
        });
        console.log("************ ctrl+c *************");
      }
    },
    async fn_pastefiles(item) {
      console.log("วางงงงงงงง");
      this.listfilecopy = [];
      // console.log("this.choosecopy",this.choosecopy);
      //จัดข้อมูลที่ซ้ำ
      // get ค่าจาก sessionstorage

      console.log("this.listfilecopy***", this.listfilecopy);

      let payload = {
        account_id: this.dataAccountId,
        folder_id: this.$route.params.id,
        copied: item,
      };
      console.log("1");
      console.log("payload", payload);
      let auth = await gbfGenerate.generateToken();
      console.log("auth", auth);
      let resp = await axios.post(process.env.VUE_APP_SERVICE_UPLOAD_API + "/api/paste_data", payload, {
        headers: { Authorization: auth.code },
      });
      console.log(resp);
      try {
        if (resp.data.status === "OK") {
          console.log("เข้า 22222222");
          this.loaddataprogress = false;
          this.disablescrolling = false;
          Toast.fire({
            icon: "success",
            title: "วาง",
          });
          this.fn_loadfileandfolder_scrolling_child();
        } else {
          this.loaddataprogress = false;
          this.disablescrolling = fasle;
          Toast.fire({
            icon: "error",
            title: resp.data.errorMessage,
          });
        }
      } catch (err) {
        console.log(err);
        Toast.fire({
          icon: "error",
          title: err,
        });
      }
    },
    // ตั้ง thumbnail icon
    fn_setThumbnailIcon(_type) {

      let dataicon;
      let parameter;
      if (typeof _type === "string") {
        parameter = _type.toLowerCase();
      } else {
        parameter = _type;
      }
      console.log(parameter);
      if (parameter === "xlsx" || parameter === "xls" || parameter === "xlsb") {
        // dataicon = ["mdi-file-excel", "green"];
        dataicon = ["mdi-file-excel", "#00733b"];
      } else if (parameter === "pptx" || parameter === "ppt") {
        // dataicon = ["mdi-file-powerpoint", "orange"];
        dataicon = ["mdi-file-powerpoint", "#e03303"];
      } else if (parameter === "docx" || parameter === "doc") {
        // dataicon = ["mdi-file-word", "primary"];
        dataicon = ["mdi-file-word", "#0263d1"];
      } else if (parameter === "html") { 
        // dataicon = ["mdi-language-html5", "orange"];
        dataicon = ["mdi-language-html5", "#FF603F"];
      }else if (parameter === "zip") {
        dataicon = ["mdi-zip-box", "blue-grey"];
      } else if (parameter === "pdf") {
        //  dataicon = ["mdi-file-pdf", "red"];
        dataicon = ["mdi-file-pdf", "#e5252a"];
      } else if (parameter === "xml") {
        dataicon = ["mdi-file-code", "orange"];
      } else if (parameter === "txt" || parameter === "txt") {
        dataicon = ["mdi-note-text", "blue-grey"];
      } else if (parameter === "csv") {
        dataicon = ["mdi-file-table", "green"];
      } else if (parameter === "jpeg" || parameter === "jpg" || parameter === "png" || parameter === "gif") {
        // dataicon = ["mdi-file-image", "blue-grey"];
        dataicon = ["mdi-file-image", "#0ac963"];
      } else if (
        parameter === "avi" ||
        parameter === "mpeg" ||
        parameter === "mov" ||
        parameter === "mp4" ||
        parameter === "mkv" ||
        parameter === "wmv" ||
        parameter === "3gp" ||
        parameter === "flv"
      ) {
        dataicon = ["mdi-file-video", "purple"];
      } else if (parameter === "shortcut") {
        dataicon = ["mdi-file-link", "pink"];
      } else if (parameter === "r" || parameter === "folder") {
        // dataicon = ["mdi-folder", "#FDD361"];
        dataicon = ["mdi-folder", "#ffc107"];
      }
      else {
        dataicon = ["mdi-file", "black"];
      }
      return dataicon;
    },
    // โหลดรูป thumbnail มาแสดง
    async fn_loadthumbnail(_file_id, _file_type,_file_size, _status_lock_data) {
     // console.log("_file_size",parseInt(_file_size));
      // this.filearraybuffer = "data:" + mimetype + ";base64," + b64encoded;
      let getThumbnail = "";
      // if (
      //   _file_type.toLowerCase() === "png" ||
      //   _file_type.toLowerCase() === "jpg" ||
      //   _file_type.toLowerCase() === "jpeg" ||
      //   _file_type.toLowerCase() == "bmp" ||
      //   _file_type.toLowerCase() === "gif"
      // ) {
      const extension_file = ['jpg','jpeg','bmp','gif','pdf', "png", 'JPG', 'JPEG', 'BMP', 'GIF','PDF', "PNG"]
      if (_status_lock_data !== 'Y') {
        // if(extension_file.includes(_file_type) === true && parseInt(_file_size) <= 10485760) {
        if(extension_file.includes(_file_type) === true) {
          let auth = await gbfGenerate.generateToken();
          this.axios
            .get(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/v1/thumbnail?file_id=" + _file_id, {
              headers: { Authorization: auth.code },
            })
            .then((response) => {
            // console.log('response thumbnail',response.data)
            if (response.data.status === "OK") {
              let strThumbnail = response.data.base64_img;
              // console.log('thumbnail',"data:image/" + _file_type + ";base64," + strThumbnail)
              // let default_type = 'jpg'
              getThumbnail = "data:image/" + _file_type + ";base64," + strThumbnail;
              this.rootfile.filter((o) => o.file_id === _file_id).forEach((o) => (o.file_thumbnail = getThumbnail));
              // this.filesLastView.filter((o) => o.file_id === _file_id).forEach((o) => (o.file_thumbnail = getThumbnail));
            }
            // console.log("rootfile",this.rootfile); 
          });
        }
      }
    },
    checktabs() {
      // console.log("id",id);
      console.log(" this.tab", this.tab);
      console.log(!this.tab);
    },
    gotoparent(parameter) {
      console.log("parameterparent", parameter);
      this.parentidfile = parameter.file_parent_folder_id;
      console.log(this.parentidfile);
      if (this.parentidfile === this.dataAccountActive["directory_id"]) {
        this.loadingdata = true;
        this.rootfile = [];
        this.buff = [];
        // this.$router.replace("/mydrive");
        this.$router.push("/mydrive");
        console.log("mydrive");
      } else {
        if (this.parentidfile === "" || this.parentidfile === null || this.parentidfile === undefined) {
          this.noparent = this.$route.params.id;
          this.noparent_M = this.dataAccountActive.business_info.my_folder_id;
          this.loadingdata = true;
          this.rootfile = [];
          this.buff = [];
          // this.$router.replace({ path: "/directory/" + this.noparent });
          this.$router.push({ path: "/directory/" + this.noparent });
          this.fn_loadfileandfolder_scrolling_child();
        } else if (this.parentidfile === this.$route.params.id) {
          if(this.heardertextsearch || this.smartuplloadsearch){
            this.backfromsearch(),
            this.show_back = false, 
            this.statusmutipledownload = false, 
            this.loaddataprogress = true
          } else {
            Toast.fire({ icon: "info", title: this.$t("toast.isSourceFileFolder") });
          }
        } else {
          this.rootfile = [];
          this.buff = [];
          // this.$router.replace({ path: "/directory/" + this.parentidfile });
          this.$router.push({ path: "/directory/" + this.parentidfile });
          // this.parentfolder = this.$route.params.id;
          this.fn_loadfileandfolder_scrolling_child();
        }
      }
    },

    disableformulticlick(item) {
      // if(item === "Y"){
      //   if(this.filemultipledownload.length>1||this.statusmutipledownload==true){
      //     return true
      //   }
      // }else{
      //   return false
      // }
      // defalut
      return false;
    },

    clearparentvalue() {
      this.checkedList = [];
      this.filemultipledownload = [];
    },

    async getdoctype() {
      let payload = {
        // folder_id : this.folderid_yourfolder,
        system: this.system,
      };
      console.log("folderid_yourfolder", payload);
      let auth = await gbfGenerate.generateToken();
      this.axios
        .post(process.env.VUE_APP_SERVICE_SELECT_FILE + "/api/file_get_all_doctype", payload, {
          headers: { Authorization: auth.code },
        })
        .then((response) => {
          if (response.data.status === "OK") {
            console.log(response);
            var i;
            for (i = 0; i < response.data.result.length; i++) {
              this.data_doctype.push(response.data.result[i]);
            }
            console.log("this.data_doctype----", this.data_doctype);
          }
        })
        .catch((error) => {
          console.log(error);
          this.loaddataprogress = false;
          Toast.fire({
            icon: "error",
            title: "error",
          });
        });
    },

    formatDate(date) {
      if (!date) return "";

      const [year, month, day] = date.split("-");
      return `${year}${month}${day}`;
    },
    //  filter file SG
    async loadfilterdata(data) {
      this.disablescrolling = true;
      this.totaldata = {
        totalall: 0,
        totalfile: 0,
        totalfolder: 0,
      };
      // console.log("dataaaaaaaaaaaaaaa", data);
      // console.log("datafilter", data);
      this.rootfile = [];
      this.dataafterfilter = [];
      this.filteroffset = 0;
      this.disablescrollfilter = false;
      this.buff = [];
      var payload = {
        user_id: this.dataUsername,
        department_id: "",
        account_id: this.dataAccountId,
        business_id: this.dataAccountActive.business_info.business_id,
        folder_id: this.folderid,
        system: "",
        filename: data.filename,
        doc_type: data.documenttype,
        doc_no_start: data.documentid,
        doc_no_end: data.documentidto,
        doc_date_start: this.formatDate(data.fromdatedocument),
        doc_date_end: this.formatDate(data.todatedocument),
        cre_dtm_start: this.formatDate(data.fromdateupload),
        cre_dtm_end: this.formatDate(data.todateupload),
        // folder_name: data.foldername,
        taxid: data.buyertaxid,
        branch_id: data.buyerbranchid,
        cust_id: data.customer_id,
        customer_name: data.customername,
        // customer_box: this._customer_box,
      };
      console.log("payload", payload);
      this.loaddataprogress = true;
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/v3/search_file_system_by_filter", payload, {
          headers: { Authorization: auth.code },
        })
        .then(async (response) => {
          if (response.data.status === "OK") {
            console.log("filterloaddata", response.data.result);
            var i;
            for (i = 0; i < response.data.result.length; i++) {
              // this.size = response.data.result[i].length;
              // console.log(response.data.result[i]);
              if (response.data.result[i]["data_type"] === "folder") {
                let datafolder = {};
                datafolder["account_reciever"] = "";
                datafolder["account_sender"] = this.dataAccountId;
                datafolder["account_id"] = response.data.result[i]["account_id"];
                datafolder["countnum"] = "";
                datafolder["file_lastdtm"] = response.data.result[i]["cre_dtm"] || "-";
                datafolder["folder_id"] = response.data.result[i]["folder_id"];
                datafolder["file_id"] = response.data.result[i]["id"];
                datafolder["file_name"] = response.data.result[i]["name"];
                datafolder["file_linkshare"] = response.data.result[i]["link"];
                datafolder["file_owner_eng"] = response.data.result[i]["name_eng"];
                datafolder["file_owner_th"] = response.data.result[i]["name_th"];
                datafolder["file_parent_folder_id"] = response.data.result[i]["parent_folder_id"];
                // datafolder["file_permission"] = response.data.result[i]["permission_account"];
                datafolder["file_permission_2"] = response.data.result[i]["permission_account_v2"];

                datafolder["file_department"] = "";
                datafolder["file_permission"] = {
                  admin_data: "True",
                  delete: "True",
                  download: "True",
                  edit: "True",
                  upload: "True",
                  view_only: "True",
                };
                datafolder["file_permission_department_setting"] = response.data.result[i]["permission_department_setting"];
                datafolder["permission_department_setting"] = response.data.result[i]["permission_department_setting"];
                datafolder["permission_setting"] = response.data.result[i]["permission_setting"];
                datafolder["file_status_share"] = response.data.result[i]["status_share"];
                datafolder["file_status_sharelink"] = response.data.result[i]["status_share_link"];
                datafolder["status_updating"] = response.data.result[i]["status_updating"] || "Done";
                datafolder["system"] = response.data.result[i]["system"];
                datafolder["file_type"] = response.data.result[i]["data_type"];
                datafolder["type"] = response.data.result[i]["data_type"];
                datafolder["file_user_id"] = response.data.result[i]["user_id"];
                datafolder["file_size"] = "-";
                datafolder["file_date"] = "";
                datafolder["file_status"] = "";
                // datafolder["file_icon"] = ["folder", "#FDD361"];
                datafolder["file_icon"] = ["folder", "#ffc107"];
                datafolder["user_id"] = response.data.result[i]["user_id"];
                /////
                // datafolder["file_type"] = type === "folder" ? "folder" : typefile[typefile.length - 1];
                // datafolder["file_createdtm"] = response.data.result[i].cre_dtm;
                // datafolder["file_lastdtm"] = response.data.result[i].last_dtm;
                datafolder["priority"] = "";
                datafolder["permission_business_setting"] = response.data.result[i].permission_business_setting;
                datafolder["relation_id"] = response.data.result[i]["relation_id"];
                datafolder["relation_name"] = response.data.result[i]["relation_name"];
                datafolder["relation_status"] = response.data.result[i]["relation_status"];
                datafolder["status_lock_folder"] = "";
                datafolder["status_lock_file"] = "";
                datafolder["status_lock_data"] = response.data.result[i]["status_lock_data"];
                datafolder["status_expired"] = response.data.result[i]["status_expired"];
                datafolder["expiration_time"] = response.data.result[i]["expiration_time"];
                datafolder["version_tag"] = response.data.result[i]["version_tag"];
                datafolder["tag_id"] = response.data.result[i]["tag_id"];
                datafolder["hashtag"] = response.data.result[i]["hashtag"];
                datafolder["editing_status"] = response.data.result[i]["editing_status"];
                datafolder["update_by"] = response.data.result[i]["update_by"];
                datafolder["last_update_name"] = response.data.result[i]["last_update_name"];
                datafolder["warning_expiration_time"] = response.data.result[i]["warning_expiration_time"];
                datafolder["onlyofficeeditsecces"] = false;

                // this.rootfile.push(datafolder);
                this.dataafterfilter.push(datafolder);
                // console.log("this.rootfile", this.rootfile);
                console.log("dataafterfilter", this.dataafterfilter);
                this.buff.push(datafolder);
                // this.count_folder_filter.push(datafolder);
              } else {
                let typefile = response.data.result[i]["filename"].split(".");
                let datafolder = {};
                datafolder["account_reciever"] = "";
                datafolder["account_sender"] = this.dataAccountId;
                datafolder["account_id"] = response.data.result[i]["account_id"];
                datafolder["businees_id"] = response.data.result[i]["businees_id"];
                datafolder["folder_id"] = response.data.result[i]["folder_id"];
                datafolder["file_id"] = response.data.result[i]["id"];
                datafolder["file_name"] = response.data.result[i]["filename"];
                datafolder["file_size"] = response.data.result[i]["size_file"];
                datafolder["file_lastdtm"] = response.data.result[i]["cre_dtm"] || "-";
                datafolder["file_date"] = "";
                datafolder["file_linkshare"] = response.data.result[i]["link"];
                datafolder["file_status_share"] = response.data.result[i]["status_share"];
                datafolder["file_status_sharelink"] = response.data.result[i]["status_share_link"];
                datafolder["file_owner_eng"] = response.data.result[i]["name_eng"];
                datafolder["file_owner_th"] = response.data.result[i]["name_th"];
                datafolder["file_type"] = typefile;
                datafolder["file_department"] = "";
                datafolder["file_icon"] = gb_setIconFiles.iconExtensionFilesOnly_new(typefile[typefile.length - 1]);
                datafolder["file_parent_folder_id"] = response.data.result[i]["parent_folder_id"];
                datafolder["file_status"] = response.data.result[i]["status_file"];
                datafolder["file_permission"] = response.data.result[i]["permission_account"];
                datafolder["file_permission_2"] = response.data.result[i]["permission_account_v2"];
                datafolder["file_permission_department_setting"] = response.data.result[i]["permission_department_setting"];
                datafolder["file_permission_setting"] = response.data.result[i]["permission_setting"];
                datafolder["relation_id"] = response.data.result[i]["relation_id"];
                datafolder["relation_name"] = response.data.result[i]["relation_name"];
                datafolder["relation_status"] = response.data.result[i]["relation_status"];
                //
                datafolder["countnum"] = "";
                datafolder["file_permission"] = {
                  admin_data: "True",
                  delete: "True",
                  download: "True",
                  edit: "True",
                  upload: "True",
                  view_only: "True",
                };
                datafolder["status_updating"] = response.data.result[i]["status_updating"] || "Done";
                datafolder["system"] = response.data.result[i]["system"];
                datafolder["file_user_id"] = response.data.result[i]["user_id"];
                datafolder["priority"] = "";
                datafolder["permission_business_setting"] = response.data.result[i].permission_business_setting;
                datafolder["status_lock_folder"] = "";
                datafolder["status_lock_file"] = "";
                datafolder["status_expired"] = response.data.result[i]["status_expired"];
                datafolder["expiration_time"] = response.data.result[i]["expiration_time"];
                datafolder["version_tag"] = response.data.result[i]["version_tag"];
                datafolder["tag_id"] = response.data.result[i]["tag_id"];
                datafolder["editing_status"] = response.data.result[i]["editing_status"];
                datafolder["update_by"] = response.data.result[i]["update_by"];
                datafolder["last_update_name"] = response.data.result[i]["last_update_name"];
                datafolder["status_lock_data"] = response.data.result[i]["status_lock_data"];
                datafolder["hashtag"] = response.data.result[i]["hashtag"];
                datafolder["permission_department_setting"] = response.data.result[i]["permission_department_setting"];
                datafolder["permission_setting"] = response.data.result[i]["permission_setting"];
                datafolder["type"] = response.data.result[i]["data_type"];
                datafolder["user_id"] = response.data.result[i]["user_id"];
                datafolder["warning_expiration_time"] = response.data.result[i]["warning_expiration_time"];
                datafolder["onlyofficeeditsecces"] = false;
                // this.rootfile.push(datafolder);
                this.dataafterfilter.push(datafolder);
                this.buff.push(datafolder);
                // this.count_file_filter.push(datafolder);
              }
            }
            
            this.totaldata = {
              totalall: response.data.total_file + response.data.total_folder || 0,
              totalfile: response.data.total_file || 0,
              totalfolder: response.data.total_folder || 0,
            };
            console.log("this.totaldata", this.totaldata);

            if (this.clickSort === true) {
              // this.rootfile.push(this.sortFile(this.rootfile));
              this.dataafterfilter.push(this.sortFile(this.dataafterfilter));
            }
            // this.loadfilterfiles(data);
            this.countfolder = response.data.result.length;
            console.log("dataafterfilter", this.loaddataprogress,this.dataafterfilter);
            // เพิ่ม 30 ไฟล์/โฟลเดอร์สำหรับแสดงเบื้องต้น
            if (this.dataafterfilter.length > 0) {
              for (let z = 0; z < this.size; z++) {
                if (this.dataafterfilter.length <= z) {
                  this.disablescrollfilter = true;
                  break;
                }
                this.rootfile.push(this.dataafterfilter[z])
                this.filteroffset = z;
              }
              // กำหนด offset เริ่มต้น
              this.filteroffset = this.size - 1;
            } else {
              this.disablescrollfilter = true;
            }
            this.loaddataprogress = false;
            for(let y = 0; y < this.rootfile.length; y++){
              if(this.rootfile[y].file_owner_th === '' || this.rootfile[y].file_owner_eng === ''){
                await this.get_name_owner(this.rootfile[y])
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.loaddataprogress = false;
          Toast.fire({
            icon: "error",
            // "ไม่สามารถเชื่อมต่อไปที่ ONE-BOX ได้ในขณะนี้"
            title: this.$t("toast.cannotconnectonebox"),
          });
        });
    },
    // เรียกใช้เมื่อมีการ scroll data filtr เกิน 30 ไฟล์/โฟลเดอร์
    async fn_limit_filter() {
      if(this.filteroffset >= this.size - 1 && this.rootfile.length < this.dataafterfilter.length){
        for(let i = 1; (this.filteroffset + i) < (this.filteroffset + this.size + 1); i++){
          // Scroll จะหยุดเมื่อ rootfile ที่แสดงไฟลื/โฟลเดอร์และ dataafterfilter ที่เก็บไฟลื/โฟลเดอร์ เท่ากัน
          if (this.rootfile.length < this.dataafterfilter.length) {
            this.rootfile.push(this.dataafterfilter[this.filteroffset + i])
          } else {
            this.disablescrollfilter = true;
            break;
          }
        }
        console.log("this.rootfile_fillter", this.rootfile);
        console.log("this.dataafterfilter_limit", this.dataafterfilter);

        // เรียกใช้ creator_name
        for(let y = 1; (this.filteroffset + y) < this.rootfile.length; y++){
          if (this.rootfile[this.filteroffset + y]){
            if(this.rootfile[this.filteroffset + y].file_owner_th === '' || this.rootfile[this.filteroffset + y].file_owner_eng === ''){
              await this.get_name_owner(this.rootfile[this.filteroffset + y])
            }
          } else {
            break;
          }
        }
        this.filteroffset = this.filteroffset + this.size;
      }
    },

    // changeLocalStorage(parameter) {
    //   if (parameter === localStorage.getItem("changeStoreUI")) {
    //     if (localStorage.getItem("changeStoreUI") === "true") {
    //       localStorage.setItem("changeStoreUI", "false");
    //       this.changeUI = false;
    //     } else if (localStorage.getItem("changeStoreUI") === "false") {
    //       localStorage.setItem("changeStoreUI", "true");
    //       this.changeUI = true;
    //     } else {
    //       console.log("localStore", localStorage.getItem("changeStoreUI"));
    //       localStorage.removeItem("changeStoreUI");
    //     }
    //   }
    // },

    changeLocalStorage(parameter) {
      if (parameter === this.changeUI.toString()) {
        if (this.changeUI === true) {
          localStorage.setItem("changeStoreUI", "false");
          this.changeUI = false;
          console.log("localStore", localStorage.getItem("changeStoreUI"))
        } else if (this.changeUI === false) {
          localStorage.setItem("changeStoreUI", "true");
          this.changeUI = true;
          this.show_menumanage = false
          this.close_header = true
          console.log("localStore", localStorage.getItem("changeStoreUI"))
        } else {
          console.log("localStore", localStorage.getItem("changeStoreUI"));
          localStorage.removeItem("changeStoreUI");
        }
      }
    },

    shiftSelect(item) {
      // คลิ๊ก item ที่ select ไว้ clear ค่าที่ไม่ได้ click ใน select ทั้งหมด
      if (this.statusmutipledownload == true) {
      } else {
        //แบบเดิมไม่มี if else
        this.resetFile();
        this.filemultipledownload.push(item);
        this.checkedList.push(item);
        console.log("Clear", this.filemultipledownload);
      }
    },

    removeCheckedListItem(item) {
      if (
        item ==
        this.filemultipledownload.find((i) => {
          return i == item;
        })
      ) {
        let updateFileMultipleDownload = this.filemultipledownload.filter((e) => e !== item);
        this.filemultipledownload = updateFileMultipleDownload;
        this.checkedList = updateFileMultipleDownload;
        console.log("UPDATE", this.filemultipledownload);
      } else {
        this.filemultipledownload.push(item);
        this.checkedList.push(item);
        console.log("UPDATE", this.filemultipledownload);
      }
    },

    vBadgeIcon(item) {
      let stringClass = "";
      if (item.file_status_sharelink === "Y") {
        stringClass = "link";
      } else {
        stringClass = "";
      }
      if (item.file_status_share === "Y") {
        if (stringClass == "") {
          stringClass = "group_add";
        } else {
          stringClass += " group_add";
        }
      }
      if (item.status_updating === "") {
        if (stringClass == "") {
          stringClass = "check";
        } else {
          stringClass += " check";
        }
      } else {
        stringClass += "";
      }
      // if(item.relation_status === 0 || item.relation_status === 1){
      //   stringClass += "device_hub";
      // }
      return stringClass;
    },

    selectColor(file_id, type) {
       if (type === "card") {
         if (this.filemultipledownload.length < 1) {
         } else {
          for (let i = 0; i < this.filemultipledownload.length; i++) {
            if (file_id === this.filemultipledownload[i].file_id) {
              return "#D6EAF8";
            }
          }
         }
       } else if (type === "table") {
         if (this.filemultipledownload.length < 1) {
         } else {
          for (let i = 0; i < this.filemultipledownload.length; i++) {
            if (file_id === this.filemultipledownload[i].file_id) {
              return "color:#2471A3 ";
            }
          }
         }
       } else {
         if (this.filemultipledownload.length < 1) {
         } else {
          for (let i = 0; i < this.filemultipledownload.length; i++) {
            if (file_id === this.filemultipledownload[i].file_id) {
              return "color:#2471A3 ";
            }
          }
         }
       }
    },

    resetFile() {
      if (this.statusmutipledownload == true) {
      } else {
        //แบบเดิมไม่มี if else
        this.filemultipledownload = [];
        this.checkedList = [];
      }
    },
    // ลากคลุมไฟล์
    fn_handleDragSelectorChange(_checkedList) {
      console.log("Input:", _checkedList);
      this.choosecopy = [];
      // this.choosecopy = _checkedList;
      // เอาตัวซ้ำออก _checkedList เป็น parameter แค่รับมา filter ค่าซ้ำออก
      let newArrayFile = [...new Set(_checkedList)];
      newArrayFile = newArrayFile.filter((element) => {
        return element.file_status != "M";
      });
      // fileter เอาค่าที่ไม่ต้องการออก
      if (newArrayFile.length > 1) {
        this.filemultipledownload = newArrayFile.filter((element) => {
          // return (
          //   element.status_lock_folder !== "Y" &&
          //   element.status_lock_file !== "Y" &&
          //   element.file_permission.edit === "True" &&
          //   element.status_lock_data !== "Y" &&
          //   element.editing_status !== "Y"
          // );เปลี่ยนมาเช็คอันนี้
          return (
            element.status_lock_folder !== "Y" &&
            element.status_lock_file !== "Y" &&
            (element.file_permission_2 === "04"||
            element.file_permission_2 === "05"||
            element.file_permission_2 === "06") &&
            element.status_lock_data !== "Y" &&
            element.editing_status !== "Y"
          );
        });
      }
      this.choosecopy = this.filemultipledownload;
      // Toast แจ้งเตือน ------------------------------------------------------------------------------------------------------------------------------------------------------------
      // console.log("Output:", this.filemultipledownload);
      // // Toast ยิงไปบอกว่าบางไฟล์ที่คลุมไม่ขึ้น user นั้นไม่มีสิทธ์ หรือ ไฟล์มันล็อกอยู่
      // document.onmouseup=()=>{
      //   // Object.keys() ไว้เช็ค obj = {}
      //   if(Object.keys(this.filemultipledownload).length === 0 && this.checkedList.length > 1){
      //     console.log('CASE 1','filemultipledownload:',this.filemultipledownload.length,'checkedlist:',this.checkedList.length,'newArrayFile',newArrayFile.length)
      //     // กรณี มีไฟล์แต่ติด permission ทั้งหมด
      //     // filemultipledownload = {} | newArrayFile > 1 (มากกว่า 0 ไม่ได้เพราะมีกรณีคลิ๊กไฟล์เดียว)
      //     Toast.fire({
      //       icon: "warning",
      //       title: this.$t('toast.nopermissionfile'),
      //       // Some files you do not have permission to access
      //     });
      //     // แจ้งเตือนเสร็จให้เคลียค่าออก
      //     this.checkedList =[]
      //     newArrayFile = []
      //   } else if (Object.keys(this.filemultipledownload).length >= 1 && (Object.keys(this.filemultipledownload).length != newArrayFile.length) && newArrayFile.length != 0){
      //     // กรณีมีบางไฟล์ติด permission
      //     // filemultipledownload > 1 แต่มีบางตัว filter ออก | newArrayFile > 1
      //     // ดัก newArrayFile.length != 0 สำหรับเวลา dblclick เข้าโฟลเดอร์
      //     console.log('CASE 2','filemultipledownload:',this.filemultipledownload.length,'checkedlist:',this.checkedList.length,'newArrayFile',newArrayFile.length)
      //     Toast.fire({
      //       icon: "warning",
      //       title: this.$t('toast.nopermissionfile'),
      //       // Some files you do not have permission to access
      //     });
      //     // แจ้งเตือนเสร็จให้เคลียค่าออก
      //     this.checkedList =[]
      //     newArrayFile = []
      //   } else {
      //     console.log('else')
      //     // filemultipledownload 0 checkList 0
      //   }
      //   // console.log('filemultipledownload length:',Object.keys(this.filemultipledownload).length)
      //   // console.log('newArrayFile length:',Object.keys(_checkedList).length)
      //   // console.log('checkedList length:',this.checkedList.length)
      // }
    },

    multideletetotrash() {
      console.log("filemultipledownload", this.filemultipledownload);
      if (this.filemultipledownload.length < 1) {
        Toast.fire({
          icon: "warning",
          title: this.$t("myinboxPage.msg.nofile"),
        });
      } else {
        let datalist = [];
        this.status_data = "T";
        //เช็คว่า ลอคอิน business หรือไม่
        if (this.dataAccountActive.type == "Business") {
          this.openchekmultidelete = true;
          if (this.navigate[0].text[1] == this.dataAccountActive["business_info"]["my_folder_id"] && this.role_level == "True") {
            // admin
            this.status_data = "TB";
          }
        } else {
          this.openchekmultidelete = true;
          this.status_data = "T";
        }
        //  this.$store.dispatch("set_loading", true).then((res) => {});
        // for (let i = 0; i < this.filemultipledownload.length; i++) {
        //   console.log(this.filemultipledownload[i]);
        //   if (this.filemultipledownload[i].file_permission.delete === "True") {
        //     if (this.filemultipledownload[i].file_type === "folder") {
        //       console.log("folder");
        //       datalist.push({
        //         data_id: this.filemultipledownload[i]["file_id"],
        //         data_type: this.filemultipledownload[i]["file_type"],
        //         data_status: this.status_data,
        //       });
        //     } else {
        //       console.log("file");
        //       datalist.push({
        //         data_id: this.filemultipledownload[i]["file_id"],
        //         data_type: "file",
        //         data_status: this.status_data,
        //       });
        //     }
        //   } else {
        //   }
        // }

        // let payload = {
        //   account_id: this.dataAccountId,
        //   user_id: this.dataUsername,
        //   data_list: datalist,
        //   // status_data:status_data
        // };
        // console.log("payload", payload);

        // this.axios
        //   .post(process.env.VUE_APP_SERVICE_UPDATE_MULTIPLES + "/api/remove_multiples_file_folder_to_trash", payload)
        //   .then((response) => {
        //     console.log(response);
        //     if (response.data.status === "OK") {
        //       this.$store.dispatch("set_loading", false).then((res) => {});
        //       Toast.fire({
        //         icon: "success",
        //         title: this.$t("toast.multiset.remove"),
        //       });
        //       this.statusmutipledownload = false;
        //       this.clearmultipledownload();
        //       this.fn_loadfileandfolder_scrolling_child();
        //       this.loadstorage();
        //     } else {
        //       Toast.fire({
        //         icon: "error",
        //         title: response.data.errorMessage,
        //       });
        //     }
        //   });
      }
    },

    async setmultiupdate(filemultipledownload, status) {
      if (this.filemultipledownload.length < 1) {
        Toast.fire({
          icon: "warning",
          title: this.$t("myinboxPage.msg.nofile"),
        });
      } else {
        let count_item_nostar = 0;
        let count_item_star = 0;
        let status_star;
        let apifile;
        let apifolder;
        let payloadfile;
        let payloadfolder;
        let msgres;
        let filedata = [];
        let folderdata = [];
        for (let i = 0; i < this.filemultipledownload.length; i++) {
          if (filemultipledownload[i].file_status !== status) {
            count_item_nostar++;
            if (filemultipledownload[i].file_type === "folder") {
              folderdata.push(this.filemultipledownload[i]["file_id"]);
            } else {
              filedata.push(this.filemultipledownload[i]["file_id"]);
            }
          } else {
            count_item_star++;
            console.log("repeat", filemultipledownload[i]);
            if (filemultipledownload[i].file_type === "folder") {
              folderdata.push(this.filemultipledownload[i]["file_id"]);
            } else {
              filedata.push(this.filemultipledownload[i]["file_id"]);
            }
          }
        }
        if (count_item_star == filemultipledownload.length) {
          console.log("nnnnnnnn");
          status_star = "N";
        } else if (count_item_nostar <= filemultipledownload.length) {
          console.log("ssssssss");
          status_star = "S";
        }
        payloadfolder = {
          list_folder: folderdata,
          account_id: this.dataAccountId,
          status_folder: status_star,
        };
        apifolder = "/api/multiple_update_status_folders";
        payloadfile = {
          list_file: filedata,
          account_id: this.dataAccountId,
          status_file: status_star,
        };
        apifile = "/api/multiple_update_status_files";

        console.log("folder", folderdata);
        console.log("file", filedata);
        try {
          let auth = await gbfGenerate.generateToken();
          let resultFile = await this.axios.post(process.env.VUE_APP_SERVICE_UPDATE_MULTIPLES + apifile, payloadfile, {
            headers: { Authorization: auth.code },
          });
          console.log("resultFile", resultFile);
          console.log("result =>", resultFile.data.result);
          let resultFolder = await this.axios.post(process.env.VUE_APP_SERVICE_UPDATE_MULTIPLES + apifolder, payloadfolder, {
            headers: { Authorization: auth.code },
          });
          console.log("resultFolder", resultFolder.status);
          console.log("result =>", resultFolder.data.result);
          if (resultFile.data.status == "OK" || resultFolder.data.status == "OK") {
            if (status_star === "S") {
              // "ติดดาวสำเร็จ";
              msgres = this.$t("toast.multiset.star");
            } else if (status === "T") {
              // "นำออกสำเร็จ";
              msgres = this.$t("toast.multiset.remove");
            } else {
              // "ยกเลิกติดดาวสำเร็จ";
              msgres = this.$t("toast.multiset.cancle");
            }
            Toast.fire({
              icon: "success",
              title: msgres,
            });
            this.close_header = true
            //OLD
            this.statusmutipledownload = false;
            this.clearmultipledownload();
            // this.loadfolder();
            this.fn_loadfileandfolder_scrolling_child();
            //NEW
            // this.statusmutipledownload = false;
            // this.clearmultipledownload();
            // this.loadfileandfolder();
          } else {
            console.log("ERROR");
            Toast.fire({
              icon: "error",
              title: resultFile.data.errorMessage || resultFolder.data.errorMessage,
            });
          }
        } catch (error) {
          console.log("result ERROR !!!", error);
          // แก้เลือกแต่ File await รอของ Folder ไม่โหลดค่าใหม่

          this.statusmutipledownload = false;
          this.clearmultipledownload();
          // this.loadfolder();
          this.fn_loadfileandfolder_scrolling_child();
          // ถ้าเอาออก มันจะไม่ update status ของตัว File
          if (status === "S") {
            // "ติดดาวสำเร็จ";
            msgres = this.$t("toast.multiset.star");
          } else if (status === "T") {
            // "นำออกสำเร็จ";
            msgres = this.$t("toast.multiset.remove");
          } else {
            // "ยกเลิกติดดาวสำเร็จ";
            msgres = this.$t("toast.multiset.cancle");
          }
          Toast.fire({
            icon: "success",
            title: msgres,
          });
        }
      }
    },

    dragenter(e) {
      document.querySelector("#dropzone").style.visibility = "";
      document.querySelector("#dropzone").style.opacity = 1;
      document.querySelector("#textnode").style.fontSize = "48px";
    },

    dragleave(e) {
      e.preventDefault();
      e.stopPropagation();
      document.querySelector("#dropzone").style.visibility = "hidden";
      document.querySelector("#dropzone").style.opacity = 0;
      document.querySelector("#textnode").style.fontSize = "42px";
    },

    dragover(e) {
      e.preventDefault();
      e.stopPropagation();
      document.querySelector("#dropzone").style.visibility = "";
      document.querySelector("#dropzone").style.opacity = 1;
      document.querySelector("#textnode").style.fontSize = "48px";
    },

    async drop(e) {
      e.preventDefault();
      e.stopPropagation();
      document.querySelector("#dropzone").style.visibility = "hidden";
      document.querySelector("#dropzone").style.opacity = 0;
      document.querySelector("#textnode").style.fontSize = "42px";
      //var filelist = document.querySelector('#filelist');
      // this.uploadFile(files);
      this.checkCCFolder = false;
      this.checkCC = false;
      // this.total = 0
      // this.count = 0
      let files = e.dataTransfer.files;
      this.folderupload = [];
      // console.log("eeeeeeeeeeeeeeeeeeeeee",e);
      console.log("Drop files:", files);
      //this.uploadfiles(files);
      //this.onDrop(files);
      // console.log(this.account_active["directory_id"]);
      var that = this;
      var that2 = await this;
      let parentfolder = "";
      let countt = 0;
      let total = 0;
      var beak = false;
      var traverseFileTreeCount = function self(item, path) {
        if (item.isFile) {
        } else if (item.isDirectory) {
          // Get folder contents
          var dirReader = item.createReader();
          dirReader.readEntries(async function(entries) {
            for (var i = 0; i < entries.length; i++) {
              that.total++;
              self(entries[i], path + item.name + "/");
            }
          });
        }
      };
      var traverseFileTree = function self(item, path) {
        if (that.checkCCFolder === true) {
          console.log("cancel");
          let objIndex = that.listDataUpload.findIndex((obj) => obj.name === that.rootname);
          that.listDataUpload[objIndex]["value"] = 500;
          that.listDataUpload[objIndex]["status"] = "Operation canceled by the user.";
          that.listDataUpload[objIndex]["color"] = "red";
        } else {
          path = path || "";
          if (item.isFile) {
            // Get file
            item.file(function(file) {});
          } else if (item.isDirectory) {
            // Get folder contents
            var dirReader = item.createReader();
            dirReader.readEntries(async function(entries) {
              for (var i = 0; i < entries.length; i++) {
                if (that.checkCCFolder === true) {
                  console.log("breakkkkkkkkkkk");
                  let objIndex = that.listDataUpload.findIndex((obj) => obj.name === that.rootname);
                  that.listDataUpload[objIndex]["value"] = 500;
                  that.listDataUpload[objIndex]["status"] = "Operation canceled by the user. ";
                  that.listDataUpload[objIndex]["color"] = "red";

                  beak = true;
                  break;
                } else {
                  countt++;
                  if (that.parentfolderDD !== "") {
                    await that.uploadfolderDD("l", entries[i], files, total, countt);
                  }
                  if (beak) {
                    console.log("breakkkkkkkkkkk");
                    break;
                  } else {
                    await self(entries[i], path + item.name + "/");
                  }
                }
              }
            });
          }
        }
      };
      let dataupload = [];
      var items = e.dataTransfer.items;

      for (let i = 0; i < files.length; i++) {
        if (files[i].type !== "") {
          dataupload.push(files[i]);
        }
      }
      this.onDrop(dataupload, "", "Fi", "");
      for (var i = 0; i < items.length; ++i) {
        //console.log("itemmmmmm",items[i]);
        //if (items[i].kind == 'file') {

        let item = items[i].webkitGetAsEntry();
        console.log("itemssssssssssssss");
        await traverseFileTreeCount(item);
        if (item.isFile) {
        } else if (item.isDirectory) {
          if (this.checkCCFolder === true) {
          } else {
            await this.uploadfolderDD("r", item);
            await traverseFileTree(item);
            // Toast.fire({
            //   icon: "error",
            //   title: "ยังไม่รองรับการอัปโหลดโฟลเดอร์",
            //  });
          }
        }
      }

      // let item = items[i].webkitGetAsEntry(function(entry) {
      // displayEntry(entry.name + (entry.isDirectory ? ' [dir]' : ''));
      // });
      //console.log(this.traverseFileTree(item));
      // }
    },

    async getFile(fileEntry) {
      try {
        return await new Promise((resolve, reject) => fileEntry.file(resolve, reject));
      } catch (err) {
        console.log(err);
      }
    },

    char_count(str, letter) {
      var letter_Count = 0;
      for (var position = 0; position < str.length; position++) {
        if (str.charAt(position) == letter) {
          letter_Count += 1;
        }
      }
      return letter_Count;
    },

    async uploadfolderDD(status, item, files, totalfolder, countfolder) {
      if (this.checkCCFolder === true) {
        console.log(this.checkCCFolder);
        let objIndex = this.listDataUpload.findIndex((obj) => obj.name === this.rootname);
        source.cancel("Operation canceled by the user.");
        this.listDataUpload[objIndex]["value"] = 500;
        this.listDataUpload[objIndex]["status"] = "Cancel upload folder by user";
        this.listDataUpload[objIndex]["color"] = "red";

        return;
      } else {
        console.log("totalfolder", this.total, countfolder);
        if (item.isFile) {
          let x = item.fullPath.search(item.name);
          let chpath = item.fullPath.slice(0, x - 1);
          let res1 = item.fullPath.split("/");
          //let res2 = res1[res1.length-1]
          let filepath = "";
          for (let i = 0; i < res1.length - 1; i++) {
            filepath += res1[i] + "/";
          }
          for (let i = 0; i < this.folderupload.length; i++) {
            //console.log("tttttt================================================",this.folderupload[i]["folder_path"]);
            if (filepath === this.folderupload[i]["folder_path"] + "/") {
              if (this.checkCCFolder === true) {
                let objIndex = this.listDataUpload.findIndex((obj) => obj.name === this.rootname);
                source.cancel("Operation canceled by the user.");
                this.listDataUpload[objIndex]["value"] = 500;
                this.listDataUpload[objIndex]["status"] = "Cancel upload folder by user";
                this.listDataUpload[objIndex]["color"] = "red";
              } else {
                await this.getFile(item).then((res) => {
                  this.onDrop(res, this.folderupload[i]["folder_id"], "", this.rootFolder, totalfolder, countfolder);
                });
                //  }
              }
            }
          }
        } else if (item.isDirectory) {
          let x = item.fullPath.search(item.name);
          let chpath = item.fullPath.slice(0, x - 1);
          let res1 = item.fullPath.split("/");
          //let res2 = res1[res1.length-1]
          let chpath1 = "";
          for (let i = 0; i < res1.length - 1; i++) {
            chpath1 += res1[i] + "/";
          }

          for (let i = 0; i < this.folderupload.length; i++) {
            if (chpath1 === this.folderupload[i]["folder_path"] + "/") {
              this.parentfolderDD = this.folderupload[i]["folder_id"];
            }
          }
          if (status === "r") {
            let dataUpload = {};
            dataUpload["name"] = item.name;
            dataUpload["status"] = "กำลังอัปโหลด";
            dataUpload["value"] = 0;
            dataUpload["color"] = "green";
            dataUpload["type"] = "folder";
            // dataUpload["icon"] = ["folder", "#FDD361"];
            dataUpload["icon"] = ["folder", "#ffc107"];
            dataUpload["showUpload"] = false;
            this.listDataUpload.push(dataUpload);
            // console.log(this.listDataUpload);
            let parentfolder = this.sendParentfolder();
            console.log("parentfolder", parentfolder);
            var payload = {
              folder_name: item.name,
              user_id: this.dataUsername,
              id: this.parentfolder,
              account_id: this.dataAccountId,
              department_id: this.dataDepartmentAccessId,
            };
            //console.log("uploadheadfolder",payload);
            this.rootname = item.name;
          } else if (status === "l") {
            var payload = {
              folder_name: item.name,
              user_id: this.dataUsername,
              id: this.parentfolderDD,
              account_id: this.dataAccountId,
              department_id: this.dataDepartmentAccessId,
            };
          }
          let objIndex = this.listDataUpload.findIndex((obj) => obj.name === this.rootname);
          let CancelToken = this.axios.CancelToken;
          let source = new CancelToken.source();

          try {
            this.snackbar = true;
            let auth = await gbfGenerate.generateToken();
            let result = await this.axios({
              method: "POST",
              url: process.env.VUE_APP_SERVICE_UPLOAD_FOLDER + "/api/v2/insert_folder",
              cancelToken: source.token,
              data: payload,
              headers: { Authorization: auth.code },

              onUploadProgress: (e) => {
                //console.log(this.checkCC);

                if (this.checkCCFolder === true) {
                  source.cancel("Operation canceled by the user.");
                  this.listDataUpload[objIndex]["value"] = 500;

                  this.listDataUpload[objIndex]["status"] = "Operation canceled by the user.";
                  this.listDataUpload[objIndex]["color"] = "red";

                  //
                }

                // this.listDataUpload[objIndex]["value"] = 0;
                // this.listDataUpload[objIndex]["status"] = this.$t(
                //   "uploadFile.upload"
                // );
                // this.listDataUpload[objIndex]["color"] = "green";
              },
            });

            //this.axios.post(process.env.VUE_APP_SERVICE_UPLOAD_FOLDER + "/api/v2/insert_folder", payload).then(response => {
            let a = item.fullPath.substring(1);
            let b = a.search("/");
            //  console.log("-----------------b----------------",b);
            if (result.data.status === "OK") {
              if (b === -1) {
                this.count++;
                //this.re1 = this.char_count(a,'/');
                let x = item.fullPath.search(item.name);
                let datafolder = {};
                datafolder["folder_id"] = result.data.data.folder_id;
                this.rootFolder = item.name;
                datafolder["folder_path"] = item.fullPath;
                this.folderupload.push(datafolder);
                this.parentfolderDD = result.data.data.folder_id;
                let a = item.fullPath.substring(1);
                //console.log("reeeeeeeeeeeeeeeeee11111111111111",this.folderupload);
                //this.parentfolderDD2 = result.data.data.folder_id
                this.listDataUpload[objIndex]["value"] = parseInt((100 / this.total) * this.count);
                // this.listDataUpload[objIndex]["status"] = "กำลังอัปโหลด";
                // this.listDataUpload[objIndex]["color"] = "green";
                console.log("========= root success ========", countfolder);
              } else {
                this.count++;
                this.parentfolderDD2 = result.data.data.folder_id;
                let x = item.fullPath.search(item.name);
                let datafolder = {};
                datafolder["folder_id"] = result.data.data.folder_id;
                datafolder["folder_path"] = item.fullPath;
                this.folderupload.push(datafolder);

                if (this.total - this.count === 0 || this.total - this.count === 1) {
                  this.listDataUpload[objIndex]["value"] = 100;
                  this.listDataUpload[objIndex]["status"] = "อัปโหลดสำเร็จ";
                  this.listDataUpload[objIndex]["color"] = "green";
                  //console.log("========= last success ========",countfolder);
                  this.fn_loadfileandfolder_scrolling_child();
                } else {
                  this.listDataUpload[objIndex]["value"] = parseInt((100 / this.total) * this.count);
                  //this.listDataUpload[objIndex]["status"] = "กำลังอัปโหลด";
                  //this.listDataUpload[objIndex]["color"] = "green";

                  // console.log("========= other success ========",countfolder,this.total);
                }
              }
            } else {
              this.listDataUpload[objIndex]["value"] = 500;
              this.listDataUpload[objIndex]["status"] = result.data.errorMessage;
              this.listDataUpload[objIndex]["color"] = "red";
              // Toast.fire({
              //   icon: 'error',
              //   title: result.data.errorMessage
              // })
            }
          } catch (err) {
            // check if the request was cancelled
            if (this.axios.isCancel(err)) {
              // console.log(err.message);
              //this.checkCCFolder = false;
              // console.log(this.checkCC);
            }

            //console.log("ยกเลิกอัปโหลดไม่ทันอะ");
          }
        }
      }
    },

    removeEvent() {
      //console.log("close");
      // var self = this;
      // self.closeDragDrop = true;
      // window.removeEventListener("dragenter", self.dragenter, true);
      // window.removeEventListener("dragleave", self.dragleave, true);
      // window.removeEventListener("dragover", self.dragover, true);
      // window.removeEventListener("drop", self.drop, true);
      this.removeevent = true;
      //console.log(this.removeevent);
    },

    addEvent() {
      if (this.resolutionScreen >= 500) {
        console.log("open");
        // if (this.resolutionScreen >= 500) {
        //   var self = this;
        //   self.closeDragDrop = false;
        //   window.addEventListener("dragenter", self.dragenter, true);
        //   window.addEventListener("dragleave", self.dragleave, true);
        //   window.addEventListener("dragover", self.dragover, true);
        //   window.addEventListener("drop", self.drop, true);
        // }
        this.removeevent = false;
        console.log(this.removeevent);
      }
    },

    checkSort() {
      // this.loadfolder();
      this.rootfile = this.buff;
      this.clickSort = false;
      this.multiSort = false;
    },

    //เก็บ file_id ที่เวลาไม่เกิน 60 นาที
    sortCreDtmNewUpload() {
      // วันปัจจุบัน
      let today = new Date();
      this.dataCredtmNewUpload = [];
      this.arrayCredtmNewUpload = [];
      this.dataCredtmNewUpload = this.rootfile;
      console.log("this.dataCredtmNewUpload", this.dataCredtmNewUpload);
      for (let i = 0; i < this.dataCredtmNewUpload.length; i++) {
        // เช็คค่าว่าง
        if (
          this.dataCredtmNewUpload[i].file_createdtm === undefined ||
          this.dataCredtmNewUpload[i].file_createdtm === null ||
          this.dataCredtmNewUpload[i].file_createdtm === ""
        ) {
          continue;
        } else {
          // สร้างวันไว้คำนวณความต่างของเวลา
          let checkCre = new Date(
            parseInt(
              this.dataCredtmNewUpload[i].file_createdtm[0] +
                this.dataCredtmNewUpload[i].file_createdtm[1] +
                this.dataCredtmNewUpload[i].file_createdtm[2] +
                this.dataCredtmNewUpload[i].file_createdtm[3]
            ),
            parseInt(
              this.dataCredtmNewUpload[i].file_createdtm[4] +
                this.dataCredtmNewUpload[i].file_createdtm[5]
            ) - 1,
            parseInt(
              this.dataCredtmNewUpload[i].file_createdtm[6] +
                this.dataCredtmNewUpload[i].file_createdtm[7]
            ),
            parseInt(
              this.dataCredtmNewUpload[i].file_createdtm[8] +
                this.dataCredtmNewUpload[i].file_createdtm[9]
            ),
            parseInt(
              this.dataCredtmNewUpload[i].file_createdtm[10] +
                this.dataCredtmNewUpload[i].file_createdtm[11]
            ),
            parseInt(
              this.dataCredtmNewUpload[i].file_createdtm[12] +
                this.dataCredtmNewUpload[i].file_createdtm[13]
            )
          );
          let cal_differenceTime =
            Math.round(today.getTime() - checkCre.getTime()) / (1000 * 60);
          let differenceTime = cal_differenceTime.toFixed(0);
          if (differenceTime <= 60) {
            this.arrayCredtmNewUpload.push(this.dataCredtmNewUpload[i].file_id);
          } else {
            continue;
          }
        }
      }
    },

    // เช็คจากไฟล์ไม่เกิน 60 นาที
    checkNewDataFormDate(item) {
      let countNewDataFormDate = 0;
      for (let i = 0; i < this.arrayCredtmNewUpload.length; i++) {
        if (this.arrayCredtmNewUpload[i] === item.file_id) {
          countNewDataFormDate = 1;
          break;
        } else {
          continue;
        }
      }
      if (countNewDataFormDate === 0) {
        return false
      } else {
        return true
      }

    },

    // เนื่องจากการเปิดปิด preview file ไม่มีการรีเฟรชข้อมูล
    // จึงต้อสร้างตัวเก็บข้อมูลการเปิดปิดก่อนจะรีเฟรช
    // เช็คจากไฟล์ที่เปิดล่าสุดก่อนรีเฟรชหรือเปลี่ยนหน้า
    checkNewDataNoRefresh(item) {
      let countDataNoRefresh = 1;
      if (this.arrayOpenNewfiles.length === 0) {
        countDataNoRefresh = 1;
      } else {
        for (let i = 0; i < this.arrayOpenNewfiles.length; i++) {
          if (this.arrayOpenNewfiles[i] === item.file_id) {
            countDataNoRefresh = 0;
            break;
          } else {
            continue;
          }
        }
      }
      if (countDataNoRefresh === 0) {
        return false
      } else {
        return true
      }
    },

    // เช็คจากไฟล์ที่เปิดล่าสุดหลังรีเฟรชหรือเปลี่ยนหน้า
    checkNewDataRefresh(item) {
      let countDataRefresh = 0;
      for (let i = 0; i < this.rootfile.length; i++) {
        if (this.rootfile[i].file_id === item.file_id) {
          if (this.rootfile[i].last_view === null) {
            countDataRefresh = 1;
            break;
          } else {
            break;
          }
        } else {
          continue;
        }
      }
      if (countDataRefresh === 0) {
        return false
      } else {
        return true
      }
    },

    //ฟังก์ชันการเพิ่มข้อมูลการเปิดไฟล์ก่อนรีเฟรช
    cacheOpenNewfiles(item) {
      let countDupicateNewFile = 0;
      for (let i = 0; i < this.arrayOpenNewfiles.length; i++) {
        if (this.arrayOpenNewfiles[i] === item) {
          countDupicateNewFile = 1;
          break;
        } else {
          continue;
        }
      }
      if (countDupicateNewFile === 0) {
        this.arrayOpenNewfiles.push(item);
      } else {
        // console.log("this.arrayOpenNewfiles", this.arrayOpenNewfiles);
      }
    },
    // sortFile(parameter) {
    //   this.folderSort = [];
    //   this.fileSort = [];
    //   this.sortdata = [];
    //   this.rootfile = [];
    //   let i, j;
    //   for (i = 0; i < parameter.length; i++) {
    //     if (parameter[i].file_type === "folder") {
    //       this.folderSort.push(parameter[i]);
    //     } else this.fileSort.push(parameter[i]);
    //   }

    //   if (this.sortby === "name") {
    //     if (this.multiSort === true) {
    //       this.folderSort.sort(function(a, b) {
    //         return a.file_name.localeCompare(b.file_name);
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return a.file_name.localeCompare(b.file_name);
    //       });
    //     } else {
    //       this.folderSort.sort(function(a, b) {
    //         return b.file_name.localeCompare(a.file_name);
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return b.file_name.localeCompare(a.file_name);
    //       });
    //     }
    //   } else if (this.sortby === "date") {
    //     if (this.multiSort === true) {
    //       this.folderSort.sort(function(a, b) {
    //         return a.file_lastdtm.localeCompare(b.file_lastdtm);
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return a.file_lastdtm.localeCompare(b.file_lastdtm);
    //       });
    //     } else {
    //       this.folderSort.sort(function(a, b) {
    //         return b.file_lastdtm.localeCompare(a.file_lastdtm);
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return b.file_lastdtm.localeCompare(a.file_lastdtm);
    //       });
    //     }
    //   } else if (this.sortby === "size") {
    //     if (this.multiSort === true) {
    //       this.folderSort.sort(function(a, b) {
    //         return b.file_size - a.file_size;
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return b.file_size - a.file_size;
    //       });
    //     } else {
    //       this.folderSort.sort(function(a, b) {
    //         return a.file_size - b.file_size;
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return a.file_size - b.file_size;
    //       });
    //     }
    //   } else if (this.sortby === "owner") {
    //     if (this.multiSort === true) {
    //       this.folderSort.sort(function(a, b) {
    //         return a.file_owner_eng.localeCompare(b.file_owner_eng);
    //       });
    //       this.folderSort.sort(function(a, b) {
    //         return a.file_owner_th.localeCompare(b.file_owner_th);
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return a.file_owner_eng.localeCompare(b.file_owner_eng);
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return a.file_owner_th.localeCompare(b.file_owner_th);
    //       });
    //     } else {
    //       this.folderSort.sort(function(a, b) {
    //         return b.file_owner_eng.localeCompare(a.file_owner_eng);
    //       });
    //       this.folderSort.sort(function(a, b) {
    //         return b.file_owner_th.localeCompare(a.file_owner_th);
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return b.file_owner_eng.localeCompare(a.file_owner_eng);
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return b.file_owner_th.localeCompare(a.file_owner_th);
    //       });
    //     }
    //   }
    //   this.sortdata.push(this.folderSort);
    //   this.sortdata.push(this.fileSort);
    //   for (i = 0; i < this.sortdata.length; i++) {
    //     for (j = 0; j < this.sortdata[i].length; j++) {
    //       this.rootfile.push(this.sortdata[i][j]);
    //     }
    //   }
    //   return this.rootfile;
    // },

    clearmultipledownload() {
      console.log("opendialogmultidownload_new", this.opendialogmultidownload_new);
      this.filemultipledownload.splice(0, this.filemultipledownload.length);
      this.opendialogmultidownload_new = false;
    },

    zeroPadding(num, digit) {
      var zero = "";
      for (var i = 0; i < digit; i++) {
        zero += "0";
      }
      return (zero + num).slice(-digit);
    },

    multipledownload() {
      // this.percentCompleted = 0;
      // var d = new Date();
      // this.datetime =
      //   d.toISOString().slice(0, 10) +
      //   " " +
      //   "at" +
      //   " " +
      //   this.zeroPadding(d.getHours(), 2) +
      //   "-" +
      //   this.zeroPadding(d.getMinutes(), 2) +
      //   "-" +
      //   this.zeroPadding(d.getSeconds(), 2);
      if (this.filemultipledownload.length < 1) {
        Toast.fire({
          icon: "warning",
          title: this.$t("myinboxPage.msg.nofile"),
        });
      } else {
        console.log("ไฟล์ที่เลือก", this.filemultipledownload);
       // เก่า this.opendialogcheckmultidownload = true;
       this.opendialogmultidownload_new = true;

        // this.$store.dispatch("show_loading", true);
        // let filedata = [];
        // for (let i = 0; i < this.filemultipledownload.length; i++) {
        //   filedata.push(this.filemultipledownload[i]["file_id"]);
        // }
        ///////////////////////////////////////////////////////
        // let filedata = [];
        // for (let i = 0; i < this.filemultipledownload.length; i++) {
        //   if (this.filemultipledownload[i].file_type === "folder") {
        //     filedata.push({
        //       id: this.filemultipledownload[i]["file_id"],
        //       type: this.filemultipledownload[i]["file_type"],
        //     });
        //   } else {
        //     filedata.push({
        //       id: this.filemultipledownload[i]["file_id"],
        //       type: "file",
        //     });
        //   }
        // }
        // let payload = {
        //   list: filedata,
        //   account_id: this.dataAccountId,
        // };
        // console.log("pppppp", payload);
        // // this.snackbardowload = true;
        // this.opensnackbarmultidownload = true;
        // // api/multiple_downloads_file
        // // api/download_multiples_all
        // this.axios
        //   .post(process.env.VUE_APP_SERVICE_DOWNLOAD_MULTIPLE + "/api/download_multiples_all", payload, {
        //     onDownloadProgress: (progressEvent) => {
        //       let progresspercent = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
        //       if (progresspercent >= 95) {
        //         this.percentCompleted = 95;
        //       } else {
        //         this.percentCompleted = progresspercent;
        //       }
        //       this.percent = this.percentCompleted;
        //       console.log(this.percent);
        //     },
        //     withCredentials: false,
        //     responseType: "arraybuffer",
        //   })
        //   .then((response) => {
        //     this.statusmutipledownload = false;
        //     if (response.statusText === "OK") {
        //       this.percentCompleted = 100;
        //       this.percent = this.percentCompleted;
        //     }
        //     //     const url = window.URL.createObjectURL(new Blob([response.data]));
        //     //     const link = document.createElement("a");
        //     //     link.href = url;
        //     //     link.setAttribute("download",datetime+".zip");
        //     //     document.body.appendChild(link);
        //     //     link.click();
        //     const blob = new Blob([response.data]);
        //     const content = response.headers["content-type"];
        //     saveAs(blob, this.datetime + ".zip");
        //     this.clearmultipledownload();
        //   })
        //   .catch((error) => {
        //     this.opensnackbarmultidownload = false;
        //     Toast.fire({
        //       icon: "error",
        //       title: this.$t("myinboxPage.msg.apierror"),
        //     });
        //   });
      }
    },

    filteredItems(search) {
      // this.search = search;
      this.disablescrolling = true;
      if (search !== "") {
        this.rootfile = this.buff;
        let filldata = this.rootfile.filter((item) => {
          return item.file_name.toLowerCase().indexOf(search.toLowerCase()) > -1 && item.file_type === "folder";
        });
        this.rootfile = [];
        filldata.forEach((filedata) => {
          this.rootfile.push(filedata);
        });
      } else {
        this.rootfile = this.buff;
      }
    },

    formatdatetime(_datetime) {
      if (_datetime === "" || _datetime === "-" || _datetime === undefined || _datetime === null) {
        return "-";
      } else {
        let dateyear = _datetime.split("")[0] + _datetime.split("")[1] + _datetime.split("")[2] + _datetime.split("")[3];
        let datemonth = _datetime.split("")[4] + _datetime.split("")[5];
        let dateday = _datetime.split("")[6] + _datetime.split("")[7];
        let hour = _datetime.split("")[8] + _datetime.split("")[9];
        let minute = _datetime.split("")[10] + _datetime.split("")[11];
        let second = _datetime.split("")[12] + _datetime.split("")[13];

        return dateday + "/" + datemonth + "/" + dateyear + " " + hour + ":" + minute;
        // return dateday + "/" + datemonth + "/" + dateyear + " " + hour + ":" + minute + ":" + second;
      }
    },

    fn_calculatesize(_sizefilebyte) {
      return gb_sizeDataConverter.convertStorageToKbMbGb(_sizefilebyte);
    },

    backtoroot() {
      // this.$router.replace("/mydrive");
      this.$router.push("/mydrive");
    },

    async deletenewfunction(parameter) {
      // status_data
      // ถ้าเป็นแอดมิน้ข่าโฟลเดอรบริษัทลบด้วย TB
      // คนปกติใช้ T

      let status_data = "T";
      if (this.dataAccountActive["type"] === "Citizen") {
        status_data = "T";
      } else {
        if (this.navigate[0].text[1] == this.dataAccountActive["business_info"]["my_folder_id"] && this.role_level == "True") {
          // admin
          status_data = "TB";
        } else {
          status_data = "T";
        }
      }

      let payload = {
        account_id: parameter.account_sender,
        user_id: this.dataUsername,
        data_id: parameter.file_id,
        data_type: parameter.file_type === "folder" ? "folder" : "file",
        status_data: status_data,
      };
      console.log("payload", payload);
      let auth = await gbfGenerate.generateToken();
      this.axios
        .post(process.env.VUE_APP_SERVICE_UPDATE_MULTIPLES + "/api/remove_file_folder_to_trash", payload, {
          headers: { Authorization: auth.code },
        })
        .then((response) => {
          if (response.data.status === "OK") {
            console.log("delresponse", response);
            Toast.fire({
              icon: "success",
              title: this.$t("toast.text.remove") + parameter.file_name + this.$t("toast.text.totrash"),
            });
            this.fn_loadfileandfolder_scrolling_child();
            this.loadstorage();
          } else {
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          }
        });
    },

    async deleterelationfile(parameter) {
      console.log("deleterelation");
      if (parameter.relation_id === "") {
        Toast.fire({
          icon: "error",
          title: "this document isn't relation",
        });
      } else {
        var payload = {
          relation_id: parameter.relation_id,
          account_id: this.dataAccountId,
        };
        let auth = await gbfGenerate.generateToken();
        this.axios
          .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/delete_relation_file_folder", payload, {
            headers: { Authorization: auth.code },
          })
          .then((response) => {
            console.log(response);
            if (response.data.status === "OK") {
              Toast.fire({
                icon: "success",
                title: "delete relation success",
              });
              this.fn_loadfileandfolder_scrolling_child();
            } else {
              Toast.fire({
                icon: "error",
                title: "can not delete relation",
              });
            }
          })
          .catch((error) => {
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          });
      }
    },

    async setPermissionFileStar(parameter, status, key) {
      console.log(parameter);
      if (parameter.status_lock_data === "Y" && key === "st") {
        console.log("setPermissionFileStar", parameter, status);
        this.opendialog_checklog = true;
        console.log(parameter);
        this.newdata = parameter;
        this.check_btn = key;
        this.star_status = status;
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
        });
        let api;
        let payload;
        let msgres;
        if (status === "T") {
          // this.opensdialogcheckdeleterightclick = true;
          this.deletenewfunction(parameter);
        } else {
           if(status === "S"){
            console.log("restore+++++++++++++++++");
            payload = {
              starred_id:[parameter.starred_id],
              data_id:[parameter.file_id],
              account_id:this.dataAccountId,
              process:"insert"
            };
            api = "/api/multiple_star";
          }else{
             payload = {
               starred_id:[parameter.starred_id],
              data_id:[parameter.file_id],
              account_id:this.dataAccountId,
              process:"delete"
            };
            api = "/api/multiple_star";
          }
          // if (parameter.file_type === "folder") {
          //   payload = {
          //     folder_id: parameter.file_id,
          //     folder_name: "",
          //     status_folder: status,
          //     move_to_id: "",
          //     account_id: parameter.account_sender,
          //     // user_id: this.dataUsername
          //   };
          //   api = "/api/v2/update_folder_id";
          // } else {
          //   payload = {
          //     // user_id: this.dataUsername,
          //     account_id: parameter.account_sender,
          //     file_id: parameter.file_id,
          //     filename: "",
          //     folder_id: "",
          //     status_file: status,
          //   };
          //   api = "/api/v2/update_file_id";
          // }
          let auth = await gbfGenerate.generateToken();
          this.axios
            .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + api, payload, { headers: { Authorization: auth.code } })
            .then((response) => {
              if (response.data.status === "OK") {
                if (status === "S") {
                  // "ติดดาว " + parameter.file_name + " สำเร็จ"
                  msgres = this.$t("toast.text.starred") + parameter.file_name + this.$t("toast.text.success");
                } else if (status === "T") {
                  // "นำ " + parameter.file_name + " ออกสำเร็จ"
                  msgres = this.$t("toast.text.remove") + parameter.file_name + this.$t("toast.text.success");
                } else {
                  // "ยกเลิกติดดาว " + parameter.file_name + " สำเร็จ"
                  msgres = this.$t("toast.text.canclestart") + parameter.file_name + this.$t("toast.text.success");
                }
                Toast.fire({
                  icon: "success",
                  title: msgres,
                });
                // this.$emit('closecurrent')
                this.fn_loadfileandfolder_scrolling_child();
              } else {
                Toast.fire({
                  icon: "error",
                  title: response.data.errorMessage,
                });
              }
            })
            .catch((error) => {
              Toast.fire({
                icon: "error",
                // "ไม่สามารถติดดาว " + parameter.file_name + " ได้"
                title: this.$t("toast.text.cannotstar") + parameter.file_name + this.$t("toast.text.textth"),
              });
            });
        }
      }
    },

    appsbarmultimovefile(e) {
      if (this.filemultipledownload.length < 1) {
        Toast.fire({
          icon: "warning",
          title: this.$t("myinboxPage.msg.nofile"),
        });
      } else {
        e.preventDefault();
        this.x = e.clientX;
        this.y = e.clientY;
        this.sendParentfolder();
        this.opendialogmultimove = true;
      }
    },
    apphashtag() {
      if (this.filemultipledownload.length > 0) {
        this.opendialogeditdeletehashtag = true;
      } else {
        Toast.fire({
          icon: "warning",
          title: this.$t("myinboxPage.msg.nofile"),
        });
      }
    },
    rightclickfileandfolder(e, file) {
      // console.log(e);
      if (this.dataAccountActive.type === "Business") {
        for (let i = 0; i < this.navigate.length; i++) {
          // console.log(this.navigate[i].text[1],this.dataAccountActive["business_info"]["my_folder_id"]);
          if (this.dataAccountActive["business_info"]["my_folder_id"] === this.navigate[i].text[1]) {
            this.checksetpermission = true;
          }
        }

      }
      // เมื่อคลิกจุดสามจุด ให้โฟกัสรายการที่เลือก
      if (this.filemultipledownload.filter((item) => item.file_id === file.file_id).length === 0) {
        this.filemultipledownload = [file];
      }
      if (this.filemultipledownload.length > 1) {
        //เช็คว่า ล็อคอิน business หรือไม่
        if (this.dataAccountActive.type == "Business") {
          if (this.navigate[0].text[1] == this.dataAccountActive["business_info"]["my_folder_id"] && this.role_level == "True") {
            // admin
            this.status_data = "TB";
          } else {
            this.status_data = "T";
          }
        } else {
          this.status_data = "T";
        }
        // เปิด multifilerightclick
        this.rightclickmultifile = true;
      }
      console.log('Root File ', this.rootfile)
      e.preventDefault();
      this.showcurrentMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.showMenu = false
      this.triggered_rc_fileorfolder = true
      this.$nextTick(() => {
        this.currentfile = file;
        this.showcurrentMenu = true;
        if (this.showMenu === true) {
          this.showcurrentMenu = false;
        } else if (this.rightclickmultifile === true) {
          this.showcurrentMenu = false;
        } else {
          setTimeout(() => {
            this.showcurrentMenu = true;
          }, 300);
        }
      });
    },

    gotodirectory(folderid, type, file) {
      // ให้ reset ค่าของ filemultipledownload กับ checklist เวลากดเม้า Toast จะได้ไม่แจ้งเตือน
      // this.resetFile()

      localStorage.setItem("scrollHistory", 0);

      console.log("file  dbclick ******", file, type);

      if (file.file_type === "folder") {
        if (file.file_status === "M") {
          this.tabdepartment = 0;
        }
        // localStorage.setItem("changeStoreUI","true")
        // localStorage.setItem("changeUI","true")
        // this.changeUI = true;

        this.rootfile = [];
        this.buff = [];
        // this.$router.replace({ path: "/directory/" + folderid });
        this.$router.push({ path: "/directory/" + folderid });
        this.parentfolder = this.$route.params.id;
        this.fn_loadfileandfolder_scrolling_child();
        console.log(this.openpreviewfile);
      } else {
        this.mouseLeaveFile_onlyoffice() // เอาไว้ปิด hover เมื่อกดเปิดไฟล์ 
        console.log("file",file);
        // let checkinet_jv = gb_checkeditonline.checkeditonlineinetjv(this.dataAccountActive.taxid)
        // console.log("checkinet_jv",checkinet_jv);
        // if(checkinet_jv === -1){
          // let checkinet_jv = VueCookies.get("feature_onlyoffice")
          // if(checkinet_jv === 'false'){
            console.log("checkinet_jv",checkinet_jv);
          let checkinet_jv = this.feature_onlyoffice
          if(checkinet_jv === false){
          this.param_preview = "preview";
          this.openpreviewfile = true;
        }else{
          if(
            // (['04', '05', '06'].includes(file.file_permission_2)) &&
            (file.file_type && ['docx','xlsx','pptx','xlsb','xml','txt','html'].includes(file.file_type.toLowerCase()) || 
            file.data_type && ['docx','xlsx','pptx','xlsb','xml','txt','html'].includes(file.data_type.toLowerCase()))
          ){
            this.param_preview = "editfileonline";
          }else{
            this.param_preview = "preview";
          }

        }
        this.currentfile = file;
        // if(file.status_encrypt === 'N'){
          if(file.editing_status === 'S'){
            Toast.fire({
                icon: "warning",
                title: "ไม่สามารถเปิดได้เนื่องจากมีการแก้ไขไฟล์",
              });
          }else if(file.editing_status === 'Y'){
            this.openpreviewfile = true;
            // if(file.last_update_name.name_th === 'ฉัน'){
            //   this.openpreviewfile = true;
            // }else{
            //   Toast.fire({
            //     icon: "warning",
            //     title: "ไม่สามารถเปิดได้เนื่องจากมีการแก้ไขไฟล์",
            //   });
            // }
          }else{
            this.openpreviewfile = true;
          }
        // }else{
        //   this.opendialogdownloadvideo = true;
        // }
        console.log("currentfile", this.currentfile, this.openpreviewfile);
      }
    },

    //check clickขวา แบบไม่กดปุ่ม
    show(e) {
      console.log("ถูกกกกก");
      e.preventDefault();
      this.showMenu = false;
      if(this.triggered_rc_fileorfolder === true){
        //do not work this 
        this.showPrevMenu = true
      }
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        if (this.showcurrentMenu === true 
        || this.rightclickmultifile === true) {
          
           if(this.showPrevMenu === true){
            this.showMenu = false
          }else{
            this.showMenu = true
            this.showcurrentMenu = false
          }
        } else {
          setTimeout(() => {
            if (this.tapbusiness === false) {
              this.showMenu = false;
            } else {
              this.showMenu = true;
            }
          }, 300);
        }
        this.showPrevMenu = false
        this.triggered_rc_fileorfolder = false
      });
    },

    cancelAll() {
      this.snackbar = false;
      this.checkCCFolder = true;
      this.checkCC = true;
      //console.log("test");
      this.total = 0;
      this.count = 0;
      this.fn_loadfileandfolder_scrolling_child();
    },

    cancel(item) {
      this.itemC = item;
      console.log(this.itemC);
      var res = this.itemC["name"].split(".");
      if (res[1] === undefined) {
        this.checkCCFolder = true;
        //console.log("test");
        let objIndex = this.listDataUpload.findIndex((obj) => obj.name === this.rootname);
        this.listDataUpload[objIndex]["value"] = 500;
        this.listDataUpload[objIndex]["status"] = "Operation canceled by the user.";
        this.listDataUpload[objIndex]["color"] = "red";
      } else {
        this.checkCC = true;
      }
    },

    onDrop(files, folderid, status, rootfolder, totalfolder, countfolder) {
      console.log("status", status);
      if (status === "Fi") {
        this.listDataUpload = [];
        for (let i = 0; i < files.length; i++) {
          let dataUpload = {};
          var res = files[i].name.split(".");
          console.log("files[i].name", files[i].name);
          console.log("res", res[1]);
          dataUpload["name"] = files[i].name;
          dataUpload["status"] = "กำลังอัปโหลด";
          dataUpload["value"] = 0;
          dataUpload["color"] = "green";
          dataUpload["icon"] = gb_setIconFiles.iconExtensionFilesOnly(res[1]);
          dataUpload["showUpload"] = false;
          this.listDataUpload.push(dataUpload);
          // console.log("files", files[i]);
          //this.listDataUpload = this.listDataUpload.filter(item => item !== this.itemC)
          this.uploadfiles(files[i]);
        }
        // console.log(this.listDataUpload);
      } else {
        this.uploadfilesFolder(files, folderid, rootfolder, totalfolder, countfolder);
      }
    },

    async uploadfilesFolder(files, folderid, rootfolder, totalfolder, countfolder) {
      if (this.checkCCFolder === true) {
        console.log(this.checkCCFolder);
        let objIndex = this.listDataUpload.findIndex((obj) => obj.name === rootfolder);
        source.cancel("Operation canceled by the user.");
        this.listDataUpload[objIndex]["value"] = 500;
        this.listDataUpload[objIndex]["status"] = "Cancel upload folder by user";
        this.listDataUpload[objIndex]["color"] = "red";
        return;
      } else {
        console.log("uploadfilesFolder", totalfolder, countfolder);

        let objIndex = this.listDataUpload.findIndex((obj) => obj.name === rootfolder);

        const formData = new FormData();

        //formData.append("file", files[i]);
        formData.append("file", files);
        //formData.append("filname", files[i].name);
        formData.append("size_file", files.size);
        //formData.append("size_file", files[i].size);

        formData.append("folder_id", folderid);
        //console.log(folderid);

        formData.append("user_id", this.dataUsername);
        formData.append("department_id", this.dataDepartmentAccessId);
        formData.append("account_id", this.dataAccountId);
        //console.log("hover2",formData);
        // the request itself
        //if (files[i].size >= 500000000) {
        if (files.size >= 2147483648) {
          this.listDataUpload[objIndex]["value"] = 500;
          this.listDataUpload[objIndex]["status"] = "size file more than 1GB";
          this.listDataUpload[objIndex]["color"] = "red";
        } else {
          let CancelToken = this.axios.CancelToken;
          let source = new CancelToken.source();
          //  console.log("lklklklklkl", source);
          // console.log(this.checkCC);
          if (this.checkCCFolder === true) {
            source.cancel("Operation canceled by the user.");
            this.listDataUpload[objIndex]["value"] = 500;
            this.listDataUpload[objIndex]["status"] = "Cancel upload folder by user";
            this.listDataUpload[objIndex]["color"] = "red";

            //
          }
          try {
            let auth = await gbfGenerate.generateToken();
            this.snackbar = true;
            let result = await this.axios({
              method: "POST",
              url: process.env.VUE_APP_SERVICE_UPLOAD_FILE + "/api/upload_files",
              cancelToken: source.token,
              data: formData,
              headers: { Authorization: auth.code },

              onUploadProgress: (e) => {
                //console.log(this.checkCC);
                if (this.checkCCFolder === true) {
                  source.cancel("Operation canceled by the user.");
                  this.listDataUpload[objIndex]["value"] = 500;
                  this.listDataUpload[objIndex]["status"] = "Cancel upload folder by user";
                  this.listDataUpload[objIndex]["color"] = "red";

                  //
                }
              },
            });
            // console.log("result", result);
            if (result.data.status === "OK") {
              if (this.total - this.count === 0) {
                this.listDataUpload[objIndex]["value"] = 100;
                this.listDataUpload[objIndex]["status"] = result.data.message;
                this.listDataUpload[objIndex]["color"] = "green";
                this.fn_loadfileandfolder_scrolling_child();
              } else {
                this.listDataUpload[objIndex]["value"] = parseInt((100 / this.total) * this.count);
                //this.listDataUpload[objIndex]["status"] = "กำลังอัปโหลด";
                // this.listDataUpload[objIndex]["color"] = "green";
                this.count++;
              }
            } else if (result.data.status === 413) {
              this.listDataUpload[objIndex]["value"] = 500;
              this.listDataUpload[objIndex]["status"] = result.data.errorMessage;
              this.listDataUpload[objIndex]["color"] = "red";
            } else if (result.data.errorCode === "ER999") {
              this.listDataUpload[objIndex]["value"] = 500;
              this.listDataUpload[objIndex]["status"] = "Can not upload";
              this.listDataUpload[objIndex]["color"] = "red";
            } else {
              this.listDataUpload[objIndex]["value"] = 500;
              this.listDataUpload[objIndex]["status"] = result.data.errorMessage;
              this.listDataUpload[objIndex]["color"] = "red";
            }
          } catch (err) {
            // check if the request was cancelled
            if (this.axios.isCancel(err)) {
              // console.log(err.message);
              //this.checkCCFolder = false;
              // console.log(this.checkCC);
            }

            //console.log("ยกเลิกอัปโหลดไม่ทันอะ");
          }
        }
        // }
        // this.loadfolder();
        // this.$store
        //   .dispatch("check_storage", this.dataAccountId)
        //   .then((res) => {});
        // console.log("this.dataAccountId------", this.dataAccountId);
      }
    },

    async uploadfiles(files, folderid, rootfolder) {
      // console.log("hover",folderid,rootfolder);
      let parent = this.sendParentfolder();
      //console.log("process start",parent);
      // formData.append('file', file, file.name);
      // for (let i = 0; i < files.length; i++) {
      let objIndex = this.listDataUpload.findIndex((obj) => obj.name === files.name);

      const formData = new FormData();

      //formData.append("file", files[i]);
      formData.append("file", files);
      //formData.append("filname", files[i].name);
      formData.append("size_file", files.size);
      //formData.append("size_file", files[i].size);
      if (folderid === undefined) {
        formData.append("folder_id", this.parentfolder);
        //console.log(this.parentfolderDD);
      } else {
        formData.append("folder_id", folderid);
        //console.log(folderid);
      }
      formData.append("user_id", this.dataUsername);
      formData.append("department_id", this.dataDepartmentAccessId);
      formData.append("account_id", this.dataAccountId);
      //console.log("hover2",formData);
      // the request itself
      //if (files[i].size >= 500000000) {
      if (files.size >= 2147483648) {
        this.listDataUpload[objIndex]["value"] = 500;
        this.listDataUpload[objIndex]["status"] = "size file more than 1GB";
        this.listDataUpload[objIndex]["color"] = "red";
      } else {
        let CancelToken = this.axios.CancelToken;
        let source = new CancelToken.source();
        //  console.log("lklklklklkl", source);
        // console.log(this.checkCC);
        try {
          let auth = await gbfGenerate.generateToken();
          this.snackbar = true;
          let result = await this.axios({
            method: "POST",
            url: process.env.VUE_APP_SERVICE_UPLOAD_FILE + "/api/upload_files",
            cancelToken: source.token,
            data: formData,
            headers: { Authorization: auth.code },

            onUploadProgress: (e) => {
              //console.log(this.checkCC);
              if (this.checkCC === true) {
                source.cancel("Operation canceled by the user.");
                this.listDataUpload[objIndex]["value"] = 500;
                this.listDataUpload[objIndex]["status"] = "canceled by the user.";
                this.listDataUpload[objIndex]["color"] = "red";

                //
              }

              // this.listDataUpload[objIndex]["value"] = 0;
              // this.listDataUpload[objIndex]["status"] = this.$t(
              //   "uploadFile.upload"
              // );
              //this.listDataUpload[objIndex]["color"] = "green";
              if ((e.loaded / e.total) * 100 >= 95) {
                this.listDataUpload[objIndex]["value"] = 95;
                this.timeout = (e.loaded / e.total) * 100000;
              } else {
                this.listDataUpload[objIndex]["value"] = parseInt((e.loaded / e.total) * 100);
              }
            },
          });
          // console.log("result", result);
          if (result.data.status === "OK") {
            this.listDataUpload[objIndex]["value"] = 100;
            this.listDataUpload[objIndex]["status"] = result.data.message;
            this.listDataUpload[objIndex]["color"] = "green";
          } else if (result.data.status === 413) {
            this.listDataUpload[objIndex]["value"] = 500;
            this.listDataUpload[objIndex]["status"] = result.data.errorMessage;
            this.listDataUpload[objIndex]["color"] = "red";
          } else if (result.data.errorCode === "ER999") {
            this.listDataUpload[objIndex]["value"] = 500;
            this.listDataUpload[objIndex]["status"] = "Can not upload";
            this.listDataUpload[objIndex]["color"] = "red";
          } else {
            this.listDataUpload[objIndex]["value"] = 500;
            this.listDataUpload[objIndex]["status"] = result.data.errorMessage;
            this.listDataUpload[objIndex]["color"] = "red";
          }
        } catch (err) {
          // check if the request was cancelled
          if (this.axios.isCancel(err)) {
            // console.log(err.message);
            this.listDataUpload[objIndex]["status"] = err.message;
            this.checkCC = false;
            // console.log(this.checkCC);
          }

          //console.log("ยกเลิกอัปโหลดไม่ทันอะ");
        }
      }
      // }
      this.fn_loadfileandfolder_scrolling_child();
      // this.$store
      //   .dispatch("check_storage", this.dataAccountId)
      //   .then((res) => {});
      // console.log("this.dataAccountId------", this.dataAccountId);
    },

    sendParentfolder() {
      if (this.$route.params.id === undefined) {
        this.parentfolder = "";
        this.parentfoldername = "ROOT";
      } else {
        this.parentfolder = this.$route.params.id;
        this.parentfoldername = this.foldername;
      }
      // this.openUploadfolder = true
    },

    // async fn_advancesearch(_param) {
    //   console.log("_param",_param);
    //   this.rootfile = [];
    //   this.buff = [];
    //   const datafolder = gb_advanceSearch.setDataCompleteAdvanceSearch(_param, this.dataAccountId);
    //   // console.log("datafolder",datafolder);
    //   let count_file = datafolder.filter((item) => {
    //     return item.file_type === "file";
    //   });
    //   let count_folder = datafolder.filter((item) => {
    //     return item.file_type === "folder";
    //   });
    //   // console.log("count_file",count_file);
    //   // console.log("count_folder",count_folder);
    //   this.totaldata = {
    //     totalall: count_file.length + count_folder.length,
    //     totalfile: count_file.length,
    //     totalfolder: count_folder.length,
    //   };
    //   // console.log("this.totaldata",this.totaldata);
    //   // this.rootfile = datafolder;
    //   this.rootfile.push(datafolder);
    //   console.log("this.rootfile",this.rootfile);
    //   for (let index = 0; index < this.rootfile.length; index++) {
    //     const element = this.rootfile[index];
    //     element.file_thumbnail = "";
    //     let typeFile = element.file_name.split(".");
    //     await this.fn_loadthumbnail(element.file_id, typeFile[typeFile.length - 1],element.file_size, element.status_lock_data);
        
    //     if(this.rootfile[index].file_owner_th === '' || this.rootfile[index].file_owner_eng === ''){
    //       await this.get_name_owner(this.rootfile[index])
    //     }
    //   }
    //   console.log("this.count_data_adv_",this.count_data_adv_);
    //   this.disablescrolling_advancedsearch = this.count_data_adv_ <= this.rootfile.length;
    //   console.log("this.disablescrolling_advancedsearch",this.disablescrolling_advancedsearch);
    //   this.buff.push(datafolder);
    //   // this.buff = datafolder;
    //   datafolder
    // },
    // async fn_advancesearch_loop(_param) {
    //   console.log("_param",_param);
    //   // this.processloader = true;
    //   // this.heardertextsearch = true;
    //   // this.disablescrolling = true;
    //   // this.disablescrollfilter = true;
    //   // this.disablescrolling_advancedsearch = false;
    //   // this.load_advanced = true;

    //   const datafolder = gb_advanceSearch.setDataCompleteAdvanceSearch(_param, this.dataAccountId);
    //   // console.log("datafolder",datafolder);
    //   let count_file = datafolder.filter((item) => {
    //     return item.file_type === "file";
    //   });
    //   let count_folder = datafolder.filter((item) => {
    //     return item.file_type === "folder";
    //   });
    //   // console.log("count_file",count_file);
    //   // console.log("count_folder",count_folder);
    //   this.totaldata = {
    //     totalall: count_file.length + count_folder.length,
    //     totalfile: count_file.length,
    //     totalfolder: count_folder.length,
    //   };
    //   // console.log("this.totaldata",this.totaldata);
    //   console.log("datafolder",datafolder);
    //   // this.rootfile = datafolder;
    //   this.rootfile.push(datafolder);
    //   console.log("this.rootfile",this.rootfile);
    //   for (let index = 0; index < this.rootfile.length; index++) {
    //     const element = this.rootfile[index];
    //     element.file_thumbnail = "";
    //     let typeFile = element.file_name.split(".");
    //     await this.fn_loadthumbnail(element.file_id, typeFile[typeFile.length - 1],element.file_size, element.status_lock_data);
        
    //     if(this.rootfile[index].file_owner_th === '' || this.rootfile[index].file_owner_eng === ''){
    //       await this.get_name_owner(this.rootfile[index])
    //     }
    //   }
    //   console.log("this.count_data_adv_",this.count_data_adv_);
    //   this.disablescrolling_advancedsearch = this.count_data_adv_ <= this.rootfile.length;
    //   console.log("this.disablescrolling_advancedsearch",this.disablescrolling_advancedsearch);
    //   // this.buff = datafolder;
    //   this.buff.push(datafolder);
    // },

    async fn_advancesearch_new(_param){
      console.log("_param",_param);
      this.totaldata['totalfolder'] = 0;
      this.totaldata['totalfile'] = 0;
      this.totaldata['totalall'] = 0;
      // this.processloader = true;
      // this.heardertextsearch = true;
      // this.disablescrolling = true;
      // this.disablescrollfilter = true;
      // this.disablescrolling_advancedsearch = false;
      // this.loaddataprogress = true;
      // console.log("rot", this.$router.currentRoute.path.split("/"));
      this.path_folder_split = this.$router.currentRoute.path.split("/");
      this.path_folder = "";
      if (this.path_folder_split[1] === "directory") {
        this.path_folder = this.path_folder_split[2];
      } else if (this.path_folder_split[1] === "directorysharefile") {
        this.path_folder = this.path_folder_split[2];
      } else {
        if (this.dataAccountActive["type"] === "Business") {
          this.path_folder = this.dataAccountActive["business_info"][
            "my_folder_id"
          ];
        } else {
          this.path_folder = this.dataAccountActive["directory_id"];
        }
      }
      let cal_limit = this.size;
      let cal_offset = this.size * (this.pagee++ - 1);

      console.log("cal_offset",typeof(cal_offset));
      console.log("cal_offset",cal_offset);
      let payload;
      // console.log(cal_offset)
      this.heardertextsearch = true;
      if (cal_offset == 0) {
        // this.processloader = true;
        this.disablescrolling = true;
        this.disablescrollfilter = true;
        this.disablescrolling_advancedsearch = true;
        this.loaddataprogress = true;
        this.listdatarootfile = [];
        this.rootfile = [];
        this.buff = [];
        this.count_data_adv_ = 0;
      }

      if(cal_offset == 0){
        const data_param = JSON.stringify(_param);
        sessionStorage.setItem("advancedsearch_new", data_param);
        let _param2_ = sessionStorage.getItem("advancedsearch_new");
        let _param2 = JSON.parse(_param2_);
        this.data_search = _param2;
        // console.log("page",this.page);
        // console.log("cal_offset",typeof(cal_offset));
        // console.log("cal_offset",cal_offset);

        console.log("เข้า1");
        console.log("_param",_param);
        console.log("data_search",this.data_search);
 

        if (this.path_folder_split[1] === "directorysharefile") {
          payload = {
            account_id: this.dataAccountId,
            cre_dtm: this.data_search.cre_dtm,
            to_dtm: this.data_search.to_dtm,
            type_search: this.data_search.type_search,
            name: this.data_search.name,
            cctv:this.data_search.cctv,
            folder_id: this.data_search.folder_id,
            dept_id: this.data_search.dept_id,
            limit: cal_limit,
            offset: cal_offset,
          };
          console.log("fake payload", payload);
          // this.processdelete = true;
          let auth = await gbfGenerate.generateToken();
          this.axios
            .post(
              process.env.VUE_APP_SERVICE_DOWNLOAD_API +
                `/api/shared/advanced_search`,
              payload,
              {
                headers: { Authorization: auth.code },
              }
            )
            .then(async (response) => {
              console.log("advance search", response.data);
              if (response.data.status === "OK") {
                this.loaddataprogress = false;
                for(let i=0; i < response.data.data.length; i++){
                  let datafolder = {};
                  let typefile = response.data.data[i]["type"] === 'folder' ? "folder" : response.data.data[i]["name"].split(".") ;
                    datafolder["account_reciever"] = "";
                    datafolder["account_sender"] = this.dataAccountId;
                    datafolder["folder_id"] = response.data.data[i]["folder_id"];
                    if ("file_id" in response.data.data) {
                      datafolder["file_id"] = response.data.data[i]["file_id"];
                    } else {
                      datafolder["file_id"] = response.data.data[i]["id"];
                    }
                    datafolder["file_name"] = response.data.data[i]["name"];
                    datafolder["file_size"] = response.data.data[i]["size"];
                    datafolder["file_status"] = response.data.data[i]["status"];
                    datafolder["file_owner_eng"] = response.data.data[i]["name_eng"];
                    datafolder["file_owner_th"] = response.data.data[i]["name_th"];
                    datafolder["file_linkshare"] = "";
                    datafolder["file_lastdtm"] = response.data.data[i]["last_dtm"];
                    datafolder["file_date"] = "";
                    datafolder["file_permission_2"] = response.data.data[i]["permission_account_v2"];
                    datafolder["file_parent_folder_id"] = response.data.data[i]["folder_id"];
                    if ("permission_account" in response.data.data) {
                      datafolder["file_permission"] = response.data.data[i]["permission_account"];
                    } else {
                      datafolder["file_permission"] = {
                        admin_data: "False",
                        delete: "False",
                        download: "False",
                        edit: "False",
                        upload: "False",
                        view_only: "True",
                      };
                    }

                    datafolder["file_permission_department_setting"] = response.data.data[i]["permission_department_setting"];
                    datafolder["file_permission_setting"] = response.data.data[i]["permission_setting"];
                    datafolder["permission_business_setting"] = response.data.data[i]["permission_business_setting"];
                    datafolder["file_status_share"] = response.data.data[i]["status_share"];
                    datafolder["file_status_sharelink"] = response.data.data[i]["status_share_link"];
                    datafolder["file_type"] = response.data.data[i]["data_type"];
                    datafolder["data_type"] = typefile[typefile.length - 1];
                    datafolder["status_updating"] = response.data.data[i]["status_updating"];
                    datafolder["file_department"] = "";
                    datafolder["file_icon"] = this.fn_setThumbnailIcon(typefile[typefile.length - 1]);
                    datafolder["status_updating"] = response.data.data[i]["status_updating"];
                    datafolder["status_lock_folder"] = response.data.data[i]["status_lock_folder"];
                    datafolder["status_lock_file"] = response.data.data[i]["status_lock_file"];
                    datafolder["status_lock_data"] = response.data.data[i]["status_lock_data"];
                    datafolder["file_thumbnail"] = "";
                    datafolder["file_thumbnail_icon"] = this.fn_setThumbnailIcon(typefile[typefile.length - 1]);
                    datafolder["editing_status"] = response.data.data[i]["editing_status"];
                    datafolder["update_by"] = response.data.data[i]["update_by"];
                    datafolder["last_update_name"] = response.data.data[i]["last_update_name"];
                    datafolder["version_tag"] = response.data.data[i]["version_tag"];
                    datafolder["tag_id"] = response.data.data[i]["tag_id"];
                    datafolder["hashtag"] = response.data.data[i]["hashtag"];
                    datafolder["status_expired"] = response.data.data[i]["status_expired"];
                    datafolder["expiration_time"] = response.data.data[i]["expiration_time"];
                    datafolder["warning_expiration_time"] = response.data.data[i]["warning_expiration_time"];

                    this.listdatarootfile.push(datafolder);
                    // this.rootfile.push(datafolder);
                    // this.buff.push(datafolder);
                    datafolder["status_hilightword"] = true
                
                  
                }
                this.count_data_adv_ = response.data.total;
                // this.looplistdatarootfile();
                // for (let index = 0; index < this.rootfile.length; index++) {
                //   const element = this.rootfile[index];
                //   element.file_thumbnail = "";
                //   let typeFile = element.file_name.split(".");
                //   await this.fn_loadthumbnail(element.file_id, typeFile[typeFile.length - 1],element.file_size, element.status_lock_data);
                  
                //   if(this.rootfile[index].file_owner_th === '' || this.rootfile[index].file_owner_eng === ''){
                //     await this.get_name_owner(this.rootfile[index])
                //   }
                // }
                // console.log("this.count_data_adv_",this.count_data_adv_);
                // this.disablescrolling_advancedsearch = this.count_data_adv_ <= this.rootfile.length;
                // console.log("this.disablescrolling_advancedsearch",this.disablescrolling_advancedsearch);
                // this.index_loop = 0;
                // this.num_count = 30;
                this.looplistdatarootfile();

              } else {
                console.log("ERR");
                this.disablescrolling_advancedsearch = true;
                this.loaddataprogress = false;
                this.load_advanced = false;
              }
            });
        } else {
          payload = {
            account_id: this.dataAccountId,
            cre_dtm: this.data_search.cre_dtm,
            to_dtm: this.data_search.to_dtm,
            type_search: this.data_search.type_search,
            name: this.data_search.name,
            cctv:this.data_search.cctv,
            folder_id: this.data_search.folder_id,
            dept_id: this.data_search.dept_id,
            limit: cal_limit,
            offset: cal_offset,
          };
          console.log("fake payload", payload);
          // this.processdelete = true;
          let auth = await gbfGenerate.generateToken();
          this.axios
            .post(
              process.env.VUE_APP_SERVICE_DOWNLOAD_API +
                `/api/v2/advanced_search`,
              payload,
              {
                headers: { Authorization: auth.code },
              }
            )
            .then(async (response) => {
              console.log("advance search cal_offset", response.data.data);
              if (response.data.status === "OK") {
                this.isAdvancedSearch = true;
                this.loaddataprogress = false;
                this.count_data_adv_ = response.data.total;
                console.log("totaldata", this.totaldata);
                // this.fn_advancesearch(response);
                for(let i=0; i < response.data.data.length; i++){
                  let datafolder = {};
                  let typefile = response.data.data[i]["type"] === 'folder' ? "folder" : response.data.data[i]["name"].split(".") ;
                    datafolder["account_reciever"] = "";
                    datafolder["account_sender"] = this.dataAccountId;
                    datafolder["folder_id"] = response.data.data[i]["folder_id"];
                    if ("file_id" in response.data.data) {
                      datafolder["file_id"] = response.data.data[i]["file_id"];
                    } else {
                      datafolder["file_id"] = response.data.data[i]["id"];
                    }
                    datafolder["file_name"] = response.data.data[i]["name"];
                    datafolder["file_size"] = response.data.data[i]["size"];
                    datafolder["full_path"] = response.data.data[i]["full_path"];
                    datafolder["full_path_id"] = response.data.data[i]["full_path_id"];
                    let fullPathName = datafolder["full_path"] ? datafolder["full_path"].split('/') : [];
                    let fullPathId = datafolder["full_path_id"] ? datafolder["full_path_id"].split('/') : [];
                    datafolder["file_location"] = fullPathName.map((name, index) => {
                      let fileExtensions = [".jpeg", ".jpg", ".png", ".gif", ".pdf", ".xlsx", ".pptx", ".xls", ".ppt", ".doc", ".docx", ".mp4", ".mov", ".txt", ".xml", ".xlsb", ".html", ".csv"];
                      let fileType = fileExtensions.some(ext => name.toLowerCase().endsWith(ext)) ? "file" : "folder";
                      return {
                        folder_id: fullPathId[index],
                        folder_name: name,
                        file_type: fileType
                      };
                    });
                    datafolder["file_location"].pop();
                    if (datafolder["file_location"].length <= 3) {
                      datafolder["display_file_location"] = datafolder["file_location"];
                    } else {
                      datafolder["display_file_location"] = [
                        datafolder["file_location"][0], {
                          folder_id: '',
                          folder_name: '...',
                          file_type: ''
                        }, 
                        datafolder["file_location"][datafolder["file_location"].length - 1]
                      ];
                    }
                    datafolder["is_expand_file_location"] = false;

                    datafolder["file_status"] = response.data.data[i]["status"];
                    datafolder["file_owner_eng"] = response.data.data[i]["name_eng"];
                    datafolder["file_owner_th"] = response.data.data[i]["name_th"];
                    datafolder["file_linkshare"] = "";
                    datafolder["file_lastdtm"] = response.data.data[i]["last_dtm"];
                    datafolder["file_date"] = "";
                    datafolder["file_permission_2"] = response.data.data[i]["permission_account_v2"];
                    datafolder["file_parent_folder_id"] = response.data.data[i]["folder_id"];
                    // if ("permission_account" in response.data.data) {
                    datafolder["file_permission"] = response.data.data[i]["permission_account"];
                    // } else {
                    //   datafolder["file_permission"] = {
                    //     admin_data: "False",
                    //     delete: "False",
                    //     download: "False",
                    //     edit: "False",
                    //     upload: "False",
                    //     view_only: "True",
                    //   };
                    // }

                    datafolder["file_permission_department_setting"] = response.data.data[i]["permission_department_setting"];
                    datafolder["file_permission_setting"] = response.data.data[i]["permission_setting"];
                    datafolder["permission_business_setting"] = response.data.data[i]["permission_business_setting"];
                    datafolder["file_status_share"] = response.data.data[i]["status_share"];
                    datafolder["file_status_sharelink"] = response.data.data[i]["status_share_link"];
                    datafolder["file_type"] = response.data.data[i]["data_type"];
                    datafolder["data_type"] = typefile[typefile.length - 1];
                    datafolder["status_updating"] = response.data.data[i]["status_updating"];
                    datafolder["file_department"] = "";
                    datafolder["file_icon"] = this.fn_setThumbnailIcon(typefile[typefile.length - 1]);
                    datafolder["status_updating"] = response.data.data[i]["status_updating"];
                    datafolder["status_lock_folder"] = response.data.data[i]["status_lock_folder"];
                    datafolder["status_lock_file"] = response.data.data[i]["status_lock_file"];
                    datafolder["status_lock_data"] = response.data.data[i]["status_lock_data"];
                    datafolder["file_thumbnail"] = "";
                    datafolder["file_thumbnail_icon"] = this.fn_setThumbnailIcon(typefile[typefile.length - 1]);
                    datafolder["editing_status"] = response.data.data[i]["editing_status"];
                    datafolder["update_by"] = response.data.data[i]["update_by"];
                    datafolder["last_update_name"] = response.data.data[i]["last_update_name"];
                    datafolder["version_tag"] = response.data.data[i]["version_tag"];
                    datafolder["tag_id"] = response.data.data[i]["tag_id"];
                    datafolder["hashtag"] = response.data.data[i]["hashtag"];
                    datafolder["status_expired"] = response.data.data[i]["status_expired"];
                    datafolder["expiration_time"] = response.data.data[i]["expiration_time"];
                    datafolder["warning_expiration_time"] = response.data.data[i]["warning_expiration_time"];
                    datafolder["status_hilightword"] = true
                    this.listdatarootfile.push(datafolder);
                    if (i+1 === response.data.data.length) {
                      let count_file = this.listdatarootfile.filter(item => item.file_type !== "folder").length;
                      let count_folder = this.listdatarootfile.filter(item => item.file_type === "folder").length
                      this.totaldata['totalfolder'] = count_folder;
                      this.totaldata['totalfile'] = count_file;
                      this.totaldata['totalall'] = this.listdatarootfile.length;
                    }
                    // this.rootfile.push(datafolder);
                    // this.buff.push(datafolder);
                
                  
                }
                // this.index_loop = 0;
                // this.num_count = 30;
                this.looplistdatarootfile();

                // for (let index = 0; index < this.rootfile.length; index++) {
                //   const element = this.rootfile[index];
                //   element.file_thumbnail = "";
                //   let typeFile = element.file_name.split(".");
                //   await this.fn_loadthumbnail(element.file_id, typeFile[typeFile.length - 1],element.file_size, element.status_lock_data);
                  
                //   if(this.rootfile[index].file_owner_th === '' || this.rootfile[index].file_owner_eng === ''){
                //     await this.get_name_owner(this.rootfile[index])
                //   }
                // }
                // console.log("this.count_data_adv_",this.count_data_adv_);
                // this.disablescrolling_advancedsearch = this.count_data_adv_ <= this.rootfile.length;
                // console.log("this.disablescrolling_advancedsearch",this.disablescrolling_advancedsearch);

              } else {
                console.log("ERR");
                this.disablescrolling_advancedsearch = true;
                this.loaddataprogress = false;
                this.isAdvancedSearch = false;
              }
            });
        }
      }
      // else{
      //   // this.pagee++
      //   console.log("เข้า2");
      //   this.disablescrolling_advancedsearch = false;
      //   // this.loaddataprogress = false;
      //   let _param2_ = sessionStorage.getItem("advancedsearch_new");
      //   let _param2 = JSON.parse(_param2_);
      //   this.data_search = _param2;
      //   console.log("_param2222",this.data_search);
      //   if (this.path_folder_split[1] === "directorysharefile") {
      //     payload = {
      //       account_id: this.dataAccountId,
      //       cre_dtm: this.data_search.cre_dtm,
      //       to_dtm: this.data_search.to_dtm,
      //       type_search: this.data_search.type_search,
      //       name: this.data_search.name,
      //       cctv:this.data_search.cctv,
      //       folder_id: this.data_search.folder_id,
      //       dept_id: this.data_search.dept_id,
      //       limit: cal_limit,
      //       offset: cal_offset,
      //     };
      //     console.log("fake payload", payload);
      //     // this.processdelete = true;
      //     let auth = await gbfGenerate.generateToken();
      //     this.axios
      //       .post(
      //         process.env.VUE_APP_SERVICE_DOWNLOAD_API +
      //           `/api/shared/advanced_search`,
      //         payload,
      //         {
      //           headers: { Authorization: auth.code },
      //         }
      //       )
      //      .then(async (response) => {
      //         console.log("advance search", response.data);
      //         if (response.data.status === "OK") {
      //           this.loaddataprogress = false;
      //           // this.fn_advancesearch_loop(response);
      //           for(let i=0; i < response.data.data.length; i++){
      //             let datafolder = {};
      //               let typefile = response.data.data[i]["type"] === 'folder' ? "folder" : response.data.data[i]["name"].split(".") ;
      //               datafolder["account_reciever"] = "";
      //               datafolder["account_sender"] = this.dataAccountId;
      //               if ("file_id" in response.data.data) {
      //                 datafolder["file_id"] = response.data.data[i]["file_id"];
      //               } else {
      //                 datafolder["file_id"] = response.data.data[i]["id"];
      //               }
      //               datafolder["file_name"] = response.data.data[i]["name"];
      //               datafolder["file_size"] = response.data.data[i]["size"];
      //               datafolder["file_status"] = response.data.data[i]["status"];
      //               datafolder["file_owner_eng"] = response.data.data[i]["name_eng"];
      //               datafolder["file_owner_th"] = response.data.data[i]["name_th"];
      //               datafolder["file_linkshare"] = "";
      //               datafolder["file_lastdtm"] = response.data.data[i]["last_dtm"];
      //               datafolder["file_date"] = "";
      //               datafolder["file_permission_2"] = response.data.data[i]["permission_account_v2"];
      //               datafolder["file_parent_folder_id"] = response.data.data[i]["folder_id"];
      //               if ("permission_account" in response.data.data) {
      //                 datafolder["file_permission"] = response.data.data[i]["permission_account"];
      //               } else {
      //                 datafolder["file_permission"] = {
      //                   admin_data: "False",
      //                   delete: "False",
      //                   download: "False",
      //                   edit: "False",
      //                   upload: "False",
      //                   view_only: "True",
      //                 };
      //               }

      //               datafolder["file_permission_department_setting"] = response.data.data[i]["permission_department_setting"];
      //               datafolder["file_permission_setting"] = response.data.data[i]["permission_setting"];
      //               datafolder["permission_business_setting"] = response.data.data[i]["permission_business_setting"];
      //               datafolder["file_status_share"] = response.data.data[i]["status_share"];
      //               datafolder["file_status_sharelink"] = response.data.data[i]["status_share_link"];
      //               datafolder["file_type"] = response.data.data[i]["data_type"];
      //               datafolder["data_type"] = typefile[typefile.length - 1];
      //               datafolder["status_updating"] = response.data.data[i]["status_updating"];
      //               datafolder["file_department"] = "";
      //               datafolder["file_icon"] = this.fn_setThumbnailIcon(typefile[typefile.length - 1]);
      //               datafolder["status_updating"] = response.data.data[i]["status_updating"];
      //               datafolder["status_lock_folder"] = response.data.data[i]["status_lock_folder"];
      //               datafolder["status_lock_file"] = response.data.data[i]["status_lock_file"];
      //               datafolder["status_lock_data"] = response.data.data[i]["status_lock_data"];
      //               datafolder["file_thumbnail"] = "";
      //               datafolder["file_thumbnail_icon"] = this.fn_setThumbnailIcon(typefile[typefile.length - 1]);
      //               datafolder["editing_status"] = response.data.data[i]["editing_status"];
      //               datafolder["update_by"] = response.data.data[i]["update_by"];
      //               datafolder["last_update_name"] = response.data.data[i]["last_update_name"];
      //               datafolder["version_tag"] = response.data.data[i]["version_tag"];
      //               datafolder["tag_id"] = response.data.data[i]["tag_id"];
      //               datafolder["hashtag"] = response.data.data[i]["hashtag"];
      //               datafolder["status_expired"] = response.data.data[i]["status_expired"];
      //               datafolder["expiration_time"] = response.data.data[i]["expiration_time"];
      //               datafolder["warning_expiration_time"] = response.data.data[i]["warning_expiration_time"];

      //               this.rootfile.push(datafolder);
      //               this.buff.push(datafolder);
                  
      //           }
      //           for (let index = 0; index < this.rootfile.length; index++) {
      //             const element = this.rootfile[index];
      //             element.file_thumbnail = "";
      //             let typeFile = element.file_name.split(".");
      //             await this.fn_loadthumbnail(element.file_id, typeFile[typeFile.length - 1],element.file_size, element.status_lock_data);
                  
      //             if(this.rootfile[index].file_owner_th === '' || this.rootfile[index].file_owner_eng === ''){
      //               await this.get_name_owner(this.rootfile[index])
      //             }
      //           }
      //           console.log("this.count_data_adv_",this.count_data_adv_);
      //           this.disablescrolling_advancedsearch = this.count_data_adv_ <= this.rootfile.length;
      //           console.log("this.disablescrolling_advancedsearch",this.disablescrolling_advancedsearch);
      //           // this.processloader = true;
      //           // this.heardertextsearch = true;
      //           // this.disablescrolling = true;
      //           // this.disablescrollfilter = true;
      //           // this.disablescrolling_advancedsearch = true;
      //           // this.load_advanced = false;
      //         } else {
      //           console.log("ERR");
      //           // this.processloader = true;
      //           // this.heardertextsearch = true;
      //           // this.disablescrolling = true;
      //           // this.disablescrollfilter = true;
      //           this.disablescrolling_advancedsearch = true;
      //           this.loaddataprogress = false;
      //           // this.load_advanced = false;
      //           // this.$emit("advancesearch", response);
      //         }
          
      //       });
      //   } else {
      //     payload = {
      //       account_id: this.dataAccountId,
      //       cre_dtm: this.data_search.cre_dtm,
      //       to_dtm: this.data_search.to_dtm,
      //       type_search: this.data_search.type_search,
      //       name: this.data_search.name,
      //       cctv:this.data_search.cctv,
      //       folder_id: this.data_search.folder_id,
      //       dept_id: this.data_search.dept_id,
      //       limit: cal_limit,
      //       offset: cal_offset,
      //     };
      //     console.log("fake payload", payload);
      //     // this.processdelete = true;
      //     let auth = await gbfGenerate.generateToken();
      //     this.axios
      //       .post(
      //         process.env.VUE_APP_SERVICE_DOWNLOAD_API +
      //           `/api/v2/advanced_search`,
      //         payload,
      //         {
      //           headers: { Authorization: auth.code },
      //         }
      //       )
      //       .then(async (response) => {
      //         console.log("advance search", response.data.data);
      //         if (response.data.status === "OK") {
      //           this.loaddataprogress = false;
      //           // this.fn_advancesearch_loop(response);
      //           for(let i=0; i < response.data.data.length; i++){
      //             let datafolder = {};
      //             let typefile = response.data.data[i]["type"] === 'folder' ? "folder" : response.data.data[i]["name"].split(".") ;
      //               datafolder["account_reciever"] = "";
      //               datafolder["account_sender"] = this.dataAccountId;
      //               if ("file_id" in response.data.data) {
      //                 datafolder["file_id"] = response.data.data[i]["file_id"];
      //               } else {
      //                 datafolder["file_id"] = response.data.data[i]["id"];
      //               }
      //               datafolder["file_name"] = response.data.data[i]["name"];
      //               datafolder["file_size"] = response.data.data[i]["size"];
      //               datafolder["file_status"] = response.data.data[i]["status"];
      //               datafolder["file_owner_eng"] = response.data.data[i]["name_eng"];
      //               datafolder["file_owner_th"] = response.data.data[i]["name_th"];
      //               datafolder["file_linkshare"] = "";
      //               datafolder["file_lastdtm"] = response.data.data[i]["last_dtm"];
      //               datafolder["file_date"] = "";
      //               datafolder["file_permission_2"] = response.data.data[i]["permission_account_v2"];
      //               datafolder["file_parent_folder_id"] = response.data.data[i]["folder_id"];
      //               if ("permission_account" in response.data.data) {
      //                 datafolder["file_permission"] = response.data.data[i]["permission_account"];
      //               } else {
      //                 datafolder["file_permission"] = {
      //                   admin_data: "False",
      //                   delete: "False",
      //                   download: "False",
      //                   edit: "False",
      //                   upload: "False",
      //                   view_only: "True",
      //                 };
      //               }

      //               datafolder["file_permission_department_setting"] = response.data.data[i]["permission_department_setting"];
      //               datafolder["file_permission_setting"] = response.data.data[i]["permission_setting"];
      //               datafolder["permission_business_setting"] = response.data.data[i]["permission_business_setting"];
      //               datafolder["file_status_share"] = response.data.data[i]["status_share"];
      //               datafolder["file_status_sharelink"] = response.data.data[i]["status_share_link"];
      //               datafolder["file_type"] = response.data.data[i]["data_type"];
      //               datafolder["data_type"] = typefile[typefile.length - 1];
      //               datafolder["status_updating"] = response.data.data[i]["status_updating"];
      //               datafolder["file_department"] = "";
      //               datafolder["file_icon"] = this.fn_setThumbnailIcon(typefile[typefile.length - 1]);
      //               datafolder["status_updating"] = response.data.data[i]["status_updating"];
      //               datafolder["status_lock_folder"] = response.data.data[i]["status_lock_folder"];
      //               datafolder["status_lock_file"] = response.data.data[i]["status_lock_file"];
      //               datafolder["status_lock_data"] = response.data.data[i]["status_lock_data"];
      //               datafolder["file_thumbnail"] = "";
      //               datafolder["file_thumbnail_icon"] = this.fn_setThumbnailIcon(typefile[typefile.length - 1]);
      //               datafolder["editing_status"] = response.data.data[i]["editing_status"];
      //               datafolder["update_by"] = response.data.data[i]["update_by"];
      //               datafolder["last_update_name"] = response.data.data[i]["last_update_name"];
      //               datafolder["version_tag"] = response.data.data[i]["version_tag"];
      //               datafolder["tag_id"] = response.data.data[i]["tag_id"];
      //               datafolder["hashtag"] = response.data.data[i]["hashtag"];
      //               datafolder["status_expired"] = response.data.data[i]["status_expired"];
      //               datafolder["expiration_time"] = response.data.data[i]["expiration_time"];
      //               datafolder["warning_expiration_time"] = response.data.data[i]["warning_expiration_time"];

      //               this.rootfile.push(datafolder);
      //               this.buff.push(datafolder);
                  
      //           }

      //           for (let index = 0; index < this.rootfile.length; index++) {
      //             const element = this.rootfile[index];
      //             element.file_thumbnail = "";
      //             let typeFile = element.file_name.split(".");
      //             await this.fn_loadthumbnail(element.file_id, typeFile[typeFile.length - 1],element.file_size, element.status_lock_data);
                  
      //             if(this.rootfile[index].file_owner_th === '' || this.rootfile[index].file_owner_eng === ''){
      //               await this.get_name_owner(this.rootfile[index])
      //             }
      //           }
      //           console.log("this.count_data_adv_",this.count_data_adv_);
      //           this.disablescrolling_advancedsearch = this.count_data_adv_ <= this.rootfile.length;
      //           console.log("this.disablescrolling_advancedsearch",this.disablescrolling_advancedsearch);
      //           console.log("this.rootfile",this.rootfile);
      //           // this.processloader = true;
      //           // this.heardertextsearch = true;
      //           // this.disablescrolling = true;
      //           // this.disablescrollfilter = true;
      //           // this.disablescrolling_advancedsearch = true;
      //           // this.load_advanced = false;
      //         } else {
      //           console.log("ERR");
      //           // this.processloader = true;
      //           // this.heardertextsearch = true;
      //           // this.disablescrolling = true;
      //           // this.disablescrollfilter = true;
      //           this.disablescrolling_advancedsearch = true;
      //           this.loaddataprogress = false;
      //           // this.load_advanced = false;
      //         }
      //       });
      //   }
      // }
    },
  //   async fn_advancesearch_new_(_param){
  //     this.processloader = true;
  //     this.heardertextsearch = true;
  //     this.disablescrolling = true;
  //     this.disablescrollfilter = true;
  //     this.disablescrolling_advancedsearch = false;
  //     this.load_advanced = true;
  //    // console.log("rot", this.$router.currentRoute.path.split("/"));
  //    this.path_folder_split = this.$router.currentRoute.path.split("/");
  //    this.path_folder = "";
  //    if (this.path_folder_split[1] === "directory") {
  //      this.path_folder = this.path_folder_split[2];
  //    } else if (this.path_folder_split[1] === "directorysharefile") {
  //      this.path_folder = this.path_folder_split[2];
  //    } else {
  //      if (this.dataAccountActive["type"] === "Business") {
  //        this.path_folder = this.dataAccountActive["business_info"][
  //          "my_folder_id"
  //        ];
  //      } else {
  //        this.path_folder = this.dataAccountActive["directory_id"];
  //      }
  //    }
  //    let payload;
  //    let cal_limit = this.size;
  //    let cal_offset = this.size * (this.pagee++ - 1);

  //    console.log("page",this.page);
  //    console.log("cal_offset",this.size * (this.pagee++ - 1));
  //   //  if( cal_offset == 0){
  //   //    console.log("เข้า1");
  //   //    const data_param = JSON.stringify(_param);
  //   //    sessionStorage.setItem("advancesearch_new", data_param);
  //   //    // VueCookies.set("advancesearch_new",data_param)
  //   //    if (this.path_folder_split[1] === "directorysharefile") {
  //   //      payload = {
  //   //        account_id: this.dataAccountId,
  //   //        cre_dtm: _param.cre_dtm,
  //   //        to_dtm: _param.to_dtm,
  //   //        type_search: _param.type_search,
  //   //        name: _param.name,
  //   //        cctv:_param.cctv,
  //   //        folder_id: _param.folder_id,
  //   //        dept_id: _param.dept_id,
  //   //        limit: cal_limit,
  //   //        offset: cal_offset,
  //   //      };
  //   //      console.log("fake payload", payload);
  //   //      // this.processdelete = true;
  //   //      let auth = await gbfGenerate.generateToken();
  //   //      this.axios
  //   //        .post(
  //   //          process.env.VUE_APP_SERVICE_DOWNLOAD_API +
  //   //            `/api/shared/advanced_search`,
  //   //          payload,
  //   //          {
  //   //            headers: { Authorization: auth.code },
  //   //          }
  //   //        )
  //   //        .then((response) => {
  //   //          console.log("advance search", response.data);
  //   //          if (response.data.status === "OK") {
  //   //              this.count_data_adv_ = response.data.total;
  //   //            this.fn_advancesearch(response);
  //   //            // this.count_data_adv = response.data.data.total;
  //   //            // console.log("this.count_data_adv",this.count_data_adv);
  //   //            // this.$emit("count_data_ad", this.count_data_adv);
  //   //            // this.$emit("advancesearch", response);
  //   //          } else {
  //   //            console.log("ERR");
  //   //            // this.$emit("advancesearch", response);
  //   //          }
         
  //   //        });
  //   //    } else {
  //   //      payload = {
  //   //        account_id: this.dataAccountId,
  //   //        cre_dtm: _param.cre_dtm,
  //   //        to_dtm: _param.to_dtm,
  //   //        type_search: _param.type_search,
  //   //        name: _param.name,
  //   //        cctv:_param.cctv,
  //   //        folder_id: _param.folder_id,
  //   //        dept_id: _param.dept_id,
  //   //        limit: cal_limit,
  //   //        offset: cal_offset,
  //   //      };
  //   //      console.log("fake payload", payload);
  //   //      // this.processdelete = true;
  //   //      let auth = await gbfGenerate.generateToken();
  //   //      this.axios
  //   //        .post(
  //   //          process.env.VUE_APP_SERVICE_DOWNLOAD_API +
  //   //            `/api/v2/advanced_search`,
  //   //          payload,
  //   //          {
  //   //            headers: { Authorization: auth.code },
  //   //          }
  //   //        )
  //   //        .then((response) => {
  //   //          console.log("advance search", response.data);
  //   //          if (response.data.status === "OK") {
  //   //            this.count_data_adv_ = response.data.total;
  //   //            this.fn_advancesearch(response);
  //   //            // this.count_data_adv = response.data.total;
  //   //            // console.log("this.count_data_adv",this.count_data_adv);
  //   //            // this.$emit("count_data_ad", this.count_data_adv);
  //   //            // this.$emit("advancesearch", response);
  //   //          } else {
  //   //            console.log("ERR");
  //   //            // this.$emit("advancesearch", response);
  //   //          }
  //   //          // this.advancesearch = false;
  //   //          // this.processdelete = false;
  //   //        });
  //   //    }
  //   //  }else{
  //      console.log("เข้า22");
  //      let _param2_ = sessionStorage.getItem("advancesearch_new");
  //      // let _param2_ = VueCookies.get("advancesearch_new")
  //      let _param2 = JSON.parse(_param2_);
  //      if (this.path_folder_split[1] === "directorysharefile") {
  //        payload = {
  //          account_id: this.dataAccountId,
  //          cre_dtm: _param2.cre_dtm,
  //          to_dtm: _param2.to_dtm,
  //          type_search: _param2.type_search,
  //          name: _param2.name,
  //          cctv:_param2.cctv,
  //          folder_id: _param2.folder_id,
  //          dept_id: _param2.dept_id,
  //          limit: cal_limit,
  //          offset: cal_offset,
  //        };
  //        console.log("fake payload", payload);
  //        // this.processdelete = true;
  //        let auth = await gbfGenerate.generateToken();
  //        this.axios
  //          .post(
  //            process.env.VUE_APP_SERVICE_DOWNLOAD_API +
  //              `/api/shared/advanced_search`,
  //            payload,
  //            {
  //              headers: { Authorization: auth.code },
  //            }
  //          )
  //          .then((response) => {
  //            console.log("advance search", response.data);
  //            if (response.data.status === "OK") {
  //              this.fn_advancesearch_loop(response);
  //              this.processloader = true;
  //               this.heardertextsearch = true;
  //               this.disablescrolling = true;
  //               this.disablescrollfilter = true;
  //               this.disablescrolling_advancedsearch = true;
  //               this.load_advanced = false;
  //            } else {
  //              console.log("ERR");
  //              this.processloader = true;
  //               this.heardertextsearch = true;
  //               this.disablescrolling = true;
  //               this.disablescrollfilter = true;
  //               this.disablescrolling_advancedsearch = true;
  //               this.load_advanced = false;
  //              // this.$emit("advancesearch", response);
  //            }
         
  //          });
  //      } else {
  //        payload = {
  //          account_id: this.dataAccountId,
  //          cre_dtm: _param2.cre_dtm,
  //          to_dtm: _param2.to_dtm,
  //          type_search: _param2.type_search,
  //          name: _param2.name,
  //          cctv:_param2.cctv,
  //          folder_id: _param2.folder_id,
  //          dept_id: _param2.dept_id,
  //          limit: cal_limit,
  //          offset: cal_offset,
  //        };
  //        console.log("fake payload", payload);
  //        // this.processdelete = true;
  //        let auth = await gbfGenerate.generateToken();
  //        this.axios
  //          .post(
  //            process.env.VUE_APP_SERVICE_DOWNLOAD_API +
  //              `/api/v2/advanced_search`,
  //            payload,
  //            {
  //              headers: { Authorization: auth.code },
  //            }
  //          )
  //          .then((response) => {
  //            console.log("advance search", response.data);
  //            if (response.data.status === "OK") {
  //              this.fn_advancesearch_loop(response);
  //              this.processloader = true;
  //               this.heardertextsearch = true;
  //               this.disablescrolling = true;
  //               this.disablescrollfilter = true;
  //               this.disablescrolling_advancedsearch = true;
  //               this.load_advanced = false;
  //            } else {
  //              console.log("ERR");
  //              this.processloader = true;
  //               this.heardertextsearch = true;
  //               this.disablescrolling = true;
  //               this.disablescrollfilter = true;
  //               this.disablescrolling_advancedsearch = true;
  //               this.load_advanced = false;
  //              // this.$emit("advancesearch", response);
  //            }
  //            // this.advancesearch = false;
  //            // this.processdelete = false;
  //          });
  //     //  }
  //    }
  //  },
    looplistdatarootfile(){
      this.loaddataprogress = false;
      // console.log('this.index_loop1',this.index_loop);
      if (this.changeUI) {
        this.size = 30;
      }
      let cal_limit = this.size;
      let cal_offset = this.rootfile.filter((item) => { 
        return item.file_status !== 'M';
      }).length;

      let count_push = 0;
      for(let i = cal_offset; i < this.count_data_adv_; i++){
        if (count_push >= cal_limit) break;

        this.rootfile.push(this.listdatarootfile[i])
        this.buff.push(this.listdatarootfile[i])
        const element = this.rootfile[i];
            element.file_thumbnail = "";
            let typeFile = element.file_name.split(".");
            this.fn_loadthumbnail(element.file_id, typeFile[typeFile.length - 1],element.file_size, element.status_lock_data);
        if(this.rootfile[i].file_owner_th === '' || this.rootfile[i].file_owner_eng === ''){
          this.get_name_owner(this.rootfile[i])
        }
        count_push += 1;
      }
      console.log('this.rootfile',this.rootfile);

      // if(this.num_count < this.count_data_adv_ ){
      //   this.num_count += 30;
      // }
      // for (let index = 0; index < this.rootfile.length; index++) {
      // const element = this.rootfile[index];
      // element.file_thumbnail = "";
      // let typeFile = element.file_name.split(".");
      // await this.fn_loadthumbnail(element.file_id, typeFile[typeFile.length - 1],element.file_size, element.status_lock_data);
      
      // if(this.rootfile[index].file_owner_th === '' || this.rootfile[index].file_owner_eng === ''){
      //   await this.get_name_owner(this.rootfile[index])
      // }
      // }
       this.disablescrolling_advancedsearch = this.count_data_adv_ <= this.rootfile.length;
    },
    async loadfolder() {
      console.log("testdirect");
      var payload;
      this.loaddataprogress = true;
      this.rootfile = [];
      this.filemultipledownload = [];
      this.checkedList = [];
      this.buff = [];
      if (this.dataAccountActive["type"] === "Business") {
        if (this.$route.params.id === this.dataAccountActive["business_info"]["my_folder_id"]) {
          var a;
          for (a = 0; a < this.dataAccountActive["directory_business"].length; a++) {
            let datafolder = {};
            datafolder["file_id"] = this.dataAccountActive["directory_business"][a]["department_folder_id"];
            datafolder["file_name"] = this.dataAccountActive["directory_business"][a]["department_name"];
            datafolder["file_size"] = "-";
            datafolder["file_department"] = this.dataAccountActive["directory_business"][a]["department_id"];
            datafolder["file_lastdtm"] = "-";
            datafolder["file_owner_eng"] = "-";
            datafolder["file_owner_th"] = "-";
            datafolder["file_status"] = "M";
            datafolder["file_type"] = "folder";
            datafolder["file_icon"] = ["mdi-folder-heart", "green"];
            datafolder["status_updating"] = "Done";

            this.rootfile.push(datafolder);
            this.buff.push(datafolder);
            this.filestatus = datafolder["file_status"];
          }
        }
        console.log("frootfileolderbusiness", this.rootfile);

        if (this.clickSort === true) {
          this.rootfile.push(this.sortFile(this.rootfile));
        }
        let checkPersonalBusiness = this.navigate.findIndex(
          (r) => r.text[1] === this.dataAccountActive["business_info"]["my_folder_id"]
        );
        if (this.$route.params.id === this.dataAccountActive["business_info"]["my_folder_id"]) {
          payload = {
            user_id: this.dataUsername,
            account_id: this.dataAccountId, //apiอันเก่าไม่ใช้
            folder_id: this.$route.params.id,
            status_folder: "",
          };
        } else if (checkPersonalBusiness !== -1) {
          payload = {
            user_id: this.dataUsername,
            account_id: this.dataAccountId, //apiอันเก่าไม่ใช้
            folder_id: this.$route.params.id,
            status_folder: "",
          };
        } else {
          payload = {
            user_id: this.dataUsername,
            account_id: this.dataAccountId,
            folder_id: this.$route.params.id,
            status_folder: "",
          };
        }
      } else {
        var payload = {
          user_id: this.dataUsername,
          account_id: this.dataAccountId,
          folder_id: this.$route.params.id,
          status_folder: "",
        };
      }
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
      });
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(process.env.VUE_APP_SERVICE_SELECT_FOLDER + "/api/v2/search_folder_byid", payload, {
          headers: { Authorization: auth.code },
        })
        .then((response) => {
          if (response.data.status === "OK") {
            if (response.data.your_folder[0]["status_lock_folder"] === "Y") {
              this.opendialog_checklog = true;
              let datafolder = {};
              this.datafolder[""] = response.data.your_folder[0]["id"];
            }
            this.permission_currentfolder = response.data.your_folder[0]["permission_account"];
            this.permission_currentfolder_v2 = response.data.your_folder[0]["permission_account_v2"];
            // console.log("data",response.data.folder.length);
            this.aspectData = [
              {
                aspect: response.data.your_folder[0]["aspect"],
                aspect_key: response.data.your_folder[0]["aspect_key"],
              },
            ];
            console.log("api aspect data", this.aspectData);

            let checknavigate = this.navigate.findIndex((y) => y.text[1] === this.$route.params.id);
            if (checknavigate === -1) {
              if (this.dataAccountActive["type"] === "Business") {
                if (response.data.your_folder[0]["folder_id"] === this.dataAccountActive["business_info"]["my_folder_id"]) {
                  this.navigate.push({
                    text: [
                      this.$store.getters.dataTypeusercurrent(this.$store.state.account_active["type"], this.$t("en")),
                      response.data.your_folder[0]["folder_id"],
                      this.$store.getters.dataTypeusercurrent(this.$store.state.account_active["type"], this.$t("th"))
                    ],
                    disabled: false,
                  });
                } else {
                  this.navigate.push({
                    text: [response.data.your_folder[0]["folder_name"], response.data.your_folder[0]["folder_id"]],
                    disabled: false,
                  });
                }
              } else {
                this.navigate.push({
                  text: [response.data.your_folder[0]["folder_name"], response.data.your_folder[0]["folder_id"]],
                  disabled: false,
                });
              }
            } else {
              let datanavigate = this.navigate;
              let i;
              this.navigate = [];
              for (i = 0; i <= checknavigate; i++) {
                this.navigate.push(datanavigate[i]);
              }
            }
            // console.log(
            //   "navigate------------------------------",
            //   this.navigate
            // );
            this.foldername = response.data.your_folder[0]["folder_name"];
            this.folderid = response.data.your_folder[0]["folder_id"];
            this.system = response.data.your_folder[0]["system"];
            this.status_aspect_key = response.data.your_folder[0]["status_aspect_key"];
            this.folderid_yourfolder = response.data.your_folder[0]["folder_id"];
            console.log("this.system-----", this.system);
            console.log("this.status_aspect_key", this.status_aspect_key);
            if (response.data.your_folder[0]["system"] !== null && response.data.your_folder[0]["status_aspect_key"] === "Y") {
              localStorage.setItem("changeStoreUI", "false");
              localStorage.setItem("changeUI", "false");
              this.changeUI = false;
              this.getdoctype();
            }

            // this.rootfile = response.data.folder
            // if (response.data.folder.length) {
            //   this.page += 1;
            //   this.rootfile.push(...response.data.folder);
            //   $state.loaded();
            // } else {
            //   $state.complete();
            // }
            var i;
            for (i = 0; i < response.data.folder.length; i++) {
              let datafolder = {};
              datafolder["account_reciever"] = "";
              datafolder["account_sender"] = this.dataAccountId;
              datafolder["file_id"] = response.data.folder[i]["folder_id"];
              datafolder["file_name"] = response.data.folder[i]["folder_name"];
              datafolder["file_size"] = "-";
              datafolder["file_lastdtm"] = response.data.folder[i]["cre_dtm"] || "-";
              datafolder["file_date"] = "";
              datafolder["file_linkshare"] = response.data.folder[i]["link"];
              datafolder["file_status_share"] = response.data.folder[i]["status_share"];
              datafolder["file_status_sharelink"] = response.data.folder[i]["status_share_link"];
              datafolder["file_status"] = response.data.folder[i]["status"];
              datafolder["file_owner_eng"] = response.data.folder[i]["name_eng"];
              datafolder["file_owner_th"] = response.data.folder[i]["name_th"];
              datafolder["file_type"] = "folder";
              datafolder["file_department"] = "";
              // datafolder["file_icon"] = ["folder", "#FDD361"];
              datafolder["file_icon"] = ["folder", "#ffc107"];
              datafolder["file_parent_folder_id"] = response.data.folder[i]["parent_folder_id"];
              datafolder["file_permission"] =
                response.data.folder[i]["permission_setting"] === "True" ||
                response.data.folder[i]["permission_business_setting"] === "True"
                  ? response.data.folder[i]["permission_account"]
                  : response.data.your_folder[0]["permission_account"];
              datafolder["file_permission_department_setting"] =
                response.data.folder[i]["permission_setting"] === "True" ||
                response.data.folder[i]["permission_business_setting"] === "True"
                  ? response.data.folder[i]["permission_department_setting"]
                  : response.data.your_folder[0]["permission_department_setting"];
              datafolder["file_permission_setting"] =
                response.data.folder[i]["permission_setting"] === "True" ||
                response.data.folder[i]["permission_business_setting"] === "True"
                  ? response.data.folder[i]["permission_setting"]
                  : response.data.your_folder[0]["permission_setting"];
              datafolder["relation_id"] = response.data.folder[i]["relation_id"];
              datafolder["relation_name"] = response.data.folder[i]["relation_name"];
              datafolder["relation_status"] = response.data.folder[i]["relation_status"];
              datafolder["file_permission_2"] = response.data.folder[i]["permission_account_v2"];
              datafolder["status_updating"] = response.data.folder[i]["status_updating"];
              datafolder["editing_status"] = response.data.data[i]["editing_status"];
              if (
                (datafolder["file_permission_setting"] === "True" ||
                  datafolder["file_permission_department_setting"] === "True" ||
                  datafolder["permission_business_setting"] === "True") &&
                datafolder["file_permission"]["view_only"] === "False" &&
                datafolder["file_permission"]["edit"] === "False" &&
                datafolder["file_permission"]["download"] === "False"
              ) {
                continue;
              } else {
                if (this.dataAccountActive["type"] === "Business") {
                  if (("datafolder", datafolder["file_permission"].view_only === "True")) {
                    this.rootfile.push(datafolder);
                  }
                } else {
                  this.rootfile.push(datafolder);
                }
                this.buff.push(datafolder);
              }
            }
            // if (this.dataAccountActive["type"] === "Business") {
            //   if(datafolder["file_permission_department_setting"] === "True" || datafolder["file_permission_setting"] === "True"){
            //     if(datafolder["file_permission"].view_only !== "False" ){
            //        this.rootfile.push(datafolder);
            //     }

            //   }else{
            //     this.rootfile.push(datafolder);
            //   }
            // } else {
            //   this.rootfile.push(datafolder);
            //  }
            //  if (this.dataAccountActive["type"] === "Business") {
            //   if (
            //     ("datafolder",
            //     datafolder["file_permission"].view_only === "True")
            //   ) {
            //     // console.log("2", datafolder);

            //     this.rootfile.push(datafolder);
            //   }
            // } else {
            //   this.rootfile.push(datafolder);
            // }

            //   this.buff.push(datafolder);
            // }
            // console.log("rootfilefolder", this.rootfile);

            if (this.clickSort === true) {
              this.rootfile.push(this.sortFile(this.rootfile));
            }
            if (this.system !== null) {
              this.getdoctype();
            }
            this.loadfiles(this.$route.params.id, "", response.data.your_folder[0]);
            this.countfolder = response.data.folder.length;
          } else {
            this.loaddataprogress = false;
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.loaddataprogress = false;
          Toast.fire({
            icon: "error",
            // "ไม่สามารถเชื่อมต่อไปที่ ONE-BOX ได้ในขณะนี้"
            title: this.$t("toast.cannotconnectonebox"),
          });
        });
    },

    async loadfiles(folder, status, current_folder) {
      // console.log("cccccccc", current_folder);

      let payload = {};
      if (this.dataAccountActive["type"] === "Business") {
        let checkPersonalBusiness = this.navigate.findIndex(
          (r) => r.text[1] === this.dataAccountActive["business_info"]["my_folder_id"]
        );
        if (folder === this.dataAccountActive["business_info"]["my_folder_id"]) {
          payload = {
            user_id: this.dataUsername,
            account_id: this.dataAccountId, //apiเก่าไม่ใช้
            folder_id: folder,
            status: status,
          };
        } else if (checkPersonalBusiness !== -1) {
          payload = {
            user_id: this.dataUsername,
            account_id: this.dataAccountId, //apiเก่าไม่ใช้
            folder_id: folder,
            status: status,
          };
        } else {
          payload = {
            user_id: this.dataUsername,
            account_id: this.dataAccountId,
            folder_id: folder,
            status: status,
          };
        }
      } else {
        payload = {
          user_id: this.dataUsername,
          account_id: this.dataAccountId,
          folder_id: folder,
          status: status,
        };
      }
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(process.env.VUE_APP_SERVICE_SELECT_FILE + "/api/v2/search_file", payload, {
          headers: { Authorization: auth.code },
        })
        .then((response) => {
          // console.log(response);
          if (response.data.status === "OK") {
            console.log("response------", response);
            console.log(this.dataAccountId);
            // console.log("file",response.data.data.length);
            let i;
            let rawdata = response.data.data;
            for (i = 0; i < response.data.data.length; i++) {
              let datafolder = {};
              let typefile = rawdata[i]["filename"].split(".");
              datafolder["account_reciever"] = "";
              datafolder["account_sender"] = this.dataAccountId;
              datafolder["folder_id"] = response.data.data[i]["folder_id"];
              datafolder["file_id"] = rawdata[i]["id"];
              datafolder["file_name"] = rawdata[i]["filename"];
              datafolder["file_size"] = rawdata[i]["size_file"];
              datafolder["file_lastdtm"] = response.data.data[i]["last_dtm"] || "-";
              datafolder["file_date"] = "";
              datafolder["file_status"] = rawdata[i]["status_file"];
              datafolder["file_owner_eng"] = rawdata[i]["name_eng"];
              datafolder["file_owner_th"] = rawdata[i]["name_th"];
              datafolder["file_linkshare"] = rawdata[i]["link"];
              datafolder["file_status_share"] = rawdata[i]["status_share"];
              datafolder["file_status_sharelink"] = rawdata[i]["status_share_link"];
              datafolder["file_type"] = typefile[typefile.length - 1];
              datafolder["file_department"] = "";
              datafolder["file_parent_folder_id"] = rawdata[i]["parent_folder_id"];
              datafolder["file_permission"] =
                rawdata[i]["permission_setting"] === "True"
                  ? rawdata[i]["permission_account"]
                  : current_folder["permission_account"];
              datafolder["file_permission_2"] = rawdata[i]["permission_account_v2"];
              datafolder["file_permission_department_setting"] =
                rawdata[i]["permission_setting"] === "True"
                  ? rawdata[i]["permission_department_setting"]
                  : current_folder["permispermission_department_setting"] || rawdata[i]["permission_department_setting"];
              datafolder["file_permission_setting"] =
                rawdata[i]["permission_setting"] === "True"
                  ? rawdata[i]["permission_setting"]
                  : current_folder["permission_setting"] || rawdata[i]["permission_setting"];
              datafolder["status_updating"] = rawdata[i]["status_updating"];
              datafolder["file_icon"] = gb_setIconFiles.iconExtensionFilesOnly(typefile[typefile.length - 1]);
              datafolder["relation_id"] = rawdata[i]["relation_id"];
              datafolder["relation_name"] = rawdata[i]["relation_name"];
              datafolder["relation_status"] = rawdata[i]["relation_status"];
              datafolder["status_updating"] = rawdata[i]["status_updating"];
              if (
                (datafolder["file_permission_setting"] === "True" ||
                  datafolder["file_permission_department_setting"] === "True") &&
                datafolder["file_permission"]["view_only"] === "False" &&
                datafolder["file_permission"]["edit"] === "False" &&
                datafolder["file_permission"]["download"] === "False"
              ) {
                continue;
              } else {
                if (this.dataAccountActive["type"] === "Business") {
                  if (("datafolder", datafolder["file_permission"].view_only === "True")) {
                    this.rootfile.push(datafolder);
                  }
                } else {
                  this.rootfile.push(datafolder);
                }
                this.buff.push(datafolder);
              }
            }
            console.log("rootfilefile", this.rootfile);
            //  if (this.dataAccountActive["type"] === "Business") {
            //    if (
            //      ("datafolder",
            //      datafolder["file_permission"].view_only === "True")
            //    ) {
            //      // console.log("2", datafolder);

            //      this.rootfile.push(datafolder);
            //    }
            //  } else {
            //    this.rootfile.push(datafolder);
            //  }
            //   this.buff.push(datafolder);
            // }
            // console.log("file->", this.rootfile);

            if (this.clickSort === true) {
              this.rootfile.push(this.sortFile(this.rootfile));
            }
            this.countfile = response.data.data.length;
            // console.log(this.rootfile);
            this.loaddataprogress = false;
            this.loadingdata = false;
          } else {
            this.loadingdata = false;
            this.loaddataprogress = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.loaddataprogress = false;
          this.loadingdata = false;
          Toast.fire({
            icon: "error",
            // "ไม่สามารถเรียกไฟล์ได้ในขณะนี้"
            title: this.$t("toast.cannotopenfile"),
          });
        });
    },

    loadstorage() {
      this.$store.dispatch("check_storage", this.dataAccountId).then((res) => {});
    },

    backtopreventfolder() {
      if (this.navigate.length > 1) {
        this.navigate.pop();
        // console.log("navigate",this.navigate);
        // console.log("this.navigate.length",this.navigate.length);
        if (this.navigate.length === 1) {
          (this.opendialog_checklog = false), (this.loadingdata = true);
          (this.check_folder_department = false),
            console.log(this.navigate[0]["text"][1], this.dataAccountActive["directory_id"]);
          if (this.navigate[0]["text"][1] !== this.dataAccountActive["directory_id"]) {
            this.filemultipledownload = [];
            this.checkedList = [];
            // this.$router.replace({
            this.$router.push({
              path: "/directory/" + this.navigate[this.navigate.length - 1]["text"][1],
            });
            this.fn_loadfileandfolder_scrolling_child();
            this.parentfolder = this.$route.params.id;
          } else {
            this.rootfile = [];
            this.buff = [];
            // this.$router.replace("/mydrive");
            this.$router.push("/mydrive");
          }
        } else {
          (this.opendialog_checklog = false), (this.loadingdata = true);
          this.filemultipledownload = [];
          this.checkedList = [];
          // this.$router.replace({
          this.$router.push({
            path: "/directory/" + this.navigate[this.navigate.length - 1]["text"][1],
          });
          this.fn_loadfileandfolder_scrolling_child();
          this.parentfolder = this.$route.params.id;
        }
      }
    },

    gotofolder(_folderid) {
      if (!_folderid) {
        return Toast.fire({ icon: "error", title: this.$t("toast.folderPathInvalid") });
      }
      //console.log(this.navigate);
      if (_folderid === this.dataAccountActive["directory_id"]) {
        this.loadingdata = true;
        this.rootfile = [];
        this.buff = [];
        // this.$router.replace("/mydrive");
        this.$router.push("/mydrive");
        this.isAdvancedSearch = false;
      } else {
        this.filemultipledownload = [];
        this.checkedList = [];
        this.loadingdata = true;
        // this.$router.replace({ path: "/directory/" + _folderid });
        this.$router.push({ path: "/directory/" + _folderid });
        this.fn_loadfileandfolder_scrolling_child('', { cal_offset: 0 });
        this.parentfolder = this.$route.params.id;
        this.isAdvancedSearch = false;
      }
    },

    changepage() {
      this.loaddataprogress = true;
      setTimeout(() => {
        this.loaddataprogress = false;
      }, 1000);
    },

    // ดึงข้อมูลโฟลเดอร์และไฟล์ แบบ Scroll โดยจะดึงแบบ limit offset
    async fn_loadfileandfolder_scrolling() {
      console.log("fn_loadfileandfolder_scrolling");
      this.disablescrolling_advancedsearch = true;
      this.disablescrolling_smartsearch = true;
      this.disablescrollfilter = true;
      this.disablescrolling = false;
      this.loaddataprogress = true;
      let cal_limit = this.size;
      let cal_offset = this.rootfile.filter((item) => { 
        return item.file_status !== 'M';
      }).length;
      // let cal_offset = this.size * (this.page++ - 1);
      let payload;
      // console.log(cal_offset)
      if (cal_offset == 0) {
        this.rootfile = [];
        this.buff = [];
      }

      if (this.dataAccountActive["type"] === "Business") {
        if (cal_offset == 0) {
          let checkPersonalBusiness = this.navigate.findIndex(
            (r) => r.text[1] === this.dataAccountActive["business_info"]["my_folder_id"]
          );
          if (this.$route.params.id === this.dataAccountActive["business_info"]["my_folder_id"]) {
            // get Department folder
            for (var a = 0; a < this.dataAccountActive["directory_business"].length; a++) {
              let datafolder = {};
              datafolder["file_id"] = this.dataAccountActive["directory_business"][a]["department_folder_id"];
              datafolder["file_name"] = this.dataAccountActive["directory_business"][a]["department_name"];
              datafolder["file_size"] = "-";
              datafolder["file_department"] = this.dataAccountActive["directory_business"][a]["department_id"];
              datafolder["file_lastdtm"] = "-";
              datafolder["file_owner_eng"] = "-";
              datafolder["file_owner_th"] = "-";
              datafolder["file_status"] = "M";
              datafolder["file_type"] = "folder";
              datafolder["file_icon"] = ["mdi-folder-heart", "green"];
              datafolder["status_updating"] = "Done";
              datafolder["editing_status"] = "N";
              datafolder["last_update_name"] = "-";
              datafolder["file_status_sharelink"] = "N";
              datafolder["onlyofficeeditsecces"] = false;
              this.rootfile.push(datafolder);
              this.buff.push(datafolder);
              this.filestatus = datafolder["file_status"];
            }
            console.log("rootfileDepartment", this.rootfile);
            payload = {
              user_id: this.dataUsername,
              account_id: this.dataAccountId,
              folder_id: this.$route.params.id,
              status_file: "",
              status_folder: "",
              limit: cal_limit,
              offset: cal_offset,
              sort_by: this.sort_by,
              direction: this.direction
            };
          } else if (checkPersonalBusiness >= 0) {
            payload = {
              user_id: this.dataUsername,
              account_id: this.dataAccountId,
              folder_id: this.$route.params.id,
              status_file: "",
              status_folder: "",
              limit: cal_limit,
              offset: cal_offset,
              sort_by: this.sort_by,
              direction: this.direction
            };
          } else {
            payload = {
              user_id: this.dataUsername,
              account_id: this.dataAccountId,
              folder_id: this.$route.params.id,
              status_file: "",
              status_folder: "",
              limit: cal_limit,
              offset: cal_offset,
              sort_by: this.sort_by,
              direction: this.direction
            };
          }
        } else {
          payload = {
            user_id: this.dataUsername,
            account_id: this.dataAccountId,
            folder_id: this.$route.params.id,
            status_file: "",
            status_folder: "",
            limit: cal_limit,
            offset: cal_offset,
            sort_by: this.sort_by,
            direction: this.direction
          };
        }
      } else {
        payload = {
          user_id: this.dataUsername,
          account_id: this.dataAccountId,
          folder_id: this.$route.params.id,
          status_file: "",
          status_folder: "",
          limit: cal_limit,
          offset: cal_offset,
          sort_by: this.sort_by,
          direction: this.direction
        };
      }
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/select_files_and_folder", payload, {
          headers: { Authorization: auth.code },
        })
        .then(async (response) => {
          this.loaddataprogress = false;
          // ตรวจสอบ  Navigation Bar
          // let checknavigate = this.navigate.findIndex((y) => y.text[1] === this.$route.params.id);
          // if (checknavigate === -1) {
          //   if (
          //     this.dataAccountActive["type"] === "Business" &&
          //     response.data.your_folder[0]["id"] === this.dataAccountActive["business_info"]["my_folder_id"]
          //   ) {
          //     this.navigate.push({
          //       text: [
          //         this.$store.getters.dataTypeusercurrent(this.$store.state.account_active["type"], this.$t("default")),
          //         response.data.status === "OK" ? response.data.your_folder[0]["id"] : this.$route.params.id,
          //       ],
          //       disabled: false,
          //     });
          //   } else {
          //     this.navigate.push({
          //       text: [
          //         response.data.status === "OK" ? response.data.your_folder[0]["name"] : "-",
          //         response.data.status === "OK" ? response.data.your_folder[0]["id"] : this.$route.params.id,
          //       ],
          //       disabled: false,
          //     });
          //   }
          // } else {
          //   let datanavigate = this.navigate;
          //   let i;
          //   this.navigate = [];
          //   for (i = 0; i <= checknavigate; i++) {
          //     this.navigate.push(datanavigate[i]);
          //   }
          // }

          // if (cal_offset == 0) {
          //   // สร้าง Navigation ฺBar
          //   this.fn_setNavigationbar(true, response.data.your_folder[0])
          // }
          //console.log("folderdata_1",response.data.your_folder[0]["status_lock_folder"] === "Y");
          if (response.data.status === "OK") {
            this.fn_checkevaluate();
            // ตรวจสอบ  Navigation Bar
            if (cal_offset == 0) {
              this.fn_setNavigationbar(true, response.data.your_folder[0], this.$route.params.id);
            }

            // ตรวจสอบ  lock Folder
            if (cal_offset == 0 && response.data.your_folder[0]["status_lock_folder"] === "Y") {
              this.opendialog_checklog = true;
              let folderdata = {};
              folderdata["file_id"] = response.data.your_folder[0]["id"];
              folderdata["file_name"] = response.data.your_folder[0]["name"];
              this.newdata = folderdata;
            }

            this.foldername = response.data.your_folder[0]["name"];
            this.folderid = response.data.your_folder[0]["id"];
            this.system = response.data.your_folder[0]["system"];
            this.status_aspect_key = response.data.your_folder[0]["status_aspect_key"];
            this.folderid_yourfolder = response.data.your_folder[0]["id"];

            // ตรวจสอบ system และ status_aspect_key เพื่อเปลี่บนเป็น table ui
            if (response.data.your_folder[0]["system"] !== null && response.data.your_folder[0]["status_aspect_key"] === "Y") {
              localStorage.setItem("changeStoreUI", "false");
              localStorage.setItem("changeUI", "false");
              this.changeUI = false;
              this.getdoctype();
            }

            // File and Folder
            for (let i = 0; i < response.data.data.length; i++) {
              let dataFileAndFolder = {};
              let typefile = response.data.data[i].data_type === "folder" ? "folder" : response.data.data[i].name.split(".");
              let type = response.data.data[i].data_type;
              let yourfolder_path = response.data.your_folder[0].full_path_id.split("/");

              console.log("yourfolder_path", yourfolder_path[0]);
              dataFileAndFolder["account_reciever"] = "";
              dataFileAndFolder["account_sender"] = this.dataAccountId;
              dataFileAndFolder["account_id"] = response.data.data[i].account_id;
              dataFileAndFolder["countnum"] = response.data.data[i].countnum;
              dataFileAndFolder["file_createdtm"] = response.data.data[i].cre_dtm;
              dataFileAndFolder["folder_id"] = response.data.data[i].folder_id;
              dataFileAndFolder["file_id"] = response.data.data[i].id;
              dataFileAndFolder["file_lastdtm"] = response.data.data[i].last_dtm;
              dataFileAndFolder["file_linkshare"] = response.data.data[i].link;
              dataFileAndFolder["file_name"] = response.data.data[i].name;
              dataFileAndFolder["file_owner_eng"] = response.data.data[i].name_eng;
              dataFileAndFolder["file_owner_th"] = response.data.data[i].name_th;
              dataFileAndFolder["priority"] = response.data.data[i].priority;
              dataFileAndFolder["file_size"] = response.data.data[i].size;
              dataFileAndFolder["file_status"] = response.data.data[i].status;
              dataFileAndFolder["file_status_share"] = response.data.data[i].status_share;
              dataFileAndFolder["file_status_sharelink"] = response.data.data[i].status_share_link;
              dataFileAndFolder["system"] = response.data.data[i].system;
              dataFileAndFolder["type"] = response.data.data[i].data_type;
              // dataFileAndFolder["file_icon"] = gb_setIconFiles.iconExtensionFilesFolder(type, typefile[typefile.length - 1]);
              dataFileAndFolder["file_icon"] = this.fn_setThumbnailIcon(typefile[typefile.length - 1]);
              dataFileAndFolder["file_type"] = type === "folder" ? "folder" : typefile[typefile.length - 1];
              dataFileAndFolder["data_type"] = typefile[typefile.length - 1];
              dataFileAndFolder["user_id"] = response.data.data[i].user_id;
              dataFileAndFolder["starred_id"] = response.data.data[i].starred_id;
              dataFileAndFolder["file_department"] = "";
              dataFileAndFolder["file_parent_folder_id"] = response.data.data[i]["folder_id"];
              // dataFileAndFolder["file_permission"] = (response.data.data[i]["permission_setting"] === "True" || response.data.data[i]["permission_business_setting"] === "True") ? response.data.data[i]["permission_account"] : response.data.your_folder[0]["permission_account"];
              // dataFileAndFolder["file_permission_department_setting"] =(response.data.data[i]["permission_setting"] === "True" || response.data.data[i]["permission_business_setting"] === "True") ? response.data.data[i]["permission_department_setting"]  : response.data.your_folder[0]["permission_department_setting"];
              // dataFileAndFolder["file_permission_setting"] = (response.data.data[i]["permission_setting"] === "True" || response.data.data[i]["permission_business_setting"] === "True") ? response.data.data[i]["permission_setting"]  : response.data.your_folder[0]["permission_setting"];
              dataFileAndFolder["file_permission"] =
                this.dataAccountActive["type"] === "Business"
                  ? yourfolder_path[0] === this.dataAccountActive.directory_id
                    ? { admin_data: "False", delete: "True", download: "True", edit: "True", upload: "True", view_only: "True" }
                    : response.data.data[i].permission_account
                  : response.data.data[i].permission_account;
              console.log(dataFileAndFolder["file_permission"]["admin_data"]);
              dataFileAndFolder["permission_department_setting"] = response.data.data[i].permission_department_setting;
              dataFileAndFolder["permission_business_setting"] = response.data.data[i].permission_business_setting;
              dataFileAndFolder["permission_setting"] = response.data.data[i].permission_setting;
              dataFileAndFolder["file_permission_department_setting"] = response.data.data[i]["permission_department_setting"];
              dataFileAndFolder["file_permission_setting"] = response.data.data[i]["permission_setting"];
              dataFileAndFolder["permission_role_setting"] = response.data.data[i].permission_role_setting;

              dataFileAndFolder["relation_id"] = response.data.data[i]["relation_id"];
              dataFileAndFolder["relation_name"] = response.data.data[i]["relation_name"];
              dataFileAndFolder["relation_status"] = response.data.data[i]["relation_status"];
              dataFileAndFolder["file_permission_2"] = response.data.data[i]["permission_account_v2"];
              dataFileAndFolder["status_updating"] = response.data.data[i]["status_updating"];
              dataFileAndFolder["status_lock_folder"] = response.data.data[i]["status_lock_folder"];
              dataFileAndFolder["status_lock_data"] = response.data.data[i]["status_lock_data"];
              dataFileAndFolder["status_expired"] = response.data.data[i]["status_expired"];
              dataFileAndFolder["expiration_time"] = response.data.data[i]["expiration_time"];
              dataFileAndFolder["version_tag"] = response.data.data[i]["version_tag"];
              dataFileAndFolder["tag_id"] = response.data.data[i]["tag_id"];
              dataFileAndFolder["hashtag"] = response.data.data[i]["hashtag"];
              dataFileAndFolder["file_thumbnail"] = "";
              dataFileAndFolder["file_thumbnail_icon"] = this.fn_setThumbnailIcon(typefile[typefile.length - 1]);
              dataFileAndFolder["editing_status"] = response.data.data[i]["editing_status"];
              dataFileAndFolder["update_by"] = response.data.data[i]["update_by"];
              dataFileAndFolder["last_update_name"] = response.data.data[i]["last_update_name"];
              dataFileAndFolder["warning_expiration_time"] = response.data.data[i]["warning_expiration_time"];
              dataFileAndFolder["status_encrypt"] = response.data.data[i]["status_encrypt"];   
              dataFileAndFolder["last_view"] = response.data.data[i].last_view;           

              if (
                (dataFileAndFolder["file_permission_setting"] === "True" ||
                  dataFileAndFolder["file_permission_department_setting"] === "True" ||
                  dataFileAndFolder["permission_business_setting"] === "True") &&
                dataFileAndFolder["file_permission"]["view_only"] === "False" &&
                dataFileAndFolder["file_permission"]["edit"] === "False" &&
                dataFileAndFolder["file_permission"]["download"] === "False"
              ) {
                continue;
              } else {
                if (this.dataAccountActive["type"] === "Business") {
                  if (("dataFileAndFolder", dataFileAndFolder["file_permission"].view_only === "True")) {
                    this.rootfile.push(dataFileAndFolder);
                  }
                } else {
                  this.rootfile.push(dataFileAndFolder);
                }
                this.buff.push(dataFileAndFolder);
              }
              this.fn_loadthumbnail(response.data.data[i].id, typefile[typefile.length - 1],response.data.data[i].size, response.data.data[i].status_lock_data);
              // this.rootfile.push(dataFileAndFolder);
              //               this.rootfile.push(datafolder);
              // this.buff.push(datafolder);
              // console.log(this.rootfile);
            }
            this.sortCreDtmNewUpload()

            this.totaldata = {
              totalall: response.data.count,
              totalfile: response.data.count_file,
              totalfolder: response.data.count_folder,
            };
            this.permission_currentfolder = response.data.your_folder[0]["permission_account"];
            this.permission_currentfolder_v2 = response.data.your_folder[0]["permission_account_v2"];
            this.aspectData = [
              { aspect: response.data.your_folder[0]["aspect"], aspect_key: response.data.your_folder[0]["aspect_key"] },
            ];
            // this.aspectData[0].aspect = 'True'
            // this.aspectData[0].aspect_key.push({
            //   key_name: "condition",
            //   key_type: "condition"
            // })
            // console.log('aspect',this.aspectData[0].aspect);
            // console.log('aspect_key',this.aspectData[0].aspect_key);
            this.disablescrolling = this.totaldata["totalall"] <= this.rootfile.length;
            this.departmentid = response.data.your_folder[0]["department_id"];
            this.taxbox_status = response.data.your_folder[0]["taxbox_status"];
            console.log("this.departmentid", this.departmentid);
            // console.log("Newrootfile", this.newrootfile);

            if(VueCookies.get("check_alert_email") === "N"){
              this.opendialogalertstoragefull = true;
            }

            // if(this.taxbox_status === "Y"){
            //   console.log("taxbox ยิง api  etax");
            //   this.fn_PutTransactiontaxbox();
            // }else{
            //   console.log("taxbox ยิง api  etax falseeeeee");
            // }

            for(let y = 0; y < this.rootfile.length; y++){
              if(this.rootfile[y].file_owner_th === '' || this.rootfile[y].file_owner_eng === ''){
                await this.get_name_owner(this.rootfile[y])
              }
            }

          } else {
            this.disablescrolling = true;
            // ตรวจสอบ  Navigation Bar
            if (cal_offset == 0) {
              this.fn_setNavigationbar(false, []);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          // ตรวจสอบ  Navigation Bar
          // let checknavigate = this.navigate.findIndex((y) => y.text[1] === this.$route.params.id);
          // if (checknavigate === -1) {
          //   if (
          //     this.dataAccountActive["type"] === "Business" &&
          //     this.$route.params.id === this.dataAccountActive["business_info"]["my_folder_id"]
          //   ) {
          //     this.navigate.push({
          //       text: [
          //         this.$store.getters.dataTypeusercurrent(this.$store.state.account_active["type"], this.$t("default")),
          //         this.$route.params.id,
          //       ],
          //       disabled: false,
          //     });
          //   } else {
          //     this.navigate.push({ text: ["-", this.$route.params.id], disabled: false });
          //   }
          // } else {
          //   let datanavigate = this.navigate;
          //   let i;
          //   this.navigate = [];
          //   for (i = 0; i <= checknavigate; i++) {
          //     this.navigate.push(datanavigate[i]);
          //   }
          // }
          this.loaddataprogress = false;
          this.disablescrolling = true;
          // ตรวจสอบ  Navigation Bar
          if (cal_offset == 0) {
            this.fn_setNavigationbar(false, []);
          }
          Toast.fire({ icon: "error", title: this.$t("toast.cannotconnectonebox") });
        });
    },

    //ดึงข้อมูลเจ้าของไฟล์ที่ไม่ใช่ของฉัน
    async get_name_owner(listrootfile){
      console.log("listrootfile",listrootfile);
      let payload = {
        data_id : listrootfile.file_id ,
        data_type : listrootfile.file_type === 'folder' ? 'folder' : 'file',
        type_search : "creator"
      }
      console.log("payload",payload);
      let auth = await gbfGenerate.generateToken();
      console.log("auth",auth);
      console.log("auth.code",auth.code);
      await this.axios
        .post(process.env.VUE_APP_SERVICE_SELECT_FILE + "/api/v1/get/data/creator_name", payload, {
          headers: { Authorization: auth.code },
        })
        .then((response) => {
          if (response.data.status === "OK") {
            listrootfile.file_owner_eng = response.data.name.name_eng
            listrootfile.file_owner_th = response.data.name.name_th
          } 
        })
        .catch((error) => {
          Toast.fire({
            icon: "error",
            title:"ไม่สามารถเรียกไฟล์ได้ในขณะนี้",
          });
        });
    },

    // ดึงข้อมูลโฟลเดอร์และไฟล์ ในกรณีที่ต้องการดึงใหม่ตั้งแต่ต้น ใช้สำหรับให้ child component เรียกข้อมูล
    async fn_loadfileandfolder_scrolling_child(_id, options={}) {
      this.isAdvancedSearch = false
      this.disablescrolling_advancedsearch = true;
      this.disablescrolling_smartsearch = true;
      this.show_menumanage = false
      this.isCheckbox_sep_header = false
      this.filemultipledownload = []
      this.status_animation = ""
      this.close_header = true
      localStorage.setItem("scrollHistory", 0);
      console.log("jjpp", this.default_folder_id);
      this.disablescrollfilter = true;
      this.id_default = _id;
      this.heardertextsearch = false;
      this.smartuplloadsearch = false;
      this.loaddataprogress = true;
      this.disablescrolling = true;
      this.rootfile = [];

      this.page = 1;
      // this.size = 30;
      let {
        cal_limit = this.size,
        cal_offset = this.rootfile.filter((item) => { 
          return item.file_status !== 'M'
        }).length,
      } = options
      // let cal_offset = this.size * (this.page++ - 1);

      let payload;
      this.filemultipledownload = [];
      this.checkedList = [];
      // console.log(cal_offset)
      if (cal_offset == 0) {
        this.rootfile = [];
        this.buff = [];
      }

      if (this.dataAccountActive["type"] === "Business") {
        if (cal_offset == 0) {
          let checkPersonalBusiness = this.navigate.findIndex(
            (r) => r.text[1] === this.dataAccountActive["business_info"]["my_folder_id"] 
          );
          if (this.$route.params.id === this.dataAccountActive["business_info"]["my_folder_id"]) {
            // get Department folder
            for (var a = 0; a < this.dataAccountActive["directory_business"].length; a++) {
              let datafolder = {};
              datafolder["file_id"] = this.dataAccountActive["directory_business"][a]["department_folder_id"];
              datafolder["file_name"] = this.dataAccountActive["directory_business"][a]["department_name"];
              datafolder["file_size"] = "-";
              datafolder["file_department"] = this.dataAccountActive["directory_business"][a]["department_id"];
              datafolder["file_lastdtm"] = "-";
              datafolder["file_owner_eng"] = "-";
              datafolder["file_owner_th"] = "-";
              datafolder["file_status"] = "M";
              datafolder["file_type"] = "folder";
              datafolder["file_icon"] = ["mdi-folder-heart", "green"];
              datafolder["status_updating"] = "Done";
              datafolder["editing_status"] = "N";
              datafolder["last_update_name"] = "-";
              datafolder["file_status_sharelink"] = "N";
              datafolder["onlyofficeeditsecces"] = false;
              this.rootfile.push(datafolder);
              this.buff.push(datafolder);
              this.filestatus = datafolder["file_status"];
            }
            console.log("dy");
            payload = {
              user_id: this.dataUsername,
              account_id: this.dataAccountId,
              folder_id: this.$route.params.id,
              status_file: "",
              status_folder: "",
              limit: cal_limit,
              offset: cal_offset,
              sort_by: this.sort_by,
              direction: this.direction
            };
          } else if (checkPersonalBusiness >= 0) {
            console.log("dt", this.$route);
            payload = {
              user_id: this.dataUsername,
              account_id: this.dataAccountId,
              folder_id: this.$route.params.id,
              status_file: "",
              status_folder: "",
              limit: cal_limit,
              offset: cal_offset,
              sort_by: this.sort_by,
              direction: this.direction
            };
          } else if (_id) {
            //console.log("jjjjjjjjjj",this.default_folder_id);
            for (var a = 0; a < this.dataAccountActive["directory_business"].length; a++) {
              let datafolder = {};
              datafolder["file_id"] = this.dataAccountActive["directory_business"][a]["department_folder_id"];
              datafolder["file_name"] = this.dataAccountActive["directory_business"][a]["department_name"];
              datafolder["file_size"] = "-";
              datafolder["file_department"] = this.dataAccountActive["directory_business"][a]["department_id"];
              datafolder["file_lastdtm"] = "-";
              datafolder["file_owner_eng"] = "-";
              datafolder["file_owner_th"] = "-";
              datafolder["file_status"] = "M";
              datafolder["file_type"] = "folder";
              datafolder["file_icon"] = ["mdi-folder-heart", "green"];
              datafolder["status_updating"] = "Done";
              datafolder["editing_status"] = "N";
              datafolder["last_update_name"] = "-";
              datafolder["file_status_sharelink"] = "N";
              datafolder["onlyofficeeditsecces"] = false;
              this.rootfile.push(datafolder);
              this.buff.push(datafolder);
              this.filestatus = datafolder["file_status"];
            }
            // console.log("dr");
            payload = {
              user_id: this.dataUsername,
              account_id: this.dataAccountId,
              folder_id: _id,
              status_file: "",
              status_folder: "",
              limit: cal_limit,
              offset: cal_offset,
              sort_by: this.sort_by,
              direction: this.direction
            };
          } else {
            //console.log("di");
            payload = {
              user_id: this.dataUsername,
              account_id: this.dataAccountId,
              folder_id: this.$route.params.id,
              status_file: "",
              status_folder: "",
              limit: cal_limit,
              offset: cal_offset,
              sort_by: this.sort_by,
              direction: this.direction
            };
          }
        } else {
          //console.log("de");
          payload = {
            user_id: this.dataUsername,
            account_id: this.dataAccountId,
            folder_id: this.$route.params.id,
            status_file: "",
            status_folder: "",
            limit: cal_limit,
            offset: cal_offset,
            sort_by: this.sort_by,
            direction: this.direction
          };
        }
      } else {
        //console.log("dd");
        payload = {
          user_id: this.dataUsername,
          account_id: this.dataAccountId,
          folder_id: this.$route.params.id,
          status_file: "",
          status_folder: "",
          limit: cal_limit,
          offset: cal_offset,
          sort_by: this.sort_by,
          direction: this.direction
        };
      }
      //console.log("/api/select_files_and_folder",this.dataAccountActive["business_info"]["my_folder_id"]);
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/select_files_and_folder", payload, {
          headers: { Authorization: auth.code },
        })
        .then(async (response) => {
          this.loaddataprogress = false;
          // ตรวจสอบ  Navigation Bar
          // let checknavigate = this.navigate.findIndex((y) => y.text[1] === this.$route.params.id);
          // if (checknavigate === -1) {
          //   if (
          //     this.dataAccountActive["type"] === "Business" &&
          //     response.data.your_folder[0]["id"] === this.dataAccountActive["business_info"]["my_folder_id"]
          //   ) {
          //     this.navigate.push({
          //       text: [
          //         this.$store.getters.dataTypeusercurrent(this.$store.state.account_active["type"], this.$t("default")),
          //         response.data.status === "OK" ? response.data.your_folder[0]["id"] : this.$route.params.id,
          //       ],
          //       disabled: false,
          //     });
          //   } else {
          //     this.navigate.push({
          //       text: [
          //         response.data.status === "OK" ? response.data.your_folder[0]["name"] : "-",
          //         response.data.status === "OK" ? response.data.your_folder[0]["id"] : this.$route.params.id,
          //       ],
          //       disabled: false,
          //     });
          //   }
          // } else {
          //   let datanavigate = this.navigate;
          //   let i;
          //   this.navigate = [];
          //   for (i = 0; i <= checknavigate; i++) {
          //     this.navigate.push(datanavigate[i]);
          //   }
          // }

          if (response.data.status === "OK") {
            this.fn_checkevaluate();
            // ตรวจสอบ  Navigation Bar
            if (cal_offset == 0) {
              this.fn_setNavigationbar(true, response.data.your_folder[0], this.$route.params.id);
            }
            // ตรวจสอบ  lock Folder
            if (cal_offset == 0 && response.data.your_folder[0]["status_lock_folder"] === "Y") {
              this.opendialog_checklog = true;
              let folderdata = {};
              folderdata["file_id"] = response.data.your_folder[0]["id"];
              folderdata["file_name"] = response.data.your_folder[0]["name"];
              // folderdata["file_type"] = response.data.your_folder[0]["data_type"];
              // folderdata["full_path_id"] = response.data.your_folder[0]["full_path_id"];
              this.newdata = folderdata;
              //console.log(folderdata);
            }

            this.foldername = response.data.your_folder[0]["name"];
            this.folderid = response.data.your_folder[0]["id"];
            this.system = response.data.your_folder[0]["system"];
            this.status_aspect_key = response.data.your_folder[0]["status_aspect_key"];
            this.folderid_yourfolder = response.data.your_folder[0]["id"];

            // ตรวจสอบ system และ status_aspect_key เพื่อเปลี่บนเป็น table ui
            if (response.data.your_folder[0]["system"] !== null && response.data.your_folder[0]["status_aspect_key"] === "Y") {
              localStorage.setItem("changeStoreUI", "false");
              localStorage.setItem("changeUI", "false");
              this.changeUI = false;
              this.getdoctype();
            }

            // File and Folder
            for (let i = 0; i < response.data.data.length; i++) {
              let dataFileAndFolder = {};
              let typefile = response.data.data[i].data_type === "folder" ? "folder" : response.data.data[i].name.split(".");
              let type = response.data.data[i].data_type;
              let yourfolder_path = response.data.your_folder[0].full_path_id.split("/");

              dataFileAndFolder["account_reciever"] = "";
              dataFileAndFolder["account_sender"] = this.dataAccountId;
              dataFileAndFolder["account_id"] = response.data.data[i].account_id;
              dataFileAndFolder["countnum"] = response.data.data[i].countnum;
              dataFileAndFolder["file_createdtm"] = response.data.data[i].cre_dtm;
              dataFileAndFolder["folder_id"] = response.data.data[i].folder_id;
              dataFileAndFolder["file_id"] = response.data.data[i].id;
              dataFileAndFolder["file_lastdtm"] = response.data.data[i].last_dtm;
              dataFileAndFolder["file_linkshare"] = response.data.data[i].link;
              dataFileAndFolder["file_name"] = response.data.data[i].name;
              dataFileAndFolder["file_owner_eng"] = response.data.data[i].name_eng;
              dataFileAndFolder["file_owner_th"] = response.data.data[i].name_th;
              dataFileAndFolder["priority"] = response.data.data[i].priority;
              dataFileAndFolder["file_size"] = response.data.data[i].size;
              dataFileAndFolder["file_status"] = response.data.data[i].status;
              dataFileAndFolder["file_status_share"] = response.data.data[i].status_share;
              dataFileAndFolder["file_status_sharelink"] = response.data.data[i].status_share_link;
              dataFileAndFolder["system"] = response.data.data[i].system;
              dataFileAndFolder["type"] = response.data.data[i].data_type;
              // dataFileAndFolder["file_icon"] = gb_setIconFiles.iconExtensionFilesFolder_new(type, typefile[typefile.length - 1]);
              dataFileAndFolder["file_icon"] = this.fn_setThumbnailIcon(typefile[typefile.length - 1]);
              dataFileAndFolder["file_type"] = type === "folder" ? "folder" : typefile[typefile.length - 1];
              dataFileAndFolder["data_type"] = typefile[typefile.length - 1];
              dataFileAndFolder["user_id"] = response.data.data[i].user_id;
              dataFileAndFolder["starred_id"] = response.data.data[i].starred_id;

              dataFileAndFolder["file_parent_folder_id"] = response.data.data[i]["folder_id"];
              // dataFileAndFolder["file_permission"] = (response.data.data[i]["permission_setting"] === "True" || response.data.data[i]["permission_business_setting"] === "True") ? response.data.data[i]["permission_account"] : response.data.your_folder[0]["permission_account"];
              // dataFileAndFolder["file_permission_department_setting"] =(response.data.data[i]["permission_setting"] === "True" || response.data.data[i]["permission_business_setting"] === "True") ? response.data.data[i]["permission_department_setting"]  : response.data.your_folder[0]["permission_department_setting"];
              // dataFileAndFolder["file_permission_setting"] = (response.data.data[i]["permission_setting"] === "True" || response.data.data[i]["permission_business_setting"] === "True") ? response.data.data[i]["permission_setting"]  : response.data.your_folder[0]["permission_setting"];
              dataFileAndFolder["file_permission"] =
                this.dataAccountActive["type"] === "Business"
                  ? yourfolder_path[0] === this.dataAccountActive.directory_id
                    ? { admin_data: "False", delete: "True", download: "True", edit: "True", upload: "True", view_only: "True" }
                    : response.data.data[i].permission_account
                  : response.data.data[i].permission_account;
              dataFileAndFolder["file_permission_department_setting"] = response.data.data[i]["permission_department_setting"];
              dataFileAndFolder["permission_department_setting"] = response.data.data[i].permission_department_setting;
              dataFileAndFolder["permission_business_setting"] = response.data.data[i].permission_business_setting;
              dataFileAndFolder["permission_setting"] = response.data.data[i].permission_setting;
              dataFileAndFolder["file_permission_setting"] = response.data.data[i]["permission_setting"];
              dataFileAndFolder["permission_role_setting"] = response.data.data[i].permission_role_setting;
              dataFileAndFolder["file_department"] = "";
              dataFileAndFolder["relation_id"] = response.data.data[i]["relation_id"];
              dataFileAndFolder["relation_name"] = response.data.data[i]["relation_name"];
              dataFileAndFolder["relation_status"] = response.data.data[i]["relation_status"];
              dataFileAndFolder["file_permission_2"] = response.data.data[i]["permission_account_v2"];
              dataFileAndFolder["status_updating"] = response.data.data[i]["status_updating"];
              dataFileAndFolder["status_lock_folder"] = response.data.data[i]["status_lock_folder"];
              dataFileAndFolder["status_lock_file"] = response.data.data[i]["status_lock_file"];
              dataFileAndFolder["status_lock_data"] = response.data.data[i]["status_lock_data"];
              dataFileAndFolder["status_expired"] = response.data.data[i]["status_expired"];
              dataFileAndFolder["expiration_time"] = response.data.data[i]["expiration_time"];
              dataFileAndFolder["version_tag"] = response.data.data[i]["version_tag"];
              dataFileAndFolder["tag_id"] = response.data.data[i]["tag_id"];
              dataFileAndFolder["hashtag"] = response.data.data[i]["hashtag"];
              dataFileAndFolder["file_thumbnail"] = "";
              dataFileAndFolder["file_thumbnail_icon"] = this.fn_setThumbnailIcon(typefile[typefile.length - 1]);
              dataFileAndFolder["editing_status"] = response.data.data[i]["editing_status"];
              dataFileAndFolder["update_by"] = response.data.data[i]["update_by"];
              dataFileAndFolder["last_update_name"] = response.data.data[i]["last_update_name"];
              dataFileAndFolder["warning_expiration_time"] = response.data.data[i]["warning_expiration_time"];
              dataFileAndFolder["status_encrypt"] = response.data.data[i]["status_encrypt"];
              dataFileAndFolder["last_view"] = response.data.data[i].last_view;

              if (
                (dataFileAndFolder["file_permission_setting"] === "True" ||
                  dataFileAndFolder["file_permission_department_setting"] === "True" ||
                  dataFileAndFolder["permission_business_setting"] === "True") &&
                dataFileAndFolder["file_permission"]["view_only"] === "False" &&
                dataFileAndFolder["file_permission"]["edit"] === "False" &&
                dataFileAndFolder["file_permission"]["download"] === "False"
              ) {
                continue;
              } else {
                if (this.dataAccountActive["type"] === "Business") {
                  if (("dataFileAndFolder", dataFileAndFolder["file_permission"].view_only === "True")) {
                    this.rootfile.push(dataFileAndFolder);
                  }
                } else {
                  this.rootfile.push(dataFileAndFolder);
                }
                this.buff.push(dataFileAndFolder);
              }
              this.fn_loadthumbnail(response.data.data[i].id, typefile[typefile.length - 1], response.data.data[i].size, response.data.data[i].status_lock_data);

              // this.rootfile.push(dataFileAndFolder);
              //               this.rootfile.push(datafolder);
              // this.buff.push(datafolder);
            }
            this.sortCreDtmNewUpload()

            this.totaldata = {
              totalall: response.data.count,
              totalfile: response.data.count_file,
              totalfolder: response.data.count_folder,
            };
            this.permission_currentfolder = response.data.your_folder[0]["permission_account"];
            this.permission_currentfolder_v2 = response.data.your_folder[0]["permission_account_v2"];
            this.aspectData = [
              { aspect: response.data.your_folder[0]["aspect"], aspect_key: response.data.your_folder[0]["aspect_key"] },
            ];
            this.disablescrolling = this.totaldata["totalall"] <= this.rootfile.length;
            this.departmentid = response.data.your_folder[0]["department_id"];
            this.taxbox_status = response.data.your_folder[0]["taxbox_status"];
            console.log("this.departmentid", this.departmentid);
            // console.log("Newrootfile", this.newrootfile);
            this.scrollHistory();
            if(VueCookies.get("check_alert_email") === "N"){
              this.opendialogalertstoragefull = true;
            }

            for(let y = 0; y < this.rootfile.length; y++){
              if(this.rootfile[y].file_owner_th === '' || this.rootfile[y].file_owner_eng === ''){
                await this.get_name_owner(this.rootfile[y])
              }
            }
          } else {
            this.disablescrolling = true;
            if (cal_offset == 0) {
              this.fn_setNavigationbar(false, []);
            }
          }
        })
        .catch((error) => {
          // ตรวจสอบ  Navigation Bar
          if (cal_offset == 0) {
            this.fn_setNavigationbar(false, []);
          }
          // let checknavigate = this.navigate.findIndex((y) => y.text[1] === this.$route.params.id);
          // if (checknavigate === -1) {
          //   if (
          //     this.dataAccountActive["type"] === "Business" &&
          //     this.$route.params.id === this.dataAccountActive["business_info"]["my_folder_id"]
          //   ) {
          //     this.navigate.push({
          //       text: [
          //         this.$store.getters.dataTypeusercurrent(this.$store.state.account_active["type"], this.$t("default")),
          //         this.$route.params.id,
          //       ],
          //       disabled: false,
          //     });
          //   } else {
          //     this.navigate.push({ text: ["-", this.$route.params.id], disabled: false });
          //   }
          // } else {
          //   let datanavigate = this.navigate;
          //   let i;
          //   this.navigate = [];
          //   for (i = 0; i <= checknavigate; i++) {
          //     this.navigate.push(datanavigate[i]);
          //   }
          // }
          console.log(error);
          this.loaddataprogress = false;
          this.disablescrolling = true;
          Toast.fire({ icon: "error", title: this.$t("toast.cannotconnectonebox") });
        });
    },

    async fn_check_transactiontaxbox_duplicate(){
        VueCookies.set('taxbox',false)
        console.log("เข้า fn_PutTransactiontaxbox");
        this.processloader_Sync = true;
        let user_id = CryptoJS.AES.decrypt(VueCookies.get("accountid"), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, });
        user_id = window.atob(user_id.toString(CryptoJS.enc.Utf8))
        let payload = {
          oneid_user_id: user_id
        };
        console.log("payload", payload);
        let auth = await gbfGenerate.generateToken();
        this.axios
          .post(
            process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/taxbox/check_duplicate_transaction", payload, {
              headers: { Authorization: auth.code } 
            }
          )
          .then((response) => {
            console.log("response", response);
            this.rootfile = [];
            if (response.data.status === "OK") {
              console.log("response", response.data);
              if(response.data.data.length == 0){
                this.processloader_Sync = false;
                this.fn_loadfileandfolder_scrolling_child();
              }else{
                console.log("วนยิง");
                this.fn_PutTransactiontaxbox(response.data.data);
              }
              
            } else {
              this.processloader_Sync = false;
              Toast.fire({
                icon: "error",
                title: response.data.errorMessage,
              });
            }
          })
          .catch((error) => {
            this.processloader_Sync = false;
            console.log('err', error);
            Toast.fire({
              icon: "error",
              title: error.response.data.errorMessage,
            });
          });

    },

    async fn_PutTransactiontaxbox(list){
      console.log(list);
      for (let i = 0; i < list.length; i++) {
        await this.loopPutTransaction(list[i].process_id, list[i].seller_tax_id,list[i].process_dtm, list[i].document_id,list).then(
          async (res) => {
            if (res.status === "success") {
              console.log("yess");
              if (list.length - i === 1) {
                this.processloader_Sync = false;
                this.fn_loadfileandfolder_scrolling_child();
              }
            } else {
              this.processloader_Sync = false;
              console.log("nooo");
              Toast.fire({
                icon: "error",
                title: response.data.errorMessage,
              });
            }
          }
        );
      }
    },
    async loopPutTransaction(process_id,seller_tax_id,process_dtm,document_id){
      let user_id = CryptoJS.AES.decrypt(VueCookies.get("accountid"), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, });
      user_id = window.atob(user_id.toString(CryptoJS.enc.Utf8))
        let payload = {
        oneid_user_id: user_id,
        process_id : process_id,
        seller_tax_id : seller_tax_id,
        document_id: document_id,
        process_dtm: process_dtm
      };
      let auth = await gbfGenerate.generateToken();
        let response = await this.axios({
          method: "POST",
          url:
            process.env.VUE_APP_SERVICE_DOWNLOAD_API + "api/taxbox/PutTransaction/PerProcessId",
          data: payload,
          headers: { Authorization: auth.code },
        });
        return new Promise((resolve, reject) => {
          try {
            if (response.data.status === "OK") {
              setTimeout(() => {
                resolve({ status: "success", msg: "OK", code: "" });
              }, 500);
            } else {
              Toast.fire({
                icon: "error",
                title: response.data.errorMessage,
              });
              setTimeout(() => {
                resolve({ status: "error", msg: "OK", code: "" });
              }, 500);
            }
          } catch (err) {
            setTimeout(() => {
              resolve({ status: "denied", msg: ": " + err, code: "" });
            }, 500);
          }
        });
    },

    // async fn_PutTransactiontaxbox(){
    //     // VueCookies.set('taxbox',false)
    //     console.log("เข้า fn_PutTransactiontaxbox");
    //     this.processloader_Sync = true;
    //    let user_id = CryptoJS.AES.decrypt(VueCookies.get("accountid"), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, });
    //    user_id = window.atob(user_id.toString(CryptoJS.enc.Utf8))
    //     let payload = {
    //       oneid_user_id: user_id
    //     };
    //     console.log("payload", payload);
    //     let auth = await gbfGenerate.generateToken();
    //     this.axios
    //       .post(
    //         process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/taxbox/PutTrasncation", payload, {
    //           headers: { Authorization: auth.code } 
    //         }
    //       )
    //       .then((response) => {
    //         console.log("response", response);
    //         this.rootfile = [];
    //         if (response.data.status === "OK") {
    //           this.processloader_Sync = false;
    //           this.fn_loadfileandfolder_scrolling_child();
    //         } else {
    //           this.processloader_Sync = false;
    //           Toast.fire({
    //             icon: "error",
    //             title: response.data.errorMessage,
    //           });
    //         }
    //       })
    //       .catch((error) => {
    //         this.processloader_Sync = false;
    //         console.log('err', error);
    //         Toast.fire({
    //           icon: "error",
    //           title: error.response.data.errorMessage,
    //         });
    //       });

    // },


    //ตั้ง Navigation Bar
    fn_setNavigationbar(status, _yourfolder, folder) {
      //เก็บค่าในกรณีที่ยิง API FAIL
      let pre_navigate = this.navigate;
      this.navigate = [];

      //กรณี Success
      if (status === true) {
        // กำหนด Navigation My Drive
        let splitPath = _yourfolder["full_path_id"].split("/");
        let splitPathName = _yourfolder["full_path"].split("/");
        let folder_id = folder || "";
        //  console.log("name",this.dataAccountActive.business_info["my_folder_id"],folder_id,this.dataAccountActive.directory_business);
        //  console.log(objIndex,this.dataAccountActive["directory_business"][objIndex]);

        // ตรวจสอบว่าเป็น Account Business หรือไม่
        if (this.dataAccountActive.type === "Business") {
          let objIndex = this.dataAccountActive.directory_business.findIndex((id) => id.department_folder_id === folder_id);
          // console.log(objIndex,this.dataAccountActive["directory_business"][objIndex]);
          //ตรวจสอบว่าเป็นโฟลเดอร์แผนก
          if (objIndex !== -1) {
            this.check_folder_department = true;
          }
          // ตรวจสอบว่าอยู่ในภายใต้ Folder ใหญ่ของบริษัท
          if (
            this.dataAccountActive.business_info["my_folder_id"] === splitPath[0] ||
            objIndex !== -1 ||
            this.check_folder_department === true
          ) {
            // console.log("dddd",this.dataAccountActive.business_info["my_folder_id"],splitPath[0]);
            // อยู่ที่ Personal Business
          } else {
            this.navigate.push({ text: ["My drive", this.dataAccountActive["directory_id"], "ไดร์ฟของฉัน"], disabled: false });
          }

          // เข้า else เป็น Account Citizen
        } else {
          this.navigate.push({
            text: ["My drive", this.dataAccountActive["directory_id"], "ไดร์ฟของฉัน"],
            disabled: false,
          });
        }

        // ตรวจสอบว่าเข้าในนาม Business
        if (this.dataAccountActive.type === "Business") {
          // ตรวจสอบว่าเข้าไปโฟลเดอร์แผนก
          let indexDepartment = this.dataAccountActive.directory_business.findIndex(
            (r) => r.department_folder_id === splitPath[0]
          );
          //console.log("navigate_2",this.navigate);
          if (indexDepartment >= 0) {
            console.log(
              "ดดด",
              this.$store.getters.dataTypeusercurrent(this.$store.state.account_active["type"], this.$t("default"))
            );
            this.navigate.push({
              text: [
                this.$store.getters.dataTypeusercurrent(this.$store.state.account_active["type"], this.$t("en")),
                this.dataAccountActive["business_info"]["my_folder_id"],
                this.$store.getters.dataTypeusercurrent(this.$store.state.account_active["type"], this.$t("th"))
              ],
              disabled: false,
            });
            this.navigate.push({
              text: [
                this.dataAccountActive.directory_business[indexDepartment]["department_name"],
                this.dataAccountActive.directory_business[indexDepartment]["department_folder_id"],
              ],
              disabled: false,
            });
            console.log("navigate_1", this.navigate);
            // ตรวจสอบว่าเข้าไปโฟลเดอร์บริษัท
          } else if (splitPath[0] === this.dataAccountActive["business_info"]["my_folder_id"]) {
            this.navigate.push({
              text: [
                this.$store.getters.dataTypeusercurrent(this.$store.state.account_active["type"], this.$t("en")),
                this.dataAccountActive["business_info"]["my_folder_id"],
                this.$store.getters.dataTypeusercurrent(this.$store.state.account_active["type"], this.$t("th"))
              ],
              disabled: false,
            });
            console.log("navigate_3", this.navigate);
          }
        }

        for (let i = 1; i < splitPath.length; i++) {
          this.navigate.push({
            text: [splitPathName[i], splitPath[i]],
            disabled: false,
          });
        }

        //กรณี Fail
      } else {
        if (pre_navigate.length === 0) {
          // กำหนด Navigation My Drive
          this.navigate.push({
            text: ["My drive", this.dataAccountActive["directory_id"], "ไดร์ฟของฉัน"],
            disabled: false,
          });

          // กำหนด Directory ล่าสุด
          this.navigate.push({ text: ["-", this.$route.params.id], disabled: false });
        } else {
          this.navigate = pre_navigate;
          // กำหนด Directory ล่าสุด
          this.navigate.push({ text: ["-", this.$route.params.id], disabled: false });
        }
      }
    },
    addEventcopypaste() {
      window.addEventListener("keypress", this.fn_checkkeyevent, true);
    },
    removeEventcopypaste() {
      window.removeEventListener("keypress", this.fn_checkkeyevent, true);
    },
    onScroll(e) {
      this.scrollY = e.target.scrollTop;
      if (this.scrollY !== 0)
        localStorage.setItem("scrollHistory", this.scrollY);   
    },
    animatemenumanage (valueScroll, from, to) {
      if(this.statusmutipledownload === true){
        if(valueScroll > from){
          this.show_menumanage = true
          this.close_header = false
          
          // //Cloning 
          // let headerTable = document.getElementsByClassName('v-data-table-header');
          // let clonedMenu = headerTable[0].cloneNode(true);
          // clonedMenu.id = 'cloned_header';
          // document.getElementsByClassName('cloningHeader')[0].appendChild(clonedMenu);
          // ///////
          this.status_animation = "slideUp"
        } else if ( valueScroll < to) {
          this.show_menumanage = false
          this.close_header = true
        }
      }
    },
    scrollHistory() {
      const element = this.$el.querySelector(`#${this.container_directory}`);
      // const element = this.$el.querySelector("#thiscontainer_directoryfile");
      const desiredScrollPosition = localStorage.getItem("scrollHistory");
      const scrollInterval = setInterval(() => {
        const scrollAmount = Math.min(800, desiredScrollPosition - element.scrollTop);
        if (scrollAmount <= 0) {
          clearInterval(scrollInterval);
          return;
        }
        element.scrollTop += scrollAmount;
        if (element.scrollTop >= desiredScrollPosition) {
          clearInterval(scrollInterval);
        }
      }, 100);

      // setTimeout(() => {
      //   element.scrollTop = localStorage.getItem("scrollHistory");
      // }, 1000);
      // console.log("scrollHistory", localStorage.getItem("scrollHistory"));
    },
    selectCardBgColor(item) {
      return this.selectColor(item.file_id, 'card') ? 'opacity: 0.3' : 'opacity: unset'
    },
    fn_close_all_dialog(){
      this.opendialogmultidownload_new = false;
      this.opendialogalertstoragefull = false;
      this.opensnackbarloadingprint = false;
      this.opendialogeditdeletehashtag = false;
      this.opendialogcheckrememberlogin = false;
      this.opensdialogcheckdeleterightclick = false;
      this.opendialogcheckmultidownload = false;
      this.opensnackbarmultidownload = false;
      this.openchekmultidelete = false;
      this.opendialog_checklog = false;
      this.opendialogmultimove = false;
      this.openpreviewfile = false;
      this.openUploadfolder = false;
      this.openUploadfiles = false;
    },
    fn_reload(){
      this.pagee = 1,
      this.heardertextsearch = false;
      this.isAdvancedSearch = false;
    },
    fn_newsearch(){
      this.pagee = 1;
    },
    selectItemTable(file_id) {
      return this.selectColor(file_id, "table")
        ? "background-color:#D6EAF8;"
        : "opacity: unset;";
    },
    setSelectitemtable(file) {
      if (file.file_id) {
        if (this.statusmutipledownload === false) {
          this.filemultipledownload = [];
          if (file.status_lock_data === "N") {
            this.filemultipledownload = [file]
          }
        }
      }
    },
    fn_checkevaluate(){
      let one_id = CryptoJS.AES.decrypt(VueCookies.get("accountid"), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, });
      one_id = window.atob(one_id.toString(CryptoJS.enc.Utf8))

      let payload = {
        one_id: one_id
      };
      console.log("payload", payload);
      this.axios
        .post(
          process.env.VUE_APP_SERVICE_AUTHORIZE_API + 
          "/api/get_user_evaluate",
          payload,
          // {
          //   headers: { Authorization: auth.code },
          // }
        )
        .then((response) => {
          console.log("response",response);
          if (response.data.status === "OK") {
            this.statusevaluation = response.data.data.status_evaluate
            this.eva_expired = response.data.data.evaluate_expired
             if(this.statusevaluation === "N"){
              if(this.eva_expired === "Y"){
                this.opendialogalertevaluation = true;
                // this.opendialog_eva = true
                this.icon_eva = false
              }
             }else if(this.statusevaluation === "W"){
              if(this.eva_expired === "N"){
                this.opendialogalertevaluation = false;
                // this.opendialog_eva = false
                this.icon_eva = true
              }else if(this.eva_expired === "Y"){
                this.opendialogalertevaluation = true;
                // this.opendialog_eva = true
                this.icon_eva = false
              }
             }else if(this.statusevaluation === "Y"){
              if(this.eva_expired === "N"){
                this.opendialogalertevaluation = false;
                // this.opendialog_eva = false
                this.icon_eva = false
              }else if(this.eva_expired === "Y"){
                this.opendialogalertevaluation = true;
                // this.opendialog_eva = true
                this.icon_eva = false
              }
             }
            //  console.log("btn myfile",this.icon_eva);
             VueCookies.set("iconeva",this.icon_eva)
          } else {
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          }
        });
    }
  },
  mounted() {
    this.fn_checkevaluate();
    //this.fn_loadfileandfolder_scrolling_child();
    console.log("this.taxbox_status",this.taxbox_status);
    // VueCookies.set('taxbox', true);
    if(VueCookies.get("taxbox") === "true"){
        console.log("taxbox ยิง api  etax");
        this.fn_check_transactiontaxbox_duplicate();
      }

    if (localStorage.getItem("changeStoreUI") === "true") {
      this.size = 30;
    } else {
      if (localStorage.getItem("limitLoadFileFolder")) {
        this.size = parseInt(localStorage.getItem("limitLoadFileFolder"));
      }
    }

      // else{
      //   this.fn_loadfileandfolder_scrolling_child();
      //   console.log("taxbox ยิง api  etax falseeeeee");
      // }
    console.log("==",this.$router);
    if(VueCookies.get("check_alert_email") === "N"){
      this.opendialogalertstoragefull = true;
    }

    // if(VueCookies.get("check_btn_login_new") === null){
    //   if(VueCookies.get('checklogin') == 'false' || VueCookies.get('checklogin') === null){
    //     if (sessionStorage.getItem('checklogin') === null) {
    //       this.opendialogcheckrememberlogin = true;
    //     }
    //   }
    // }


    var self = this;
    self.removeEvent();
    self.addEvent();

    // self.removeEventcopypaste();
    // self.addEventcopypaste();
    self.loadstorage();
    // console.log("testmounted", this.$route.params.id);
    if (this.dataDepartmentAccessId === "" && this.dataAccountActive["type"] === "Business") {
      let a = this.dataAccountActive["directory_business"].findIndex((a) => a.department_folder_id === this.$route.params.id);
      // console.log("aaaaaaa_______" + a);
      if (a !== -1) {
        // console.log(
          //   this.dataAccountActive["directory_business"][a]["department_id"]
        // );
        this.$store.dispatch("department_access_id", this.dataAccountActive["directory_business"][a]["department_id"]);
      }
      // this.loadfolder();
    } else {
      // this.loadfolder();
    }

    this.navigate.push({
      text: ["My drive", this.dataAccountActive["directory_id"], "ไดร์ฟของฉัน"],
      disabled: false,
    });
    window.addEventListener('popstate', () => {
      // this.fn_loadfileandfolder_scrolling_child();
      this.fn_close_all_dialog();
    });
  },
  sockets: {
    connect() {
      console.log('Socket connected');
    },
    disconnect() {
      console.log('Socket disconnected');
    },
    update_event(data) {
      setTimeout(() => {
        if (this.$route.params.id === data.folder_id) {
          if (this.feature_onlyoffice === true) {
            let indexfile = this.rootfile.findIndex(item => item.file_id === data.file_id_old);
            if (indexfile != -1) {
              this.rootfile[indexfile].file_id = data.file_id_new
              this.rootfile[indexfile].onlyofficeeditsecces = true
              this.rootfile[indexfile].file_lastdtm = data.last_dtm
              this.rootfile[indexfile].file_size = data.size
              this.rootfile[indexfile].editing_status = "N"
            }
          }
        }
        // console.log('Received update_event:', data);
      }, 1000);
    },
    update_sync(data) {
      setTimeout(() => {
        if (this.$route.params.id === data.folder_id) {
          if (this.feature_onlyoffice === true) {
            let indexfile = this.rootfile.findIndex(item => item.file_id === data.file_id);
            if (indexfile != -1) {
              this.rootfile[indexfile].editing_status = "S"
            }
          }
        }
      }, 100);
    }
  },
 async created() {
    // this.myfolder = localStorage.getItem('my_folder_id')
    // this.myfolder = CryptoJS.AES.decrypt(localStorage.getItem('my_folder_id'), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, });
    // this.myfolder = window.atob(this.myfolder.toString(CryptoJS.enc.Utf8))
    // this.removeEventcopypaste();
    // this.addEventcopypaste();
    // window.addEventListener('keypress', this.fn_checkkeyevent);
  },

  beforeDestroy() {
    this.removeEvent();
    this.removeEventcopypaste();
    window.removeEventListener('popstate', () => {
      console.log("remove popstate");
    });
  },
};
</script>

<style scoped>
/* Add new   */
::v-deep .custom-table .v-data-table__empty-wrapper {
  display: none;
}
.custom-table{
  border-radius: 0px;
}
.dot {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  /* position: absolute; */
  display: flex;
  justify-content: center;
  align-items: center
}
.v-tooltip__content.menuable__content__active {
  opacity: 1 !important;
  border-radius: 10px;
  background-color: #6b6b6b;
}
.tri-right.left-top:after{
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
  left: -20px;
	right: auto;
  top: 0px;
	bottom: auto;
	border: 22px solid;
	border-color: #6b6b6b transparent transparent transparent;
}
.tri-right.right-top:after{
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
  left: auto;
	right: -20px;
  top: 0px;
	bottom: auto;
	border: 22px solid;
	border-color: #6b6b6b transparent transparent transparent;
  
}

.tri-right.btm-left:after{
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
	left: 0px;
  right: auto;
  top: auto;
	bottom: -20px;
	border: 22px solid;
	border-color: transparent transparent transparent #6b6b6b;
}
.avatars {
  display: inline-flex;
  flex-direction: row-reverse;
}

.avatar {
  position: relative;
  border: 1px solid #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 20px;
  height: 20px;
}

.avatar:not(:last-child) {
  margin-left: -10px;
}

.avatar img {
  width: 100%;
  display: block;
}
.animated-listmenu{
  transform: translateY(30px);
}
.slideUp{
	animation-name: slideUp;
	-webkit-animation-name: slideUp;	

	animation-duration: .5s;	
	-webkit-animation-duration: .5s;

	animation-timing-function: ease;	
	-webkit-animation-timing-function: ease;

	visibility: visible !important;			
}



@-webkit-keyframes slideUp {
	0% {
		-webkit-transform: translateY(100%);
	}
	/* 50%{
		-webkit-transform: translateY(-8%);
	}
	65%{
		-webkit-transform: translateY(4%);
	}
	80%{
		-webkit-transform: translateY(-4%);
	}
	95%{
		-webkit-transform: translateY(2%);
	}			 */
	100% {
		-webkit-transform: translateY(0%);
	}	
}

.slideDown{
	animation-name: slideDown;
	-webkit-animation-name: slideDown;	

	animation-duration: 1s;	
	-webkit-animation-duration: 1s;

	animation-timing-function: ease;	
	-webkit-animation-timing-function: ease;	

	visibility: visible !important;						
}

@-webkit-keyframes slideDown {
	0% {
		-webkit-transform: translateY(-100%);
	}
	50%{
		-webkit-transform: translateY(8%);
	}
	65%{
		-webkit-transform: translateY(-4%);
	}
	80%{
		-webkit-transform: translateY(4%);
	}
	95%{
		-webkit-transform: translateY(-2%);
	}			
	100% {
		-webkit-transform: translateY(0%);
	}	
}


/* ////////// */
.v-snack__content {
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
}
#demo {
  width: 100%;
  height: 100%;
}

div#textnode {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  transition: font-size 175ms;
}

div#dropzone {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: visibility 175ms, opacity 175ms;
  display: table;
  text-shadow: 1px 1px 2px #000;
  color: #fff;
  background: rgba(0, 0, 0, 0.45);
  font: bold 42px Oswald, DejaVu Sans, Tahoma, sans-serif;
}

#dropzone {
  min-height: 100%;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  #thiscontainer_directoryfile {
  height: calc(100vh - 180px);
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
  }
}
/* ของใหม่ๆ */
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  #thiscontainer_directoryfile {
  height: calc(100vh - 180px);
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  #thiscontainer_directoryfile {
  height: calc(100vh - 180px);
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
  }
  #thiscontainer_directoryfile_with_header {
  height: calc(100vh - (180px + 180px));
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  #thiscontainer_directoryfile {
  height: calc(100vh - 180px);
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
  }
  #thiscontainer_directoryfile_with_header {
  height: calc(100vh - (180px + 250px));
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  #thiscontainer_directoryfile {
  height: calc(100vh - 230px);
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
  }
  #thiscontainer_directoryfile_with_header {
  height: calc(100vh - (230px + 160px));
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
  }
}

.pointer {
  cursor: pointer;
}

.v-progress-circular {
  margin: 1rem;
}
#borderradius {
  border-radius: 15px;
}
#borderradius-img {
  border-radius: 15px 15px 0px 0px;
}
#borderradius-word {
  border-radius: 0px 0px 15px 15px;
}
#borderradius_btn {
  border-radius: 5px;
}
div#borderradius-img {
  height: 145px;
}
.drag-selector-wrapper{
  cursor: default;
}
span.name-location {
  text-overflow: ellipsis; 
  overflow: hidden; 
  white-space: nowrap; 
  width: 100px; 
  text-align: left;
}
span.path-name-location {
  text-overflow: ellipsis; 
  overflow: hidden; 
  white-space: nowrap; 
  width: 100%;
  max-width: 150px;
  text-align: left;
}
.breadcrumbs-location::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.menu-content {
  right: 9% !important;
  left: auto !important;
  margin-top: -8px;
}
.tooltip-content {
  height: 25px;
  display: flex;
  align-items: center;
}
</style>
